import { useParams } from 'react-router-dom';
import UserAccountForm from './components/UserAccountForm';
import useUserAccount from './hooks/useUserAccount';

const UserAccount = () => {
  const {
    userAccount,
    isLoading,
    submit,
    requestDeleteUserAccount,
    requestPasswordReset,
  } = useUserAccount();
  const { id } = useParams();

  if (!userAccount && id) {
    return null;
  }

  return (
    <UserAccountForm
      id={id}
      isLoading={isLoading}
      userAccount={userAccount}
      onSubmit={submit}
      onDelete={requestDeleteUserAccount}
      onPasswordReset={requestPasswordReset}
    />
  );
};

export default UserAccount;
