import { useState } from 'react';
import { t } from 'i18next';

const useFormModal = () => {
  const [modalProps, setModalProps] = useState<any>({
    isOpen: false,
    fields: [],
    initialValues: {},
    onSubmit: () => true,
    onClose: () => true,
  });

  const openPriceModal = ({ initialValues, onSubmit }: any) => {
    setModalProps({
      isOpen: true,
      title: t('editPriceModalTitle'),
      fields: [
        {
          name: 'price',
          type: 'text',
          rules: ['required', 'number', 'greaterThanZero'],
        },
      ],
      initialValues,
      onSubmit,
      onClose,
    });
  };
  const openQuickSaleModal = ({ initialValues, onSubmit }: any) => {
    const baseRules = ['required', 'number', 'greaterThanZero'];
    setModalProps({
      title: t('quickSaleModalTitle'),
      isOpen: true,
      initialValues,
      fields: [
        {
          label: 'Valor del artículo',
          name: 'price',
          type: 'text',
          rules: baseRules,
        },
        {
          label: 'Cantidad',
          name: 'quantity',
          type: 'text',
          rules: baseRules.concat('integer'),
        },
        {
          label: 'Descripción',
          name: 'description',
          type: 'text',
        },
      ],
      confirmButtonText: t('cart.add'),
      onSubmit,
      onClose,
    });
  };

  const openQuantityModal = ({
    initialValues,
    format,
    onSubmit,
    renderFooter,
  }: any) => {
    const baseRules = ['required', 'number', 'greaterThanZero'];

    setModalProps({
      title: t('editQuantityModalTitle'),
      isOpen: true,
      fields: [
        {
          name: 'quantity',
          type: 'text',
          rules: format === 'unit' ? baseRules.concat('integer') : baseRules,
        },
      ],
      initialValues,
      onSubmit,
      onClose,
      renderFooter,
    });
  };

  const onClose = () => {
    setModalProps((state: any) => ({ ...state, isOpen: false }));
  };
  const openNoteModal = ({ initialValues, onSubmit, onReset }: any) => {
    setModalProps({
      title: t('editNotesModalTitle'),
      isOpen: true,
      fields: [
        {
          name: 'notes',
          type: 'paragraph',
          rules: ['required'],
        },
      ],
      initialValues,
      onSubmit: (values: any) => {
        onSubmit(values);
        onClose();
      },
      onClose,
      onReset,
    });
  };

  const openDeleteModal = ({ initialValues, onSubmit }: any) => {
    setModalProps({
      id: 'quick-sale',
      title: t('deleteUser'),
      isOpen: true,
      fields: [
        {
          label: t('modals.delete.deleteUser'),
          name: 'confirmText',
          type: 'text',
          rules: ['required'],
          placeholder: t('deleteUserMessage'),
        },
      ],
      confirmButtonText: t('delete'),
      confirmButtonColor: 'bg-red-700',
      initialValues,
      onSubmit: (values: any) => {
        onSubmit(values);
        onClose();
      },
      onClose,
    });
  };

  const openDeliveryModal = ({
    initialValues,
    onSubmit,
    shipping,
    onReset,
  }: any) => {
    const options = [
      {
        label: 'Retiro',
        value: 'pickup',
      },
      {
        label: 'Envío a dirección',
        value: 'shipping',
      },
    ];
    setModalProps({
      isOpen: true,
      title: t('editDeliveryModalTitle'),
      fields: [
        {
          name: 'type',
          type: 'radio',
          options: options.filter(({ value }) =>
            value === 'shipping' ? isShippingValid(shipping) : true,
          ),
        },
      ],
      initialValues,
      onSubmit: (values: any) => {
        onSubmit({ type: values.type });
        onClose();
      },
      onClose,
      onReset,
    });
  };

  const openDiscountModal = ({ initialValues, onSubmit }: any) => {
    setModalProps({
      title: t('editDiscountModalTitle'),
      isOpen: true,
      columnsNumber: 2,
      fields: [
        {
          label: 'Descuento por valor',
          name: 'discount',
          type: 'text',
          rules: ['number'],
          onFormatValue: ({ value, setField }: any) => {
            const discountValue = (value * 100) / initialValues.subtotal;
            setField('discountRate', discountValue);

            return value;
          },
        },
        {
          label: 'Descuento por porcentaje (%)',
          name: 'discountRate',
          type: 'text',
          rules: ['number'],
          onFormatValue: ({ value, setField }: any) => {
            const discountValue = initialValues.subtotal * (value / 100);
            setField('discount', discountValue);

            return value;
          },
          validate: (value: string) => {
            if (Number(value) > 100) {
              return {
                type: 'custom',
                message: `Descuento debe ser menor al 100%`,
              };
            }
          },
        },
      ],
      initialValues,
      onSubmit,
      onClose,
    });
  };

  const openLocationModal = ({
    options = [],
    initialValues,
    onSubmit,
  }: any) => {
    setModalProps({
      isOpen: true,
      title: t('editLocationModalTitle'),
      fields: [
        {
          name: 'locationId',
          type: 'radio',
          options,
        },
      ],
      initialValues,
      onSubmit: (values: any) => {
        onSubmit(values);
        onClose();
      },
      onClose,
    });
  };

  return {
    modalProps,
    openPriceModal,
    openQuantityModal,
    openNoteModal,
    openDeliveryModal,
    openDiscountModal,
    openQuickSaleModal,
    openLocationModal,
    openDeleteModal,
  };
};

const isShippingValid = (shipping: any = {}) => {
  const { address } = shipping;

  return address;
};

export default useFormModal;
