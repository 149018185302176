const entireNumberRegex = /^\d+$/;
const decimalNumberRegex = /^\d+(\.\d{0,2})?$/;
const currencyDecimalPlaces = {
  ARS: 2,
  BOB: 2,
  CLP: 0,
  COP: 2,
  MXN: 2,
  PEN: 2,
  PYG: 0,
  USD: 2,
  UYU: 2,
  VES: 2,
};
type DecimalPlace = typeof currencyDecimalPlaces;
type Currency = keyof DecimalPlace;

const createPriceFormatter = (initialCurrency: Currency) => {
  let savedCurrency = initialCurrency;

  const roundMoneyValue = (amount: number) => {
    const decimalPlaces = getNumberOfDecimals();

    return Number(parseFloat(amount.toString()).toFixed(decimalPlaces));
  };

  /** this method is mostly used to prevent old data from displaying too many decimals, it can be revisted at a later time */
  const formatMoneyValue = (amount: string) => {
    return Number(parseFloat(amount.toString()).toFixed(2));
  };

  const toPriceTag = (amount: number) => {
    const [integerPart, decimalPart] = String(amount).split(".");

    const integerNumber = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return `$${integerNumber}${decimalPart ? "," + decimalPart : ""}`;
  };

  const getNumberOfDecimals = () => currencyDecimalPlaces[savedCurrency];

  const onParse = (val: string) => {
    const lastElement = val[val.length - 1];
    const formattedValue = lastElement === "," ? val.replace(",", ".") : val;
    const regex = getNumberOfDecimals()
      ? decimalNumberRegex
      : entireNumberRegex;
    const match = formattedValue.match(regex);

    if (match) {
      return formattedValue;
    } else {
      return formattedValue.slice(0, formattedValue.length - 1);
    }
  };

  const onEndEditing =
    (form: any, field: string) =>
    ({ nativeEvent }: { nativeEvent: any }) => {
      const value = nativeEvent.text;
      if (value) {
        form.mutators.setField(
          field,
          parseFloat(value).toFixed(getNumberOfDecimals())
        );
      }
    };

  const getCurrency = () => savedCurrency;
  const setCurrency = (currency: Currency) => (savedCurrency = currency);
  const getItemGross = (item: { price: number; quantity: number }) =>
    roundMoneyValue(item.price * item.quantity);

  return {
    toPriceTag,
    formatMoneyValue,
    roundMoneyValue,
    setCurrency,
    getCurrency,
    onParse,
    onEndEditing,
    getNumberOfDecimals,
    getItemGross,
  };
};

// Create a single instance for the module export
export const priceFormatter = createPriceFormatter("CLP");
