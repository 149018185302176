import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { useForm, LoadingDim } from '@posy/components';
import { useAuth } from '../../Auth/AuthProvider';
import useFields from './useFields';
import Section from '../components/Section';
import usePermissions from '../hooks/usePermissions';
import updateSettings from 'api/updateSettings';
import { useSettings } from 'providers/SettingsProvider';

const GeneralSettings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hasPermission, user } = useAuth();
  const { settings, setSettings } = useSettings();
  const { shouldShowSaveButton } = usePermissions({
    hasPermission,
  });
  const [isLoading, setIsLoading] = useState(false);

  const { registerField, handleSubmit } = useForm({
    defaultValues: settings,
  });

  const fields = useFields();

  const onSubmit = (values: any) => {
    setIsLoading(true);
    updateSettings(user.storeId, values).then(() => {
      setSettings(values);
      navigate(-1);
      setIsLoading(false);
    });
  };

  if (!settings) {
    return null;
  }

  return (
    <div className="px-4 overflow-auto">
      <div className="flex justify-end p-4">
        <div className="flex-1">
          <button
            type="button"
            className="inline-flex text-2xl font-semibold text-gray-900"
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon className="h-8 w-8" aria-hidden="true" />
            {t('settings.generalSettings.settings.title')}
          </button>
        </div>

        {shouldShowSaveButton && (
          <button
            className="bg-indigo-700 text-white font-medium rounded-md px-4 py-2"
            onClick={handleSubmit(onSubmit)}
          >
            {t('save')}
          </button>
        )}
      </div>
      <Wrapper>
        <div className="flex gap-4">
          <Section
            fields={fields}
            title={t('settings.generalSettings.settings.subtitle')}
            registerField={registerField}
          />
        </div>
      </Wrapper>
      <LoadingDim isLoading={isLoading} />
    </div>
  );
};

const Wrapper = styled.div`
  grid-gap: 12px;
`;

export default GeneralSettings;
