import { getFirestore, doc, writeBatch, arrayUnion } from 'firebase/firestore';
import { getCurrentTime, getTimelinePayload } from './helpers';
import updateInventoryLevels from './updateInventoryLevels';

const confirmOrder = (order: any, user: any, isInDefaultLocation: boolean) => {
  const db = getFirestore();
  const batch = writeBatch(db);
  const timelinePayload = getTimelinePayload('confirmed', user);
  const orderPayload = {
    ...order,
    status: 'confirmed',
    timeline: arrayUnion(timelinePayload),
    updatedAt: getCurrentTime(),
    processedAt: getCurrentTime(),
    confirmed: true,
    isConfirmed: true,
  };

  const orderRef = doc(db, 'orders', order.id);
  batch.update(orderRef, orderPayload);
  updateInventoryLevels(
    order.items,
    batch,
    'order',
    timelinePayload.uid,
    order.locationId,
    isInDefaultLocation,
  );
  batch.commit();
};

export default confirmOrder;
