/* eslint-disable react/display-name */
import { MutableRefObject, useRef, useState, forwardRef } from 'react';
import {
  getStorage,
  ref as fileRef,
  uploadBytes,
  getDownloadURL,
} from 'firebase/storage';
import { useTranslation } from 'react-i18next';
import Image from './Image';

const ThumbnailInput = forwardRef(
  (
    {
      label,
      name,
      onChange,
      value,
      imageRef,
      rounded,
    }: {
      label: string;
      name: string;
      onChange: any;
      value: any;
      imageRef: string;
      rounded: boolean;
    },
    ref: any,
  ) => {
    const inputRef = useRef<HTMLInputElement>();
    const [img, setImg] = useState<string>();
    const [isLoading, setLoading] = useState<any>(false);
    const { t } = useTranslation();

    const onFileChange = (e: any) => {
      const [file] = e.target.files;
      if (file) {
        setLoading(true);
        setImg(URL.createObjectURL(file));
        const storage = getStorage();
        const storageRef = fileRef(storage, imageRef);

        // 'file' comes from the Blob or File API
        uploadBytes(storageRef, file)
          .then((snapshot) => getDownloadURL(snapshot.ref))
          .then((url) => {
            onChange({ target: { name: name, value: url } });
            setLoading(false);
          });
      }
    };

    return (
      <div className="col-span-3">
        {label && (
          <label className="block text-sm font-medium text-gray-700">
            {label}
          </label>
        )}
        <div
          className="mt-1 flex items-center"
          role="button"
          onClick={() => {
            (inputRef as MutableRefObject<HTMLInputElement>).current.click();
          }}
        >
          <input
            id="file-upload"
            name="file-upload"
            type="file"
            className="sr-only"
            ref={(innerRef: any) => {
              ref(innerRef);
              inputRef.current = innerRef;
            }}
            onChange={onFileChange}
          />

          <span
            className={`inline-block bg-gray-100 ${
              rounded && 'rounded-full'
            } overflow-hidden h-20 w-20 flex items-center justify-center`}
          >
            {isLoading ? (
              <p className="text-center" style={{ fontSize: 12 }}>
                {`${t('loading')}...`}
              </p>
            ) : (
              <Image src={img || value} alt="" />
            )}
          </span>
        </div>
      </div>
    );
  },
);

export default ThumbnailInput;
