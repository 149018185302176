import AppLogo from './components/AppLogo';

const LoadingPage = () => {
  return (
    <div className="flex items-center justify-center h-screen w-full bg-gradient-to-r from-indigo-700 to-purple-900">
      <div className="flex flex-col">
        <AppLogo />
      </div>
    </div>
  );
};

export default LoadingPage;
