import { utils, writeFile } from 'xlsx';
import moment from 'moment-timezone';
import { t } from 'i18next';
import { ordersHeader, itemsHeader, customersHeader } from './constants';
import {
  getFinancialItems,
  getItemCostPrice,
  getItemDiscount,
  getItemGross,
  getItemProfit,
  getPaymentMethods,
} from './itemHelpers';

const useReports = (data: any) => {
  const getReportSheet = (type: string) => {
    if (type === 'orders') {
      return getOrderReportSheet(data.orders);
    } else if (type === 'items') {
      return getItemReportSheet(data.orders);
    } else if (type === 'customers') {
      return getCustomerReportSheet(data.orders);
    } else {
      return getItemReportSheet(data.orders);
    }
  };

  const generateReport = (startDate: string, endDate: string) => {
    const wb = utils.book_new();
    wb.SheetNames.push('Pedidos');
    wb.SheetNames.push('Items');
    wb.SheetNames.push('Clientes');
    wb.Sheets['Pedidos'] = getReportSheet('orders');
    wb.Sheets['Items'] = getReportSheet('items');
    wb.Sheets['Clientes'] = getReportSheet('customers');
    return writeFile(wb, `${getReportName(startDate, endDate)}.xlsx`);
  };

  return {
    generateReport,
  };
};

const getReportName = (startDate: string, endDate: string) => {
  const periodStart = moment(startDate).format('DD-MM-YY');
  const periodEnd = moment(endDate).format('DD-MM-YY');

  return `Reporte Posy Pedidos completados periodo ${periodStart} a ${periodEnd}`;
};

const getOrderReportSheet = (orders: any[]) => {
  const ordersData = orders.reduce((result: any, order: any) => {
    const customer = order.customer;
    const user = order.user;
    const item = [
      order.name,
      customer ? customer.name : '',
      order.subtotal,
      order.discount,
      order.total,
      getPaymentMethods(order.payments),
      t(`statusLabels.${order.status}`),
      moment(order.createdAt).format('DD/MM/YYYY HH:mm'),
      moment(order.closedAt).format('DD/MM/YYYY HH:mm'),
      user ? user.name : 'Catalogo',
      order.notes || '',
    ];

    return [...result, item];
  }, []);

  const ws_orders = [ordersHeader, ...ordersData];
  return utils.aoa_to_sheet(ws_orders);
};

const getItemReportSheet = (orders: any[]) => {
  const data = orders.reduce((obj, order) => {
    return getFinancialItems(obj, order.items, order.subtotal, order.discount);
  }, {});

  const ordersData = Object.keys(data).map((key) => {
    const item = data[key];
    const discount = parseFloat(item.discount.toFixed(3));
    const profit = parseFloat(item.profit.toFixed(3));
    const total = item.gross - discount;
    const averageGrossPrice = item.gross / item.quantity;
    const averageCostPrice = item.costPrice / item.quantity;
    const averageDiscount = discount / item.quantity;
    const averageProfit = profit / item.quantity;

    return [
      item.name,
      item.count,
      item.quantity,
      item.gross,
      discount,
      total,
      item.costPrice,
      profit,
      averageGrossPrice,
      averageCostPrice,
      averageDiscount,
      averageProfit,
    ];
  });

  const ws_orders = [itemsHeader, ...ordersData];
  return utils.aoa_to_sheet(ws_orders);
};

const getCustomerReportSheet = (orders: any) => {
  const data = orders.reduce((obj: any, order: any) => {
    const customerId = order.customerId;
    const itemObjDefault = {
      gross: 0,
      discount: 0,
      profit: 0,
      costPrice: 0,
    };

    const customerObj = {
      name: order.customer ? order.customer.name : 'Sin registrar',
      count: obj[customerId] ? (obj[customerId].count || 0) + 1 : 1,
    };
    Object.keys(order.items).forEach((key) => {
      const itemObj = obj[customerId] || itemObjDefault;
      const item = order.items[key];
      const gross = getItemGross(item);
      const discount = getItemDiscount(gross, order.subtotal, order.discount);
      const costPrice = getItemCostPrice(item);
      const profit = getItemProfit(gross, costPrice, discount);

      obj = {
        ...obj,
        [customerId]: {
          ...customerObj,
          gross: itemObj.gross + gross,
          discount: itemObj.discount + discount,
          profit: itemObj.profit + profit,
          costPrice: itemObj.costPrice + costPrice,
        },
      };
    });

    return obj;
  }, {});

  const ordersData = Object.keys(data).map((key) => {
    const d = data[key];
    const discount = parseFloat(d.discount.toFixed(3));
    const profit = parseFloat(d.profit.toFixed(3));

    return [
      d.name,
      d.count,
      d.gross,
      discount,
      d.gross - discount,
      d.costPrice,
      profit,
    ];
  });

  const ws_orders = [customersHeader, ...ordersData];
  return utils.aoa_to_sheet(ws_orders);
};

export default useReports;
