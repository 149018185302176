import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { BadgeCheckIcon } from '@heroicons/react/outline';

const SubscriptionStatus = ({ subscription, trialEndDate }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getSubscriptionMessage = () => {
    if (subscription) {
      return `${t('subscriptionStatus.validUntil')} ${moment(
        subscription.nextChargeDate,
      ).format('DD/MM/YY')}`;
    } else {
      if (moment(trialEndDate).isAfter(moment())) {
        return `${t('subscriptionStatus.trialValidUntil')} ${moment(
          trialEndDate,
        ).format('DD/MM/YY')}`;
      } else {
        return t('subscriptionStatus.subscriptionNotFound');
      }
    }
  };

  const getAvatar = () => {
    return <BadgeCheckIcon className="h-12 w-12 text-indigo-800" />;
  };

  const shouldDisplayRenewButton = () => {
    if (subscription) {
      return (
        ['flow', 'paypal'].includes(subscription?.provider) &&
        !subscription?.autoRenew
      );
    } else {
      return true;
    }
  };

  const getProButtonLabel = () => {
    if (subscription) {
      return t('subscriptionStatus.renewPRO');
    } else {
      return t('subscriptionStatus.goPRO');
    }
  };

  return (
    <div className="bg-white y-4 p-4 my-4">
      <div className="flex flex-col h-full">
        <div className="flex-1 flex items-center justify-center">
          <div className="flex flex-col items-center">
            {getAvatar()}
            <p className="my-4 text-gray-700 font-medium">
              {getSubscriptionMessage()}
            </p>
          </div>
        </div>
        <div className="flex justify-center">
          {shouldDisplayRenewButton() && (
            <button
              className="bg-indigo-700 text-white font-medium rounded-md px-4 py-1"
              onClick={() => navigate('/pricing')}
            >
              {getProButtonLabel()}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionStatus;
