import React from 'react';
import { useTranslation } from 'react-i18next';
import background2 from './../../../public/background2.png';
import phone from './../../../public/phone.png';
import qrcode from './../../../public/qrcode.svg';
import playstore from './../../../public/google-store.png';
import applestore from './../../../public/apple-store.svg';

export interface Store {
  id: string;
  name: string;
  subtitle: string;
  isActive: true;
  uniqueName: string;
  logo: string;
}

export interface CatalogOverviewProps {
  store: Store;
  onlineData: any[];
}

const CardApp = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-white justify-center relative">
      <div className="flex flex-col text-center">
        <div
          className="flex flex-col md:flex-row bg-cover rounded-md"
          style={{ backgroundImage: `url(${background2})` }}
        >
          <div className="md:w-1/3 flex justify-left mt-12 ml-4">
            <img src={phone} alt="image" className="w-full rounded-md " />
          </div>
          <div className="md:w-2/3  flex flex-col justify-center p-12">
            <div className="w-62 flex flex-col">
              <p className="md:text-white text-left font-bold text-4xl">
                {t('cardsHome.downloadApp.title')}
              </p>
              <p className="md:text-white text-left text-xl">
                {t('cardsHome.downloadApp.subtitle')}
              </p>
              <p className="md:text-white text-left text-xl">
                <strong>{t('cardsHome.downloadApp.subtitle2')}</strong>
              </p>
            </div>
            <div className="w-3/8 flex justify-left  mt-6">
              <img src={qrcode} alt="image" className="h-32 rounded-md " />
              <div>
                <div className="w-3/8 flex justify-left  mt-3 ml-4">
                  <a href="https://apps.apple.com/cl/app/posy-stock-pedidos-catalogo/id1459927377">
                    <button
                      style={{
                        backgroundImage: `url(${applestore})`,
                        backgroundSize: 'cover',
                        width: '150px',
                        height: '50px',
                      }}
                    ></button>
                  </a>
                </div>
                <div className="w-3/8 flex justify-left  ml-2">
                  <a href="https://play.google.com/store/apps/details?id=cl.bravor.posy&hl=en_US">
                    <button
                      style={{
                        backgroundImage: `url(${playstore})`,
                        backgroundSize: 'cover',
                        width: '170px',
                        height: '60px',
                      }}
                    ></button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardApp;
