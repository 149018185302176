import { getFirestore, addDoc, collection } from 'firebase/firestore';
import { collectionName } from './constants';

const createPaymentMethod = async (
  values: any,
  storeId: string,
  userId: string,
) => {
  const payload = {
    ...values,
    storeId,
    uid: userId,
    createdAt: new Date().getTime() * 1000,
  };

  const paymentMethodsRef = collection(getFirestore(), collectionName);
  const snapshot = await addDoc(paymentMethodsRef, payload);

  return {
    id: snapshot.id,
    ...payload,
  };
};

export default createPaymentMethod;
