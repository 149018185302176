import React, { useEffect } from 'react';
import { logEvent as logAnalyticsEvent } from 'firebase/analytics';
import { setUserId, setUserProperties } from 'firebase/analytics';
import * as amplitude from '@amplitude/analytics-browser';

interface EventParam {
  [key: string]: any;
}

interface ModalContextType {
  logEvent: (eventName: string, params?: EventParam) => void;
  logUser: (userData: any) => void;
}

const ModalContext = React.createContext<ModalContextType>(null!);

const AnalyticsProvider = ({
  children,
  analytics,
}: {
  children: React.ReactNode;
  analytics: any;
}) => {
  useEffect(() => {
    const analyticsId = process.env.REACT_APP_AMPLITUDE_CLIENT_ID;
    analyticsId && amplitude.init(analyticsId);
  }, []);

  const logEvent = (eventName: string, params?: EventParam) => {
    logAnalyticsEvent(analytics, eventName, params);
    amplitude.track(eventName);
  };

  const logUser = (user: any) => {
    const data: any = {
      storeName: user?.store.name,
      storeUniqueName: user?.uniqueName || '',
      storeCity: user?.store.city || '',
      storeState: user?.store.state || '',
      storeCountry: user?.store.country,
    };

    const identifyEvent = new amplitude.Identify();
    identifyEvent.set('storeId', user?.store?.id);
    identifyEvent.set('storeName', user?.store?.name);
    identifyEvent.set('role', user?.isAdmin ? 'admin' : 'collaborator');
    identifyEvent.set('isSubscriptionActive', user?.isSubscriptionActive);
    identifyEvent.set('subscriptionCount', user?.subscriptions.length);
    amplitude.identify(identifyEvent);
    amplitude.setUserId(user.id);
    setUserId(analytics, user.id);
    setUserProperties(analytics, data);
  };

  const value = { logEvent, logUser };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

function useAnalytics() {
  return React.useContext(ModalContext);
}

export { useAnalytics };
export default AnalyticsProvider;
