import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import AuthProvider from './Auth/AuthProvider';
import {
  SidePanelProvider,
  ModalProvider,
  ToastProvider,
} from '@posy/components';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { initializeFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';

import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { datadogRum } from '@datadog/browser-rum';
import DefaultLocationProvider from 'providers/DefaultLocationProvider';
import ProductsProvider from 'providers/ProductsProvider';
import SettingsProvider from 'providers/SettingsProvider';
import CustomersProvider from 'providers/CustomersProvider';
import credentials from './api/credentials';
import AnalyticsProvider from './providers/AnalyticsProvider';
import resources from './locale';

const app = initializeApp(credentials);
const analytics = getAnalytics(app);

if (
  window.location.hostname === 'localhost' &&
  process.env.REACT_APP_ENABLE_EMULATOR === 'true'
) {
  initializeFirestore(app, {
    experimentalForceLongPolling: true,
  });

  //const auth = initializeAuth(app);
  //connectDatabaseEmulator(getDatabase(), 'localhost', 9000);
  //connectAuthEmulator(auth, 'http://localhost:9099');
  //connectFirestoreEmulator(db, 'localhost', 8080);
  connectFunctionsEmulator(getFunctions(), 'localhost', 5003);
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'es',

  interpolation: {
    escapeValue: false,
  },
});

datadogRum.init({
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  env: process.env.REACT_APP_ENV,
  applicationId: process.env.REACT_APP_DATADOG_APP_ID || '',
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
  site: 'us5.datadoghq.com',
  service: 'posy-web',
  sessionSampleRate: 100,
  premiumSampleRate: 100,
  trackUserInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
});

datadogRum.startSessionReplayRecording();

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID || '',
  });
  ReactDOM.render(
    <React.StrictMode>
      <LDProvider>
        <AnalyticsProvider analytics={analytics}>
          <Router>
            <AuthProvider>
              <SidePanelProvider>
                <ModalProvider>
                  <ToastProvider>
                    <SettingsProvider>
                      <DefaultLocationProvider>
                        <ProductsProvider>
                          <CustomersProvider>
                            <App />
                          </CustomersProvider>
                        </ProductsProvider>
                      </DefaultLocationProvider>
                    </SettingsProvider>
                  </ToastProvider>
                </ModalProvider>
              </SidePanelProvider>
            </AuthProvider>
          </Router>
        </AnalyticsProvider>
      </LDProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
