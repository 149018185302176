import { useParams } from 'react-router-dom';
import { Field, LoadingDim, useForm, useSidePanel } from '@posy/components';
import { useTranslation } from 'react-i18next';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import styled from 'styled-components';
import { useAuth } from 'Auth/AuthProvider';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import FormCard from 'components/FormCard';
import { useAnalytics } from 'providers/AnalyticsProvider';
import Button from 'components/Button';
import createPaymentGateway from 'api/paymentGateways/createPaymentGateway';
import { useState, useEffect } from 'react';
import updatePaymentGateway from 'api/paymentGateways/updatePaymentGateway';
import validate from './../api/paymentGateways/validate';
import flowProviderImage from '../../public/flow.png';

type Location = {
  name: string;
  storeId: string;
  userId: string;
  createdAt: number;
  default?: boolean;
};

const PaymentGateway = () => {
  const { navigateTo, params } = useSidePanel();
  const { t } = useTranslation();
  const { logEvent } = useAnalytics();
  const { id } = useParams();
  const [isLoading, setLoading] = useState(false);
  const FlowLogo = () => (
    <img src={flowProviderImage} alt="Flow Provider" className="w-20 h-15" />
  );

  const [defaultValues, setDefaultValues] = useState<any>({
    name: t('paymentMethods.flow'),
    provider: 'flow',
  });
  const { registerField, handleSubmit, getField } = useForm({ defaultValues });
  const { user } = useAuth();
  const { paymentGatewayId } = params?.values || {};

  const fields = [
    {
      label: t('form.name'),
      name: 'name',
      type: 'text',
      rules: ['required'],
      readOnly: true,
    },
    {
      label: t('vendor'),
      name: 'provider',
      type: 'custom',
      rules: ['required'],
      renderComponent: () => (
        <div>
          <p className="font-semibold">{t('vendor')}</p>
          <FlowLogo />
        </div>
      ),
    },
  ];

  const addressFields = [
    {
      label: 'Api key',
      name: 'credentials.apiKey',
      type: 'text',
      rules: ['required'],
    },
    {
      label: 'Secret key',
      name: 'credentials.secretKey',
      type: 'text',
      rules: ['required'],
    },
  ];

  useEffect(() => {
    loadPaymentGateway();
  }, []);

  const loadPaymentGateway = async () => {
    const docRef = doc(
      getFirestore(),
      'paymentGateways',
      `${paymentGatewayId}`,
    );
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setDefaultValues({
        id: docSnap.id,
        ...docSnap.data(),
      });
    } else {
      console.error('No such document!');
    }
  };

  const onClose = () => {
    navigateTo('');
  };

  const submit = async (values: Location) => {
    setLoading(true);
    if (id) {
      updatePaymentGateway(id, values);
      params.onSubmit({ paymentGatewayId: id });

      logEvent('update_payment_method');
    } else {
      const data = await createPaymentGateway(values, user.storeId, user.id);
      const isValid = await validate(user.storeId);

      if (isValid) {
        params.onSubmit({ paymentGatewayId: data.id });
        logEvent('create_payment_method');
        onClose();
      } else {
        alert('Keys are invalid');
      }
      setLoading(false);
    }
  };

  return (
    <div className="px-4 overflow-auto">
      <div className="flex justify-end p-4">
        <div className="flex-1">
          <button
            type="button"
            className="inline-flex text-2xl font-semibold text-gray-900"
            onClick={onClose}
          >
            <ChevronLeftIcon className="h-8 w-8" aria-hidden="true" />
            {id ? getField('name') : 'Medio de pago'}
          </button>
        </div>
        <Button label={t('save')} onClick={handleSubmit(submit)} />
      </div>
      <Wrapper>
        <div className="gap-4">
          <FormCard>
            {fields.map((field) => (
              <Field key={field.name} {...registerField(field)} />
            ))}
          </FormCard>
          <FormCard title={'Datos de integración'}>
            {addressFields.map((field) => (
              <Field key={field.name} {...registerField(field)} />
            ))}
          </FormCard>
        </div>
      </Wrapper>
      <LoadingDim isLoading={isLoading} />
    </div>
  );
};

const Wrapper = styled.div`
  grid-gap: 12px;
`;

export default PaymentGateway;
