import React from 'react';
import useProducts from '../hooks/useProducts';
import { useDefaultLocationProvider } from './DefaultLocationProvider';
import { Product } from 'types';

interface ModalContextType {
  products: Product[];
  isLoading: boolean;
  defaultLocationId: string;
}

const ModalContext = React.createContext<ModalContextType>(null!);

const ProductsProvider = ({ children }: { children: React.ReactNode }) => {
  const data = useDefaultLocationProvider();
  const { products, isLoading }: any = useProducts(data?.defaultLocationId);
  const value: ModalContextType = {
    products,
    isLoading,
    defaultLocationId: data?.defaultLocationId,
  };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

function useProviderProducts() {
  return React.useContext(ModalContext);
}

export { useProviderProducts };
export default ProductsProvider;
