import {
  collection,
  query,
  where,
  getFirestore,
  getDocs,
} from 'firebase/firestore';
import { collectionName } from './constants';

const getCategories = async (storeId: string) => {
  try {
    const db = getFirestore();
    const ref = collection(db, collectionName);
    const q = query(ref, where('storeId', '==', storeId));
    const categorySnap = await getDocs(q);
    const categories: any = categorySnap.docs.map((doc: any) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return categories;
  } catch (e) {
    console.error({ e });
    return [];
  }
};

export default getCategories;
