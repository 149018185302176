import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  doc,
  query,
  getFirestore,
  collection,
  getDocs,
  orderBy,
} from 'firebase/firestore';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { Table, Image, useSidePanel } from '@posy/components';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/solid';
import moment from 'moment';
import { getBadgeClasses } from '../Products/helpers';
import useLocations from 'hooks/useLocations';
import { useDefaultLocationProvider } from 'providers/DefaultLocationProvider';
import { getStockLevel } from '@posy/products';

const InventoryMovements = () => {
  const { t } = useTranslation();
  const { navigateTo, params } = useSidePanel();
  const [movements, setMovements] = useState<any>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const { locations, getLocationNameById } = useLocations();
  const { defaultLocationId } = useDefaultLocationProvider();

  const {
    variationId,
    product,
    productName,
    variation,
    hasMultipleVariations,
    locationId: initialLocationId,
  } = params;

  const filteredLocations = locations.filter(
    (location: { id: string }) =>
      product.locations[location.id]?.variations[variationId],
  );

  const [locationId, setLocationId] = useState(initialLocationId);
  const { available } = getStockLevel({ locationId, product, variationId });

  useEffect(() => {
    if (variationId) {
      fetchMovements();
    }
  }, [variationId]);

  const fetchMovements = async () => {
    const variationRef = doc(getFirestore(), 'variations', variationId);
    const movementRef = collection(variationRef, 'movements');
    const q = query(movementRef, orderBy('createdAt', 'desc'));
    const snapshot = await getDocs(q);
    const movements = snapshot.docs.map((doc: any) => ({
      id: doc.id,
      creationDate: moment(doc.data().createdAt).format('DD/MM/YY hh:mm'),
      ...doc.data(),
      locationId: doc.data().locationId || defaultLocationId,
    }));
    setMovements(movements);
    setLoading(false);
  };
  const goBack = () => {
    const path = hasMultipleVariations ? 'InventorySelection' : '';
    const { product, variations } = params;
    navigateTo(path, { product, variations, locationId });
  };

  const getActionIcon = (action: string) => {
    const isAdditive = ['reception', 'return'].includes(action);
    const color = isAdditive ? 'green' : 'red';
    const Icon = isAdditive ? ArrowUpIcon : ArrowDownIcon;

    return <Icon className={`w-4 h-4 mr-2`} style={{ color }} />;
  };

  const columns = [
    {
      key: 'action',
      label: t('movement.action'),
      render: ({ action, user }: any) => (
        <div className="whitespace-nowrap px-3 py-4 text-sm pl-6">
          <div className="flex items-center">
            {getActionIcon(action)}
            <div>
              <p>{t(`inventoryMovements.${action}`)}</p>
              <p className="text-gray-500">{user?.name}</p>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: 'locationId',
      label: 'Ubicación',
      render: ({ locationId }: any) => (
        <div className="whitespace-nowrap px-3 py-4 text-sm pl-6">
          <p className="text-sm">{getLocationNameById(locationId)}</p>
        </div>
      ),
    },
    { key: 'quantity', label: t('movement.quantity') },
    { key: 'creationDate', label: t('table.date') },
  ];

  return (
    <>
      <div className="flex justify-end p-4">
        <div className="flex-1">
          <button
            type="button"
            className="inline-flex text-xl font-semibold text-gray-900"
            onClick={goBack}
          >
            <ChevronLeftIcon className="h-7 w-7" aria-hidden="true" />
            {t('inventoryMovements.title')}
          </button>
        </div>
      </div>
      <Header
        item={variation}
        productName={productName}
        available={available}
      />
      <p className="text-gray-500 text-xs font-semibold ml-5 mt-4">
        {t('inventoryMovements.lastMovements')}
      </p>
      <Table
        columns={columns}
        rows={movements}
        isLoading={isLoading}
        filters={[
          {
            label: 'Todas las ubicaciones',
            name: 'locationId',
            options: filteredLocations,
            onChange: (value: string) => setLocationId(value),
            default: locationId,
          },
        ]}
        style={{
          overflow: 'auto',
          height: '100%',
          background: 'white',
          margin: 16,
        }}
      />
    </>
  );
};

const Header = ({
  item,
  productName,
  available,
}: {
  item: any;
  productName: string;
  available: number;
}) => {
  return (
    <div className="py-4 pl-4 pr-3 text-sm sm:pl-6">
      <div className="flex items-center">
        <div className="h-10 w-10 flex-shrink-0">
          <Image
            className="h-10 w-10"
            src={item?.thumbnail}
            alt="Product logo"
          />
        </div>
        <div className="ml-4 flex-1">
          <div className="font-medium text-gray-600">{productName}</div>
          <div className="font-medium text-gray-900">{item?.name}</div>
        </div>
        <span
          className={` inline-flex rounded-full ${getBadgeClasses(
            available,
            item?.minQuantity,
          )} px-4 py-3 mr-8 text-lg font-semibold leading-5 
            `}
        >
          {available}
        </span>
      </div>
    </div>
  );
};

export default InventoryMovements;
