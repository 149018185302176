import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { Customer } from 'types';

const getCustomer = async (customerId: string) => {
  const docRef = doc(getFirestore(), 'customers', customerId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const customer = docSnap.data() as Customer;

    return { id: docSnap.id, ...customer };
  } else {
    console.error('No such document!');
  }
};

export default getCustomer;
