import { useNavigate, useParams } from 'react-router-dom';
import { useLocation, Field, useForm } from '@posy/components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { doc, getDoc, updateDoc, getFirestore } from 'firebase/firestore';
import styled from 'styled-components';
import { useAuth } from '../../Auth/AuthProvider';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import FormCard from '../../components/FormCard';
import { useAnalytics } from '../../providers/AnalyticsProvider';
import createLocation from 'api/createLocation';
import useFeatureFlags from 'hooks/useFeatureFlags';
import Button from '../../components/Button';
import { countries } from '../../constants';

type Location = {
  name: string;
  storeId: string;
  userId: string;
  createdAt: number;
  default?: boolean;
};

const Location = () => {
  const { t } = useTranslation();
  const { logEvent } = useAnalytics();
  const navigate = useNavigate();
  const { enableCreateLocations } = useFeatureFlags();
  const { id } = useParams();
  const shouldDisplayCreation = id ? true : enableCreateLocations;
  const [location, setLocation] = useState<any>({});
  const { registerField, handleSubmit, getField } = useForm({
    defaultValues: location,
  });
  const { regiones, comunas } = useLocation(getField('state'));
  const country = getField('country');
  const { user, hasPermission } = useAuth();
  useEffect(() => {
    loadLocation();
  }, []);

  const loadLocation = async () => {
    const docRef = doc(getFirestore(), 'locations', `${id}`);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setLocation({
        id: docSnap.id,
        ...docSnap.data(),
      });
    } else {
      console.error('No such document!');
    }
  };
  const hasNoEditPermission = !hasPermission('CUSTOMER_UPDATE');

  const fields = [
    {
      label: t('form.name'),
      name: 'name',
      type: 'text',
      rules: ['required'],
      readOnly: hasNoEditPermission,
    },
  ];

  const addressFields = [
    {
      label: t('shippingAddress.address'),
      name: 'address',
      type: 'text',
    },
    {
      label: t('shippingAddress.state'),
      name: 'state',
      type: 'select',
      options: regiones,
      hidden: country !== 'CL',
    },
    {
      label: t('shippingAddress.city'),
      name: 'city',
      type: country === 'CL' ? 'select' : 'text',
      options: comunas,
      disabled: false,
    },
    {
      label: t('shippingAddress.country'),
      name: 'country',
      type: 'select',
      rules: ['required'],
      disabled: false,
      options: countries,
    },
  ];
  if (!location || !shouldDisplayCreation) {
    return null;
  }

  const submit = (values: Location) => {
    if (id) {
      updateDoc(doc(getFirestore(), 'locations', `${id}`), values);
      logEvent('update_location');
    } else {
      createLocation(values, user.storeId, user.id);
      logEvent('create_location');
    }
    navigate('/settings/locations');
  };

  return (
    <div className="px-4 overflow-auto">
      <div className="flex justify-end p-4">
        <div className="flex-1">
          <button
            type="button"
            className="inline-flex text-2xl font-semibold text-gray-900"
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon className="h-8 w-8" aria-hidden="true" />
            {id ? getField('name') : 'Nueva ubicación de stock'}
          </button>
        </div>
        <Button label={t('save')} onClick={handleSubmit(submit)} />
      </div>
      <Wrapper>
        <div className="flex gap-4">
          <FormCard title={t('basicData')}>
            {fields.map((field) => (
              <Field key={field.name} {...registerField(field)} />
            ))}
          </FormCard>
          <FormCard title={t('locations.addressInformation')}>
            {addressFields.map((field) => (
              <Field key={field.name} {...registerField(field)} />
            ))}
          </FormCard>
        </div>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  grid-gap: 12px;
`;

export default Location;
