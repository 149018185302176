import React from 'react';

const FormCard = ({
  title,
  subtitle,
  children,
  visible = true,
}: {
  title?: string;
  subtitle?: string;
  children: any;
  visible?: boolean;
}) => {
  return visible ? (
    <div className="bg-white p-6 shadow sm:rounded-md flex-1 h-fit">
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
        <p className="mt-1 text-sm text-gray-500">{subtitle}</p>
      </div>
      <div className="flex flex-col flex-1 gap-4 mt-4">{children}</div>
    </div>
  ) : null;
};

export default FormCard;
