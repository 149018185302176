import React from 'react';

import { useSidePanel } from './SidePanelProvider';
import SidePanel from './SidePanel';

const SidePanelRouter = ({ children }: { children: React.ReactNode }) => {
  const { route, params, navigateTo } = useSidePanel();
  const isOpen = Boolean(route);

  return (
    <SidePanel
      isOpen={isOpen}
      onClose={() => navigateTo('')}
      maxWidth={params?.maxWidth}
    >
      {children}
    </SidePanel>
  );
};

export default SidePanelRouter;
