import { useTranslation } from 'react-i18next';

const currencies = [
  { name: 'Peso Argentino (ARS)', id: 'ARS' },
  { name: 'Peso Boliviano (BOB)', id: 'BOB' },
  { name: 'Peso Colombiano (COP)', id: 'COP' },
  { name: 'Peso Chileno (CLP)', id: 'CLP' },
  { name: 'Peso Mexicano (MXN)', id: 'MXN' },
  { name: 'Sol Peruano (PEN)', id: 'PEN' },
  { name: 'Guarani Paraguayo (PYG)', id: 'PYG' },
  { name: 'Peso Uruguayo (UYU)', id: 'UYU' },
  { name: 'Dolar Estadounidense (USD)', id: 'USD' },
  { name: 'Bolivar Venezolano (VES)', id: 'VES' },
];

const useFields = () => {
  const { t } = useTranslation();

  return [
    {
      label: t('currency'),
      name: 'currency',
      type: 'select',
      rules: ['required'],
      options: currencies,
    },
    {
      label: t('settings.generalSettings.settings.sortProductsBy'),
      name: 'productListOrderBy',
      type: 'select',
      rules: ['required'],
      options: [
        {
          id: 'creationDate',
          name: t('settings.generalSettings.settings.creationDate'),
        },
        {
          id: 'alphabetical',
          name: t('settings.generalSettings.settings.alphabetical'),
        },
      ],
      default: 'creationDate',
    },
    {
      label: t('settings.generalSettings.settings.productVariants'),
      name: 'allowVariations',
      type: 'boolean',
      default: false,
    },
    {
      label: t('settings.generalSettings.settings.hideCanceledOrders'),
      name: 'hideCanceledOrders',
      type: 'boolean',
      default: false,
    },
    {
      label: t('settings.generalSettings.settings.hideOutOfStockItems'),
      name: 'hideOutOfStockItems',
      type: 'boolean',
      default: false,
    },
  ];
};

export default useFields;
