import Dim from './Dim/Dim';

import { useTranslation } from 'react-i18next';

interface Props {
  isLoading: boolean;
}

const LoadingDim = ({ isLoading }: Props) => {
  const { t } = useTranslation();

  return <Dim isOpen={isLoading} message={t('loading')} />;
};

export default LoadingDim;
