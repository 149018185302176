import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  DesktopComputerIcon,
  UserGroupIcon,
  GlobeAltIcon,
} from '@heroicons/react/outline';

const MarketingModal = ({ visible }: { visible: boolean }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const shouldOpenModal = open && visible;
  const navigate = useNavigate();

  const post = {
    title: t('planModal.title'),
    description: t('planModal.description'),
    featuresTitle: 'Posy PRO te ofrece muchas ventajas:',
    features: [
      { title: t('planModal.computerVersion'), icon: <DesktopComputerIcon /> },
      { title: t('planModal.team'), icon: <UserGroupIcon /> },
      { title: t('planModal.onlineStore'), icon: <GlobeAltIcon /> },
    ],
  };

  const onSubmit = () => {
    setOpen(false);
    navigate('/pricing');
  };

  return (
    <Transition.Root show={shouldOpenModal} as={Fragment} appear>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20 my-auto h-fit">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <div className="flex transform-gpu divide-x divide-gray-100">
                <div className="hidden h-96 w-1/2 flex-none flex-col overflow-y-auto sm:flex">
                  <div className="flex-none p-6 text-center">
                    <h2 className="text-left mt-3 font-semibold text-lg text-gray-900 mb-2">
                      {post.title}
                    </h2>
                    <p className="text-left text-sm leading-6 text-gray-500">
                      {post.description}
                    </p>
                  </div>
                  <div className="flex flex-auto flex-col justify-between p-6">
                    <p className="font-semibold text-gray-900 text-sm">
                      {post.featuresTitle}
                    </p>
                    <div className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                      {post.features.map((feature, index) => (
                        <div key={index} className="flex w-full items-center">
                          <div className="w-5 h-5">{feature.icon}</div>
                          <p
                            key={index}
                            className="col-end-1 font-semibold text-gray-900 ml-3"
                          >
                            {feature.title}
                          </p>
                        </div>
                      ))}
                    </div>
                    <button
                      type="button"
                      className="mt-6 w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={onSubmit}
                    >
                      {t('getProLabel')}
                    </button>
                  </div>
                </div>
                <div className={classNames('flex-auto bg-gray-200')}>
                  <div className="-mx-2 text-sm text-gray-700">
                    <img src="/5.png" className="w-full h-full" />
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const classNames = (...classes: any) => classes.filter(Boolean).join(' ');

export default MarketingModal;
