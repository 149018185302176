import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface Props {
  isOpen: boolean;
  message?: string;
  onClose?: () => void;
}
const Dim = ({ isOpen, message, onClose }: Props) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => onClose && onClose()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity flex justify-center items-center">
            {message && <p className="text-gray-200 font-medium">{message}</p>}
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export default Dim;
