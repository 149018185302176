import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
  orderBy,
} from 'firebase/firestore';
import { useEffect, useState, useMemo } from 'react';
import { useAuth } from '../Auth/AuthProvider';
import keyBy from 'lodash/keyBy';

const useLocations = () => {
  const [locations, setLocations] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    if (user?.storeId) {
      const ref = collection(getFirestore(), 'locations');
      const q = query(
        ref,
        where('storeId', '==', user?.storeId),
        orderBy('createdAt', 'desc'),
      );

      getDocs(q)
        .then((querySnapshot: any) => {
          setLocations(
            querySnapshot.docs.map((doc: any) => ({
              id: doc.id,
              ...doc.data(),
            })),
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [user?.storeId]);

  const getLocationNameById = (locationId: string | null) => {
    if (locationId) {
      const location: any = locations.find(
        (location: { id: string }) => location.id === locationId,
      );

      return location?.name;
    } else {
      const location: any = locations.find((location: any) => location.default);

      return location?.name;
    }
  };

  const locationsIndex: any = useMemo(
    () => keyBy(locations, 'id'),
    [locations.length],
  );

  return { locations, getLocationNameById, locationsIndex, isLoading };
};

export default useLocations;
