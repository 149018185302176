import { getStockLevel } from '@posy/products';

import { getIsLowStock, getIsOutOfStock } from './helpers';

const getItemInventory = (
  product: any,
  variationId: string,
  quantity: number,
  locationId: string,
) => {
  const { available, tracked } = getStockLevel({
    product,
    locationId,
    variationId,
  });
  const minQuantity = product?.variations[variationId].minQuantity;
  const isOutOfStock = tracked ? getIsOutOfStock(available, quantity) : false;
  const isLowStock = tracked
    ? getIsLowStock(available, quantity, minQuantity)
    : false;

  return {
    isOutOfStock,
    isLowStock,
    available,
    tracked,
  };
};

export default getItemInventory;
