import { useTranslation } from 'react-i18next';
import { Field, useSidePanel } from '@posy/components';

import Button from 'components/Button';
import FieldLabel from 'components/FieldLabel';
const FlowPayment = ({
  visible = true,
  values,
  isConfigured,
  registerField,
  setField,
}: {
  isConfigured: boolean;
  visible?: boolean;
  values: any;
  registerField: any;
  setField: any;
}) => {
  const { navigateTo } = useSidePanel();
  const { t } = useTranslation();

  const onSubmit = (data: any) => {
    setField('paymentMethods.flow', data);
  };

  const openPanel = () => {
    navigateTo('PaymentGateway', { values, onSubmit, registerField });
  };

  const field = {
    name: 'paymentMethods.flow',
    type: 'custom',
    renderComponent: () => {
      return (
        <>
          {isConfigured ? (
            <Field
              key={field.name}
              {...registerField({
                label: 'Pago en linea con flow',
                name: 'paymentMethods.flow.active',
                type: 'boolean',
                default: false,
              })}
            />
          ) : (
            <div className="flex items-center">
              <FieldLabel label={t('paymentMethods.flow')} />
              <div className="flex-auto">
                <img src="/flow.png" className="w-20 ml-1" />
              </div>
              <Button
                bordered
                noMargin
                label={t('setup')}
                onClick={openPanel}
              />
            </div>
          )}
          {isConfigured && (
            <Button
              label={t('edit')}
              textSize="xs"
              noBorder
              onClick={openPanel}
            />
          )}
        </>
      );
    },
  };

  return visible ? <Field {...registerField(field)} /> : null;
};

export default FlowPayment;
