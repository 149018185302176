import { useForm, Field, useSidePanel } from '@posy/components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FormCard from '../../components/FormCard';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { useState } from 'react';

const EditPayment = () => {
  const { t } = useTranslation();
  const { navigateTo, params } = useSidePanel();
  const { toPay, amount, method, order, onFinish } = params;
  const [defaultValues] = useState({ amount, method });
  const { registerField, handleSubmit } = useForm({ defaultValues });

  const fields = [
    {
      label: t('amountToPay', { method: t(`paymentMethods.${method}`) }),
      name: 'amount',
      type: 'text',
      rules: ['required', 'greaterThanZero'],
      validate: (value: number) => {
        const isNotCash = method !== 'cash';

        if (isNotCash && value > order.total) {
          return {
            type: 'custom',
            message: 'Monto a pagar no debe ser mayor al total',
          };
        }
      },
    },
  ];

  const goBack = () => {
    navigateTo('Payment', { order, method, toPay, onFinish });
  };

  const submit = (values: any) => {
    navigateTo('Payment', {
      order,
      method,
      toPay,
      editedAmount: parseInt(values.amount),
      onFinish,
    });
  };

  return (
    <>
      <div className="px-4 overflow-auto">
        <div className="flex justify-end p-4">
          <div className="flex-1">
            <button
              type="button"
              className="inline-flex text-2xl font-semibold text-gray-900"
              onClick={goBack}
            >
              <ChevronLeftIcon className="h-8 w-8" aria-hidden="true" />
              {t('editPayment')}
            </button>
          </div>
        </div>
        <Wrapper>
          <div className="flex gap-4">
            <FormCard>
              {fields.map((field) => (
                <Field key={field.name} {...registerField(field)} />
              ))}
            </FormCard>
          </div>
        </Wrapper>
        <div className="flex justify-end  my-4"></div>
      </div>
      <div className="mt-8 px-4 pt-6 my-4">
        <div>
          <button
            className="bg-indigo-700 text-white font-medium rounded-md w-full px-4 py-2"
            onClick={handleSubmit(submit)}
          >
            {t('save')}
          </button>
        </div>
      </div>
    </>
  );
};

const Wrapper = styled.div`
  grid-gap: 12px;
`;

export default EditPayment;
