import { getAuth } from 'firebase/auth';
import {
  getFirestore,
  setDoc,
  doc,
  collection,
  arrayUnion,
} from 'firebase/firestore';

const createPendingOrder = (draftOrder: any) => {
  const auth = getAuth();
  const currentUser = auth.currentUser;

  const statusPayload = {
    status: 'pending',
    timeline: arrayUnion({
      status: 'pending',
      createdAt: new Date().getTime(),
      user: {
        name: currentUser?.displayName,
      },
      uid: currentUser?.uid,
    }),
  };
  const orderRef = doc(collection(getFirestore(), 'orders'));
  setDoc(orderRef, {
    ...draftOrder,
    name: `#${orderRef.id.slice(0, 4)}`,
    ...statusPayload,
  });
};

export default createPendingOrder;
