import { doc, getFirestore, deleteDoc } from 'firebase/firestore';
import { collectionName } from './constants';

const deleteCategory = async (categoryId: any) => {
  const db = getFirestore();
  const categoryRef = doc(db, collectionName, categoryId);
  deleteDoc(categoryRef);
};

export default deleteCategory;
