import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from './AuthProvider';
import {
  LoadingDim,
  BackButton,
  useToast,
  Field,
  useForm,
} from '@posy/components';
import { useNavigate } from 'react-router-dom';

const Left = () => {
  return <div className="hidden lg:block relative w-0 flex-1 bg-gray-300" />;
};

export default function Example() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [defaultValues] = useState({});
  const { registerField, handleSubmit } = useForm({ defaultValues });

  const { sendPasswordReset } = useAuth();

  const { openToast } = useToast();

  const submit = (values: any) => {
    setIsLoading(true);
    sendPasswordReset(values.email).then(() => {
      setIsLoading(false);
      openToast({
        title: t('resetPasswordMessage.title'),
        description: t('resetPasswordMessage.description', {
          email: values.email,
        }),
        type: 'success',
      });
    });
  };

  return (
    <>
      <div className="h-screen flex">
        <Left />
        <LoadingDim isLoading={isLoading} />
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img className="h-16 w-auto" src="posy_icon.png" alt="Logo" />
              <div className="mt-6">
                <BackButton onPress={() => navigate('/login')} />
                <h2 className="text-3xl font-extrabold text-gray-900">
                  {t('resetPasswordHeader')}
                </h2>
              </div>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <Field
                  {...registerField({
                    label: t('form.email'),
                    name: 'email',
                    type: 'text',
                    rules: ['required'],
                  })}
                />
                <button
                  type="submit"
                  onClick={handleSubmit(submit)}
                  className="w-full flex justify-center py-2 px-4 mt-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {t('reset')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
