import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Table } from '@posy/components';
import { useAuth } from '../Auth/AuthProvider';
import getColumns from './getColumns';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';
import generateReport from './generateReport';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { useProviderCustomers } from 'providers/CustomersProvider';

const Customers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { logEvent } = useAnalytics();
  const { hasPermission } = useAuth();
  const { customers, isLoading } = useProviderCustomers();
  const columns = getColumns();
  const searchFields = [{ label: t('form.name'), value: 'name' }];

  return (
    <>
      <PageHeader title={t('customers')}>
        <Button
          isVisible={hasPermission('CUSTOMER_UPDATE')}
          label={t('exportCustomers')}
          onClick={() => {
            generateReport(customers);
            logEvent('export_customers');
          }}
          bordered
        />
        <Button
          isVisible={hasPermission('CUSTOMER_UPDATE')}
          label={t('createCustomer')}
          onClick={() => navigate(`/customers/new`)}
        />
      </PageHeader>
      <Table
        columns={columns}
        rows={customers}
        onRowClick={(id: string) => navigate(`/customers/${id}`)}
        searchFields={searchFields}
        isLoading={isLoading}
        sort={['name', 'ASC']}
        style={{
          height: '100%',
          background: 'white',
          margin: 16,
        }}
      />
    </>
  );
};

export default Customers;
