import { useState } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateRangePicker = ({
  startDate: initialStartDate,
  endDate: initialEndDate,
  onChange,
}: {
  startDate: string;
  endDate: string | null;
  onChange: (startDate: string, endDate: string | null) => void;
}) => {
  const [startDate, setStartDate] = useState<string>(initialStartDate);
  const [endDate, setEndDate] = useState<string | null>(initialEndDate);

  return (
    <div>
      <DatePicker
        popperPlacement="top"
        dateFormat="dd/MM/yyyy"
        selected={startDate ? new Date(startDate) : new Date()}
        onChange={(date) => {
          const startDate = moment(date).startOf('day').toISOString();
          onChange(startDate, endDate);

          setStartDate(startDate);
          setEndDate(null);
        }}
      />
      <DatePicker
        popperPlacement="top"
        dateFormat="dd/MM/yyyy"
        selected={endDate ? new Date(endDate) : new Date()}
        onChange={(date) => {
          const endDate = moment(date).endOf('day').toISOString();
          onChange(startDate, endDate);
          setEndDate(endDate);
        }}
      />
    </div>
  );
};

export default DateRangePicker;
