const currencies = {
  US: 'USD',
  AR: 'ARS',
  BO: 'BOB',
  CL: 'CLP',
  CO: 'COP',
  MX: 'MXN',
  PE: 'PEN',
  PY: 'PYG',
  UY: 'UYU',
  EC: 'USD',
  VE: 'VEN',
  ES: 'EUR',
};

export type CountryKeys = keyof typeof currencies;

export { currencies };
