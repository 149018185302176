import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { Category } from 'types';
import { collectionName } from './constants';

const getCategory = async (categoryId: string) => {
  const docRef = doc(getFirestore(), collectionName, categoryId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const category = docSnap.data() as Category;

    return { id: docSnap.id, ...category };
  } else {
    console.error('No such document!');

    return null;
  }
};

export default getCategory;
