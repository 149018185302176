interface Props {
  isEditing: boolean;
  hasPermission: (permission: string) => boolean;
}

const usePermissions = ({ isEditing, hasPermission }: Props) => {
  const canUpdateProduct = hasPermission('PRODUCT_UPDATE');
  const canHandleStock = hasPermission('HANDLE_STOCK');
  const shouldShowSaveButton = canUpdateProduct || canHandleStock;
  const shouldShowDeleteButton = isEditing && canUpdateProduct;
  const shouldShowCreateVariantButton = canUpdateProduct;

  return {
    shouldShowSaveButton,
    shouldShowDeleteButton,
    shouldShowCreateVariantButton,
    canUpdateProduct,
    canHandleStock,
  };
};

export default usePermissions;
