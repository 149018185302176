const formatPriceValue = (value) => {
  return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const compareString = (str1, str2) => {
  const nameSanitized = str1
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim()
    .toLowerCase();

  return nameSanitized.includes(str2.toLowerCase());
};

const sanitizeString = (input) => {
  const formattedInput = input ? String(input) : '';
  return formattedInput
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim()
    .toLowerCase();
};

const getDiscountRate = (variation) => {
  const discount = (variation.price * 100) / variation.referencePrice;
  return `-${(100 - discount).toFixed(0)}%`;
};

export { formatPriceValue, getDiscountRate, compareString, sanitizeString };
