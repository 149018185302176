const formatPriceValue = (value: number) => {
  return "$" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const compareString = (str1: string, str2: string) => {
  const nameSanitized = str1
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .trim()
    .toLowerCase();

  return nameSanitized.includes(str2.toLowerCase());
};

const sanitizeString = (input: string) => {
  const formattedInput = input ? String(input) : "";
  return formattedInput
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .trim()
    .toLowerCase();
};

const getDiscountRate = (variation: any) => {
  const discount = (variation.price * 100) / variation.referencePrice;
  return `-${(100 - discount).toFixed(0)}%`;
};

const getCatalogHostname = () => {
  const hostname = window.location.hostname;
  const isStaging =
    hostname.includes("staging") || hostname.includes("localhost");

  if (isStaging) {
    return "staging-myposyshop.com";
  } else {
    return "myposyshop.com";
  }
};

const formatUrlName = (input: string) => {
  return input
    .toLowerCase()
    .trim()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
};

export {
  getCatalogHostname,
  formatUrlName,
  formatPriceValue,
  getDiscountRate,
  compareString,
  sanitizeString,
};
