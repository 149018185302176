import styled from 'styled-components';

interface Props {
  isOutOfStock: boolean;
  isLowStock: boolean;
  quantity: number;
}
const InventoryBadge = ({ isOutOfStock, isLowStock, quantity }: Props) => {
  return (
    <Badge className={getBgClass(isOutOfStock, isLowStock)}>
      <p className="text-white font-bold text-sm mx-2">{quantity}</p>
    </Badge>
  );
};

const getBgClass = (isOutOfStock: boolean, isLowStock: boolean) => {
  if (isOutOfStock) {
    return 'bg-red-700';
  } else if (isLowStock) {
    return 'bg-yellow-600';
  } else {
    return 'bg-indigo-700';
  }
};

const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-width: 32px;
  min-height: 32px;
  border-radius: 32px;
  position: relative;
  top: -8px;
  left: -8px;
`;

export default InventoryBadge;
