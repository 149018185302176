import {
  collection,
  query,
  where,
  orderBy,
  getFirestore,
  getDocs,
} from 'firebase/firestore';

const getLocations = async (storeId: string) => {
  const ref = collection(getFirestore(), 'locations');
  const q = query(
    ref,
    where('storeId', '==', storeId),
    orderBy('createdAt', 'desc'),
  );

  const snapshot = await getDocs(q);

  return snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

export default getLocations;
