import {
  getFirestore,
  doc,
  collection,
  query,
  where,
  getDoc,
  getDocs,
} from 'firebase/firestore';
import getSubscriberByStoreId from './getSubscriberByStoreId';
import moment from 'moment-timezone';

const getUserData = async (user: any) => {
  if (user) {
    const userId = user.uid;
    const db = getFirestore();
    const userRef = doc(db, 'users', userId);
    const ref = collection(db, 'stores');
    const q = query(ref, where(`users.${userId}.active`, '==', true));
    try {
      const userSnapshot = await getDoc(userRef);
      const storeSnapshot = await getDocs(q);
      const [store] = storeSnapshot.docs;

      const subscriptions = await getSubscriberByStoreId(store.id);

      const storeUID = store.data().uid;
      const userId = userSnapshot.id;
      const isAdmin = userId === storeUID;
      const { plan, trialDayNumber, trialProgress } = getSubscriptionData(
        subscriptions,
        store.data().trialEndDate,
      );
      const isEligibleForDiscount = getIsEligibleForDiscount(subscriptions);
      if (userSnapshot.exists() && store) {
        const isBaseCountry = ['CL', 'Chile'].includes(store.data().country);

        return {
          id: userId,
          ...userSnapshot.data(),
          storeId: store.id,
          store: {
            id: store.id,
            ...store.data(),
          },
          isAdmin,
          plan,
          isSubscriptionActive: ['trial', 'pro'].includes(plan),
          isEligibleForDiscount,
          subscriptions,
          isBaseCountry,
          trialDayNumber,
          trialProgress,
        };
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  } else {
    return null;
  }
};

const getSubscriptionData = (subscriptions: any[], trialEndDate: any) => {
  const now = moment();

  if (subscriptions.length > 0) {
    const [subscription] = subscriptions;
    const isSubscriptionActive = moment(subscription?.nextChargeDate).isAfter(
      now,
    );

    return {
      plan: isSubscriptionActive ? 'pro' : 'free',
    };
  } else {
    const isSubscriptionActive = moment(trialEndDate).isAfter(now);
    const trialDays = 7;
    const trialDayNumber = trialDays - moment(trialEndDate).diff(now, 'days');
    const trialProgress = (trialDayNumber * 100) / trialDays;

    return {
      plan: isSubscriptionActive ? 'trial' : 'free',
      trialDayNumber,
      trialProgress,
    };
  }
};

const getIsEligibleForDiscount = (subscriptions: any[]) => {
  if (subscriptions.length > 0) {
    const filteredSubscriptions = subscriptions.filter(
      (subscription) => subscription.provider === 'flow',
    );
    const [subscription] = filteredSubscriptions;

    return subscription?.eligibleForDiscount;
  } else {
    return false;
  }
};

export default getUserData;
