import React from 'react';
import { useSidePanel } from './SidePanelProvider';

type Props = {
  name: string;
  component: React.ReactNode;
};

const SidePanelRoute = ({ name, component }: Props) => {
  const { route } = useSidePanel();

  if (route !== name) {
    return null;
  }

  return <>{component}</>;
};

export default SidePanelRoute;
