import { useState, useEffect } from 'react';

function getStorageValue(key, initialValue) {
  if (typeof window !== 'undefined') {
    const data = window.localStorage.getItem(key);
    const value = JSON.parse(data);

    return value || initialValue;
  }
  return initialValue;
}

const useLocalStorage = (key, initialValue) => {
  const [value, setValue] = useState(() => getStorageValue(key, initialValue));

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);

  return { value, setValue };
};

export default useLocalStorage;
