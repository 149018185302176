import { Table } from '@posy/components';
import { useTranslation } from 'react-i18next';

const Items = ({ data }: { data: any }) => {
  const { t } = useTranslation();
  const columns = [
    { key: 'name', label: t('form.name') },
    { key: 'total', label: 'Total', type: 'money' },
    { key: 'quantity', label: 'Cantidad' },
  ];
  return (
    <div className="flex flex-col bg-white p-4 mt-4 height-fit overflow-y-auto">
      <dt className="text-base font-normal text-gray-900 mb-4">
        {t('bestSellingItems')}
      </dt>
      <Table
        columns={columns}
        rows={data}
        style={{
          overflow: 'auto',
          flex: 1,
          background: 'white',
          margin: 16,
        }}
      />
    </div>
  );
};

export default Items;
