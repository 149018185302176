import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from 'react-router-dom';
import { useEffect } from 'react';
import { SidePanelRoute, SidePanelRouter } from '@posy/components';

import { useAuth } from './Auth/AuthProvider';
import Auth from 'Auth/Auth';
import Home from './Home/Home';
import Layout from './Layout/Layout';
import Products from './product/Products';
import ClosedOrders from './Orders/ClosedOrders';
import OpenOrders from './Orders/OpenOrders';
import Order from './Orders/Order';
import Product from './product/Products/Product';
import OnlineCatalog from './OnlineCatalog/OnlineCatalog';
import CustomerList from './Customer/CustomerList';
import Customer from './Customer/Customer';
import Pricing from './Home/Pricing';
import UserAccounts from './UserAccount/UserAccounts';
import UserAccount from './UserAccount/UserAccount';
import Category from './product/Category/Category';
import Categories from './product/Category/Categories';
import InventoryMovements from './product/InventoryMovements/InventoryMovements';
import InventorySelection from './product/InventoryMovements/InventorySelection';
import Payment from './checkout/Payment/Payment';
import PaymentCompleted from './checkout/Payment/PaymentCompleted';
import EditPayment from './checkout/Payment/EditPayment';
import ResetPassword from './Auth/ResetPassword';
import Analytics from './Analytics/Analytics';
import Checkout from './checkout/Checkout/Checkout';
import SelectCustomer from './Customer/SelectCustomer';
import Catalog from './OnlineCatalog/Catalog';
import LoadingPage from './LoadingPage';
import SubscriptionResume from './Pricing/SubscriptionResume';
import BillingFormScreen from './Pricing/BillingFormScreen';
import Settings from './settings/Settings';
import Locations from './settings/Locations/Locations';
import GeneralSettings from './settings/GeneralSettings/GeneralSettings';
import Location from './settings/Locations/Location';
import UserSetting from './settings/User/User';
import PaymentGateway from 'OnlineCatalog/PaymentGateway';
import PaymentMethod from 'OnlineCatalog/PaymentMethod';
import Delete from './settings/User/Delete';
import Receipt from 'Receipt';
import UploadProducts from 'UploadProducts';

function App() {
  return (
    <>
      <SidePanelRouter>
        <SidePanelRoute name="UploadProducts" component={<UploadProducts />} />
        <SidePanelRoute name="Receipt" component={<Receipt />} />
        <SidePanelRoute name="Category" component={<Category />} />
        <SidePanelRoute name="Categories" component={<Categories />} />
        <SidePanelRoute
          name="InventoryMovements"
          component={<InventoryMovements />}
        />
        <SidePanelRoute
          name="InventorySelection"
          component={<InventorySelection />}
        />
        <SidePanelRoute name="Payment" component={<Payment />} />
        <SidePanelRoute
          name="PaymentCompleted"
          component={<PaymentCompleted />}
        />
        <SidePanelRoute name="EditPayment" component={<EditPayment />} />
        <SidePanelRoute name="SelectCustomer" component={<SelectCustomer />} />
        <SidePanelRoute
          name="SubscriptionResume"
          component={<SubscriptionResume />}
        />
        <SidePanelRoute
          name="BillingFormScreen"
          component={<BillingFormScreen />}
        />
        <SidePanelRoute name="PaymentGateway" component={<PaymentGateway />} />
        <SidePanelRoute name="PaymentMethod" component={<PaymentMethod />} />
        <SidePanelRoute
          name="Customer"
          component={<Customer isInSidePanel />}
        />
      </SidePanelRouter>
      <Routes>
        <Route>
          <Route path="/login" element={<Auth />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/"
            element={
              <RequireAuth>
                <Layout path="/">
                  <Home />
                </Layout>
              </RequireAuth>
            }
          />
          <Route
            path="/pricing"
            element={
              <RequireAuth>
                <Layout path="/">
                  <Pricing />
                </Layout>
              </RequireAuth>
            }
          />
          <Route
            path="/checkout"
            element={
              <RequireAuth>
                <Layout path="/checkout">
                  <Checkout />
                </Layout>
              </RequireAuth>
            }
          />
          <Route
            path="/online-catalog/:id"
            element={
              <RequireAuth>
                <Layout path="/">
                  <OnlineCatalog />
                </Layout>
              </RequireAuth>
            }
          />
          <Route
            path="/online-catalog"
            element={
              <RequireAuth>
                <Layout path="/online-catalog">
                  <Catalog />
                </Layout>
              </RequireAuth>
            }
          />
          <Route
            path="/products"
            element={
              <RequireAuth>
                <Layout path="/products">
                  <Products />
                </Layout>
              </RequireAuth>
            }
          />
          <Route
            path="/products/new"
            element={
              <RequireAuth>
                <Layout path="/products">
                  <Product />
                </Layout>
              </RequireAuth>
            }
          />
          <Route
            path="/products/:id"
            element={
              <RequireAuth>
                <Layout path="/products">
                  <Product />
                </Layout>
              </RequireAuth>
            }
          />
          <Route
            path="/open-orders"
            element={
              <RequireAuth>
                <Layout path="/open-orders">
                  <OpenOrders />
                </Layout>
              </RequireAuth>
            }
          />
          <Route
            path="/open-orders/:id"
            element={
              <RequireAuth>
                <Layout path="/open-orders">
                  <Order />
                </Layout>
              </RequireAuth>
            }
          />
          <Route
            path="/closed-orders"
            element={
              <RequireAuth>
                <Layout path="/closed-orders">
                  <ClosedOrders />
                </Layout>
              </RequireAuth>
            }
          />
          <Route
            path="/closed-orders/:id"
            element={
              <RequireAuth>
                <Layout path="/closed-orders">
                  <Order />
                </Layout>
              </RequireAuth>
            }
          />
          <Route
            path="/customers"
            element={
              <RequireAuth>
                <Layout path="/customers">
                  <CustomerList />
                </Layout>
              </RequireAuth>
            }
          />
          <Route
            path="/customers/new"
            element={
              <RequireAuth>
                <Layout path="/customers">
                  <Customer />
                </Layout>
              </RequireAuth>
            }
          />
          <Route
            path="/customers/:id"
            element={
              <RequireAuth>
                <Layout path="/customers">
                  <Customer />
                </Layout>
              </RequireAuth>
            }
          />
          <Route
            path="/userAccounts"
            element={
              <RequireAuth>
                <Layout path="/userAccounts">
                  <UserAccounts />
                </Layout>
              </RequireAuth>
            }
          />
          <Route
            path="/userAccounts/new"
            element={
              <RequireAuth>
                <Layout path="/userAccounts">
                  <UserAccount />
                </Layout>
              </RequireAuth>
            }
          />
          <Route
            path="/userAccounts/:id"
            element={
              <RequireAuth>
                <Layout path="/userAccounts">
                  <UserAccount />
                </Layout>
              </RequireAuth>
            }
          />
          <Route
            path="/analytics"
            element={
              <RequireAuth>
                <Layout path="/analytics">
                  <Analytics />
                </Layout>
              </RequireAuth>
            }
          />
          <Route
            path="/settings"
            element={
              <RequireAuth>
                <Layout path="/settings">
                  <Settings />
                </Layout>
              </RequireAuth>
            }
          />
          <Route
            path="/settings/general"
            element={
              <RequireAuth>
                <Layout path="/settings/general">
                  <GeneralSettings />
                </Layout>
              </RequireAuth>
            }
          />
          <Route
            path="/settings/locations"
            element={
              <RequireAuth>
                <Layout path="/settings/locations">
                  <Locations />
                </Layout>
              </RequireAuth>
            }
          />
          <Route
            path="/settings/locations/new"
            element={
              <RequireAuth>
                <Layout path="/settings/locations/new">
                  <Location />
                </Layout>
              </RequireAuth>
            }
          />
          <Route
            path="/settings/locations/:id"
            element={
              <RequireAuth>
                <Layout path="/settings/locations/:id">
                  <Location />
                </Layout>
              </RequireAuth>
            }
          />
          <Route
            path="/settings/user/:id"
            element={
              <RequireAuth>
                <Layout path="/settings/user/:id">
                  <UserSetting />
                </Layout>
              </RequireAuth>
            }
          />
          <Route
            path="/settings/user/delete"
            element={
              <RequireAuth>
                <Layout path="/settings/user/delete">
                  <Delete />
                </Layout>
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </>
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const { user, userIsLoading } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const isAdmin = user?.isAdmin;
  const isSubscriptionActive = user?.isSubscriptionActive;

  useEffect(() => {
    !isSubscriptionActive && !userIsLoading && navigate('/pricing');
  }, [userIsLoading, isSubscriptionActive, isAdmin, location.pathname]);

  if (!user && !userIsLoading) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (userIsLoading) {
    return <LoadingPage />;
  } else {
    return children;
  }
}

export default App;
