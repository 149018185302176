import { formatPriceValue } from '../../../helpers';

interface Props {
  amount: number;
}

const Balance = ({ amount }: Props) => {
  if (amount > 0) {
    return (
      <span className="text-red-500 font-medium">
        Por pagar: {formatPriceValue(amount)}
      </span>
    );
  }

  if (amount < 0) {
    return (
      <span className="text-green-700 font-medium">
        Vuelto: {formatPriceValue(amount * -1)}
      </span>
    );
  }

  return null;
};

export default Balance;
