import { getFunctions, httpsCallable } from 'firebase/functions';
import moment from 'moment-timezone';

const getReportData = async (
  storeId: string,
  startDate: string,
  endDate: string,
  locationId?: string,
  isDefaultLocation?: boolean,
  cb?: any,
) => {
  const functions = getFunctions();
  const start: number = moment(startDate).unix() * 1000;
  const end: number = moment(endDate).unix() * 1000;

  const getOrders = httpsCallable(functions, 'order-getOrderReport');
  getOrders({
    startDate: start,
    endDate: end,
    storeId,
    status: 'completed',
    filterBy: 'closedAt',
    locationId,
    isDefaultLocation,
  }).then((response) => {
    cb(response.data);
  });
};

export default getReportData;
