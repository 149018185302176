import { getFirestore, doc, writeBatch, collection } from 'firebase/firestore';
import { getUserPayload, getStorePayload } from '../Auth/helpers';
import getCountry from './getCountry';

const createAccount = async (
  formValues: any,
  user: any,
  provider: string,
  logEvent: (eventName: string, params: any) => void,
) => {
  const db = getFirestore();

  try {
    const batch = writeBatch(db);

    const userRef = doc(collection(db, 'users'), user.uid);
    const storeRef = doc(collection(db, 'stores'));
    const locationRef = doc(collection(db, 'locations'));

    const country = await getCountry();

    batch.set(userRef, getUserPayload(formValues, user, storeRef.id));
    batch.set(storeRef, getStorePayload(formValues, user, country));
    batch.set(locationRef, {
      name: 'Store location',
      storeId: storeRef.id,
      uid: user.uid,
      createdAt: new Date().getTime(),
      default: true,
    });

    batch.commit();
    logEvent('sign_up', {
      provider,
      userId: userRef.id,
      storeId: storeRef.id,
    });
  } catch (e) {
    console.error('err', e);
  }
};

export default createAccount;
