import { useTranslation } from 'react-i18next';
import { MailIcon } from '@heroicons/react/outline';

import LoginHeader from './LoginHeader';
import GoogleIcon from './GoogleIcon';
import AppleIcon from './AppleIcon';

interface Props {
  signWithGoogle: () => void;
  signWithApple: () => void;
  signInWithEmail: () => void;
}

const ProviderOptions = ({
  signWithGoogle,
  signWithApple,
  signInWithEmail,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="mt-1 grid grid-cols-1 gap-3">
      <LoginHeader />
      <button
        onClick={signWithGoogle}
        className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
      >
        <span className="sr-only">{t('signInWithGoogle')}</span>
        <GoogleIcon />
        <span className="text-sm font-semibold leading-6 ml-4">Google</span>
      </button>

      <button
        onClick={signWithApple}
        className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
      >
        <span className="sr-only">{t('signInWithApple')}</span>
        <AppleIcon />
        <span className="text-sm font-semibold leading-6 ml-4">Apple</span>
      </button>
      <button
        onClick={signInWithEmail}
        className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
      >
        <span className="sr-only"> {t('form.email')}</span>
        <MailIcon className="w-5 h-5" />
        <span className="text-sm font-semibold leading-6 ml-4">
          {t('form.email')}
        </span>
      </button>
    </div>
  );
};

export default ProviderOptions;
