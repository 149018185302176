import { getFirestore, doc, writeBatch, arrayUnion } from 'firebase/firestore';
import { getCurrentTime, getTimelinePayload } from './helpers';

const completeOrder = (order: any, user: any) => {
  const db = getFirestore();
  const batch = writeBatch(db);
  const timelinePayload = getTimelinePayload('completed', user);
  const orderPayload = {
    ...order,
    status: 'completed',
    timeline: arrayUnion(timelinePayload),
    updatedAt: getCurrentTime(),
    closedAt: getCurrentTime(),
    confirmed: true,
    isConfirmed: true,
    isClosed: true,
    isSale: true,
  };
  console.info({ orderPayload });
  const orderRef = doc(db, 'orders', order.id);
  batch.update(orderRef, orderPayload);
  batch.commit();
};

export default completeOrder;
