import React from 'react';
import useCustomers from 'Customer/hooks/useCustomers';
import { Customer } from 'types';

interface ModalContextType {
  customers: Customer[];
  isLoading: boolean;
}

const ModalContext = React.createContext<ModalContextType>(null!);

const CustomersProvider = ({ children }: { children: React.ReactNode }) => {
  const { customers, isLoading }: any = useCustomers();
  const value: ModalContextType = {
    customers,
    isLoading,
  };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

function useProviderCustomers() {
  return React.useContext(ModalContext);
}

export { useProviderCustomers };
export default CustomersProvider;
