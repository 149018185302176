const AppLogo = () => {
  return (
    <img
      className="animate-pulse-slow h-20 w-20 mx-auto"
      src="/posy_icon.png"
      alt="Posy Logo"
    />
  );
};

export default AppLogo;
