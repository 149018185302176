import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from '@posy/components';
import { useAuth } from 'Auth/AuthProvider';
import { UserAccount } from 'types';
import getUserAccount from 'api/UserAccount/getUserAccount';
import formatPermissions from 'api/UserAccount/helpers/formatPermissions';
import { useTranslation } from 'react-i18next';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { getFunctions, httpsCallable } from 'firebase/functions';
import decodePermissions from '../helpers/decodePermissions';
import updateUserAccount from 'api/UserAccount/updateUserAccount';

const useUserAccount = () => {
  const [userAccount, setUserAccount] = useState<UserAccount>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { logEvent } = useAnalytics();
  const { user, sendPasswordReset } = useAuth();
  const { id } = useParams();
  const { openToast } = useToast();

  useEffect(() => {
    if (id) {
      getUserAccount(id).then((user) =>
        setUserAccount({
          ...user,
          permissions: formatPermissions(user.permissions),
        }),
      );
    }
  }, [id]);

  const submit = (values: any) => {
    const functions = getFunctions();
    const permissions = decodePermissions(values.permissions);

    if (id) {
      const payload = {
        name: values.name,
        permissions,
        active: values.active,
        locationId: values.locationId,
      };
      if (userAccount?.active !== values.active) {
        setIsLoading(true);
        const handleUserAccess = httpsCallable(
          functions,
          'handleStoreUserAccess',
        );
        handleUserAccess({ active: payload.active }).then(() => {
          setIsLoading(false);
          updateUserAccount(id, payload);
          navigate('/userAccounts');
        });
      } else {
        updateUserAccount(id, payload);
        navigate('/userAccounts');
      }
      logEvent('update_store_user');
    } else {
      setIsLoading(true);
      const createUserAccount = httpsCallable(functions, 'createStoreUser');
      const payload = {
        storeId: user.storeId,
        storeName: '',
        ...values,
        permissions,
        locationId: values.locationId,
      };
      createUserAccount(payload)
        .then(() => {
          setIsLoading(false);
          navigate('/userAccounts');
        })
        .catch(() => {
          setIsLoading(false);
          openToast({
            title: t('errorMessages.errorTitle'),
            description: t('errorMessages.emailTaken'),
            type: 'error',
          });
        });
      logEvent('create_store_user');
    }
  };

  const requestDeleteUserAccount = () => {
    setIsLoading(true);
    const functions = getFunctions();
    const deleteUserAccount = httpsCallable(functions, 'deleteStoreUser');
    deleteUserAccount({ uid: id })
      .then(() => {
        navigate('/userAccounts');
        logEvent('delete_store_user');
      })
      .catch((e) => {
        setIsLoading(false);
        alert(e);
        navigate('/userAccounts');
      });
  };

  const requestPasswordReset = () => {
    if (!userAccount) return;

    setIsLoading(true);
    sendPasswordReset(userAccount.email).then(() => {
      setIsLoading(false);
      openToast({
        title: t('resetPasswordMessage.title'),
        description: t('resetPasswordMessage.description', {
          email: userAccount.email,
        }),
        type: 'success',
      });
    });
  };

  return {
    userAccount,
    isLoading,
    submit,
    requestPasswordReset,
    requestDeleteUserAccount,
  };
};

export default useUserAccount;
