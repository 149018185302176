const API_URL = 'https://api.country.is/';

const getCountry = async () => {
  try {
    const res = await fetch(API_URL);
    const data = await res.json();

    return data?.country;
  } catch (e) {
    return 'US';
  }
};

export default getCountry;
