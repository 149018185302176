import { useRef, useEffect, useState } from 'react';

const useBarcodeScanner = () => {
  const barcodeEl = useRef('');
  const [isScanning, setIsScanning] = useState(false);
  const [barcode, setBarcode] = useState('');

  useEffect(() => {
    window.addEventListener('keydown', readItemFromScan);

    return () => {
      window.removeEventListener('keydown', readItemFromScan);
    };
  }, []);

  const readItemFromScan = (e: any) => {
    const isValid = barcodeEl.current.length >= 7;

    if (e.keyCode === 13) {
      isValid && setBarcode(barcodeEl.current);
      barcodeEl.current = '';
    } else {
      barcodeEl.current = `${barcodeEl.current}${e.key}`;
    }
    if (!isScanning) {
      setIsScanning(true);
      setTimeout(() => {
        barcodeEl.current = '';
        setIsScanning(false);
      }, 200);
    }
  };

  const resetBarcode = () => {
    setBarcode('');
  };

  return { barcode, resetBarcode };
};

export default useBarcodeScanner;
