import { useTranslation } from 'react-i18next';
import { priceFormatter } from '@posy/helpers';
import CopyButton from './components/CopyButton';
import ExternalButton from './components/ExternalButton';

const { onParse: onFormatValue } = priceFormatter;

interface Params {
  categories: any[];
  isEditing: boolean;
  locations: any[];
  getField: any;
  canUpdateProduct: boolean;
  canHandleStock: boolean;
  productUrl: string | null;
}

const useFields = ({
  categories,
  isEditing,
  locations,
  getField,
  canUpdateProduct,
  canHandleStock,
  productUrl,
}: Params) => {
  const { t } = useTranslation();
  const getFields = () => {
    return [
      {
        label: t('form.name'),
        name: 'name',
        type: 'text',
        rules: ['required'],
        readOnly: !canUpdateProduct,
      },
      {
        label: t('products.description'),
        name: 'description',
        type: 'paragraph',
        readOnly: !canUpdateProduct,
      },
    ];
  };

  const getOtherFields = () => {
    return [
      {
        label: t('products.category'),
        name: 'categoryId',
        type: 'select',
        options: categories,
        readOnly: !canUpdateProduct,
      },
      {
        label: t('products.format'),
        name: 'format',
        type: 'select',
        rules: ['required'],
        options: [
          {
            id: 'unit',
            name: t('products.unit'),
          },
          {
            id: 'fraction',
            name: t('products.fraction'),
          },
        ],
        disabled: false,
        readOnly: !canUpdateProduct,
      },
      {
        label: t('products.isPublished'),
        name: 'isPublished',
        type: 'boolean',
        default: true,
        readOnly: !canUpdateProduct,
        renderLabel: () => {
          const shouldDisplayActions = productUrl && getField('isPublished');

          return (
            <div className="flex items-center">
              <p className="block text-sm font-medium text-gray-700">
                {t('products.isPublished')}
              </p>
              {shouldDisplayActions && (
                <>
                  <CopyButton textToCopy={productUrl} />
                  <ExternalButton url={productUrl} />
                </>
              )}
            </div>
          );
        },
      },
      {
        label: t('products.isHighlighted'),
        name: 'isHighlighted',
        type: 'boolean',
        default: false,
      },
    ];
  };

  const getVariationArrayFields = (index: number, variations: any[]) => [
    {
      label: t('form.name'),
      name: `variations.${index}.name`,
      type: 'text',
      rules: ['required'],
      hidden: variations.length === 1,
      readOnly: !canUpdateProduct,
    },
    {
      label: t('products.price'),
      name: `variations.${index}.price`,
      type: 'text',
      rules: ['required', 'number'],
      onFormatValue: ({ value }: { value: string }) => onFormatValue(value),
      readOnly: !canUpdateProduct,
    },
    {
      label: t('products.costPrice'),
      name: `variations.${index}.costPrice`,
      type: 'text',
      rules: ['number', 'greaterThanZero'],
      onFormatValue: ({ value }: { value: string }) => onFormatValue(value),
      validate: validatePrices(t, index, 'costPrice'),
      readOnly: !canUpdateProduct,
    },
    {
      label: t('products.salePrice'),
      name: `variations.${index}.salePrice`,
      type: 'text',
      rules: ['number', 'greaterThanZero'],
      onFormatValue: ({ value }: { value: string }) => onFormatValue(value),
      validate: validatePrices(t, index, 'salePrice'),
      readOnly: !canUpdateProduct,
    },
    {
      label: t('products.barcode'),
      name: `variations.${index}.barcode`,
      type: 'text',
      readOnly: !canUpdateProduct,
    },
    {
      label: t('products.sku'),
      name: `variations.${index}.sku`,
      type: 'text',
      readOnly: !canUpdateProduct,
    },
  ];

  const getInventoryFields = (
    index: number,
    variations: any[],
    variationId: string,
  ) => [
    {
      label: t('form.name'),
      name: `variations.${index}.name`,
      type: 'text',
      rules: ['required'],
      hidden: variations.length === 1,
      readOnly: true,
    },
    ...getQuantityFields({
      t,
      locations,
      getField,
      index,
      isEditing,
      variationId,
    }),
    {
      label: t('products.lowStock'),
      name: `variations.${index}.minQuantity`,
      type: 'text',
      rules: ['number', 'integer', 'greaterThanZero'],
      hidden: !canHandleStock && !isEditing,
      readOnly: !canHandleStock,
    },
  ];

  const getQuantityFields = ({
    t,
    locations,
    getField,
    isEditing,
    index,
    variationId,
  }: any) => {
    const indexOrId = variationId || index;
    return locations.map(({ name, id: locationId }: any) => {
      return {
        label: t('products.quantity') + ' (' + name + ')',
        name: `locations.${locationId}.variations.${indexOrId}.available`,
        type: 'text',
        rules:
          getField('format') === 'unit' ? ['number', 'integer'] : ['number'],
        hidden: !canHandleStock && !isEditing,
        readOnly: !canHandleStock,
        validate: (value: string, formValues: any, defaultValue: string) => {
          const isValid =
            value === null || value === undefined || value.length === 0;

          if (isValid && isEditing && defaultValue) {
            return {
              type: 'custom',
              message: `El campo no puede quedar vacio`,
            };
          }
        },
      };
    });
  };

  return {
    getFields,
    getOtherFields,
    getVariationArrayFields,
    getInventoryFields,
  };
};

const validatePrices = (t: any, index: number, priceKey: string) => {
  return (value: number, formValues: any) => {
    const price = formValues.variations[index].price;
    const priceLabel = t(`products.${priceKey}`);

    if (Number(value) >= Number(price)) {
      return {
        type: 'custom',
        message: `${priceLabel} debe ser menor que el precio normal`,
      };
    }
  };
};

export default useFields;
