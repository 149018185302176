import { t } from 'i18next';
import uniq from 'lodash/uniq';

const getAmountInDebt = (total: number, payments: any[]): number => {
  const paidAmount = (payments || []).reduce(
    (total, payment) => total + payment.amount,
    0,
  );

  return total - paidAmount;
};

const getPaymentLabels = (payments = []) => {
  const labels = payments.map((payment: { method: string }) =>
    t(`paymentMethods.${payment.method}`),
  );

  return uniq(labels).join(', ');
};

export { getAmountInDebt, getPaymentLabels };
