import { useTranslation } from 'react-i18next';

interface Props {
  isOutOfStock: boolean;
  isLowStock: boolean;
}
const InventoryLabel = ({ isOutOfStock, isLowStock }: Props) => {
  const { t } = useTranslation();

  const getLabel = () => {
    if (isOutOfStock) {
      return <p className="text-red-500 text-xs">{t('outOfStock')}</p>;
    } else if (isLowStock) {
      return (
        <p className="text-yellow-600 text-xs">{t('products.lowStock')}</p>
      );
    }
  };

  return <div className="text-right">{getLabel()}</div>;
};

export default InventoryLabel;
