import { getFunctions, httpsCallable } from 'firebase/functions';
import moment from 'moment-timezone';

const getOverviewData = async (storeId: string, cb: any) => {
  const functions = getFunctions();
  const start: number = getStartDate();
  const end: number = getEndDate();

  const getOrders = httpsCallable(functions, 'order-getOverviewData');
  getOrders({
    start,
    end,
    storeId,
  }).then((response) => {
    cb(response.data);
  });
};

const getStartDate = () => {
  return moment().subtract(6, 'month').unix() * 1000;
};

const getEndDate = () => {
  return moment().subtract(1, 'day').endOf('day').unix() * 1000;
};

export default getOverviewData;
