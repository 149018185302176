import { useNavigate } from 'react-router-dom';
import { Field, LoadingDim, useForm, useModal } from '@posy/components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FormCard from '../../components/FormCard';
import { useAuth } from '../../Auth/AuthProvider';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useState } from 'react';

const Customer = () => {
  const { t } = useTranslation();
  const { logEvent } = useAnalytics();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { openModal } = useModal();
  const { user, hasPermission, signOut } = useAuth();
  const { registerField, handleSubmit } = useForm({
    defaultValues: user,
  });

  const shouldShowSaveButton = hasPermission('CUSTOMER_UPDATE');

  const fields = [
    {
      label: t('form.email'),
      name: 'email',
      type: 'email',
      rules: ['required'],
    },
  ];

  const submit = ({ email }: any) => {
    setIsLoading(true);
    const functions = getFunctions();
    const payload = { userId: user?.id, storeId: user?.storeId, email };

    const updateEmail = httpsCallable(functions, 'updateUserEmail');
    updateEmail(payload)
      .then(() => {
        setIsLoading(false);
        logEvent('update_user_email');
        openModal({
          title: t('updateEmail.modals.success.title'),
          body: t('updateEmail.modals.success.body'),
          onConfirm: signOut,
          confirmLabel: t('signInAgain'),
          isCancellable: false,
          color: 'bg-indigo-700',
        });
      })
      .catch(() => {
        openModal({
          title: t('updateEmail.modals.failure.title'),
          body: t('updateEmail.modals.failure.body'),
          onConfirm: () => null,
          confirmLabel: t('accept'),
          color: 'bg-indigo-700',
          isCancellable: false,
        });
        setIsLoading(false);
      });
  };

  return (
    <div className="px-4 overflow-auto">
      <div className="flex justify-end p-4">
        <div className="flex-1">
          <button
            type="button"
            className="inline-flex text-2xl font-semibold text-gray-900"
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon className="h-8 w-8" aria-hidden="true" />
            {t('updateEmail.title')}
          </button>
        </div>

        {shouldShowSaveButton && (
          <button
            className="bg-indigo-700 text-white font-medium rounded-md px-4 py-2"
            onClick={handleSubmit(submit)}
          >
            {t('update')}
          </button>
        )}
      </div>
      <Wrapper>
        <div className="flex gap-4">
          <FormCard>
            {fields.map((field) => (
              <Field key={field.name} {...registerField(field)} />
            ))}
          </FormCard>
        </div>
      </Wrapper>
      <LoadingDim isLoading={isLoading} />
    </div>
  );
};

const Wrapper = styled.div`
  grid-gap: 12px;
`;

export default Customer;
