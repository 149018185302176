const resources = {
  en: {
    translation: {
      admin: 'Admin',
      configurationGuide: 'Configuration guide',
      loading: 'Loading',
      team: 'Team',
      onlineCatalog: 'Online catalog',
      next: 'Next',
      remove: 'Remove',
      close: 'Close',
      edit: 'Edit',
      save: 'Save',
      update: 'Update',
      exportCustomers: 'Export customers',
      exportProducts: 'Export products',
      updateEmail: {
        title: 'Update email',
        modals: {
          success: {
            title: 'Completed',
            body: 'Email has been updated',
          },
          failure: {
            title: 'Error',
            body: 'Email is taken',
          },
        },
      },
      date: {
        weekDay: {
          monday: 'Monday',
          tuesday: 'Tuesday',
          wednesday: 'Wednesday',
          thursday: 'Thursday',
          friday: 'Friday',
          saturday: 'Saturday',
          sunday: 'Sunday',
        },
      },
      createLocation: 'Create location',
      signInAgain: 'Sign in again',
      search: 'Search',
      subscribe: 'To subscribe',
      setting: 'Setting',
      setup: 'Set up',
      activateStore: 'Activate Store',
      active: 'Active',
      inactive: 'Inactive',
      welcome: 'Welcome to Posy',
      goToSite: 'Go to site',
      customers: 'Customers',
      customer: 'Customer',
      seller: 'Seller',
      haveAccount: 'Already have an account',
      articles: 'Articles',
      category: 'Category',
      categories: 'Categories',
      createCategory: 'Create category',
      editCategory: 'Edit category',
      deleteCategory: 'Delete category',
      createCustomer: 'Create customer',
      newCustomer: 'New customer',
      editCustomer: 'Edit customer',
      deleteCustomer: 'Delete customer',
      createUserAccount: 'Create user account',
      newUserAccount: 'New user account',
      editUserAccount: 'Edit user account',
      deleteUserAccount: 'Delete user account',
      selectPlaceholder: 'Select an option',
      idNumber: 'Identification number',
      notes: 'Notes',
      socialMedia: 'Social media',
      basicData: 'Basic data',
      customerData: 'Customer Data',
      userAccountData: 'User account data',
      noDataToShow: 'No data to show',
      viewNotifications: 'View notifications',
      toPay: '{{amount}} to pay',
      sendRestorePWEmail: 'Send restore password email',
      permissions: {
        registerProducts: 'Register and modify products',
        controlStock: 'Control stock',
        registerCustomers: 'Register and modify customers',
        viewOrdersOtherUsers: 'See orders from other users',
        editOrdersOtherUsers: 'Manage orders from other users',
        editItemPriceInCart: 'Edit item price in cart',
        giveDiscounts: 'Edit discounts',
        editOnlineCatalog: 'Edit online catalog',
        permissions: 'Permissions',
        activateAccess: 'Activate access',
        viewAnalytics: 'View analytics',
      },
      restrictionMessage: {
        subscription: 'You have to be PRO member to continue',
        role: 'You need to hold an admin role to continue',
        screen: 'No permission to visit this screen',
      },
      signWithOtherAccount: 'Sign with other account',
      descriptionText: {
        expand: 'See more',
        shrink: 'See less',
      },
      cart: {
        add: 'Add to cart',
      },
      user: {
        profile: {
          title: 'Update your information',
        },
        openMenu: 'Open user menu',
      },
      checkout: {
        contact: {
          title: 'How do we get in touch with you?',
        },
        delivery: {
          type: {
            title: 'Enter a shipping address',
          },
          method: {
            title: 'Choose a delivery method',
          },
        },
      },
      locations: {
        addressInformation: 'Location information',
      },
      catalogFields: {
        catalogLogo: 'Store logo',
        catalogUrl: 'Catalog URL',
        setupOnlineCatalog: 'Configure your online catalog',
        acceptOnlineOrders: 'Accept online orders',
        publishOnlineCatalog: 'Publish online catalog',
        allowShipments: 'Allow shipments',
        showProductsWithoutStock: 'Show products without stock',
        businessData: 'Business data',
        descriptionBusinessData:
          'This information will be displayed publicly so be careful what you share.',
        descriptionCustomerData:
          'This information will be requested from your clients.',
        location: 'Location',
        deliveryPoint: 'Delivery point',
        customMessage: 'Custom message',
        paymentMethods: 'Payment methods',
      },
      cardToCreateYourOnlineCatalog: {
        title: 'Create your online catalog for free',
        subtitle:
          'Share the link of your store with your customers, receive orders directly on your smartphone and increase your sales',
        createOnlineCatalog: 'Create my online catalog',
      },
      form: {
        name: 'Name',
        email: 'Email',
        phone: 'Phone',
        password: 'Password',
        state: 'State',
        shortDescription: 'Short description',
      },
      contact: 'Contact',
      delivery: 'Delivery',
      payment: 'Payment',
      finish: 'Finish',
      payments: 'Payments',
      paidOut: 'Paid out',
      editPayment: 'Edit payment',
      receivable: 'Receivable',
      collectNow: 'Collect now',
      timeline: 'Time line',
      by: 'by ',
      amountToPay: 'Amount to pay with {{method}}',
      paymentMethods: {
        addPayment: 'Add payment',
        confirm: 'Confirm',
        cancel: 'Cancel order',
        none: 'Coordinate with the seller',
        cash: 'Cash',
        card: 'Card',
        transfer: 'Transfer',
        other: 'Other',
        change: 'Change',
        flow: 'Pago en linea por Flow',
      },
      shippingAddress: {
        address: 'Address',
        city: 'City',
        state: 'State',
        shippingInformation: 'Shipping information',
      },
      sidebar: {
        home: 'Home',
        sell: 'Sell',
        products: 'Products',
        orders: 'Orders',
        openOrders: 'Open orders',
        closedOrders: 'Closed orders',
        customers: 'Customers',
        userAccounts: 'User Accounts',
        analytics: 'Analytics',
        settings: 'Settings',
      },
      settings: {
        title: 'Configuración',
        locations: {
          title: 'Inventory locations',
          description: 'Manage where inventory is stored',
        },
        generalSettings: {
          title: 'General settings',
          description:
            'Manage the display of your products and canceled orders',
          settings: {
            title: 'Settings',
            productViewFormat: 'Product view format',
            sortProductsBy: 'Sort products by',
            productVariants: 'Product variants',
            hideCanceledOrders: 'Hide canceled orders',
            hideOutOfStockItems: 'Hide out of stock items',
          },
        },
      },
      statusLabels: {
        pending: 'Pending',
        confirmed: 'Confirmed',
        completed: 'Completed',
        canceled: 'Canceled',
      },
      timelineStatus: {
        pending: 'Pending',
        confirmed: 'Confirmed',
        completed: 'Completed',
        canceled: 'Canceled',
      },
      deliveryTypes: {
        shipping: 'Shipping',
        pickup: 'Pickup',
      },
      table: {
        deliveryMethod: 'Delivery method',
        order: 'Order',
        customer: 'Customer',
        generatedBy: 'Generated by',
        total: 'Total',
        state: 'State',
        date: 'Date',
        orderNumber: 'Order number',
      },
      products: {
        item: 'Item',
        items: 'Items',
        category: 'Category',
        price: 'Price',
        stock: 'Stock',
        description: 'Description',
        format: 'Format',
        unit: 'Unidad',
        fraction: 'Fracción',
        quantity: 'Quantity',
        lowStock: 'Low stock',
        costPrice: 'Cost price',
        salePrice: 'Sale price',
        barcode: 'Barcode',
        sku: 'SKU',
        createItem: 'Create Item',
        newItem: 'New Item',
        editItem: 'Editar Item',
        isPublished: 'Publish in online catalog',
        isHighlighted: 'Highlighted product',
        addVariation: 'Add Variation',
        deleteProduct: 'Delete Product',
      },
      subscriptionStatus: {
        month: 'Month',
        months: 'Months',
        monthly: 'Monthly',
        quarterly: 'Quarterly',
        yearly: 'Yearly',
        activeSubscriptionM: 'You are on the monthly PRO plan',
        activeSubscriptionQ: 'You are on the quarterly PRO plan',
        activeSubscriptionY: 'You are on the yearly PRO plan',
        activeTrial: 'Get your business off the ground with Posy PRO',
        expiredSubscription:
          "Your subscription expired, let's keep working together and enjoy the PRO plan",
        trial: 'Your free trial ends on',
        subscriptionNotFound:
          'Take your business to the next level with Posy PRO',
        freePlan: 'You are currently on the FREE Plan',
        renewPRO: 'Renew my plan PRO',
        validUntil: 'Valid until ',
        goPRO: 'Go PRO',
        selectPlan: 'Select a plan',
        proPlan: 'Pro plan',
      },
      features: {
        inventoryControl: 'Inventory control',
        unlimitedProducts: 'Ulimited products',
        productVariants: 'Product variants',
        inPersonOrders: 'Face-to-face orders',
        ordersToPay: 'Orders to pay',
        salesRecord: 'Sales record',
        receiptPrinting: 'Receipt printing',
        sellOffline: 'Sell offline',
        sumupMachineConnection: 'SumUp machine connection',
        statisticsAndReports: 'Statistics and reports',
        assistanceSupport: 'Assistance and support',
        posyWeb: 'Posy web',
        onlineOrders: 'Online Orders',
        shoppingCart: 'Online shopping cart',
        onlineStore: 'Online store for your products',
        customerRegistration: 'Customer registration',
        userAccounts: 'User accounts',
        digitalReceipt: 'Digital Receipt',
        features: 'Features',
      },
      entities: {
        product: 'Product',
        customer: 'Customer',
        userAccount: 'User account',
        category: 'Category',
      },
      errorMessages: {
        errorTitle: 'Error',
        emailTaken: 'The email has already been taken',
      },
      confirmButton: 'Confirm',
      cancelButton: 'Cancel order',
      payLeftAmount: 'Cobrar restante',
      modals: {
        delete: {
          title: 'Delete',
          body: 'Are you sure you want to delete this element? This action can not be undone',
        },
        orderConfirm: {
          title: 'Confirm order',
          body: 'Are you sure you want to confirm this order, This action can not be undone',
        },
        orderComplete: {
          title: 'Complete order',
          body: 'Are you sure you want to complete this order, This action can not be undone',
        },
        orderCancel: {
          title: 'Cancel order',
          body: 'Are you sure you want to cancel this order, This action can not be undone',
        },
        orderRefund: {
          title: 'Refund order',
          body: 'Are you sure you want to refund this order, This action can not be undone',
        },
        barcodeNotFound: {
          title: 'Product not found',
          body: 'Would you like to use this barcode to create a product?',
          confirmButton: 'Create',
        },
      },
      currency: 'Currency',
      appPosy: 'App Posy',
    },
  },
  es: {
    translation: {
      admin: 'Administrador',
      configurationGuide: 'Guía de configuración',
      accept: 'Aceptar',
      cancel: 'Cancelar',
      loading: 'Cargando',
      delete: 'Eliminar',
      team: 'Equipo',
      onlineCatalog: 'Catálogo online',
      next: 'Siguiente',
      remove: 'Quitar',
      removeSelection: 'Quitar selección',
      add: 'Agregar',
      edit: 'Editar',
      save: 'Guardar',
      update: 'Actualizar',
      exportCustomers: 'Exportar clientes',
      exportProducts: 'Exportar productos',
      date: {
        weekDay: {
          monday: 'Lunes',
          tuesday: 'Martes',
          wednesday: 'Miércoles',
          thursday: 'Jueves',
          friday: 'Viernes',
          saturday: 'Sábado',
          sunday: 'Domingo',
        },
        filter: {
          hour: 'Hora',
          date: 'Día',
          week: 'Semana',
          month: 'Mes',
        },
      },
      updateEmail: {
        title: 'Actualizar email',
        modals: {
          success: {
            title: 'Operación completa',
            body: 'Tu email fue actualizado exitosamente',
          },
          failure: {
            title: 'Error',
            body: 'El email ingresado no esta disponible',
          },
        },
      },
      createLocation: 'Crear ubicación',
      signInAgain: 'Volver a ingresar',
      search: 'Buscar',
      subscribe: 'Obtener',
      welcome: 'Bienvenido a Posy',
      customers: 'Clientes',
      customer: 'Cliente',
      seller: 'Vendedor',
      vendor: 'Proveedor',
      articles: 'Artículos',
      category: 'Categoría',
      categories: 'Categorías',
      createCategory: 'Crear categoría',
      editCategory: 'Editar categoría',
      newCategory: 'Crear categoría',
      deleteCategory: 'Eliminar categoría',
      createCustomer: 'Crear cliente',
      newCustomer: 'Nuevo cliente',
      editCustomer: 'Editar cliente',
      deleteCustomer: 'Eliminar cliente',
      createUserAccount: 'Crear colaborador',
      newUserAccount: 'Nuevo colaborador',
      editUserAccount: 'Editar colaborador',
      deleteUserAccount: 'Eliminar colaborador',
      deleteUser: 'Eliminar cuenta',
      deleteUserMessage: 'Escribe la palabra "eliminar" para confirmar',
      close: 'Cerrar',
      goBack: 'Volver',
      signOut: 'Cerrar sesión',
      signIn: 'Iniciar sesión',
      reset: 'Reestablecer',
      setting: 'Configuración',
      setup: 'Configurar',
      activateStore: 'Activar tienda',
      active: 'Activo',
      inactive: 'Inactivo',
      goToSite: 'Ir al sitio',
      copyLink: 'Copiar enlace',
      authMessage: 'Inicia sesión en tú cuenta',
      registerMessage: 'Crea una cuenta',
      resetPasswordHeader: 'Restaurar contraseña',
      startTrial: 'comienza tu prueba gratuita de 7 días',
      or: 'O',
      auth: 'Identificación',
      signInWith: 'Ingresar con',
      signInWithEmail: 'Ingresar',
      signInWithGoogle: 'Ingresar con Google',
      signInWithApple: 'Ingresar con Apple',
      continueWith: 'O continua con',
      continue: 'Continuar',
      signUp: 'Crear cuenta',
      haveAccount: 'Ya tengo una cuenta',
      signedInAs: 'Estás logueado como',
      forgotPassword: '¿Olvidaste tu contraseña?',
      selectPlaceholder: 'Selecciona una opción',
      emptyCategoryPlaceholder: 'Esta categoría no tiene productos',
      idNumber: 'N° de Identificación',
      notes: 'Notas',
      socialMedia: 'Redes sociales',
      basicData: 'Datos Básicos',
      customerData: 'Datos Clientes',
      userAccountData: 'Datos del colaborador',
      noDataToShow: 'No hay datos para mostrar',
      viewNotifications: 'Ver notificaciones',
      toPay: '{{amount}} por pagar',
      searchFor: 'Buscar por {{fields}}',
      sendRestorePWEmail: 'Enviar correo para restaurar contraseña',
      dateRanges: {
        last7Days: 'Últimos 7 días',
        last30Days: 'Últimos 30 días',
        today: 'Hoy',
        day: 'Hoy',
        yesterday: 'Ayer',
        week: 'Esta semana',
        month: 'Este mes',
        prevWeek: 'Semana pasada',
        prevMonth: 'Mes pasado',
      },
      pricing: {
        renew: {
          title: 'Renueva tu plan PRO',
          description:
            'No te quedes sin las ventajas que Posy le ofrece a tu negocio',
          featureDescription:
            'Funciones pensadas especialmente para tu negocio',
        },
        enroll: {
          title: 'Comienza una nueva etapa en tu negocio',
          description:
            'Posy es el mejor aliado para administrar tu negocio y aumentar tus ventas',
          featureDescription:
            'Funciones pensadas especialmente para tu negocio',
        },
        trialOn: {
          title: 'No pierdas acceso al mejor aliado para tu negocio',
          description:
            'Suscribete antes que tu prueba gratuita finalice y aprovecha estos descuentos',
          featureDescription:
            'Funciones pensadas especialmente para tu negocio',
        },
        trialEnded: {
          title: 'Tu prueba gratuita ha finalizado',
          description:
            'Suscribete y no pierdas todo lo que Posy ofrece a tu negocio',
          featureDescription:
            'Funciones pensadas especialmente para tu negocio',
        },
        pay: 'Realizar el pago',
        documentType: 'Tipo de documento',
        billingData: 'Datos de facturación',
      },
      permissions: {
        registerProducts: 'Registrar y modificar productos',
        controlStock: 'Controlar stock',
        registerCustomers: 'Registrar y modificar clientes',
        viewOrdersOtherUsers: 'Ver pedidos de otros usuarios',
        editOrdersOtherUsers: 'Gestionar pedidos de otros usuarios',
        editItemPriceInCart: 'Editar precio de item en carrito',
        giveDiscounts: 'Editar descuentos',
        editOnlineCatalog: 'Editar catálogo online',
        permissions: 'Permisos',
        activateAccess: 'Activar Acceso',
        viewAnalytics: 'Ver estadísticas',
      },
      resetPasswordMessage: {
        title: 'El correo ha sido enviado',
        description: 'Revisa la bandeja de entrada de {{email}}',
      },
      restrictionMessage: {
        subscription: 'Debes tener el plan PRO activo para ingresar',
        role: 'Debes ser administrador de esta cuenta para ingresar',
        screen: 'No tienes permisos para visitar esta pantalla',
      },

      signWithOtherAccount: 'Iniciar con otra cuenta',
      cart: {
        add: 'Agregar al carro',
        emptyPlaceholder: 'Tu carro esta vacio',
        disabledCheckoutMessage: '{{storeName}} desactivó los pedidos online',
        emptyButton: 'Vaciar carro',
      },

      searchItemPlaceholder: 'Buscar un item...',
      descriptionText: {
        expand: 'Ver más',
        shrink: 'Ver menos',
      },
      user: {
        profile: {
          title: 'Actualiza tu información',
        },
        openMenu: 'Open user menu',
      },
      checkout: {
        contact: {
          title: 'Donde nos contactamos contigo',
          fields: {
            email: 'Email',
            phone: 'Telefono',
            name: 'Nombre',
          },
        },
        delivery: {
          type: {
            title: 'Ingresa una dirección de envío',
          },
          shippingAddress: {
            title: 'Cuál es tu dirección de envío',
            confirm: 'Confirmar dirección',
          },
          method: {
            title: 'Selecciona un método de envío',
          },
        },
        confirm: {
          title: 'Confirma el pedido',
          subtitle: 'Revisa que todos tus datos esten en orden',
        },
        result: {
          message: 'Tu pedido ha sido generado exitosamente',
        },
      },
      locations: {
        addressInformation: 'Datos de ubicación',
      },
      catalogFields: {
        catalogLogo: 'Logo tienda',
        catalogUrl: 'URL del catálogo',
        setupOnlineCatalog: 'Configurar catálogo online',
        publishOnlineCatalog: 'Publicar catálogo online',
        acceptOnlineOrders: 'Aceptar pedidos online',
        allowShipments: 'Permitir envíos',
        showProductsWithoutStock: 'Mostrar productos sin stock',
        businessData: 'Datos del negocio',
        descriptionInformation:
          'Esta información se mostrará publicamente, cuidado con la información que compartes.',
        descriptionCustomerData:
          'Esta información se solicitará a tus clientes obligatoriamente.',
        location: 'Ubicación',
        deliveryPoint: 'Punto de entrega',
        customMessage: 'Mensaje personalizado',
        paymentMethods: 'Métodos de pago',
      },
      cardToCreateYourOnlineCatalog: {
        title: 'Crea tu catálogo online GRATIS',
        subtitle:
          'Comparte el link de tu tienda con tus clientes, recibe pedidos directamente en tu smartphone y aumenta tus ventas',
        createOnlineCatalog: 'Crear mi catálogo online',
      },
      form: {
        name: 'Nombre',
        email: 'Email',
        phone: 'Telefono',
        password: 'Contraseña',
        state: 'Estado',
        shortDescription: 'Descripción corta',
      },
      billingForm: {
        companyName: 'Razón social',
        companyTaxId: 'RUT',
        companyActivity: 'Giro',
        companyEmail: 'Emailaseg',
        companyAddress: 'Dirección',
        companyState: 'Región',
        companyCity: 'Ciudad',
      },
      contact: 'Contacto',
      delivery: 'Entrega',
      payment: 'Pago',
      payments: 'Pagos',
      paidOut: 'Pagado',
      editPayment: 'Editar pago',
      finish: 'Finalizar',
      collectNow: 'Cobrar ahora',
      collectLater: 'Cobrar más tarde',
      receivable: 'Por cobrar',
      timeline: 'Linea de tiempo',
      by: 'por ',
      paymentMethods: {
        addPayment: 'Agregar pago',
        confirm: 'Confirmar',
        cancel: 'Cancelar pedido',
        none: 'Coordinar con el vendedor',
        cash: 'Efectivo',
        card: 'Tarjeta',
        transfer: 'Transferencia',
        other: 'Otro',
        change: 'Vuelto',
        flow: 'Pago en línea',
      },
      instructions: 'Instrucciones',
      confirm: 'Confirmación',
      confirmButton: 'Confirmar',
      completeButton: 'Completar',
      cancelButton: 'Cancelar pedido',
      refundButton: 'Reembolsar pago',
      shippingAddress: {
        address: 'Dirección',
        city: 'Ciudad',
        state: 'Región',
        country: 'País',
        shippingInformation: 'Información de envío',
      },

      userNavigation: {
        orders: 'Pedidos',
        profile: 'Perfil',
      },
      sidebar: {
        home: 'Inicio',
        sell: 'Vender',
        products: 'Productos',
        orders: 'Pedidos',
        openOrders: 'Pedidos activos',
        closedOrders: 'Pedidos terminados',
        customers: 'Clientes',
        userAccounts: 'Colaboradores',
        analytics: 'Estadísticas',
        catalog: 'Catálogo Online',
        settings: 'Configuración',
      },
      settings: {
        title: 'Configuración',
        locations: {
          title: 'Ubicaciones de stock',
          description: 'Maneja los lugares desde donde almacenas inventario',
        },
        generalSettings: {
          title: 'Ajustes generales',
          description:
            'Administra la visualización de tus productos y pedidos cancelados',
          settings: {
            title: 'Opciones generales',
            subtitle: 'Ajustes',
            productViewFormat: 'Formato vista productos',
            sortProductsBy: 'Ordenar productos por',
            productVariants: 'Variantes de productos',
            hideCanceledOrders:
              'Ocultar pedidos cancelados (Solo en versión movil)',
            hideOutOfStockItems:
              'Ocultar items sin stock (Solo en pestaña Vender)',
            grid: 'Cuadricula',
            list: 'Lista',
            creationDate: 'Fecha de creación',
            alphabetical: 'Alfabético',
          },
        },
        paymentMethods: {
          title: 'Métodos de pago',
        },
      },
      table: {
        deliveryMethod: 'Entrega',
        order: 'Pedido',
        customer: 'Cliente',
        generatedBy: 'Generado por',
        total: 'Total',
        state: 'Estado',
        date: 'Fecha',
        orderNumber: 'Número de pedido',
      },
      order: {
        subtotal: 'Subtotal',
        discount: 'Descuento',
        total: 'Total',
        inDebt: 'Por pagar',
      },

      profile: {
        confirmationModal: {
          title: 'Listo!',
          subtitle: 'Tus datos han sido actualizados.',
        },
      },
      modals: {
        delete: {
          title: 'Eliminar',
          body: 'Estás seguro de eliminar este elemento? Esta acción no se puede deshacer',
          deleteUser:
            '¿Estás seguro de eliminar tu cuenta?, Esta acción no se puede deshacer',
        },
        orderConfirm: {
          title: 'Confirmar pedido',
          body: '¿Estás seguro que quieres confirmar este pedido?',
        },
        orderComplete: {
          title: 'Completar pedido',
          body: '¿Estás seguro que quieres completar este pedido?',
        },
        orderCancel: {
          title: 'Cancelar pedido',
          body: '¿Estás seguro que quieres cancelar este pedido?',
        },
        orderRefund: {
          title: 'Reembolsar pedido',
          body: '¿Estás seguro que quieres reembolsar este pedido?',
        },
        barcodeNotFound: {
          title: 'Producto no encontrado',
          body: 'Deseas usar este código para crear un producto?',
          confirmButton: 'Crear producto',
        },
        resetCart: {
          title: 'Limpiar carro',
          body: '¿Estás seguro que deseas limpiar el carro?',
          confirmButton: 'Limpiar carro',
        },
      },
      validationMessages: {
        required: 'Este campo es requerido',
        number: 'Este campo debe ser un numérico',
        email: 'Este campo debe contener un email',
        textOnly: 'Solo se permiten caracteres',
        integer: 'Debe ser un número entero',
        greaterThanZero: 'El valor debe ser mayor a 0',
      },
      statusLabels: {
        pending: 'Por confirmar',
        confirmed: 'Confirmado',
        completed: 'Completado',
        canceled: 'Cancelado',
      },
      timelineStatus: {
        pending: 'Pendiente',
        confirmed: 'Confirmado',
        completed: 'Completado',
        canceled: 'Cancelado',
      },
      deliveryTypes: {
        shipping: 'Envío',
        pickup: 'Retiro',
      },
      products: {
        item: 'Producto',
        items: 'Productos',
        category: 'Categoría',
        price: 'Precio',
        stock: 'Stock',
        description: 'Descripción',
        format: 'Formato',
        unit: 'Unidad',
        fraction: 'Fracción',
        quantity: 'Cantidad',
        lowStock: 'Bajo stock',
        costPrice: 'Precio costo',
        salePrice: 'Precio oferta',
        barcode: 'Código de barra',
        sku: 'SKU',
        createItem: 'Crear producto',
        newItem: 'Nuevo producto',
        editItem: 'Editar producto',
        isPublished: 'Publicar en catálogo online',
        isHighlighted: 'Producto destacado',
        addVariation: 'Agregar variación',
        deleteProduct: 'Eliminar producto',
      },
      subscriptionStatus: {
        month: 'Mes',
        months: 'Meses',
        monthly: 'Mensual',
        quarterly: 'Trimestral',
        yearly: 'Anual',
        activeSubscriptionM: 'Estás en el plan PRO mensual',
        activeSubscriptionQ: 'Estás en el plan PRO Trimestral',
        activeSubscriptionY: 'Estás en el plan PRO Anual',
        activeTrial: 'Haz despegar tu negocio con Posy PRO',
        expiredSubscription:
          'Tu suscripción venció, sigamos trabajando juntos y disfruta del plan PRO',
        trial: 'Tu prueba gratuita finaliza el ',
        subscriptionNotFound:
          'Lleva tu negocio al siguiente nivel con Posy PRO',
        freePlan: 'Actualmente estás en el plan FREE',
        renewPRO: 'Renovar plan',
        validUntil: 'Eres PRO hasta el',
        trialValidUntil: 'Estás en prueba gratuita hasta el',
        goPRO: 'Obtener el plan PRO',
        selectPlan: 'Elige un plan',
        proPlan: 'Plan PRO',
      },
      features: {
        inventoryControl: 'Control de inventario',
        unlimitedProducts: 'Productos ilimitados',
        productVariants: 'Variantes de producto',
        inPersonOrders: 'Pedidos presenciales',
        ordersToPay: 'Pedidos por pagar',
        salesRecord: 'Registro de ventas',
        receiptPrinting: 'Impresión de recibos',
        sellOffline: 'Vende sin conexión',
        sumupMachineConnection: 'Conectate a SumUp',
        statisticsAndReports: 'Estadísticas y Reportes',
        assistanceSupport: 'Asistencia y soporte',
        posyWeb: 'Posy web',
        onlineOrders: 'Pedidos Online',
        shoppingCart: 'Carrito de compras online',
        onlineStore: 'Catálogo en linea',
        customerRegistration: 'Registro clientes',
        userAccounts: 'Cuentas colaborador',
        digitalReceipt: 'Recibo digital',
        features: 'Funcionalidades',
      },
      entities: {
        product: 'Producto',
        customer: 'Cliente',
        userAccount: 'Colaborador',
        category: 'Categoría',
      },
      errorMessages: {
        errorTitle: 'Error',
        emailTaken: 'El email ya esta siendo utilizado por otra cuenta',
      },
      movement: {
        action: 'Acción',
        quantity: 'Cantidad',
      },
      cardsHome: {
        downloadApp: {
          title: 'DESCARGA LA APP',
          subtitle: 'Y ADMINISTRA TU NEGOCIO DESDE',
          subtitle2: 'CUALQUIER LUGAR!',
        },
        onlineCatalog: {
          title: 'TIENDA EN LÍNEA',
          subtitle: 'CREA TU',
          subtitle2: 'GRATIS',
          subtitle3: 'Y VENDE POR INTERNET',
          button: 'CREAR SITIO WEB',
        },
      },
      inventoryMovements: {
        title: 'Movimientos de stock',
        lastMovements: 'Últimos movimientos',
        shrinkage: 'Merma',
        order: 'Venta',
        reception: 'Recepción',
        return: 'Devolución',
      },
      social: {
        instagram: 'Instagram',
        facebook: 'Facebook',
        tiktok: 'Tiktok',
      },
      payLeftAmount: 'Cobrar restante',
      amountToPay: 'Monto a pagar con {{method}}',
      outOfStock: 'Stock insuficiente',
      billingFormTitle: 'Datos de facturación',
      quickSaleModalTitle: 'Vender item no registrado',
      editPriceModalTitle: 'Editar precio del item',
      editQuantityModalTitle: 'Editar cantidad del item',
      editNotesModalTitle: 'Editar notas',
      editDiscountModalTitle: 'Agregar descuento',
      editDeliveryModalTitle: 'Agregar entrega',
      editLocationModalTitle: 'Seleccionar ubicación de stock',
      stockLocation: 'Ubicación de stock',
      downloadAppButton: 'Vende con tu celular',
      downloadAppTitle: ' Descarga la App',
      downloadAppMessage:
        'Vende y gestiona pedidos mucho más rápido desde tu celular',
      downloadReport: 'Descargar reporte',
      customPeriod: 'Periodo personalizado',
      completeOrder: 'Completar pedido',
      moreOptions: 'Más opciones',
      unavailableLocationMessage:
        'Posy Web aún no se encuentra disponible en tu ubicación',
      noAnalyticsResultMessage: 'No existen datos en el periodo seleccionado',
      bestCustomers: 'Mejores clientes',
      bestSellingItems: 'Item más vendidos',
      topSellers: 'Mejores vendedores',
      savePending: 'Guardar pendiente',
      currency: 'Moneda',
      addComment: 'Comentarios Adicionales',
      businessClosing: 'Cierre de negocio',
      missingFunctionality: 'Funcionalidades faltantes',
      subscriptionPrice: 'El precio de subscripción',
      difficultUse: 'La aplicación es difícil de usar',
      betterOption: 'Encontré una mejor aplicación',
      anotherAccount: 'Ya tengo otra cuenta',
      otherReason: 'Otra razon',
      whyYouLeave: 'Cuéntanos por qué te vas',
      authTitle: 'Selecciona una opción para continuar',
      trialBannerLabel: 'Prueba gratuita en curso',
      sevenDays: '7 días',
      getProLabel: 'Obtener el PRO',
      appPosy: 'Posy App',
      planModal: {
        title: 'Tu prueba gratuita terminará pronto',
        description:
          'Suscribete al PRO y no pierdas las funcionalidades que Posy tiene para tu negocio',
        featureTitle: 'Posy PRO te ofrece muchas ventajas:',
        computerVersion: 'Versión para computadora',
        team: 'Cuenta para colaboradores',
        onlineStore: 'Tienda online',
      },
      digitalReceipt: 'Recibo dígital',
    },
  },
};

export default resources;
