const setInventory = ({
  locations,
  batch,
  getVariationRef,
  getMovementRef,
  user,
  createdAt,
}: any) => {
  Object.entries(locations).forEach(([locationId, location]: any) => {
    Object.keys(location.variations).forEach((variationId: any) => {
      const variationRef = getVariationRef(variationId);
      const movementRef = getMovementRef(variationRef);
      batch.set(movementRef, {
        id: user.id,
        user: {
          name: user.name,
        },
        quantity: location.variations[variationId].available,
        action: "reception",
        createdAt,
        locationId,
      });
    });
  });
};

export default setInventory;
