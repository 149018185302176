import { useTranslation } from 'react-i18next';
import { useAuth } from '../../Auth/AuthProvider';
import { formatPriceValue } from '../../helpers';
import { getCycleFinalPrice } from '../helpers';

const CycleCard = ({
  cycle,
  currency,
  isBaseCountry,
  onSubscribe,
}: {
  cycle: Cycle;
  currency: string;
  isBaseCountry: boolean;
  onSubscribe: any;
}) => {
  const { id, name, discountRate, discount, description, price } = cycle;
  const { t } = useTranslation();
  const { user } = useAuth();
  const taxLabel = isBaseCountry ? '+ IVA' : '';
  const currencyLabel = currency || '';
  const finalPrice = getCycleFinalPrice(
    price,
    user.isEligibleForDiscount,
    discount,
  );

  return (
    <div className="bg-white border border-gray-200 p-4 rounded-md justify-center flex flex-col flex-1">
      <p className="font-medium text-gray-600 text-center">{name}</p>
      <p className="text-center">{description}</p>
      <p className="text-indigo-700 font-bold text-center">
        {`${formatPriceValue(finalPrice)} ${currencyLabel} ${taxLabel}`}
      </p>
      {discountRate ? (
        <div className="text-center">
          <span className="bg-red-200 text-red-800 font-bold text-xs w-fit rounded-md px-3 py-1">
            ahorra {`${discountRate}%`}
          </span>
        </div>
      ) : (
        <div className="h-6" />
      )}

      <button
        className="bg-indigo-700 px-4 py-2 text-white font-medium rounded-md m-4"
        onClick={() => onSubscribe(id)}
      >
        {t('subscribe')}
      </button>
    </div>
  );
};

export interface Cycle {
  id: string;
  name: string;
  description: string;
  price: number;
  planId: string;
  discountRate: string;
  discount: number;
}

export default CycleCard;
