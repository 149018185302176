import { useState, useEffect } from 'react';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from 'Auth/AuthProvider';
import { Customer } from 'types';
import { createCustomer, updateCustomer, deleteCustomer } from 'api/customers';
import getCustomer from 'api/customers/getCustomer';
import { useSidePanel } from '@posy/components';

interface CustomerState {
  customer?: Customer;
  submit: (values: any) => void;
  requestDeleteCustomer: () => void;
  goBack: () => void;
}

const useCustomer = (isInSidePanel?: boolean): CustomerState => {
  const navigate = useNavigate();
  const { navigateTo } = useSidePanel();
  const { logEvent } = useAnalytics();
  const { user } = useAuth();
  const { id } = useParams();
  const [customer, setCustomer] = useState<Customer>();

  useEffect(() => {
    loadCustomer();
  }, [id]);

  const loadCustomer = async () => {
    if (id) {
      const customer = await getCustomer(id);
      setCustomer(customer);
    }
  };

  const submit = (values: any) => {
    if (id) {
      updateCustomer(id, values);
      logEvent('update_customer');
    } else {
      createCustomer(values, user);
      logEvent('create_customer');
    }
    goBack();
  };

  const requestDeleteCustomer = () => {
    deleteCustomer(id);
    logEvent('delete_customer');
    goBack();
  };

  const goBack = () => {
    isInSidePanel ? navigateTo('SelectCustomer') : navigate('/customers');
  };

  return {
    customer,
    submit,
    requestDeleteCustomer,
    goBack,
  };
};

export default useCustomer;
