import { getCurrentTime, getUpdatedQuantity } from './helpers';
import { getFirestore, doc, increment, collection } from 'firebase/firestore';

const updateInventoryLevels = async (
  items: any[],
  batch: any,
  action: string,
  uid?: string,
  locationId: string | null = null,
  isInDefaultLocation?: boolean,
) => {
  const db = getFirestore();
  try {
    const filteredItems = items.filter(({ trackQty }) => trackQty);
    filteredItems.forEach((item: any) => {
      const { productId, variationId, id, quantity } = item;
      const finalVariationId = variationId || id;
      const productRef = doc(db, 'products', productId);
      const variationRef = doc(db, 'variations', finalVariationId);
      const movementRef = doc(collection(variationRef, 'movements'));
      const updateStock = increment(getUpdatedQuantity(action, quantity));
      batch.update(productRef, {
        [`locations.${locationId}.variations.${variationId}.available`]:
          updateStock,
        ...(isInDefaultLocation && {
          [`variations.${variationId}.quantity`]: updateStock,
        }),
      });
      batch.update(variationRef, {
        quantity: updateStock,
      });
      batch.set(movementRef, {
        uid,
        quantity,
        action,
        createdAt: getCurrentTime(),
        locationId,
      });
    });
  } catch (e) {
    console.error(e);
  }
};

export default updateInventoryLevels;
