import React, { useEffect } from 'react';
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  signOut as userSignOut,
  OAuthProvider,
  getAdditionalUserInfo,
  fetchSignInMethodsForEmail,
} from 'firebase/auth';

import { useAnalytics } from '../providers/AnalyticsProvider';
import { useNavigate } from 'react-router-dom';
import createAccount from '../api/createAccount';
import getUserData from '../api/getUserData';

interface AuthContextType {
  user: any;
  userIsLoading: boolean;
  signIn: (user: AuthUser) => any;
  signUp: (user: NewUser) => any;
  sendPasswordReset: (email: string) => any;
  signOut: () => void;
  signWithGoogle: () => any;
  signWithApple: () => any;
  preSignIn: (email: string) => any;
  hasPermission: (permission: string) => boolean;
}

interface AuthUser {
  email: string;
  password: string;
}

interface NewUser {
  name: string;
  email: string;
  password: string;
}

const AuthContext = React.createContext<AuthContextType>(null!);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { logEvent, logUser } = useAnalytics();
  const [user, setUser] = React.useState<any>(null);
  const [userIsLoading, setUserIsLoading] = React.useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();

    onAuthStateChanged(auth, async (user: any) => {
      const userData = await getUserData(user);

      if (userData) {
        setUser(userData);
        logUser(userData);
      } else {
        setUser(null);
        navigate('/login');
      }

      setUserIsLoading(false);
    });
  }, []);

  const signIn = (formValues: AuthUser) => {
    const auth = getAuth();
    const { email, password } = formValues;
    return signInWithEmailAndPassword(auth, email, password);
  };

  const preSignIn = async (email: string) => {
    const auth = getAuth();
    return fetchSignInMethodsForEmail(auth, email)
      .then((response) => {
        if (response.length === 0) {
          return { isRegistered: false };
        } else if (response[0] === 'password') {
          logEvent('prelogin');
          return { isRegistered: true };
        } else if (response[0] === 'google.com') {
          return { isInvalid: true, error: { code: 'google' } };
        }
      })
      .catch(function (error) {
        // Handle Errors here.
        const errorMessage = error.message;

        alert(errorMessage);

        return false;
      });
  };

  const signWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    return signInWithPopup(auth, provider).then((result: any) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      //const credential = GoogleAuthProvider.credentialFromResult(result);
      //const token = credential.accessToken;
      // The signed-in user info.
      const { user, _tokenResponse } = result;
      const providerId = 'google';
      if (_tokenResponse.isNewUser) {
        // register method
        const data = { email: user.email, name: user.displayName };
        createAccount(data, user, providerId, logEvent);
      } else {
        // log sign_in
        logEvent('login', { provider: providerId });
      }
    });
  };

  const signWithApple = () => {
    const auth = getAuth();
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');

    return signInWithPopup(auth, provider).then((result) => {
      // The signed-in user info.
      const user = result.user;

      // Apple credential
      const additionalInfo = getAdditionalUserInfo(result);
      const providerId = 'apple';
      if (additionalInfo?.isNewUser) {
        // register method
        const data = { email: user.email, name: user.displayName };
        createAccount(data, user, 'apple', logEvent);
      } else {
        // log sign_in
        logEvent('login', { provider: providerId });
      }
    });
  };

  const signUp = (formValues: AuthUser) => {
    const auth = getAuth();
    const { email, password } = formValues;

    return createUserWithEmailAndPassword(auth, email, password).then(
      (userCredential) => {
        const user = userCredential.user;
        createAccount(formValues, user, 'email', logEvent);
      },
    );
  };

  const sendPasswordReset = (email: string) => {
    const auth = getAuth();

    return sendPasswordResetEmail(auth, email);
  };

  const signOut = () => {
    const auth = getAuth();
    userSignOut(auth)
      .then(() => {
        window.Intercom('shutdown');
      })
      .catch((error) => {
        // An error happened.
        alert(`error happened ${error}`);
      });
  };

  const hasPermission = (permission: string) => {
    return user?.isAdmin ? true : user?.permissions?.includes(permission);
  };

  const value = {
    user,
    userIsLoading,
    hasPermission,
    signIn,
    signUp,
    signWithGoogle,
    signWithApple,
    sendPasswordReset,
    signOut,
    preSignIn,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

function useAuth() {
  return React.useContext(AuthContext);
}

export { useAuth };
export default AuthProvider;
