import completeOrder from 'api/completeOrder';
import confirmOrder from 'api/confirmOrder';
import cancelOrder from 'api/cancelOrder';
import refundOrder from 'api/refundOrder';
import createPendingOrder from 'api/createPendingOrder';
import useLocations from './useLocations';
import { useAuth } from 'Auth/AuthProvider';

const useOrder = (inputOrder: Order) => {
  const { user } = useAuth();
  const { locations } = useLocations();
  const defaultLocation: any = locations.find(
    (location: any) => location.default,
  );
  const defaultLocationId = defaultLocation?.id;
  const order = formatOrder(inputOrder, defaultLocationId);
  const isInDefaultLocation = isDefaultLocation(
    order?.locationId,
    defaultLocationId,
  );

  const doCompleteOrder = () => {
    completeOrder(order, user);
  };

  const doConfirmOrder = () => {
    confirmOrder(order, user, isInDefaultLocation);
  };

  const doCancelOrder = () => {
    cancelOrder(order, user, isInDefaultLocation);
  };

  const doRefundOrder = async () => refundOrder(order);

  const doCreatePendingOrder = () => {
    createPendingOrder(order);
  };

  return {
    confirmOrder: doConfirmOrder,
    cancelOrder: doCancelOrder,
    completeOrder: doCompleteOrder,
    refundOrder: doRefundOrder,
    createPendingOrder: doCreatePendingOrder,
  };
};

const isDefaultLocation = (locationId: string, defaultLocation: string) =>
  locationId === defaultLocation;

type Order = {
  name: string;
  locationId: string;
};

const formatOrder = (order: Order, defaultLocationId: string) => {
  return {
    ...order,
    locationId: order?.locationId || defaultLocationId,
  };
};

export default useOrder;
