import { utils, writeFile } from 'xlsx';
import { Customer } from 'types';

const customersHeader = [
  'Nombre',
  'Telefono',
  'Email',
  'Dirección',
  'Ciudad',
  'Región',
  'Nº de identificación',
  'Notas',
];

const generateReport = (customers: Customer[]) => {
  const wb = utils.book_new();
  wb.SheetNames.push('Hoja 1');
  wb.Sheets['Hoja 1'] = getCustomerSheet(customers);

  return writeFile(wb, `Clientes.xlsx`);
};

const getCustomerSheet = (customers: Customer[]) => {
  const customerData = customers.map((customer: Customer) => [
    customer.name,
    customer.phone,
    customer.email,
    customer.shipping?.address,
    customer.shipping?.city,
    customer.shipping?.state,
    customer.docId,
    customer.notes,
  ]);

  return utils.aoa_to_sheet([customersHeader, ...customerData]);
};

export default generateReport;
