import { useEffect, useMemo } from 'react';
import { RadioGroup } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

import DocumentOption from './DocumentOption';

const DocumentSelection = ({
  initialValue,
  value,
  onSelectionChange,
  hideInvoice,
}: any) => {
  const documents = useMemo(() => getDocuments(hideInvoice), [hideInvoice]);
  const initialDocument = documents.find(
    (document) => document.id === initialValue,
  );
  const { t } = useTranslation();

  useEffect(() => {
    onSelectionChange(initialDocument);
  }, []);

  return (
    <RadioGroup value={value} onChange={onSelectionChange}>
      <RadioGroup.Label className="font-semibold">
        {t('pricing.documentType')}
      </RadioGroup.Label>
      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
        {documents.map((document) => (
          <DocumentOption key={document.id} document={document} />
        ))}
      </div>
    </RadioGroup>
  );
};

const getDocuments = (hideInvoice: boolean) => {
  const baseOptions = [{ id: 'receipt', title: 'Boleta' }];

  if (hideInvoice) {
    return baseOptions;
  } else {
    return baseOptions.concat({ id: 'invoice', title: 'Factura' });
  }
};
export default DocumentSelection;
