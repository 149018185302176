import { useTranslation } from 'react-i18next';

import { Table } from '@posy/components';

import StatusBadge from './components/StatusBadge';
import moment from 'moment-timezone';
import useOnSnapshot from '../components/useOnSnapshot';
import { useNavigate } from 'react-router-dom';
import useLocations from '../hooks/useLocations';
import PageHeader from '../components/PageHeader';
import getAmountToPay from './getAmountToPay';
import { getPaymentLabels } from './helpers';

export interface Order {
  id: string;
  name: string;
  customerName: string;
  creatorName: string;
  total: number;
  status: string;
}

const OpenOrders = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useOnSnapshot('orders');
  const navigate = useNavigate();
  const { locations, getLocationNameById } = useLocations();
  const formattedOrders: Order[] = data.map((order: any) => ({
    id: order.id,
    name: order.name,
    delivery: order.delivery,
    customerName: order.customer?.name,
    creatorName: order.shopOrderId ? 'Catálogo' : order.user.name,
    total: order.total,
    status: order.status,
    payments: order.payments,
    paymentLabels: getPaymentLabels(order.payments),
    creationDate: moment(order.createdAt).format('DD/MM/YY HH:mm'),
    locationId: order.locationId,
    isPaid: order.isPaid,
  }));
  const hasMultipleLocations = locations.length > 1;

  const columns = [
    { key: 'name', label: t('table.order') },
    { key: 'customerName', label: t('table.customer') },
    {
      key: 'delivery.type',
      label: t('table.deliveryMethod'),
      render: ({ delivery }: any) => (
        <div className="whitespace-nowrap px-3 py-4 text-sm">
          {delivery?.type && t(`deliveryTypes.${delivery.type}`)}
        </div>
      ),
    },
    {
      key: 'paymentLabels',
      label: 'Método de pago',
      isSortActive: true,
    },
    {
      key: 'locationId',
      label: 'Ubicación',
      hidden: !hasMultipleLocations,
      render: ({ locationId }: any) => (
        <div className="whitespace-nowrap px-3 py-4 text-sm pl-6">
          <p className="text-sm">{getLocationNameById(locationId)}</p>
        </div>
      ),
    },
    { key: 'total', label: t('table.total'), type: 'money' },
    {
      key: 'status',
      label: t('table.state'),
      render: ({ status, payments, total, isPaid }: any) => {
        const amountToPayLabel = getAmountToPay({ payments, total });

        return (
          <div className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <StatusBadge
              status={status}
              isPaid={isPaid}
              amountToPayLabel={amountToPayLabel}
            />
          </div>
        );
      },
    },
    { key: 'creatorName', label: t('table.generatedBy') },
    { key: 'creationDate', label: t('table.date') },
  ];

  const searchFields = [
    { label: t('table.orderNumber'), value: 'name' },
    { label: t('customer'), value: 'customerName' },
    { label: t('seller'), value: 'creatorName' },
  ];

  return (
    <>
      <PageHeader title={t('sidebar.openOrders')} />
      <Table
        columns={columns}
        rows={formattedOrders}
        isLoading={isLoading}
        searchFields={searchFields}
        onRowClick={(rowId: string) => navigate(`/open-orders/${rowId}`)}
        filters={[
          {
            label: t('table.state'),
            name: 'status',
            options: [
              { id: 'pending', name: t('statusLabels.pending') },
              { id: 'confirmed', name: t('statusLabels.confirmed') },
            ],
          },
          {
            label: 'Todas las ubicaciones',
            name: 'locationId',
            options: [...locations],
          },
          {
            label: t('table.deliveryMethod'),
            name: 'delivery.type',
            options: [
              { id: 'shipping', name: t('deliveryTypes.shipping') },
              { id: 'pickup', name: t('deliveryTypes.pickup') },
            ],
          },
        ]}
        style={{
          height: '100%',
          background: 'white',
          margin: 16,
        }}
      />
    </>
  );
};

export default OpenOrders;
