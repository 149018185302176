interface Props {
  label: string;
  textSize?: string;
  isVisible?: boolean;
  bordered?: boolean;
  noBorder?: boolean;
  noMargin?: boolean;
  onClick: () => void;
}

const Button = ({
  label,
  textSize,
  isVisible = true,
  bordered,
  onClick,
  noBorder,
  noMargin,
}: Props) => {
  if (!isVisible) {
    return null;
  }

  const getClassName = () => {
    const textSizeStr = textSize ? 'text-' + textSize : '';
    const margin = noMargin ? '' : 'mx-5';

    if (bordered) {
      return `text-indigo-700 border border-indigo-700 font-medium rounded-md px-4 py-2 ${margin} ${textSizeStr}`;
    } else if (noBorder) {
      return `text-indigo-700 font-medium rounded-md ${textSizeStr}`;
    } else {
      return 'bg-indigo-700 text-white font-medium rounded-md px-4 py-2';
    }
  };
  return (
    <button className={getClassName()} onClick={onClick}>
      {label}
    </button>
  );
};

export default Button;
