import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingDim } from '@posy/components';
import { ChevronLeftIcon } from '@heroicons/react/outline';

import { useAuth } from 'Auth/AuthProvider';
import Register from './Register';
import PreLogin from './PreLogin';
import Login from './Login';

interface Props {
  goBack: () => void;
  goToResetPassword: () => void;
}

const CustomAuth = ({ goBack, goToResetPassword }: Props) => {
  const { t } = useTranslation();
  const { preSignIn, signIn, signUp } = useAuth();
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string | null>();
  const [isLoading, setIsLoading] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    setError('');
    const formData = new FormData(event.currentTarget);
    const email = formData.get('email') as string;

    preSignIn(email)
      .then((result: any) => {
        const { isRegistered, isInvalid, error } = result;
        if (!isInvalid) {
          setEmail(email);
          setIsRegistered(isRegistered);
        }
        if (error) {
          const errorIndex: any = {
            google: 'Debes inicia sesión con tu cuenta de Google',
          };

          const description =
            errorIndex[error.code] || 'No es posible ser autenticado';

          setError(description);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmitLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    setError('');
    const formData = new FormData(event.currentTarget);
    const password = formData.get('password') as string;

    signIn({ email, password }).catch((error: any) => {
      const errorMessage = getTranslatedErrorMessage(error);

      setError(errorMessage);
      setIsLoading(false);
    });
  };

  const handleSubmitRegister = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    setError('');
    const formData = new FormData(event.currentTarget);
    const name = formData.get('name') as string;
    const password = formData.get('password') as string;

    signUp({ name, email, password }).catch((error: any) => {
      setIsLoading(false);
      const errorMessage = getTranslatedErrorMessage(error);
      setError(errorMessage);
    });
  };

  return (
    <div className="mt-6">
      <div className="mb-4">
        <a
          onClick={goBack}
          className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer flex items-center"
        >
          <ChevronLeftIcon className="w-5 h-5" />
          {t('goBack')}
        </a>
      </div>
      <p className="mb-4">{email}</p>
      {email ? (
        isRegistered ? (
          <Login
            handleSubmit={handleSubmitLogin}
            goToResetPassword={goToResetPassword}
          />
        ) : (
          <Register handleSubmit={handleSubmitRegister} />
        )
      ) : (
        <PreLogin handleSubmit={handleSubmit} />
      )}
      <LoadingDim isLoading={isLoading} />
      {error && (
        <div className="bg-red-100 border border-red-500 px-4 py-2 rounded-md mt-4">
          <p className="text-red-700 font-medum">{error}</p>
        </div>
      )}
    </div>
  );
};

const getTranslatedErrorMessage = (error: { code: string }) => {
  const { code } = error;

  if (code === 'auth/user-not-found' || code === 'auth/wrong-password') {
    return 'Su usuario o contraseña es inválida';
  } else if (code === 'auth/user-disabled') {
    return 'La cuenta ha sido desactivada por el administrador';
  } else {
    return 'Algo salió mal, intente nuevamente.';
  }
};

export default CustomAuth;
