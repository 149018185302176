import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  ChevronLeftIcon,
  TrashIcon,
  ReplyIcon,
} from '@heroicons/react/outline';

import { useForm, useModal, Field, ThumbnailInput } from '@posy/components';
import { useAuth } from '../../Auth/AuthProvider';
import deleteProduct from '../../api/products/deleteProduct';
import { useAnalytics } from '../../providers/AnalyticsProvider';
import Tabs from './components/Tabs';
import useFields from './useFields';
import Section from './components/Section';
import usePermissions from './hooks/usePermissions';
import AddVariantButton from './components/AddVariantButton';
import DeleteProductButton from './components/DeleteProductButton';
import { useSettings } from 'providers/SettingsProvider';

interface Props {
  id?: string;
  locations: any[];
  product: any;
  categories: any[];
  onSubmit: any;
}

const tabs: any = [
  { name: 'General', id: 'General' },
  { name: 'Inventario', id: 'Inventario' },
];

const ProductForm = ({
  id,
  product,
  locations,
  categories,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { logEvent } = useAnalytics();
  const { user, hasPermission } = useAuth();
  const productUrl = getProductUrl(user?.store?.uniqueName, id);
  const {
    shouldShowCreateVariantButton,
    shouldShowDeleteButton,
    shouldShowSaveButton,
    canUpdateProduct,
    canHandleStock,
  } = usePermissions({ isEditing: Boolean(id), hasPermission });
  const {
    settings: { allowVariations },
  } = useSettings();

  const [tabName, setTabName] = useState('General');

  const { openModal } = useModal();

  const {
    registerField,
    setField,
    getField,
    handleSubmit,
    registerFieldArray,
  } = useForm({
    defaultValues: product,
  });

  const {
    getFields,
    getOtherFields,
    getVariationArrayFields,
    getInventoryFields,
  } = useFields({
    canUpdateProduct,
    canHandleStock,
    locations,
    categories,
    getField,
    isEditing: Boolean(id),
    productUrl,
  });

  const categoryId = getField('categoryId');
  const categoriesCount = categories.length;

  useEffect(() => {
    registerField({ name: 'categoryName' });
  }, []);

  useEffect(() => {
    if (categoriesCount > 0) {
      const category = categories.find(
        ({ id }: { id: string }) => id === categoryId,
      );
      setField('categoryName', category ? category.name : null);
    }
  }, [categoryId, categories.length]);

  if (!product) {
    return null;
  }

  const requestDeleteProduct = () => {
    deleteProduct(id);
    logEvent('delete_product');
    navigate(-1);
  };

  const doDeleteProduct = () => {
    openModal({
      title: t('modals.delete.title'),
      body: t('modals.delete.body'),
      onConfirm: requestDeleteProduct,
    });
  };

  const addVariationItem = () => {
    const variations = getField('variations') || [];
    const index = variations.length;
    setField(`variations.${index}`, {});
  };

  return (
    <div className="px-4 overflow-auto">
      <div className="flex justify-end p-4">
        <div className="flex-1">
          <button
            type="button"
            className="inline-flex text-2xl font-semibold text-gray-900"
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon className="h-8 w-8" aria-hidden="true" />
            {id ? t('products.editItem') : t('products.newItem')}
          </button>
        </div>

        {shouldShowSaveButton && (
          <button
            className="bg-indigo-700 text-white font-medium rounded-md px-4 py-2"
            onClick={handleSubmit(onSubmit)}
          >
            {t('save')}
          </button>
        )}
      </div>
      <Wrapper>
        <div className="flex gap-4">
          <Section fields={getFields()} registerField={registerField} />
          <Section fields={getOtherFields()} registerField={registerField} />
        </div>
        <Tabs
          items={tabs}
          value={tabName}
          onTabChange={(tabName: string) => setTabName(tabName)}
        />
        <div className="bg-white y-4 p-4 my-4">
          <div style={{ display: tabName === 'General' ? 'block' : 'none' }}>
            {registerFieldArray('variations')
              .sort((a: any, b: any) => a.order - b.order)
              .map((variation: any, index: number, variations: any[]) => {
                const key = `variations-${variation.id || index}`;

                return (
                  <div key={key} className="flex gap-4 mt-6 items-center">
                    {variation.id && (
                      <ThumbnailInput
                        {...registerField({
                          name: `variations.${index}.thumbnail`,
                        })}
                        imageRef={`productGallery/${product.id}/${variation.id}.jpg`}
                      />
                    )}
                    {getVariationArrayFields(index, variations).map(
                      (field: any, fieldIndex: number) => (
                        <Field
                          key={`${key}-${fieldIndex}`}
                          {...registerField(field)}
                        />
                      ),
                    )}
                    {canUpdateProduct && (
                      <>
                        {variations.length > 1 && (
                          <button
                            className="flex items-end py-3"
                            onClick={() => {
                              if (getField(`variations.${index}.id`)) {
                                setField(
                                  `variations.${index}.isRemoved`,
                                  !variation.isRemoved,
                                );
                              } else {
                                setField(`variations`, [
                                  ...variations.slice(0, index),
                                  ...variations.slice(index + 1),
                                ]);
                              }
                            }}
                          >
                            {variation.isRemoved ? (
                              <ReplyIcon className="w-6 h-6" />
                            ) : (
                              <TrashIcon className="w-6 h-6" />
                            )}
                          </button>
                        )}
                      </>
                    )}
                  </div>
                );
              })}
          </div>

          <div style={{ display: tabName === 'Inventario' ? 'block' : 'none' }}>
            {registerFieldArray('variations').map(
              (variation: any, index: number, variations: any[]) => {
                const key = `locations-${variation.id || index}`;
                return (
                  <div key={key} className="flex gap-4 mt-6 items-center">
                    {getInventoryFields(index, variations, variation.id).map(
                      (field: any, fieldIndex: number) => (
                        <Field
                          key={`${key}-${fieldIndex}`}
                          {...registerField(field)}
                        />
                      ),
                    )}
                  </div>
                );
              },
            )}
          </div>
        </div>

        <div className="flex justify-between my-2">
          {allowVariations ? (
            <AddVariantButton
              title={t('products.addVariation')}
              isVisible={shouldShowCreateVariantButton}
              onClick={addVariationItem}
            />
          ) : (
            <div />
          )}
          <DeleteProductButton
            title={t('products.deleteProduct')}
            isVisible={shouldShowDeleteButton}
            onClick={doDeleteProduct}
          />
        </div>
      </Wrapper>
    </div>
  );
};

const getProductUrl = (uniqueName: string, itemId?: string) => {
  if (uniqueName && itemId) {
    return `https://${uniqueName}.${getHost()}?itemId=${itemId}`;
  } else {
    return null;
  }
};

const getHost = () => {
  return process.env.REACT_APP_ENV === 'PRODUCTION'
    ? 'myposyshop.com'
    : 'staging-myposyshop.com';
};

const Wrapper = styled.div`
  grid-gap: 12px;
`;

export default ProductForm;
