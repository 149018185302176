import { useState } from 'react';
import styled from 'styled-components';
import ModalTemplate from './ModalTemplate';
import { Field, useForm } from '@posy/components';
import { useTranslation } from 'react-i18next';

const deleteConfirmText = 'eliminar';

const FormModal = ({
  id,
  fields,
  initialValues,
  isOpen,
  title,
  columnsNumber = 1,
  onClose,
  onSubmit,
  onReset,
  confirmButtonText,
  confirmButtonColor,
  renderFooter,
}: {
  id?: string;
  title?: string;
  fields: any[];
  initialValues: any;
  isOpen: boolean;
  columnsNumber?: number;
  onClose: () => void;
  onSubmit: (value: any) => void;
  onReset: () => void;
  confirmButtonText?: string;
  confirmButtonColor?: string;
  renderFooter?: (data: any) => React.ReactNode;
}) => {
  const { t } = useTranslation();
  const [data] = useState<any>(initialValues);
  const { formValues, registerField, handleSubmit } = useForm({
    defaultValues: data,
  });
  const isSubmitDisabled =
    id === 'quick-sale' ? formValues?.confirmText !== deleteConfirmText : false;

  const submit = (values: any) => {
    onSubmit(values);
    onClose();
  };

  return (
    <ModalTemplate title={title} isOpen={isOpen} onClose={onClose}>
      <div>
        <FieldContainer columnsNumber={columnsNumber}>
          {fields.map((field: any) => (
            <Field key={field.name} {...registerField(field)} />
          ))}
        </FieldContainer>
        {renderFooter && renderFooter(formValues)}
        <div className="flex justify-end">
          {onReset && (
            <button
              className="text-red-700 font-medium rounded-md px-4 py-2 mt-2 mr-3"
              onClick={() => {
                onReset();
                onClose();
              }}
            >
              {t('removeSelection')}
            </button>
          )}

          <button
            className={`${
              confirmButtonColor || 'bg-indigo-700'
            } text-white font-medium rounded-md px-4 py-2 mt-4 disabled:bg-gray-500`}
            onClick={handleSubmit(submit)}
            disabled={isSubmitDisabled}
          >
            {confirmButtonText || t('save')}
          </button>
        </div>
      </div>
    </ModalTemplate>
  );
};

const FieldContainer = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: ${({ columnsNumber }: { columnsNumber: number }) =>
    `repeat(${columnsNumber},auto)`};
`;

export default FormModal;
