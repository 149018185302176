import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Transition } from '@headlessui/react';
import { useAuth } from '../Auth/AuthProvider';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import useFeatureFlags from 'hooks/useFeatureFlags';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const UserDropdown = () => {
  const { enableAccountDeletion } = useFeatureFlags();
  const { t } = useTranslation();
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const userNavigation = [
    { name: t('updateEmail.title'), action: 'updateEmail' },
    { name: t('deleteUser'), action: 'deleteUser' },
    { name: t('signOut'), action: 'signOut' },
  ];

  return (
    <Menu as="div" className="ml-3 relative">
      <div>
        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 py-1 px-3">
          <span className="sr-only">{t('user.openMenu')}</span>
          <div className="h-8 w-8 rounded-full bg-indigo-500 flex items-center justify-center">
            <p className="text-white font-bold">{user?.name?.charAt(0)}</p>
          </div>
          <div className="flex flex-col items-start ml-2">
            <p className="font-medium">{user?.name}</p>
            <p className="text-gray-700 text-xs">{user?.email}</p>
          </div>
          <ChevronDownIcon className="w-4 h-4 ml-2" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
          {userNavigation.map((item) =>
            item.action === 'deleteUser' && !enableAccountDeletion ? null : (
              <Menu.Item key={item.name}>
                {({ active }) => (
                  <div
                    role="button"
                    onClick={() => {
                      if (item.action === 'signOut') {
                        signOut();
                      } else if (item.action === 'updateEmail') {
                        navigate(`/settings/user/${user.id}`);
                      } else if (item.action === 'deleteUser') {
                        navigate('/settings/user/delete');
                      }
                    }}
                    className={classNames(
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700',
                    )}
                  >
                    {item.name}
                  </div>
                )}
              </Menu.Item>
            ),
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default UserDropdown;
