import React, { useEffect, useState } from 'react';
import { useAuth } from '../Auth/AuthProvider';
import createLocation from 'api/createLocation';
import getLocations from 'api/getLocations';

interface ModalContextType {
  defaultLocationId: string;
}

const ModalContext = React.createContext<ModalContextType>(null!);

const DefaultLocationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user } = useAuth();
  const [defaultLocation, setDefaultLocation] = useState<any>(null);

  useEffect(() => {
    if (user?.storeId) {
      getLocations(user?.storeId)
        .then(findDefaultLocation)
        .then((defaultLocation) => {
          if (defaultLocation) {
            setDefaultLocation(defaultLocation);
          } else {
            createLocation(
              { name: 'Store location', default: true },
              user?.storeId,
              user?.id,
            ).then(setDefaultLocation);
          }
        });
    }
  }, [user?.storeId]);

  if (!defaultLocation && user?.id) {
    return null;
  }

  return (
    <ModalContext.Provider
      value={{
        defaultLocationId: defaultLocation?.id,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

const findDefaultLocation = (locations: any[]) =>
  locations?.find((location: any) => location.default);

function useDefaultLocationProvider() {
  return React.useContext(ModalContext);
}

export { useDefaultLocationProvider };
export default DefaultLocationProvider;
