import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/solid';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

const DocumentOption = ({ document }: any) => {
  return (
    <RadioGroup.Option
      key={document.id}
      value={document}
      className={({ checked, active }) =>
        classNames(
          checked ? 'border-transparent' : 'border-gray-300',
          active ? 'ring-2 ring-indigo-500' : '',
          'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none',
        )
      }
    >
      {({ checked, active }) => (
        <>
          <div className="flex-1 flex">
            <div className="flex flex-col">{document.title}</div>
          </div>
          {checked ? (
            <CheckCircleIcon
              className="h-5 w-5 text-indigo-600"
              aria-hidden="true"
            />
          ) : null}
          <div
            className={classNames(
              active ? 'border' : 'border-2',
              checked ? 'border-indigo-500' : 'border-transparent',
              'absolute -inset-px rounded-lg pointer-events-none',
            )}
            aria-hidden="true"
          />
        </>
      )}
    </RadioGroup.Option>
  );
};

export default DocumentOption;
