import {
  collection,
  query,
  where,
  getFirestore,
  getDocs,
} from 'firebase/firestore';
import { UserAccount } from 'types';
import formatUserAccount from './helpers/formatUserAccount';

const getUserAccounts = async (storeId: string) => {
  const db = getFirestore();
  const ref = collection(db, 'users');
  const constrains = [where(`stores.${storeId}.userNumber`, '>', 0)];
  const q = query(ref, ...constrains);

  const snapshot = await getDocs(q);
  const userAccounts = snapshot.docs.map(formatUserAccount);

  return userAccounts.filter(
    (userAccount: UserAccount) => userAccount.employee,
  );
};

export default getUserAccounts;
