import { DuplicateIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

interface Props {
  textToCopy: string;
}

const CopyButton = ({ textToCopy }: Props) => {
  const { t } = useTranslation();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy);
  };

  return (
    <button
      type="button"
      className="relative inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 ml-4"
      onClick={copyToClipboard}
    >
      <DuplicateIcon
        className="-ml-0.5 h-5 w-5 text-gray-400"
        aria-hidden="true"
      />
      {t('copyLink')}
    </button>
  );
};

export default CopyButton;
