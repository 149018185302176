import CardApp from './CardApp';
import CatalogOverview from './CatalogOverview';
import DeviceOverview, { Device } from './DeviceOverview';

const Feed = ({ t, devices, store, data = {} }: any) => {
  const { onlineData, inPersonData } = data;

  return (
    <div className="flex flex-col overflow-auto flex-1">
      <div>
        <p className="font-medium text-gray-700 my-2">{t('onlineCatalog')}</p>
        <CatalogOverview store={store} onlineData={onlineData} />
      </div>
      <div>
        <p className="font-medium text-gray-700 my-2"> {t('team')}</p>
        {devices.map((device: Device) => (
          <DeviceOverview
            key={device.title}
            device={device}
            inPersonData={inPersonData}
          />
        ))}
      </div>
      <div>
        <p className="font-medium text-gray-700 my-2">{t('appPosy')}</p>
        <CardApp />
      </div>
    </div>
  );
};
export default Feed;
