import { doc, getFirestore, updateDoc } from 'firebase/firestore';

type UserAccountInput = {
  name: string;
  permissions: string[];
  active: boolean;
};

const updateUserAccount = (userId: string, payload: UserAccountInput) => {
  updateDoc(doc(getFirestore(), 'users', userId), payload);
};

export default updateUserAccount;
