import styled from 'styled-components';

interface Props {
  available: number;
  minStock: number;
}
const StockLabel = ({ available, minStock }: Props) => {
  return (
    <Label className={`font-medium mr-2 ${getTextColor(available, minStock)}`}>
      {available} en stock
    </Label>
  );
};

const getTextColor = (stock: number, minStock: number) => {
  if (stock > 0 && stock <= minStock) {
    return 'text-yellow-700';
  } else if (stock < 1) {
    return 'text-red-700';
  } else {
    return 'text-gray-700';
  }
};

const Label = styled.p`
  font-size: 12px;
`;

export default StockLabel;
