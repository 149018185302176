import { useEffect, useState } from 'react';
import { useAuth } from '../Auth/AuthProvider';
import { useNavigate } from 'react-router-dom';
import CycleCard from './components/CycleCard';
import getProPlan from '../api/getPlans';
import { useTranslation } from 'react-i18next';
import {
  FolderIcon,
  FolderOpenIcon,
  DeviceMobileIcon,
  CurrencyDollarIcon,
  ChevronLeftIcon,
  ClipboardIcon,
  SupportIcon,
  DesktopComputerIcon,
  ChartBarIcon,
  GlobeAltIcon,
  WifiIcon,
  PrinterIcon,
  TicketIcon,
  UserGroupIcon,
} from '@heroicons/react/outline';
import { LoadingDim, useSidePanel } from '@posy/components';

const Pricing = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const isBaseCountry = user.isBaseCountry;
  const [plan, setPlan] = useState<any>();
  const [isLoading] = useState<any>(false);
  const { navigateTo } = useSidePanel();

  useEffect(() => {
    getProPlan(isBaseCountry, setPlan);
  }, []);

  const getFormattedCycles = () => {
    const cycles = plan?.cycles || {};

    return Object.entries(cycles).map(([id, value]: any) => ({
      id,
      ...value,
      planId: plan?.id,
    }));
  };

  const features = [
    {
      title: 'First',
      features: [
        {
          label: t('features.inventoryControl'),
          Icon: ClipboardIcon,
          description:
            'Maneja el stock de tus productos y variantes con respaldo en la nube',
        },
        {
          label: t('features.unlimitedProducts'),
          Icon: FolderIcon,
          description: 'Registra tus productos y servicios sin limite',
        },
        {
          label: t('features.productVariants'),
          Icon: FolderOpenIcon,
          description:
            'Organiza de mejor manera tus productos con distintas configuraciones',
        },
        {
          label: t('features.inPersonOrders'),
          Icon: DeviceMobileIcon,
          description:
            'Crea, confirma y completa pedidos con tu smartphone en tu local físico o en terreno.',
        },
        {
          label: t('features.onlineStore'),
          Icon: GlobeAltIcon,
          description:
            'Crea tu tienda online en un minuto y comienza a recibir pedidos desde internet',
        },
        {
          label: t('features.posyWeb'),
          Icon: DesktopComputerIcon,
          description:
            'Administra tu negocio con comodidad desde tu computadora y tablet',
        },

        {
          label: t('features.ordersToPay'),
          Icon: CurrencyDollarIcon,
          description:
            'Permite a tus clientes que realicen multiples pagos o que paguen en otro momento',
        },
        {
          label: t('features.customerRegistration'),
          Icon: ClipboardIcon,
          description:
            'Almacena tu cartera de clientes y accede a sus datos de forma organizada.',
        },
        {
          label: t('features.userAccounts'),
          Icon: UserGroupIcon,
          description:
            'Crea cuentas de usuario para tus colaboradores. Gestiona distintos niveles de permisos según su rol.',
        },
        {
          label: t('features.digitalReceipt'),
          Icon: TicketIcon,
          description:
            'Generá un recibo dígital en formato PDF y compartelo en tus redes sociales',
        },
        {
          label: t('features.receiptPrinting'),
          Icon: PrinterIcon,
          description:
            'Conecta tu impresora termica a tu smartphone e imprime tickets y comandas para tus clientes.',
        },
        {
          label: t('features.sellOffline'),
          Icon: WifiIcon,
          description:
            'Sigue vendiendo incluso cuando pierdas conexión o sea inestable.',
        },
        {
          label: t('features.sumupMachineConnection'),
          Icon: ClipboardIcon,
          description: 'Puedes usar tu maquinita SumUp para cobrar tus pedidos',
        },
        {
          label: t('features.statisticsAndReports'),
          Icon: ChartBarIcon,
          description:
            'Toma mejores desiciones para tu negocio con distintas metricas que te ayudarán a crecer.',
        },
        {
          label: t('features.assistanceSupport'),
          Icon: SupportIcon,
          description:
            'Recibe una asistencia rápida y personalizada a traves de Whatsapp',
        },
      ],
    },
  ];

  const subscribe = (cycleId: string) => {
    navigateTo('SubscriptionResume', { plan, cycleId });
  };

  if (!plan) {
    return null;
  }

  const getMessageLabel = () => {
    if (user?.plan === 'trial') {
      return user?.isSubscriptionActive ? 'trialOn' : 'trialEnded';
    } else {
      return user?.isSubscriptionActive ? 'renew' : 'enroll';
    }
  };
  const messageLabel = getMessageLabel();

  return (
    <div className="p-6 lg:overflow-auto flex flex-col">
      <LoadingDim isLoading={isLoading} />

      {user?.isSubscriptionActive && (
        <div className="flex-1">
          <button
            type="button"
            className="inline-flex text-2xl font-semibold text-gray-900"
            onClick={() => navigate('/')}
          >
            <ChevronLeftIcon className="h-8 w-8" aria-hidden="true" />
            {t('subscriptionStatus.proPlan')}
          </button>
        </div>
      )}

      <div className="p-4">
        <p className="font-bold text-2xl text-center">
          {t(`pricing.${messageLabel}.title`)}
        </p>
        <p className="text-gray-600 text-lg text-center mt-2">
          {t(`pricing.${messageLabel}.description`)}
        </p>
      </div>
      <div className="flex gap-4 justify-around flex-wrap">
        {getFormattedCycles()
          .sort((a, b) => a.intervalNumber - b.intervalNumber)
          .map((cycle) => (
            <CycleCard
              key={cycle.id}
              cycle={cycle}
              currency={plan.currency}
              isBaseCountry={isBaseCountry}
              onSubscribe={subscribe}
            />
          ))}
      </div>

      <div className="w-full justify-around  mt-8">
        <p className="font-bold text-gray-600 text-lg text-center mb-4">
          {t(`pricing.enroll.featureDescription`)}
        </p>
        {features.map((feature: any) => (
          <div key={feature.label} className="grid lg:grid-cols-3">
            {feature.features.map(({ label, Icon, description }: any) => (
              <div
                key={feature.label}
                className="bg-white flex mx-2 my-2 px-4 pb-4 rounded-md"
              >
                <div>
                  <Icon className="h-6 w-6 mt-4 text-indigo-700" />
                </div>
                <div className="ml-4">
                  <p className="mt-4">{label}</p>
                  <p className="text-gray-600 text-sm">{description}</p>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pricing;
