import React from 'react';
import styled from 'styled-components';

// eslint-disable-next-line react/display-name
const Example = React.forwardRef(
  (
    {
      name,
      value,
      onChange,
    }: {
      title?: string;
      name: string;
      subtitle?: string;
      value: boolean;
      onChange: (value: any) => object;
    },
    ref: any,
  ) => {
    return (
      <Switch>
        <Input
          ref={ref}
          name={name}
          type="checkbox"
          onChange={() => {
            onChange({ target: { name, value: !value } });
          }}
          checked={value}
        />
        <Slider></Slider>
      </Switch>
    );
  },
);

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
  & > input:checked + span {
    background-color: rgb(67 56 202);
  }
  & > input:checked + span:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
  }
`;

const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  background-color: rgb(67 56 202);
  &:checked {
    background-color: rgb(67 56 202);
  }
  &:checked {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 12px;
  &:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }
`;

export default Example;
