import { getAuth } from 'firebase/auth';
import {
  getFirestore,
  collection,
  arrayUnion,
  writeBatch,
  doc,
} from 'firebase/firestore';
import updateInventoryLevels from './updateInventoryLevels';

interface PaymentDraft {
  amount: number;
  method: string;
}
const createOrder = (
  draftOrder: any,
  unpaid: number,
  paymentDraft?: PaymentDraft,
  isInDefaultLocation?: boolean,
): { id?: string } => {
  const paymentPayload = {
    amount: paymentDraft?.amount,
    method: paymentDraft?.method,
    createdAt: new Date().getTime(),
  };
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const isPaid = unpaid <= 0;
  const statusPayload = isPaid
    ? {
        closedAt: new Date().getTime(),
        isPaid,
        isClosed: isPaid,
        status: 'completed',
        isSale: true,
        timeline: arrayUnion({
          status: 'completed',
          createdAt: new Date().getTime(),
          user: {
            name: currentUser?.displayName,
          },
          uid: currentUser?.uid,
        }),
      }
    : {
        status: 'confirmed',
      };

  const items = Object.entries(draftOrder.items).map(([id, item]: any) => ({
    id,
    ...item,
  }));
  const orderPayload = {
    ...(paymentDraft ? { payments: arrayUnion(paymentPayload) } : {}),
    ...draftOrder,
    ...statusPayload,
  };

  try {
    const db = getFirestore();
    const batch = writeBatch(db);
    const orderRef = doc(collection(db, 'orders'));
    const payloadWithName = {
      ...orderPayload,
      name: `#${orderRef.id.slice(0, 4)}`,
    };
    batch.set(orderRef, payloadWithName);
    updateInventoryLevels(
      items,
      batch,
      'order',
      currentUser?.uid,
      draftOrder.locationId,
      isInDefaultLocation,
    );
    batch.commit();

    return { id: orderRef.id };
  } catch (e) {
    console.error(e);
    return {};
  }
};

export default createOrder;
