import { getFunctions, httpsCallable } from 'firebase/functions';

const requestDeletion = async (
  storeId: string,
  reason: string,
  comments: string,
) => {
  const functions = getFunctions();

  const requestAccountDeletion = httpsCallable(
    functions,
    'requestAccountDeletion',
  );

  return requestAccountDeletion({ storeId, reason, comments });
};
export default requestDeletion;
