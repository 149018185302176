import { getStockLevel } from '@posy/products';

const getCurrentTimestamp = (): number => {
  return new Date().getTime();
};

const getShippingAddress = (customer: any) => {
  const { address = null, city = null, state = null } = customer.shipping;

  return {
    address,
    city,
    state,
  };
};

const getIsOutOfStock = (
  availableQuantity: number,
  currentQuantity: number,
) => {
  return currentQuantity > availableQuantity;
};

const getIsLowStock = (
  availableQuantity: number,
  currentQuantity: number,
  minQuantity: number,
) => {
  if (!availableQuantity) {
    return false;
  }

  const newQuantity = availableQuantity - currentQuantity;
  return newQuantity <= Number(minQuantity);
};

const getFilteredData = (
  data: any[],
  query: string,
  category: string,
  hideOutOfStockItems: boolean,
  locationId: string,
): any[] => {
  return data.filter((item: any) => {
    const { name, categoryId } = item;
    const isNameMatched = sanitizeName(name).includes(query.toLowerCase());
    const shouldIncludeCategory = category ? category === categoryId : true;
    const shouldIncludeInStock = hideOutOfStockItems
      ? shouldItemBeIncluded(item, locationId)
      : true;

    return isNameMatched && shouldIncludeCategory && shouldIncludeInStock;
  });
};

const sanitizeName = (name: string) =>
  name
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

const getCartFormattedItem = (
  item: any,
  quantity: number,
  trackQty: boolean,
  selectedVariantId?: string,
) => {
  const defaultVariationId = Object.keys(item.variations)[0];
  const variationId = selectedVariantId || defaultVariationId;
  const selectedVariant = item.variations[variationId];
  const { name, price, salePrice, thumbnail, costPrice } = selectedVariant;

  return {
    name: item.name + (selectedVariantId ? ` ${name}` : ''),
    price: salePrice || price,
    format: item.format,
    trackQty,
    quantity,
    variationId,
    productId: item.id,
    ...(thumbnail ? { thumbnail } : {}),
    ...(costPrice ? { costPrice } : {}),
  };
};

const findItemByBarcode = (items: any[], q: string) => {
  let variationId = null;
  let item = null;
  items.some((it) => {
    const id = Object.keys(it.variations).find(
      (key) => it.variations[key]['barcode'] === q,
    );
    item = it;
    variationId = id;

    return id;
  });

  return {
    item,
    variationId,
  };
};

const shouldItemBeIncluded = (item: any, locationId: string) => {
  const { tracked, available } = getStockLevel({ product: item, locationId });

  if (tracked) {
    return available > 0;
  } else {
    return true;
  }
};

export {
  getCurrentTimestamp,
  getShippingAddress,
  getIsOutOfStock,
  getFilteredData,
  getCartFormattedItem,
  findItemByBarcode,
  getIsLowStock,
};
