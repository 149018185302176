import React from 'react';
import { Table } from '@posy/components';
import { useTranslation } from 'react-i18next';

const Customers = ({ data = [] }: { data: any[] }) => {
  const { t } = useTranslation();

  const columns = [
    { key: 'name', label: t('form.name') },
    { key: 'total', label: 'Total', type: 'money' },
    { key: 'count', label: 'Pedidos' },
  ];

  const formattedData = data.map((d: any) =>
    d._id ? d : { ...d, name: 'Sin registrar' },
  );

  return (
    <div
      className="flex flex-col bg-white p-4 mt-4 height-fit overflow-y-auto"
      style={{ height: 450 }}
    >
      <dt className="text-base font-normal text-gray-900 mb-4">
        {t('bestCustomers')}
      </dt>
      <Table
        columns={columns}
        rows={formattedData}
        style={{
          overflow: 'auto',
          flex: 1,
          background: 'white',
          margin: 16,
        }}
      />
    </div>
  );
};

export default Customers;
