import moment from 'moment-timezone';

const formatData = (input: any[] = []) => {
  return input
    .map((data) => ({
      name: moment(data._id).format('MMM D'),
      quantity: data.count,
    }))
    .slice(0, 7);
};

const getCycleFinalPrice = (
  regularPrice: number,
  isEligibleForDiscount: boolean,
  discount: number,
) => {
  const finalDiscount = isEligibleForDiscount ? discount : 0;

  return regularPrice - finalDiscount;
};

export { formatData, getCycleFinalPrice };
