import getInventory from "./getInventory";

const getStockLevel = ({
  product,
  locationId,
  variationId,
}: {
  product: any;
  locationId?: string;
  variationId?: string;
}) => {
  const inventoryData = getInventory({ product, locationId });

  if (locationId) {
    if (variationId) {
      const defaultStockLevel = { available: 0, tracked: false };

      return inventoryData.variations[variationId] || defaultStockLevel;
    } else {
      const { allVariations, variations } = inventoryData;
      const tracked = Object.keys(variations).length > 0;

      return { available: allVariations, tracked };
    }
  } else {
    if (variationId) {
      return {
        available: inventoryData.variations[variationId]?.available,
        tracked: Object.keys(inventoryData.variations).includes(variationId),
      };
    } else {
      const { allVariations, variations } = inventoryData;
      const tracked = Object.keys(variations).length > 0;
      return { available: allVariations, tracked };
    }
  }
};

export default getStockLevel;
