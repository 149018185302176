import React, { useState, useEffect } from 'react';
import { useModal, useSidePanel } from '@posy/components';
import { useTranslation } from 'react-i18next';
import useOnSnapshot from '../../components/useOnSnapshot';
import styled from 'styled-components';
import getOrderPayload from './getOrderPayload';
import { useAuth } from '../../Auth/AuthProvider';
import Cart from './components/Cart';
import Grid from './components/Grid';
import createPendingOrder from '../../api/createPendingOrder';
import useLocalStorage from '../../useLocalStorage';
import { useAnalytics } from '../../providers/AnalyticsProvider';
import { getShippingAddress } from './helpers';
import getItemInventory from './getItemInventory';
import InventoryBadge from './components/InventoryBadge';
import InventoryLabel from './components/InventoryLabel';
import { useProviderProducts } from '../../providers/ProductsProvider';
import { useDefaultLocationProvider } from 'providers/DefaultLocationProvider';
import StockBadge from './components/StockBadge';
import useFormModal from './components/useFormModal';
import FormModal from './components/FormModal';

interface Delivery {
  type: string;
  shippingAddres?: {
    address: string;
    city: string;
    state: string;
  };
}

interface CartItem {
  name: string;
  price: number;
  quantity: number;
  trackQty: boolean;
  thumbnail: string;
  variationId: string;
  productId: string;
}

const initialValues = {
  cartItems: [],
  delivery: null,
  customer: null,
  discount: 0,
  notes: null,
};

const Checkout = () => {
  const { products } = useProviderProducts();
  const { defaultLocationId } = useDefaultLocationProvider();
  const { data: categories } = useOnSnapshot('categories');
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { user } = useAuth();
  const cartId = getCartId(user);
  const { logEvent } = useAnalytics();
  const { value, setValue } = useLocalStorage(
    `checkout.${cartId}`,
    initialValues,
  );
  const [locationId, setLocationId] = useState<any>(value.locationId);

  useEffect(() => {
    const initialLocationId = value.locationId || defaultLocationId;
    const locationId = user?.locationId || initialLocationId;
    setLocationId(locationId);
  }, [defaultLocationId]);

  const [cartItems, setCartItems] = useState<CartItem[]>(value.cartItems);
  const [delivery, setDelivery] = useState<Delivery | null>(value.delivery);
  const [customer, setCustomer] = useState<any>(value.customer);
  const [discount, setDiscount] = useState<number>(value.discount);
  const [notes, setNotes] = useState<any>(value.notes);
  const { navigateTo } = useSidePanel();
  const { modalProps, openQuantityModal } = useFormModal();
  React.useEffect(() => {
    setValue((state: []) => {
      return {
        ...state,
        cartItems,
        delivery,
        customer,
        discount,
        notes,
        locationId,
      };
    });
  }, [cartItems, delivery, customer, discount, notes, locationId, setValue]);

  const addToCart = (item: any) => {
    const { variationId } = item;
    setCartItems((state: any) => {
      const index: number = state.findIndex(
        (item: any) => item.variationId === variationId,
      );
      if (index > -1) {
        const existingItem: any = state[index];
        const newQuantity = existingItem.quantity + item.quantity;

        return [
          ...state.slice(0, index),
          {
            ...item,
            quantity: Number(newQuantity.toFixed(3)),
          },
          ...state.slice(index + 1),
        ];
      } else {
        return state.concat(item);
      }
    });
  };

  const getDraftOrder = () => {
    return getOrderPayload(
      cartItems,
      customer,
      delivery,
      { id: user?.storeId, name: user?.store?.name },
      { id: user?.id, name: user?.name },
      notes,
      discount,
      locationId,
    );
  };

  const requestPayment = () => {
    const draftOrder = getDraftOrder();
    navigateTo('Payment', {
      order: draftOrder,
      toPay: draftOrder.total,
      onFinish: resetCart,
    });
  };

  const createPending = () => {
    createPendingOrder(getDraftOrder());
    navigateTo(`PaymentCompleted`, {
      order: {},
      toPay: 0,
      amount: 0,
      isPending: true,
    });
    resetCart();
    logEvent('create_pending_order');
  };

  const onItemChange = (inputItem: any) => {
    setCartItems((state: CartItem[]) => {
      const index: number = state.findIndex(
        (item: CartItem) => item.variationId === inputItem.variationId,
      );

      return [...state.slice(0, index), inputItem, ...state.slice(index + 1)];
    });
  };

  const onSelectCustomer = () => {
    logEvent('add_cart_customer');
    navigateTo('SelectCustomer', {
      isSelected: Boolean(customer),
      onSelect: (customer: any) => {
        setCustomer(customer);
        setDelivery(null);
      },
      onRemove: () => {
        logEvent('remove_cart_customer');
        setCustomer(null);
        setDelivery(null);
      },
    });
  };

  const onSelectNotes = (notes: string) => {
    logEvent('set_cart_notes');
    setNotes(notes);
  };

  const onSelectDiscount = (discount: number) => {
    logEvent('set_cart_discount');
    setDiscount(discount);
  };

  const onSelectDelivery = (type: string) => {
    const shouldDisplayAddress = type === 'shipping' && customer.shipping;
    const delivery = {
      type,
      ...(shouldDisplayAddress
        ? { shippingAddress: getShippingAddress(customer) }
        : {}),
    };

    setDelivery(delivery);
  };

  const onRemoveItem = (item: CartItem) => {
    setCartItems((state: CartItem[]) =>
      state.filter(({ variationId }) => item.variationId !== variationId),
    );
  };

  const resetCart = () => {
    setCartItems([]);
    setCustomer(null);
    setDelivery(null);
    setDiscount(0);
    setNotes(null);
    logEvent('reset_cart');
  };

  const onReset = () => {
    openModal({
      title: t('modals.resetCart.title'),
      body: t('modals.resetCart.body'),
      confirmLabel: t('modals.resetCart.confirmButton'),
      color: 'bg-indigo-600',
      onConfirm: resetCart,
    });
  };

  const findProduct = (products: any, productId: string) => {
    return products.find((product: any) => product.id === productId);
  };

  const onItemBadgeRender = ({ variationId, productId, quantity }: any) => {
    const product = findProduct(products, productId);

    if (!product) {
      return <></>;
    }
    const { isOutOfStock, isLowStock } = getItemInventory(
      product,
      variationId,
      quantity,
      locationId,
    );

    return (
      <InventoryBadge
        isOutOfStock={isOutOfStock}
        isLowStock={isLowStock}
        quantity={quantity}
      />
    );
  };

  const onItemLevelRender = ({ variationId, productId, quantity }: any) => {
    const product = findProduct(products, productId);

    if (!product) {
      return <></>;
    }

    const { isOutOfStock, isLowStock } = getItemInventory(
      product,
      variationId,
      quantity,
      locationId,
    );

    return (
      <InventoryLabel isOutOfStock={isOutOfStock} isLowStock={isLowStock} />
    );
  };

  const onEditItemQuantityPress = (item: any) => {
    const { variationId, productId, format } = item;
    const product = findProduct(products, productId);

    if (!product) {
      return <></>;
    }

    openQuantityModal({
      initialValues: { quantity: item.quantity },
      format,
      onSubmit: ({ quantity }: any) => {
        onItemChange({
          ...item,
          quantity: Number(quantity),
        });
      },
      renderFooter: (data: any) => {
        const { isLowStock, isOutOfStock, tracked } = getItemInventory(
          product,
          variationId,
          Number(data?.quantity),
          locationId,
        );
        return (
          <StockBadge
            visible={tracked}
            isLowStock={isLowStock}
            isOutOfStock={isOutOfStock}
          />
        );
      },
    });

    return null;
  };

  const updateLocation = (locationId: string) => {
    setLocationId(locationId);
    resetCart();
  };

  return (
    <Layout>
      <Grid
        data={products}
        categories={categories}
        locationId={locationId}
        onItemSelected={addToCart}
        onLocationChange={updateLocation}
      />
      <Cart
        items={cartItems}
        delivery={delivery}
        customer={customer}
        notes={notes}
        discount={discount}
        onItemBadgeRender={onItemBadgeRender}
        onItemLevelRender={onItemLevelRender}
        onEditItemQuantityPress={onEditItemQuantityPress}
        onItemChange={onItemChange}
        onRemoveItem={onRemoveItem}
        onRequestPayment={() => requestPayment()}
        onSave={() => createPending()}
        onReset={onReset}
        onSelectCustomer={onSelectCustomer}
        onSelectNotes={onSelectNotes}
        onRemoveNotes={() => setNotes(null)}
        onSelectDelivery={onSelectDelivery}
        onRemoveDelivery={() => setDelivery(null)}
        onSelectDiscount={onSelectDiscount}
      />
      {modalProps.isOpen && <FormModal {...modalProps} />}
    </Layout>
  );
};

const getCartId = (user: any): string => {
  const { id: userId, store } = user;

  return `${store?.id.slice(0, 4)}-${userId.slice(0, 4)}`;
};

const Layout = styled.div`
  display: grid;
  grid-template-columns: auto 350px;
`;

export default Checkout;
