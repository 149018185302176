import { useEffect, useState } from 'react';
import FormCard from '../components/FormCard';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ChevronLeftIcon, NewspaperIcon } from '@heroicons/react/outline';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { priceFormatter } from '@posy/helpers';
import StatusBadge from './components/StatusBadge';
import moment from 'moment-timezone';
import { getAmountInDebt } from './helpers';
import { useSidePanel, Image, useModal, LoadingDim } from '@posy/components';
import { XIcon, LocationMarkerIcon } from '@heroicons/react/solid';
import { useAuth } from '../Auth/AuthProvider';
import useOrder from 'hooks/useOrder';
import useLocations from 'hooks/useLocations';
import getAmountToPay from './getAmountToPay';
import ActionMenu from 'components/ActionMenu';
import useFormModal from 'checkout/Checkout/components/useFormModal';
import FormModal from 'checkout/Checkout/components/FormModal';
import { useProviderCustomers } from 'providers/CustomersProvider';
import setOrderDelivery from 'api/setOrderDelivery';
import useFeatureFlags from 'hooks/useFeatureFlags';

const { toPriceTag, getItemGross } = priceFormatter;

const Order = () => {
  const { navigateTo } = useSidePanel();
  const navigate = useNavigate();
  const { id } = useParams();
  const { getLocationNameById } = useLocations();
  const [order, setOrder] = useState<any>();
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { user, hasPermission } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const amountToPayLabel = getAmountToPay({
    payments: order?.payments || [],
    total: order?.total,
  });
  const isCreator = order?.uid === user?.id;
  const shouldShowEditButtons = isCreator || hasPermission('EDIT_ALL_ORDERS');
  const isNotCancelled = order?.status !== 'canceled';
  const shouldShowCancelButton = isNotCancelled && shouldShowEditButtons;
  const { confirmOrder, cancelOrder, completeOrder, refundOrder } =
    useOrder(order);
  const isFlowPayment = order?.paymentMethod === 'flow';
  const shouldShowRefundButton = isFlowPayment && !order?.refund;
  const { modalProps, openDeliveryModal } = useFormModal();
  const { customers } = useProviderCustomers();
  const { enableEditOrderDelivery } = useFeatureFlags();

  useEffect(() => {
    loadOrder();
  }, []);

  const doConfirmOrder = () => {
    openModal({
      title: t('modals.orderConfirm.title'),
      body: t('modals.orderConfirm.body'),
      confirmLabel: t('confirmButton'),
      color: 'bg-indigo-600',
      onConfirm: confirmOrder,
    });
  };

  const doCompleteOrder = () => {
    openModal({
      title: t('modals.orderComplete.title'),
      body: t('modals.orderComplete.body'),
      confirmLabel: t('completeButton'),
      color: 'bg-indigo-600',
      onConfirm: completeOrder,
    });
  };
  const doCancelOrder = () => {
    openModal({
      title: t('modals.orderCancel.title'),
      body: t('modals.orderCancel.body'),
      confirmLabel: t('cancelButton'),
      onConfirm: cancelOrder,
    });
  };

  const doRefundOrder = () => {
    openModal({
      title: t('modals.orderRefund.title'),
      body: t('modals.orderRefund.body'),
      confirmLabel: t('refundButton'),
      color: 'bg-indigo-600',
      onConfirm: async () => {
        setIsLoading(true);
        await refundOrder();
        setIsLoading(false);
      },
    });
  };
  const isOrderAuthor = () => {
    return user.id === order.uid;
  };

  const shouldCompleteOrder = () => {
    return (
      order.status === 'confirmed' &&
      order.isPaid &&
      (hasPermission('EDIT_ALL_ORDERS') || isOrderAuthor()) &&
      !order.refund
    );
  };

  const shouldPayOrder = () => {
    return order.status === 'confirmed' && !order.isPaid;
  };

  const goToPay = () => {
    const { total, payments = [] } = order;
    const paid = payments.reduce(
      (total: number, { amount }: any) => total + amount,
      0,
    );
    const toPay = total - paid;
    navigateTo(`Payment`, { order, toPay });
  };

  const loadOrder = async () => {
    const db = getFirestore();
    onSnapshot(doc(db, 'orders', `${id}`), (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        const items = Object.entries(data?.items || {}).map(
          ([key, value]: any) => ({
            id: key,
            ...value,
          }),
        );

        setOrder({
          id: doc.id,
          ...doc.data(),
          items,
        });
      }
    });
  };

  if (!order) {
    return null;
  }

  const shouldShowInDebtAmount = () => {
    return !order.isPaid && ['confirmed'].includes(order.status);
  };

  const getMoneyChange = (index: number) => {
    const slicedPayments = order.payments.slice(0, index);
    const paid = slicedPayments.reduce(
      (result: number, payment: any) => result + payment.amount,
      0,
    );
    const balance = order.total - paid;
    const payment = order.payments[index];

    return payment?.amount - balance;
  };

  const getActionMenuItems = () => {
    if (shouldShowCancelButton) {
      return [
        {
          label: 'Recibo dígital',
          Icon: NewspaperIcon,
          onClick: () => navigateTo('Receipt', { order }),
        },
        {
          label: 'Cancelar pedido',
          Icon: XIcon,
          onClick: doCancelOrder,
        },
      ];
    }

    return [];
  };

  return (
    <div className="p-4 overflow-auto">
      <div className="flex justify-end px-4 pt-6 mb-4">
        <div className="flex flex-1 items-center">
          <button
            type="button"
            className="inline-flex text-2xl font-semibold text-gray-900 mr-4"
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon className="h-8 w-8" aria-hidden="true" />
            <p>
              {t('table.order')} {order.name}
            </p>
          </button>
          <p className="flex items-center mr-4">
            <LocationMarkerIcon className="w-4 h-4 mr-1" />
            {getLocationNameById(order?.locationId)}
          </p>

          <StatusBadge
            status={order.status}
            amountToPayLabel={amountToPayLabel}
            isPaid={order.isPaid}
          />
        </div>
        {shouldShowEditButtons && (
          <>
            {order.status === 'pending' && (
              <button
                className="bg-indigo-700 text-white font-medium rounded-md px-4 py-2 mx-2"
                onClick={doConfirmOrder}
              >
                {t('confirmButton')}
              </button>
            )}

            {shouldPayOrder() && (
              <button
                className="bg-indigo-700 text-white font-medium rounded-md px-4 py-2 mx-2"
                onClick={goToPay}
              >
                {t('paymentMethods.addPayment')}
              </button>
            )}

            {shouldCompleteOrder() && (
              <button
                className="bg-indigo-700 text-white font-medium rounded-md px-4 py-2 mx-2"
                onClick={doCompleteOrder}
              >
                {t('completeOrder')}
              </button>
            )}
          </>
        )}
        <ActionMenu bordered items={getActionMenuItems()} />
      </div>
      <div className="flex gap-4">
        <LeftColumn>
          <FormCard title={t('articles')}>
            {Object.entries(order.items).map(([id, item]: any) => (
              <div key={id} className="grid grid-cols-3 my-2">
                <div className="flex-1 flex">
                  <Image
                    className="h-12 w-12 mr-2"
                    src={item?.thumbnail}
                    alt="Product logo"
                  />
                  {item.name}
                </div>
                <span className="text-right">
                  {`${toPriceTag(item.price)} x ${item.quantity}`}
                </span>
                <span className="text-right">
                  {toPriceTag(getItemGross(item))}
                </span>
              </div>
            ))}
          </FormCard>
          <FormCard title={t('payments')}>
            <Item label={t('order.subtotal')} value={order.subtotal} />
            <Item label={t('order.discount')} value={order.discount} />
            <Item label={t('order.total')} value={order.total} />
            {Object.entries(order.payments || {}).map(
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              ([id, item]: any, index: number) => (
                <>
                  <Item
                    label={t(`paymentMethods.${item.method}`)}
                    value={item.amount}
                    subtitle={moment(item.createdAt).format('DD/MM/YYYY HH:mm')}
                  />
                  {item.method === 'cash' && getMoneyChange(index) > 0 && (
                    <Item
                      label={t(`paymentMethods.change`)}
                      value={getMoneyChange(index)}
                    />
                  )}
                </>
              ),
            )}

            {shouldShowInDebtAmount() && (
              <Item
                label={t('order.inDebt')}
                value={getAmountInDebt(order.total, order.payments)}
                valueStyle={{ color: 'red' }}
              />
            )}
          </FormCard>
        </LeftColumn>
        <RightColumn>
          {order.delivery && (
            <FormCard title={t('delivery')}>
              <div>
                <p>
                  {order.delivery.type &&
                    t(`deliveryTypes.${order.delivery.type}`)}
                </p>
                {order.delivery.shippingAddress && (
                  <>
                    <p>{order.delivery.shippingAddress.address}</p>
                    <p>
                      {order.delivery.shippingAddress.city},{' '}
                      {order.delivery.shippingAddress.state}
                    </p>
                  </>
                )}
              </div>
              <AsignDeliveryButton
                label="Editar entrega"
                visible={order.delivery && enableEditOrderDelivery}
                orderId={order.id}
                customerId={order.customerId}
                initialDeliveryType={order.delivery?.type}
                customers={customers}
                openDeliveryModal={openDeliveryModal}
              />
            </FormCard>
          )}
          {order.customer && (
            <FormCard title={t('customer')}>
              <div>
                <p>{order.customer.name}</p>
                <p>{order.customer.phone}</p>
                <p>{order.customer.email}</p>
              </div>
              <AsignDeliveryButton
                label="Agregar entrega"
                visible={!order.delivery && enableEditOrderDelivery}
                orderId={order.id}
                customerId={order.customerId}
                initialDeliveryType={order.delivery?.type}
                customers={customers}
                openDeliveryModal={openDeliveryModal}
              />
            </FormCard>
          )}
          <FormCard
            title="Forma de pago"
            subtitle="Lo informa el comprador desde el catálogo. Asegurate de corroborar el pago antes de entregar los articulos"
            visible={Boolean(order.paymentMethod)}
          >
            <p>{t(`paymentMethods.${order.paymentMethod}`)}</p>
            {shouldShowRefundButton && (
              <button
                className="text-indigo-700 font-medium flex items-center self-end px-3"
                onClick={doRefundOrder}
              >
                {t('refundButton')}
              </button>
            )}
            {order.refund && <p>Estado del reembolso: {order.refund.status}</p>}
          </FormCard>
          <FormCard title={t('timeline')}>
            {order.timeline.map((item: any) => (
              <div key={item.createdAt} className="flex">
                <p className="flex-1">
                  {t(`timelineStatus.${item.status}`)}{' '}
                  {item.user ? t('by') + item.user.name : ''}
                </p>
                <p>{moment(item.createdAt).format('DD/MM/YYYY HH:mm')}</p>
              </div>
            ))}
          </FormCard>
          {order.notes && (
            <FormCard title={t('notes')}>
              <p>{order.notes}</p>
            </FormCard>
          )}
        </RightColumn>
      </div>
      <LoadingDim isLoading={isLoading} />
      {modalProps.isOpen && <FormModal {...modalProps} />}
    </div>
  );
};

const LeftColumn = ({ children }: any) => {
  return <div className="flex flex-col flex-1 gap-4 h-fit">{children}</div>;
};

const RightColumn = ({ children }: any) => {
  return <div className="flex flex-col gap-4 h-fit w-1/3">{children}</div>;
};

const Item = ({
  label,
  value,
  subtitle,
  valueStyle = {},
}: {
  label: string;
  subtitle?: string;
  value: number;
  valueStyle?: any;
}) => {
  return (
    <div className="flex my-1">
      <div className="flex-1">
        <p>{label}</p>
        {subtitle && <p className="text-gray-500">{subtitle}</p>}
      </div>
      <p style={valueStyle}>{toPriceTag(value)}</p>
    </div>
  );
};

const AsignDeliveryButton = ({
  label,
  visible = true,
  customers,
  orderId,
  customerId,
  openDeliveryModal,
  initialDeliveryType = 'pickup',
}: any) => {
  const customerShipping = customers.find(
    (customer: any) => customer.id === customerId,
  )?.shipping;

  if (!visible) {
    return null;
  }

  return (
    <button
      className="text-indigo-700 text-sm mx-2 self-end"
      onClick={() => {
        openDeliveryModal({
          initialValues: {
            type: initialDeliveryType,
          },
          shipping: customerShipping,
          onSubmit: (data: any) => {
            const {
              address = null,
              city = null,
              state = null,
            }: any = customerShipping;
            const shippingAddress = { address, city, state };
            setOrderDelivery(orderId, {
              type: data.type,
              ...(data.type === 'shipping' && { shippingAddress }),
            });
          },
        });
      }}
    >
      {label}
    </button>
  );
};

export default Order;
