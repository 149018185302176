import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

const Chart = ({ formattedData }: { formattedData: any }) => {
  return (
    <div className="bg-white p-4 mb-2 mt-1">
      <p className="text-sm font-medium mb-4">Pedidos generados</p>
      <ResponsiveContainer width="100%" height={240}>
        <LineChart
          data={formattedData}
          margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
        >
          <Line
            type="monotone"
            dataKey="quantity"
            stroke="#6244bb"
            strokeWidth={3}
            yAxisId={0}
          />
          <Tooltip />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />

          <XAxis dataKey="name" dy={12} />
          <YAxis tickCount={3} dx={-12} axisLine={false} tickLine={false} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
