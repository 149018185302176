interface Props {
  title: string;
  isVisible: boolean;
  onClick: any;
}

const AddVariantButton = ({ title, isVisible, onClick }: Props) =>
  isVisible ? (
    <button className="text-indigo-700" onClick={onClick}>
      {title}
    </button>
  ) : null;

export default AddVariantButton;
