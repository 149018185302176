import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { Table, Image, useSidePanel } from '@posy/components';
import { getBadgeClasses } from '../Products/helpers';
import useLocations from 'hooks/useLocations';
import { getStockLevel } from '@posy/products';
import { Product } from 'types';

const InventoryMovements = () => {
  const { t } = useTranslation();
  const { navigateTo, params } = useSidePanel();
  const { locations } = useLocations();
  const { product, variations, locationId: initialLocationId } = params;
  const [locationId, setLocation] = useState(initialLocationId);
  const filteredVariations = getFilteredVariations({ product, locationId });

  const columns = [
    {
      key: 'name',
      label: t('products.item'),
      render: (row: any) => (
        <div className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
          <div className="flex items-center">
            <div className="h-10 w-10 flex-shrink-0">
              <Image
                className="h-10 w-10"
                src={row?.thumbnail}
                alt="Product logo"
              />
            </div>
            <div className="ml-4">
              <div className="font-medium text-gray-900">{row?.name}</div>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: 'available',
      label: t('movement.quantity'),
      render: (row: any) => {
        const { available, minQuantity } = row;
        const badgeClasses = getBadgeClasses(available, minQuantity);

        return (
          <div className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <span
              className={` inline-flex rounded-full ${badgeClasses} px-2 text-xs font-semibold leading-5`}
            >
              {available}
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="p-4">
        <div>
          <button
            type="button"
            className="inline-flex"
            onClick={() => navigateTo('')}
          >
            <ChevronLeftIcon className="h-7 w-7" aria-hidden="true" />
            <p className="text-xl text-left font-semibold text-gray-900">
              {product.name}
            </p>
          </button>
        </div>
      </div>

      <Table
        columns={columns}
        rows={filteredVariations}
        keepFilterBarVisible
        onRowClick={(rowId: string) =>
          navigateTo('InventoryMovements', {
            variationId: rowId,
            productName: product?.name,
            variation: product?.variations[rowId],
            hasMultipleVariations: Object.keys(product?.variations).length > 1,
            product,
            variations,
            locationId,
            maxWidth: '36rem',
          })
        }
        filters={[
          {
            label: 'Todas las ubicaciones',
            name: 'locationId',
            options: locations,
            onChange: (value: string) => {
              setLocation(value);
            },
            disable: true,
            default: locationId,
          },
        ]}
        style={{
          overflow: 'auto',
          height: '100%',
          background: 'white',
          margin: 16,
        }}
      />
    </>
  );
};

const getFilteredVariations = ({
  product,
  locationId,
}: {
  product: Product;
  locationId: string;
}) => {
  return Object.entries(product.variations).reduce(
    (result: any, [, { id, ...rest }]: any) => {
      const { tracked, available } = getStockLevel({
        locationId,
        product,
        variationId: id,
      });
      if (tracked) {
        return result.concat({ ...rest, available, id });
      } else {
        return result;
      }
    },
    [],
  );
};

export default InventoryMovements;
