export const countries = [
  { name: 'Argentina', id: 'AR' },
  { name: 'Bolivia', id: 'BO' },
  { name: 'Chile', id: 'CL' },
  { name: 'Colombia', id: 'CO' },
  { name: 'Ecuador', id: 'EC' },
  { name: 'España', id: 'ES' },
  { name: 'Estados Unidos', id: 'US' },
  { name: 'México', id: 'MX' },
  { name: 'Perú', id: 'PE' },
  { name: 'Paraguay', id: 'PY' },
  { name: 'Uruguay', id: 'UY' },
  { name: 'Venezuela', id: 'VE' },
];
