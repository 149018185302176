import { useEffect, useState } from 'react';
import {
  getFirestore,
  query,
  collection,
  where,
  orderBy,
  onSnapshot,
} from 'firebase/firestore';
import { useAuth } from '../Auth/AuthProvider';

const useOnSnapshot = (collectionName: string) => {
  const { user, hasPermission } = useAuth();
  const storeId = user?.storeId;
  const [documents, setDocuments] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getQuery = (ref: any, collectionName: any) => {
    if (collectionName === 'categories') {
      return query(ref, where('storeId', '==', storeId));
    } else if (collectionName === 'orders') {
      const filters = [
        where('storeId', '==', storeId),
        where('status', 'in', ['pending', 'confirmed']),
        orderBy('createdAt', 'desc'),
      ];

      if (!hasPermission('VIEW_ALL_ORDERS')) {
        filters.push(where('uid', '==', user?.id));
      }

      return query(ref, ...filters);
    } else {
      return query(
        ref,
        where('storeId', '==', storeId),
        orderBy('createdAt', 'desc'),
      );
    }
  };

  useEffect(() => {
    const db = getFirestore();
    const ref = collection(db, collectionName);

    if (user) {
      const q = getQuery(ref, collectionName);

      const unsub = onSnapshot(q, (snapshot: any) => {
        const documents: any = snapshot.docs.map((doc: any) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setDocuments(documents);
        setIsLoading(false);
      });

      return () => {
        unsub();
      };
    }
  }, [storeId]);

  return { data: documents, isLoading };
};

export default useOnSnapshot;
