import { useTranslation } from 'react-i18next';

interface Props {
  visible: boolean;
  isOutOfStock: boolean;
  isLowStock: boolean;
}
const StockBadge = ({ visible = true, isOutOfStock, isLowStock }: Props) => {
  const { t } = useTranslation();

  if (!visible) {
    return null;
  }

  if (isOutOfStock) {
    return (
      <div className="mt-2">
        <p className="text-xs text-red-500">{t('outOfStock')}</p>
      </div>
    );
  } else if (isLowStock) {
    return (
      <div className="mt-2">
        <p className="text-xs text-yellow-500">{t('products.lowStock')}</p>
      </div>
    );
  } else {
    return null;
  }
};

export default StockBadge;
