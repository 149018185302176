import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Table } from '@posy/components';

import PageHeader from '../components/PageHeader';
import Button from 'components/Button';
import getColumns from './getColumns';
import useUserAccounts from './hooks/useUserAccounts';

const UserAccounts = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userAccounts, isLoading } = useUserAccounts();
  const columns = getColumns();
  const searchFields = [{ label: t('form.name'), value: 'name' }];

  return (
    <>
      <PageHeader title={t('sidebar.userAccounts')}>
        <Button
          label={t('createUserAccount')}
          onClick={() => navigate(`/userAccounts/new`)}
        />
      </PageHeader>
      <Table
        columns={columns}
        rows={userAccounts}
        onRowClick={(id: string) => navigate(`/userAccounts/${id}`)}
        searchFields={searchFields}
        isLoading={isLoading}
        sort={['name', 'ASC']}
        style={{
          height: '100%',
          background: 'white',
          margin: 16,
        }}
      />
    </>
  );
};

export default UserAccounts;
