import React from "react";
import ConfirmationModal from "./ConfirmationModal";

interface ModalContextType {
  isOpen: boolean;
  title: string;
  body: string
  confirmLabel?: string
  color: string;
  isCancellable:boolean;
  openModal: ({
    title,
    body,
    confirmLabel,
    color,
    isCancellable,
    onConfirm,
  }: {
    title: string;
    body: string;
    confirmLabel?: string;
    color?: string,
    isCancellable?:boolean,
    onConfirm: () => void;
  }) => void;
  closeModal: () => void;
  onConfirm: () => void;
}

let ModalContext = React.createContext<ModalContextType>(null!);

const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const [title, setTitle] = React.useState<string>('');
  const [body, setBody] = React.useState<string>('');
  const [confirmLabel, setConfirmLabel] = React.useState<string>()
  const [onConfirm, setOnConfirm] = React.useState<any>(() => { });
  const [color, setColor] = React.useState<string>('');
  const [isCancellable, setIsCancellable] = React.useState<boolean>(true);

  const openModal = ({ title, body, confirmLabel, onConfirm, color, isCancellable = true }: any) => {
    setOpen(true);
    setTitle(title);
    setBody(body);
    setBody(body);
    setColor(color)
    setIsCancellable(isCancellable)
    setConfirmLabel(confirmLabel)
    setOnConfirm(() => onConfirm);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const value = { isOpen, title, body, confirmLabel, color,isCancellable, openModal, closeModal, onConfirm };

  return (
    <ModalContext.Provider value={value}>
      <ConfirmationModal />
      {children}
    </ModalContext.Provider>
  );
};

function useModal() {
  return React.useContext(ModalContext);
}

export { useModal };
export default ModalProvider;
