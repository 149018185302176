import React from 'react';

// eslint-disable-next-line react/display-name
const TextArea = React.forwardRef(
  (
    {
      label,
      name,
      value,
      lines = 5,
      onChange,
    }: {
      label?: string;
      name: string;
      subtitle?: string;
      value: string;
      lines: number;
      onChange: (value: any) => object;
    },
    ref: any,
  ) => {
    return (
      <div>
        <label
          htmlFor="comment"
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
        <div className="mt-1">
          <textarea
            ref={ref}
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            rows={lines}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md p-2"
          />
        </div>
      </div>
    );
  },
);

export default TextArea;
