import { getFirestore, addDoc, collection } from 'firebase/firestore';

const createLocation = async (values: any, storeId: string, userId: string) => {
  const payload = {
    ...values,
    storeId,
    uid: userId,
    createdAt: new Date().getTime() * 1000,
  };

  const locationsRef = collection(getFirestore(), 'locations');
  const snapshot = await addDoc(locationsRef, payload);

  return {
    id: snapshot.id,
    ...payload,
  };
};

export default createLocation;
