import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Device } from './components/DeviceOverview';
import Feed from './components/Feed';
import { useAuth } from '../Auth/AuthProvider';
import getStore from '../api/getStore';
import getOverviewData from '../api/getOverviewData';
import RightSidebar from './components/RightSidebar';
import useIsLargeScreen from 'hooks/useIsLargeScreen';

export interface Subscription {
  nextChargeDate?: string;
}

const Home = () => {
  const { t } = useTranslation();
  const [store, setStore] = useState<any>();
  const [overviewData, setOverviewData] = useState<any>();
  const { user } = useAuth();
  const isLargeScreen = useIsLargeScreen();
  const devices: Device[] = [
    {
      title: user?.name,
      subtitle: user?.email,
      isActive: true,
      isAdmin: true,
    },
  ];

  useEffect(() => {
    const storeId = user?.storeId;
    if (storeId) {
      fetchStore();
      getOverviewData(storeId, (data: any) => setOverviewData(data));
    }
  }, [user?.storeId]);

  const fetchStore = async () => {
    try {
      const storeSnapshot = await getStore(user.storeId);
      setStore({ id: storeSnapshot.id, ...storeSnapshot.data() });
    } catch (e) {
      console.error(e);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <>
      <p className="text-2xl font-semibold text-gray-900 mt-4 ml-4">
        {t('welcome')}
      </p>
      <div className="flex flex-1 overflow-hidden gap-4 w-full px-4">
        <Feed
          devices={devices}
          t={t}
          user={user}
          store={store}
          data={overviewData}
        />
        <RightSidebar
          visible={isLargeScreen}
          subscriptions={user?.subscriptions}
          trialEndDate={store?.trialEndDate}
        />
      </div>
    </>
  );
};

export default Home;
