import { useEffect, useState } from 'react';
import { SearchBar, Select, useModal } from '@posy/components';
import { LightningBoltIcon } from '@heroicons/react/outline';
import { t } from 'i18next';
import { v4 as uuidv4 } from 'uuid';
import useFormModal from './useFormModal';
import FormModal from './FormModal';
import { useNavigate } from 'react-router-dom';
import useBarcodeScanner from '../useBarcodeScanner';
import { useAnalytics } from '../../../providers/AnalyticsProvider';
import ItemCard from './ItemCard';
import AddItemCard from './AddItemCard';
import VariationSelection from './VariationSelection';
import {
  findItemByBarcode,
  getCartFormattedItem,
  getFilteredData,
} from '../helpers';
import LocationPicker from './LocationPicker/LocationPicker';
import { useSettings } from 'providers/SettingsProvider';
import { getStockLevel } from '@posy/products';
import { useAuth } from 'Auth/AuthProvider';

const Grid = ({
  data,
  categories,
  onItemSelected,
  locationId,
  onLocationChange,
}: {
  data: any;
  categories: any[];
  onItemSelected: (item: any) => void;
  locationId: string;
  onLocationChange: (locationId: string) => void;
}) => {
  const { logEvent } = useAnalytics();
  const [search, setSearch] = useState('');
  const [category, setCategory] = useState('');
  const [isSelectionOpen, setSelectionOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const { modalProps, openQuickSaleModal } = useFormModal();
  const { openModal } = useModal();
  const navigate = useNavigate();
  const { barcode, resetBarcode } = useBarcodeScanner();
  const { settings } = useSettings();
  const { hasPermission } = useAuth();
  const hideOutOfStockItems = settings?.hideOutOfStockItems;

  useEffect(() => {
    if (barcode.length > 0) {
      resetBarcode();
      logEvent('scan_product');
      const { item, variationId } = findItemByBarcode(data, barcode);
      if (variationId) {
        addItem(item, 1, variationId);
      } else {
        openModal({
          title: t('modals.barcodeNotFound.title'),
          body: t('modals.barcodeNotFound.body'),
          confirmLabel: t('modals.barcodeNotFound.confirmButton'),
          color: 'bg-indigo-600',
          onConfirm: () => navigate(`/products/new?barcode=${barcode}`),
        });
      }
    }
  }, [barcode]);

  const selectItem = (item: any) => {
    const hasVariations = Object.keys(item.variations).length > 1;
    if (hasVariations || item.format === 'fraction') {
      setSelectedProduct(item);
      setSelectionOpen(true);
    } else {
      addItem(item, 1);
    }
  };

  const addItem = (item: any, quantity: number, variantId?: string) => {
    const defaultVariationId = Object.keys(item.variations)[0];
    const variationId = variantId || defaultVariationId;
    const { tracked } = getStockLevel({
      product: item,
      locationId,
      variationId,
    });
    const cartItem = getCartFormattedItem(item, quantity, tracked, variantId);
    onItemSelected(cartItem);
    logEvent('add_cart_item');
  };

  const addCustomItem = ({ description, price, quantity }: any) => {
    const customId = uuidv4();
    const productId = `prod-${customId}`;
    const variationId = `var-${customId}`;
    const name = description || 'Sin descripción';
    const customItem = {
      id: productId,
      name,
      format: 'unit',
      variations: {
        [variationId]: {
          name: '',
          price: Number(price),
        },
      },
    };
    addItem(customItem, Number(quantity));
    logEvent('add_custom_cart_item');
  };
  const filteredData = getFilteredData(
    data,
    search,
    category,
    hideOutOfStockItems,
    locationId,
  );
  const sortedProducts = filteredData.sort(
    (a: any, b: any) => b.isHighlighted - a.isHighlighted,
  );

  return (
    <div className="flex flex-col h-[calc(100vh-64px)]">
      <div className="flex mt-4 ml-4 mr-8 p-2 bg-white rounded-md">
        <LocationPicker locationId={locationId} onChange={onLocationChange} />
        <SearchBar
          onChange={(e: any) => setSearch(e.target.value)}
          placeholder="Buscar un item"
          style={{ height: 36 }}
        />
        <Select
          options={categories}
          placeholder={t('category')}
          onChange={(e) => {
            setCategory(e.target.value);
          }}
          style={{ height: 38, marginLeft: 12, marginTop: -5, width: 140 }}
        />
        <button
          type="button"
          className="inline-flex ml-4 mr-2"
          onClick={() => {
            openQuickSaleModal({
              initialValues: {
                quantity: 1,
              },
              onSubmit: addCustomItem,
            });
          }}
        >
          <LightningBoltIcon
            className="text-gray-700"
            aria-hidden="true"
            style={{ width: 24, height: 38 }}
          />
        </button>
      </div>

      <VariationSelection
        product={selectedProduct}
        productName={selectedProduct?.name}
        productFormat={selectedProduct?.format}
        variations={selectedProduct?.variations}
        locationId={locationId}
        isOpen={isSelectionOpen}
        onSubmit={(selectedItems = {}) => {
          Object.entries(selectedItems).map(
            ([id, quantity]: any) =>
              quantity > 0 && addItem(selectedProduct, quantity, id),
          );
        }}
        onClose={() => setSelectionOpen(false)}
      />
      <div className="flex gap-4 flex-wrap p-4 overflow-y-auto">
        {hasPermission('PRODUCT_UPDATE') && <AddItemCard />}
        {sortedProducts.map((item: any, index: number) => {
          const { tracked, available } = getStockLevel({
            product: item,
            locationId,
          });

          return (
            <ItemCard
              key={index}
              item={item}
              selectItem={selectItem}
              tracked={tracked}
              available={available}
            />
          );
        })}
      </div>
      {modalProps.isOpen && <FormModal {...modalProps} />}
    </div>
  );
};

export default Grid;
