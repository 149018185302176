import moment from 'moment-timezone';

type PeriodIndex = {
  [key: string]: Period;
};

type Period = {
  period: string;
  unit: number;
  subtract?: boolean;
};

const mapping: PeriodIndex = {
  day: {
    period: 'day',
    unit: 1,
  },
  yesterday: {
    period: 'day',
    unit: 1,
    subtract: true,
  },
  last7Days: {
    period: 'day',
    unit: 7,
    subtract: true,
  },
  last30Days: {
    period: 'day',
    unit: 30,
    subtract: true,
  },
  week: {
    period: 'isoWeek',
    unit: 1,
  },
  prevWeek: {
    period: 'isoWeek',
    unit: 1,
    subtract: true,
  },
  month: {
    period: 'month',
    unit: 1,
  },
  prevMonth: {
    period: 'month',
    unit: 1,
    subtract: true,
  },
};

const getRange = (key: string): { startDate: string; endDate: string } => {
  const { subtract, period, unit }: any = mapping[key];
  const m = subtract ? moment().subtract(unit, period) : moment();

  if (['last7Days', 'last30Days'].includes(key)) {
    return {
      startDate: m.toISOString(),
      endDate: moment().toISOString(),
    };
  } else {
    return {
      startDate: m.startOf(period).toISOString(),
      endDate: m.endOf(period).toISOString(),
    };
  }
};

export { getRange };
