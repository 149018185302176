import { updateDoc, getFirestore, doc, arrayUnion } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

export enum PaymentMethod {
  CASH = 'cash',
  CARD = 'card',
  TRANSFER = 'transfer',
  OTHER = 'other',
}

interface PaymentDraft {
  amount: number;
  method: string;
}

const payOrder = (
  orderId: string,
  unpaid: number,
  paymentDraft: PaymentDraft,
  user: any,
) => {
  const paymentPayload = {
    amount: paymentDraft.amount,
    method: paymentDraft.method,
    createdAt: new Date().getTime(),
  };

  const auth = getAuth();
  const currentUser = auth.currentUser;
  const isPaid = unpaid <= 0;
  const statusPayload = isPaid
    ? {
        closedAt: new Date().getTime(),
        isPaid,
        isClosed: isPaid,
        status: 'completed',
        isSale: true,
        timeline: arrayUnion({
          status: 'completed',
          createdAt: new Date().getTime(),
          user: {
            name: user?.name,
          },
          uid: currentUser?.uid,
        }),
      }
    : {
        status: 'confirmed',
      };

  updateDoc(doc(getFirestore(), 'orders', orderId), {
    payments: arrayUnion(paymentPayload),
    ...statusPayload,
  });
};

export default payOrder;
