import { getFirestore, doc, getDoc } from 'firebase/firestore';
import formatUserAccount from './helpers/formatUserAccount';

const getUserAccount = async (userId: string) => {
  const docRef = doc(getFirestore(), 'users', userId);
  const docSnap = await getDoc(docRef);

  return formatUserAccount(docSnap);
};

export default getUserAccount;
