import { t } from 'i18next';

const countries = [
  { name: 'Argentina', id: 'AR' },
  { name: 'Bolivia', id: 'BO' },
  { name: 'Chile', id: 'CL' },
  { name: 'Colombia', id: 'CO' },
  { name: 'Costa Rica', id: 'CR' },
  { name: 'Ecuador', id: 'EC' },
  { name: 'El Salvador', id: 'SV' },
  { name: 'España', id: 'ES' },
  { name: 'Estados Unidos', id: 'US' },
  { name: 'Guatemala', id: 'GT' },
  { name: 'Honduras', id: 'HN' },
  { name: 'México', id: 'MX' },
  { name: 'Nicaragua', id: 'NI' },
  { name: 'Panamá', id: 'PA' },
  { name: 'Paraguay', id: 'PY' },
  { name: 'Perú', id: 'PE' },
  { name: 'República Dominicana', id: 'DO' },
  { name: 'Uruguay', id: 'UY' },
  { name: 'Venezuela', id: 'VE' },
];

const getMainFields = (hasNoEditPermission: boolean) => [
  {
    label: t('form.name'),
    name: 'name',
    type: 'text',
    rules: ['required', 'textOnly'],
    readOnly: hasNoEditPermission,
  },
  {
    label: t('form.phone'),
    name: 'phone',
    type: 'text',
    rules: ['number'],
    readOnly: hasNoEditPermission,
  },
];

type AddressFieldsInput = {
  hasNoEditPermission: boolean;
  regiones: any[];
  comunas: any[];
  country: any;
};
const getAddressFields = ({
  hasNoEditPermission,
  regiones,
  comunas,
  country,
}: AddressFieldsInput) => [
  {
    label: t('shippingAddress.address'),
    name: 'shipping.address',
    type: 'text',
    readOnly: hasNoEditPermission,
  },
  {
    label: t('shippingAddress.state'),
    name: 'shipping.state',
    type: 'select',
    options: regiones,
    readOnly: hasNoEditPermission,
    hidden: country !== 'CL',
  },
  {
    label: t('shippingAddress.city'),
    name: 'shipping.city',
    type: country === 'CL' ? 'select' : 'text',
    options: comunas,
    disabled: false,
    readOnly: hasNoEditPermission,
  },
  {
    label: t('shippingAddress.country'),
    name: 'shipping.country',
    type: 'select',
    options: countries,
    readOnly: hasNoEditPermission,
  },
];

const getMoreFields = (hasNoEditPermission: boolean) => [
  {
    label: t('form.email'),
    name: 'email',
    type: 'text',
    readOnly: hasNoEditPermission,
  },
  {
    label: t('idNumber'),
    name: 'docId',
    type: 'text',
    readOnly: hasNoEditPermission,
  },
  {
    label: t('notes'),
    name: 'notes',
    type: 'paragraph',
    rules: ['textOnly'],
    readOnly: hasNoEditPermission,
  },
];
const getSocialMediaFields = (hasNoEditPermission: boolean) => [
  {
    label: 'Instagram',
    name: 'social.instagram',
    type: 'text',
    readOnly: hasNoEditPermission,
  },
  {
    label: 'Facebook',
    name: 'social.facebook',
    type: 'text',
    readOnly: hasNoEditPermission,
  },
  {
    label: 'Tiktok',
    name: 'social.tiktok',
    type: 'text',
    readOnly: hasNoEditPermission,
  },
];

export { getMainFields, getAddressFields, getMoreFields, getSocialMediaFields };
