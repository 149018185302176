import isNumber from "lodash/isNumber";
import { getProductDisplayPrice } from "./helpers";

const formatProduct = (product: any, defaultLocationId: string) => {
  const locations = formatLocations(
    product.locations,
    product.variations,
    defaultLocationId
  );
  const hasLocations = Object.keys(locations).length > 0;

  return {
    ...product,
    price: getProductDisplayPrice(product.variations),
    getInventoryLevel: getInventoryLevel(locations),
    minStock: getMinQuantity(product.variations),
    thumbnail: getThumbnail(product.variations),
    isTrackingStock: checkIsTrackingStock(product.variations),
    isHighlighted: Boolean(product.isHighlighted),
    ...(hasLocations && { locations }),
  };
};

const formatLocations = (
  locations: any = {},
  variations: any,
  defaultLocationId: string
) => {
  const variationEntries = Object.entries(variations);
  const defaultLocationVariations = variationEntries.reduce(
    (obj: any, [id, { trackQty, quantity }]: any) => {
      if (isNumber(quantity) && trackQty) {
        return {
          ...obj,
          [id]: {
            available: parseFloat(quantity.toFixed(3)),
            tracked: trackQty,
          },
        };
      } else {
        return obj;
      }
    },
    {}
  );
  const hasDefaultLocationVariations =
    Object.keys(defaultLocationVariations).length > 0;

  return hasDefaultLocationVariations
    ? {
        ...locations,
        [defaultLocationId]: {
          variations: defaultLocationVariations,
        },
      }
    : locations;
};

const getInventoryLevel =
  (locations: any = {}) =>
  (locationId: any) => {
    if (locationId) {
      return Object.entries(locations[locationId]?.variations || {}).reduce(
        (obj, [id, variation]: any) => {
          return {
            ...obj,
            allVariations: obj.allVariations + variation.available,
            variations: {
              ...obj.variations,
              [id]: variation.available,
            },
          };
        },
        {
          allVariations: 0,
          variations: {},
        }
      );
    } else {
      let finalObj: any = {
        allVariations: 0,
        variations: {},
      };

      Object.entries(locations || {}).forEach(([, { variations }]: any) => {
        Object.entries(variations).forEach(([id, variation]: any) => {
          finalObj = {
            ...finalObj,
            allVariations: finalObj.allVariations + variation.available,
            variations: {
              ...finalObj.variations,
              [id]: (finalObj.variations[id] || 0) + variation.available,
            },
          };
        });
      });

      return finalObj;
    }
  };

const getThumbnail = (variations: any[]): string => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [[_, variation]] = Object.entries(variations);
  return variation.thumbnail;
};

const getMinQuantity = (variations: any[]): number => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return Object.entries(variations).reduce((result, [key, value]) => {
    return isNumber(value.minQuantity) ? result + value.minQuantity : result;
  }, 0);
};

const checkIsTrackingStock = (variations: any[]): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return Object.entries(variations).some(([_, value]: any) => true);
};

export default formatProduct;
