import { getFirestore, doc, writeBatch } from 'firebase/firestore';

const setOrderDelivery = (orderId: string, delivery: any) => {
  const db = getFirestore();
  const batch = writeBatch(db);
  const orderRef = doc(db, 'orders', orderId);
  batch.update(orderRef, { delivery });
  batch.commit();
};

export default setOrderDelivery;
