import { t } from 'i18next';

const getColumns = () => [
  { key: 'name', label: t('form.name'), isSortActive: true },
  { key: 'email', label: t('form.email') },
  {
    key: 'active',
    label: t('form.state'),
    render: (row: any) => (
      <div className="whitespace-nowrap px-6 py-4 text-sm">
        {row.active ? t('active') : t('inactive')}
      </div>
    ),
  },
];

export default getColumns;
