import { useTranslation } from 'react-i18next';
import { Table, useSidePanel } from '@posy/components';
import { XIcon } from '@heroicons/react/outline';

import useOnSnapshot from '../../components/useOnSnapshot';
import Button from 'components/Button';
import { Category } from '../../types';

const Categories = () => {
  const { t } = useTranslation();
  const { navigateTo } = useSidePanel();
  const { data, isLoading } = useOnSnapshot('categories');
  const formattedCategories: Category[] = data.map(
    ({ id, name }: Category) => ({ id, name }),
  );

  const columns = [{ key: 'name', label: t('form.name'), isSortActive: true }];
  const searchFields = [{ label: t('form.name'), value: 'name' }];

  return (
    <div className="h-full flex flex-col">
      <div className="px-4 pt-6 flex mb-4">
        <div className="flex flex-1 items-center">
          <button
            type="button"
            className="inline-flex text-2xl font-semibold text-gray-900 mr-3"
            onClick={() => navigateTo('')}
          >
            <XIcon className="h-8 w-8 text-gray-500" aria-hidden="true" />
          </button>
          <span className="inline-flex text-2xl font-semibold text-gray-900">
            {t('category')}
          </span>
        </div>
        <Button
          label={t('createCategory')}
          onClick={() => navigateTo(`Category`)}
        />
      </div>
      <Table
        columns={columns}
        rows={formattedCategories}
        onRowClick={(id: string) => navigateTo('Category', { id })}
        searchFields={searchFields}
        isLoading={isLoading}
        sort={['name', 'ASC']}
        style={{
          height: '100%',
          background: 'white',
          margin: 16,
        }}
      />
    </div>
  );
};

export default Categories;
