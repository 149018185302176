import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { doc, getDoc, updateDoc, getFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import styled from 'styled-components';
import FormCard from '../components/FormCard';
import { useTranslation } from 'react-i18next';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { formatUrlName, getCatalogHostname } from '@posy/helpers';
import {
  useForm,
  Field,
  useLocation,
  LoadingDim,
  ThumbnailInput,
} from '@posy/components';
import useLocations from 'hooks/useLocations';
import { useDefaultLocationProvider } from 'providers/DefaultLocationProvider';
import useFeatureFlags from 'hooks/useFeatureFlags';
import FlowPayment from './components/FlowPayment';
import TransferPayment from './components/TransferPayment';
import { countries } from './../constants';
import { useAuth } from 'Auth/AuthProvider';
import FieldLabel from 'components/FieldLabel';

const OnlineCatalog = () => {
  const navigate = useNavigate();
  const { enableCatalogCustomLocation } = useFeatureFlags();
  const { t } = useTranslation();
  const [store, setStore] = useState<any>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const { id } = useParams();
  const { locations } = useLocations();
  const { defaultLocationId } = useDefaultLocationProvider();
  const { user } = useAuth();
  const isBaseCountry = user?.isBaseCountry;

  useEffect(() => {
    loadProduct();
  }, []);

  const loadProduct = async () => {
    const docRef = doc(getFirestore(), 'stores', `${id}`);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setStore({
        id: docSnap.id,
        ...docSnap.data(),
      });
    } else {
      // doc.data() will be undefined in this case
      setStore({
        variations: [{}],
      });
      console.error('No such document!');
    }
  };
  const [defaultValues, setDefaultValues] = useState({});

  useEffect(() => {
    setDefaultValues(getInitialValues(store, defaultLocationId));
  }, [defaultLocationId, store]);

  const { registerField, handleSubmit, getField, setField } = useForm({
    defaultValues,
  });
  const { regiones, comunas } = useLocation(getField('state'));
  const paymentMethods = getField('paymentMethods');
  const country = getField('country');

  const validateMaxCharacters = (limit: number) => {
    return (value: string) => {
      if (value?.length > limit) {
        return {
          type: 'custom',
          message: `Descripción debe ser menor a 150 caracteres`,
        };
      }
    };
  };

  const fields = [
    {
      label: t('form.name'),
      name: 'name',
      type: 'text',
      rules: ['required', 'textOnly'],
    },
    {
      label: t('form.shortDescription'),
      name: 'shortDescription',
      type: 'paragraph',
      rules: [],
      lines: 4,
      validate: validateMaxCharacters(150),
    },
  ];

  const locationFields = [
    {
      label: t('shippingAddress.address'),
      name: 'address',
      type: 'text',
    },
    {
      label: t('shippingAddress.state'),
      name: 'state',
      type: 'select',
      rules: ['required'],
      options: regiones,
      hidden: country !== 'CL',
    },
    {
      label: t('shippingAddress.city'),
      name: 'city',
      type: country === 'CL' ? 'select' : 'text',
      rules: ['required'],
      disabled: false,
      options: comunas,
    },
    {
      label: t('shippingAddress.country'),
      name: 'country',
      type: 'select',
      rules: ['required'],
      disabled: false,
      options: countries,
    },
  ];

  const socialMediaFields = [
    {
      label: 'Instagram',
      name: 'instagram',
      type: 'text',
    },
    {
      label: 'Whatsapp',
      name: 'whatsapp',
      type: 'text',
    },
    {
      label: 'Facebook',
      name: 'facebook',
      type: 'text',
    },
  ];

  const catalogFields = [
    {
      label: t('catalogFields.publishOnlineCatalog'),
      name: 'isPublished',
      type: 'boolean',
      default: true,
    },
    {
      label: t('catalogFields.catalogUrl'),
      name: 'uniqueName',
      type: 'text',
      rules: ['required'],
      onFormatValue: ({ value }: { value: string }) => formatUrlName(value),
      inputOptions: {
        addOn: {
          label: getCatalogHostname(),
        },
      },
    },
    {
      label: t('catalogFields.acceptOnlineOrders'),
      name: 'catalog.allowOnlineOrders',
      type: 'boolean',
    },
    {
      label: t('catalogFields.allowShipments'),
      name: 'catalog.allowShipping',
      type: 'boolean',
    },
    {
      label: t('catalogFields.showProductsWithoutStock'),
      name: 'catalog.showOutOfStockItems',
      type: 'boolean',
      disabled: false,
    },
    {
      label: t('catalogFields.customMessage'),
      name: 'catalog.customMessage',
      type: 'paragraph',
    },
    {
      label: t('catalogFields.deliveryPoint'),
      name: 'catalog.pickupInfo',
      type: 'paragraph',
    },
    {
      label: t('stockLocation'),
      name: 'catalogLocationId',
      type: 'select',
      options: locations,
      hidden: !enableCatalogCustomLocation,
    },
  ];

  const requestedFields = [
    {
      label: t('idNumber'),
      name: 'fields.docId',
      type: 'boolean',
      default: false,
    },
    {
      label: t('social.instagram'),
      name: 'fields.social.instagram',
      type: 'boolean',
      default: false,
    },
    {
      label: t('social.facebook'),
      name: 'fields.social.facebook',
      type: 'boolean',
      default: false,
    },
    {
      label: t('social.tiktok'),
      name: 'fields.social.tiktok',
      type: 'boolean',
      default: false,
    },
  ];

  if (!store) {
    return null;
  }

  const submit = (values: any) => {
    const { uniqueName } = values;
    const functions = getFunctions();
    const setStoreName = httpsCallable(functions, 'assignStoreName');
    if (store.uniqueName !== uniqueName) {
      setLoading(true);
      setStoreName({ storeId: id, storeName: uniqueName })
        .then(() => {
          updateDoc(doc(getFirestore(), 'stores', `${id}`), values);
          navigate(-1);
        })
        .catch((e) => {
          alert('El nombre unico del catálogo ya se encuentra ocupado');
          console.error({ e });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      updateDoc(doc(getFirestore(), 'stores', `${id}`), values);
      navigate(-1);
    }
  };

  return (
    <div className="px-4 overflow-auto">
      <LoadingDim isLoading={isLoading} />

      <div className="px-4 pt-6 flex mb-4">
        <div className="flex-1">
          <button
            type="button"
            className="inline-flex text-2xl font-semibold text-gray-900"
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon className="h-8 w-8" aria-hidden="true" />
            {t('catalogFields.setupOnlineCatalog')}
          </button>
        </div>

        <button
          className="bg-indigo-700 text-white font-medium rounded-md px-4 py-2"
          onClick={handleSubmit(submit)}
        >
          {t('save')}
        </button>
      </div>

      <Wrapper>
        <div className="flex flex-col lg:flex-row gap-4">
          <div className="flex-col flex-1">
            <FormCard
              title={t('catalogFields.businessData')}
              subtitle={t('catalogFields.descriptionInformation')}
            >
              <ThumbnailInput
                {...registerField({
                  name: 'logo',
                  label: t('catalogFields.catalogLogo'),
                })}
                imageRef={`stores/${store.id}/logo.jpg`}
              />
              {fields.map((field) => (
                <Field key={field.name} {...registerField(field)} />
              ))}
            </FormCard>
            <div className="mt-4">
              <FormCard title={t('catalogFields.location')}>
                {locationFields.map((field) => (
                  <Field key={field.name} {...registerField(field)} />
                ))}
              </FormCard>
            </div>
            <div className="mt-4">
              <FormCard title={t('socialMedia')}>
                {socialMediaFields.map((field) => (
                  <Field key={field.name} {...registerField(field)} />
                ))}
              </FormCard>
            </div>
          </div>
          <div className="flex-col flex-1">
            <FormCard
              title={t('setting')}
              subtitle={t('catalogFields.descriptionInformation')}
            >
              {catalogFields.map((field) => (
                <Field key={field.name} {...registerField(field)} />
              ))}
            </FormCard>
            <div className="mt-4">
              <FormCard
                title={t('catalogFields.paymentMethods')}
                subtitle={t('catalogFields.descriptionInformation')}
              >
                <Field
                  {...registerField({
                    label: t('paymentMethods.cash'),
                    name: 'paymentMethods.cash.active',
                    type: 'boolean',
                    default: true,
                  })}
                />
                <TransferPayment
                  values={paymentMethods?.transfer}
                  registerField={registerField}
                  setField={setField}
                />
                <FlowPayment
                  visible={isBaseCountry}
                  values={paymentMethods?.flow}
                  isConfigured={paymentMethods?.flow?.paymentGatewayId}
                  registerField={registerField}
                  setField={setField}
                />
              </FormCard>
            </div>
            <div className="mt-4 mb-4">
              <FormCard
                title={t('customerData')}
                subtitle={t('catalogFields.descriptionCustomerData')}
              >
                <FieldLabel label={t('form.name')} />
                <FieldLabel label={t('form.email')} />
                <FieldLabel label={t('form.phone')} />
                {requestedFields.map((field) => (
                  <Field key={field.name} {...registerField(field)} />
                ))}
              </FormCard>
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

const getInitialValues = (store: any, defaultLocationId: string) => {
  if (store?.catalogLocationId) {
    return store;
  } else {
    return {
      ...store,
      catalogLocationId: defaultLocationId,
    };
  }
};

const Wrapper = styled.div`
  grid-gap: 12px;
`;

export default OnlineCatalog;
