import { useTranslation } from 'react-i18next';
import { Table, useSidePanel } from '@posy/components';
import { ChevronLeftIcon, TrashIcon } from '@heroicons/react/outline';
import { Customer } from 'types';
import { useProviderCustomers } from 'providers/CustomersProvider';
import Button from 'components/Button';

const SelectCustomer = () => {
  const { t } = useTranslation();
  const { navigateTo, params } = useSidePanel();
  const { isSelected, onSelect, onRemove } = params;
  useProviderCustomers;
  const { customers, isLoading } = useProviderCustomers();
  const formattedCustomers: Customer[] = customers.map(
    ({ id, name, email, phone, shipping }: Customer) => ({
      id,
      name,
      email,
      phone,
      shipping,
    }),
  );

  const columns = [{ key: 'name', label: t('form.name') }];
  const searchFields = [{ label: t('form.name'), value: 'name' }];

  const selectCustomer = (id: string) => {
    const customer = formattedCustomers.find((customer) => customer.id === id);
    onSelect(customer);
    navigateTo('');
  };

  return (
    <div className="flex flex-col h-full">
      <div className="px-4 pt-6 flex mb-4">
        <div className="flex-1">
          <button
            type="button"
            className="inline-flex text-2xl font-semibold text-gray-900"
            onClick={() => navigateTo('')}
          >
            <ChevronLeftIcon className="h-8 w-8" aria-hidden="true" />
            {t('customer')}
          </button>
        </div>
        <Button
          isVisible
          label={t('createCustomer')}
          onClick={() => navigateTo('Customer')}
        />
      </div>
      <Table
        columns={columns}
        rows={formattedCustomers}
        onRowClick={(id: string) => selectCustomer(id)}
        searchFields={searchFields}
        isLoading={isLoading}
        style={{
          overflow: 'auto',
          height: '100%',
          background: 'white',
          margin: 16,
        }}
      />
      {isSelected && (
        <div className="flex justify-center">
          <button
            onClick={() => {
              onRemove();
              navigateTo('');
            }}
            className="flex items-center text-red-500"
          >
            {t('removeSelection')} <TrashIcon className="w-4 h-4 ml-2" />
          </button>
        </div>
      )}
    </div>
  );
};

export default SelectCustomer;
