import { useParams } from 'react-router-dom';
import { useLocation, Field, useModal, useForm } from '@posy/components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FormCard from '../components/FormCard';
import { useAuth } from '../Auth/AuthProvider';
import { ChevronLeftIcon, TrashIcon } from '@heroicons/react/outline';
import {
  getMainFields,
  getAddressFields,
  getMoreFields,
  getSocialMediaFields,
} from './getFields';
import useCustomer from './hooks/useCustomer';
import SocialIcon from './SocialIcons';

interface Props {
  isInSidePanel?: boolean;
}

const Customer = ({ isInSidePanel }: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { user, hasPermission } = useAuth();
  const { openModal } = useModal();
  const { customer, submit, requestDeleteCustomer, goBack } =
    useCustomer(isInSidePanel);
  const { registerField, handleSubmit, getField } = useForm({
    defaultValues: customer,
  });
  const { regiones, comunas } = useLocation(getField('shipping.state'));
  const shouldShowSaveButton = hasPermission('CUSTOMER_UPDATE');
  const shouldShowDeleteButton = id && user?.isAdmin;
  const hasNoEditPermission = !hasPermission('CUSTOMER_UPDATE');
  const country = getField('shipping.country');
  const igUser = getField('social.instagram');
  const fbUser = getField('social.facebook');
  const wspUser = getField('phone');
  const tkUser = getField('social.tiktok');
  const maps = getField('shipping.address');
  const email = getField('email');

  if (!customer && id) {
    return null;
  }

  return (
    <div className="px-4 overflow-auto">
      <div className="flex justify-between items-center p-4">
        <div className="flex ">
          <button
            type="button"
            className="inline-flex text-2xl font-semibold text-gray-900"
            onClick={goBack}
          >
            <ChevronLeftIcon className="h-8 w-8" aria-hidden="true" />
            {id ? getField('name') : t('newCustomer')}
          </button>
          <div className="flex ml-2 pt-2">
            {igUser && <SocialIcon name="instagram" value={igUser} />}
            {fbUser && <SocialIcon name="facebook" value={fbUser} />}
            {wspUser && <SocialIcon name="whatsapp" value={wspUser} />}
            {tkUser && <SocialIcon name="tiktok" value={tkUser} />}
            {maps && <SocialIcon name="maps" value={maps} />}
            {email && <SocialIcon name="email" value={email} />}
          </div>
        </div>
        <div className="flex justify-end">
          {shouldShowSaveButton && (
            <button
              className="bg-indigo-700 text-white font-medium rounded-md px-4 py-2"
              onClick={handleSubmit(submit)}
            >
              {t('save')}
            </button>
          )}
        </div>
      </div>
      <Wrapper>
        <div
          className={`${
            isInSidePanel ? 'flex-col' : 'flex flex-row'
          }  lg:flex-row gap-4`}
        >
          <div className="flex-col flex-1">
            <FormCard title={t('basicData')}>
              {getMainFields(hasNoEditPermission).map((field) => (
                <Field key={field.name} {...registerField(field)} />
              ))}
            </FormCard>
            <div className="mt-4">
              <FormCard title={t('shippingAddress.shippingInformation')}>
                {getAddressFields({
                  hasNoEditPermission,
                  regiones,
                  comunas,
                  country,
                }).map((field) => (
                  <Field key={field.name} {...registerField(field)} />
                ))}
              </FormCard>
            </div>
          </div>
          <div className="flex-col flex-1">
            <FormCard title={t('moreOptions')}>
              {getMoreFields(hasNoEditPermission).map((field) => (
                <Field key={field.name} {...registerField(field)} />
              ))}
            </FormCard>
            <div className="mt-4">
              <FormCard title={t('socialMedia')}>
                {getSocialMediaFields(hasNoEditPermission).map((field) => (
                  <Field key={field.name} {...registerField(field)} />
                ))}
              </FormCard>
            </div>
          </div>
        </div>
      </Wrapper>
      <div className="flex justify-end my-4">
        {shouldShowDeleteButton && (
          <button
            className="flex items-center text-red-700"
            onClick={() =>
              openModal({
                title: t('modals.delete.title'),
                body: t('modals.delete.body'),
                onConfirm: requestDeleteCustomer,
              })
            }
          >
            <TrashIcon className="w-4 h-4 mr-2" />
            {t('deleteCustomer')}
          </button>
        )}
      </div>
    </div>
  );
};

const Wrapper = styled.div`
  grid-gap: 12px;
`;

export default Customer;
