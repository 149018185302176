const FieldLabel = ({
  label,
  className,
}: {
  label: string;
  className?: string;
}) => {
  return (
    <p className={`block text-sm font-medium text-gray-700 ${className}`}>
      {label}
    </p>
  );
};

export default FieldLabel;
