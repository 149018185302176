function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

const Tabs = ({
  items,
  value,
  onTabChange,
}: {
  items?: any[];
  value: string;
  onTabChange: (tabName: string) => void;
}) => {
  return (
    <div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {items?.map((tab: any) => (
              <a
                key={tab.name}
                onClick={() => onTabChange(tab.id)}
                className={classNames(
                  value === tab.id
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                  'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer',
                )}
                aria-current={value === tab.id ? 'page' : undefined}
              >
                {tab.name}
                {tab.count ? (
                  <span
                    className={classNames(
                      value === tab.id
                        ? 'bg-indigo-100 text-indigo-600'
                        : 'bg-gray-100 text-gray-900',
                      'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block',
                    )}
                  >
                    {tab.count}
                  </span>
                ) : null}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
