import { PlusIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const AddItemCard = () => {
  const navigate = useNavigate();

  return (
    <Container
      className="bg-indigo-300 rounded-md flex justify-center items-center cursor-pointer"
      onClick={() => navigate('/products/new')}
    >
      <PlusIcon className="h-12 w-12 text-white" />
    </Container>
  );
};

const Container = styled.div`
  width: 176px;
  height: 176px;
`;

export default AddItemCard;
