import { TrashIcon } from '@heroicons/react/outline';

interface Props {
  title: string;
  isVisible: boolean;
  onClick: any;
}

const DeleteProductButton = ({ title, isVisible, onClick }: Props) =>
  isVisible ? (
    <button className="flex items-center text-red-700 m-2" onClick={onClick}>
      <TrashIcon className="w-4 h-4 mr-2" />
      {title}
    </button>
  ) : null;

export default DeleteProductButton;
