import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useSidePanel, LoadingDim } from '@posy/components';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { doc, getDoc, updateDoc, getFirestore } from 'firebase/firestore';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { formatPriceValue } from '@posy/helpers';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { getCycleFinalPrice } from '../Home/helpers';
import { useAuth } from '../Auth/AuthProvider';
import { useAnalytics } from '../providers/AnalyticsProvider';
import PayPalCheckout from './components/PaypalCheckout';
import DocumentSelection from './components/DocumentSelection';

const initialOptions = {
  clientId: String(process.env.REACT_APP_PAYPAL_CLIENT_ID),
  currency: 'USD',
  intent: 'capture',
};
const ORIGIN = 'web';

const SubscriptionResume = () => {
  const { navigateTo, params } = useSidePanel();
  const { logEvent } = useAnalytics();
  const plan = params.plan;
  const planId = plan.id;
  const cycleId = params.cycleId;
  const cycle = plan?.cycles[cycleId] || {};
  const [billingData, setBillingData] = useState<any>();
  const { user } = useAuth();
  const isBaseCountry = user.isBaseCountry;
  const storeDoc = doc(getFirestore(), 'stores', `${user?.storeId}`);
  const [isLoading, setIsLoading] = useState<any>(false);
  const [document, setDocument] = useState<any>();
  const isSubscribeEnabled = shouldEnableSubscribe(
    billingData?.companyTaxId,
    document?.id,
  );
  const intervalLabel = getIntervalLabel(cycle.intervalNumber);
  const finalPrice = getCycleFinalPrice(
    cycle.price,
    user.isEligibleForDiscount,
    cycle.discount,
  );
  const tax = isBaseCountry ? finalPrice * 0.19 : 0;
  const total = finalPrice + tax;

  useEffect(() => {
    getDoc(storeDoc).then((docSnap) => {
      if (docSnap.exists()) {
        setBillingData(docSnap.data().billing || {});
      }
    });
  }, []);

  const setBillingDocument = (document: any) => {
    setDocument(document);
    updateDoc(storeDoc, { 'billing.documentType': document.id });
    logEvent('select_billing_document_type');
  };

  const getSubscriptionPayload = (includeTax = true) => {
    const { storeId, name: subscriberName, store } = user;
    const payload: any = {
      planId,
      cycleId,
      subscriptionId: storeId,
      subscriberName,
      subscriberEmail: store?.email,
      origin: ORIGIN,
      includeTax,
      documentType: document?.id || 'receipt',
    };

    return payload;
  };

  const subscribe = () => {
    setIsLoading(true);
    const payload = getSubscriptionPayload();
    const functions = getFunctions();
    const requestPayment = httpsCallable(functions, 'flowV2-requestPayment');
    requestPayment(payload)
      .then((result) => {
        /** @type {any} */
        const data: any = result.data;
        window.location.href = data;
        setIsLoading(false);
      })
      .catch((e) => {
        alert(e);
        setIsLoading(false);
      });
  };

  const requestSubscription = () => {
    setIsLoading(true);
    const payload = getSubscriptionPayload(false);
    const functions = getFunctions();
    const requestPayment = httpsCallable(functions, 'paypal-requestPayment');
    return requestPayment(payload)
      .then((result) => {
        /** @type {any} */
        const data: any = result.data;
        setIsLoading(false);
        return data.id;
      })
      .catch(() => {
        setIsLoading(false);
        return null;
      });
  };

  const confirmSubscription = (orderId: string) => {
    setIsLoading(true);
    const functions = getFunctions();
    const confirmPayment = httpsCallable(functions, 'paypal-confirmPayment');
    return confirmPayment({ orderId })
      .then((result) => {
        /** @type {any} */
        const data: any = result.data;
        setIsLoading(false);
        navigateTo('');
        window.location.href = '/';
        return data;
      })
      .catch(() => {
        setIsLoading(false);
        return null;
      });
  };

  return (
    <div className="px-4 flex flex-col h-screen flex-1 overflow-scroll">
      <div className="flex justify-end p-4">
        <div className="flex-1">
          <button
            type="button"
            className="inline-flex text-2xl font-semibold text-gray-900"
            onClick={() => navigateTo('')}
          >
            <ChevronLeftIcon className="h-8 w-8" aria-hidden="true" />
            <h3>
              {cycle.intervalNumber} {t(intervalLabel)}
            </h3>
          </button>
        </div>
      </div>
      <div className="flex-1 mt-8 mx-12">
        <div>
          {billingData && (
            <DocumentSelection
              initialValue={billingData.documentType || 'receipt'}
              value={document}
              onSelectionChange={setBillingDocument}
              hideInvoice={!isBaseCountry}
            />
          )}

          {document?.id === 'invoice' &&
            (billingData?.companyTaxId ? (
              <>
                <p className="font-semibold my-4">Datos de facturación:</p>
                <div className="mt-4">
                  <p className="text-sm">{billingData.companyName}</p>
                  <p className="text-sm">{billingData.companyTaxId}</p>
                  <p className="text-sm">{billingData.companyActivity}</p>
                  <p className="text-sm">{billingData.companyAddress}</p>
                  <p className="text-sm">{billingData.companyCity}</p>
                  <p className="text-sm">{billingData.companyState}</p>
                  <button
                    className="text-indigo-700"
                    onClick={() => {
                      navigateTo('BillingFormScreen', { plan, cycleId });
                    }}
                  >
                    {t('edit')}
                  </button>
                </div>
              </>
            ) : (
              <div className="mt-8">
                <p className="font-semibold my-4">{t('pricing.billingData')}</p>

                <button
                  className="text-indigo-700 border border-indigo-700 px-4 py-2 rounded-md"
                  onClick={() => {
                    navigateTo('BillingFormScreen', { plan, cycleId });
                  }}
                >
                  {t('add')}
                </button>
              </div>
            ))}
        </div>
      </div>

      <div className="my-2">
        <div className="my-8">
          <DataLine
            label={`${cycle.intervalNumber} ${t(intervalLabel)}`}
            value={formatPriceValue(finalPrice)}
            visible={isBaseCountry}
          />
          <DataLine
            label="19% IVA"
            value={formatPriceValue(tax)}
            visible={isBaseCountry}
          />
          <DataLine label="Total" value={formatPriceValue(total)} />
        </div>

        {isBaseCountry ? (
          <button
            className={` rounded-md w-full px-4 py-3 ${
              isSubscribeEnabled
                ? 'bg-indigo-700 text-white'
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
            onClick={() => isSubscribeEnabled && subscribe()}
          >
            {t('pricing.pay')}
          </button>
        ) : (
          <PayPalScriptProvider options={initialOptions}>
            <PayPalCheckout
              requestSubscription={requestSubscription}
              confirmSubscription={confirmSubscription}
            />
          </PayPalScriptProvider>
        )}
        <LoadingDim isLoading={isLoading} />
      </div>
    </div>
  );
};

const DataLine = ({
  label,
  value,
  visible = true,
}: {
  label: string;
  value: string;
  visible?: boolean;
}) => {
  return visible ? (
    <div className="flex mx-4 my-2">
      <div className="flex-1">{label}</div>
      {value}
    </div>
  ) : null;
};

const getIntervalLabel = (intervalNumber: number) => {
  if (intervalNumber > 1) {
    return 'subscriptionStatus.months';
  } else {
    return 'subscriptionStatus.month';
  }
};
const shouldEnableSubscribe = (companyTaxId: string, documentType: string) => {
  return documentType === 'invoice' ? companyTaxId : true;
};

export default SubscriptionResume;
