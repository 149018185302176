import { useEffect, useState } from 'react';
import { useAuth } from '../Auth/AuthProvider';
import { useTranslation } from 'react-i18next';
import StatusBadge from './components/StatusBadge';
import { Table } from '@posy/components';
import moment from 'moment-timezone';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useNavigate } from 'react-router-dom';
import useLocations from 'hooks/useLocations';
import { useDefaultLocationProvider } from 'providers/DefaultLocationProvider';
import PageHeader from '../components/PageHeader';
import { getPaymentLabels } from './helpers';

export interface Order {
  id: string;
  name: string;
  total: number;
  status: string;
  closedDate: string;
}

const ClosedOrders = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [orders, setOrders] = useState<Order[]>([]);
  const formattedOrders: Order[] = orders.map((order: any) => ({
    ...order,
    paymentLabels: getPaymentLabels(order.payments),
  }));
  const { user, hasPermission } = useAuth();
  const navigate = useNavigate();
  const { defaultLocationId } = useDefaultLocationProvider();
  const { locations, getLocationNameById } = useLocations();
  const [locationId, setLocation] = useState<string | null>(null);
  const hasMultipleLocations = locations.length > 1;

  const columns = [
    { key: 'name', label: t('table.order') },
    { key: 'customerName', label: t('table.customer') },
    {
      key: 'delivery.type',
      label: t('table.deliveryMethod'),
      render: ({ delivery }: any) => (
        <div className="whitespace-nowrap px-3 py-4 text-sm">
          {delivery?.type && t(`deliveryTypes.${delivery.type}`)}
        </div>
      ),
    },
    {
      key: 'paymentLabels',
      label: 'Método de pago',
      isSortActive: true,
    },
    {
      key: 'locationId',
      label: 'Ubicación',
      hidden: !hasMultipleLocations,
      render: ({ locationId }: any) => (
        <div className="whitespace-nowrap px-3 py-4 text-sm pl-6">
          <p className="text-sm">{getLocationNameById(locationId)}</p>
        </div>
      ),
    },
    { key: 'total', label: t('table.total'), type: 'money' },
    {
      key: 'status',
      label: t('table.state'),
      render: (row: any) => (
        <div className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <StatusBadge status={row.status} />
        </div>
      ),
    },
    { key: 'creatorName', label: t('table.generatedBy') },
    { key: 'closedDate', label: t('table.date') },
  ];

  useEffect(() => {
    const getOrders = async () => {
      setIsLoading(true);
      const { storeId } = user;
      const payload: any = {
        storeId,
        filters: {
          orderBy: 'closedAt',
          endDate: moment().unix() * 1000,
          startDate: moment().subtract(365, 'day').unix() * 1000,
          ...(hasPermission('VIEW_ALL_ORDERS') ? {} : { userId: user?.id }),
          includeCanceledOrders: true,
          locationId,
          isDefaultLocation: defaultLocationId === locationId,
        },
        limit: 500,
      };
      const functions = getFunctions();
      const getClosedOrders = httpsCallable(functions, 'order-getClosedOrders');
      getClosedOrders(payload).then((result) => {
        /** @type {any} */
        const data: any = result.data;
        const orders: Order[] = data.map((order: any) => ({
          id: order._id,
          name: order.name,
          customerName: order.customer?.name,
          delivery: order.delivery,
          creatorName: order.shopOrderId ? 'Catálogo' : order.user.name,
          total: order.total,
          status: order.status,
          payments: order.payments,
          locationId: order.locationId,
          closedDate: moment(order.closedAt).format('DD/MM/YY HH:mm'),
        }));
        setOrders(orders);
        setIsLoading(false);
      });
    };

    getOrders();
  }, [user?.id, locationId]);

  return (
    <>
      <PageHeader title={t('sidebar.closedOrders')} />
      <Table
        columns={columns}
        rows={formattedOrders}
        isLoading={isLoading}
        filters={[
          {
            label: 'Todas las ubicaciones',
            name: 'locationId',
            options: locations,
            onChange: (value: string) => {
              setLocation(value);
            },
            default: locationId,
            disable: true,
          },
          {
            label: t('table.state'),
            name: 'status',
            options: [
              { id: 'completed', name: t('statusLabels.completed') },
              { id: 'canceled', name: t('statusLabels.canceled') },
            ],
            default: 'completed',
          },
        ]}
        onRowClick={(rowId: string) => navigate(`/closed-orders/${rowId}`)}
        style={{
          height: '100%',
          background: 'white',
          margin: 16,
        }}
      />
    </>
  );
};

export default ClosedOrders;
