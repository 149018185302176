import { useState, useEffect } from 'react';

const useLocalStorage = (key, initialValue) => {
  const [value, setValue] = useState(() => getStorageValue(key, initialValue));

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);

  const setPersistedValue = (field, value) => {
    setValue((state) => ({ ...state, [field]: value }));
  };

  const clearPersistence = () => setValue({});

  return { persistedState: value, setPersistedValue, clearPersistence };
};

function getStorageValue(key, initialValue) {
  if (typeof window !== 'undefined') {
    const data = window.localStorage.getItem(key);
    const value = JSON.parse(data);

    return value || initialValue;
  }
  return initialValue;
}

export default useLocalStorage;
