import moment from 'moment';
import { CountryKeys, currencies } from './constants';

const getUserPayload = (formData: any, user: any, storeId: string) => {
  return {
    name: formData.name,
    email: formData.email,
    thumbnail: user.photoURL,
    country: 'Chile',
    createdAt: new Date().getTime(),
    stores: {
      [storeId]: {
        name: `${formData.name} Store`,
        userNumber: 1,
      },
    },
  };
};

const getStorePayload = (formData: any, user: any, country: CountryKeys) => {
  const currency = currencies[country] || 'USD';

  return {
    uid: user.uid,
    email: user.email,
    name: `${formData.name} Store`,
    country,
    settings: {
      currency,
    },
    createdAt: new Date().getTime(),
    trialEndDate: moment().add(7, 'days').toISOString(),
    users: {
      [user.uid]: {
        active: true,
      },
    },
    usersCount: 1,
  };
};

export { getUserPayload, getStorePayload };
