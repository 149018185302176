import {
  getDatabase,
  ref,
  equalTo,
  query as dquery,
  orderByChild,
  get,
} from 'firebase/database';
import moment from 'moment-timezone';

const getSubscriberByStoreId = async (storeId: string) => {
  const db = getDatabase();
  try {
    const storeRef = dquery(
      ref(db, 'subscriptions'),
      orderByChild('storeId'),
      equalTo(storeId),
    );
    const snapshot = await get(storeRef);
    if (snapshot.exists()) {
      const val: any[] = snapshot.val();
      const subscriptions = Object.entries(val).map(([key, value]) => ({
        id: key,
        nextChargeDate: value.nextChargeDate,
        cycleId: value.cycleId,
        provider: value.provider,
        autoRenew: value.autoRenew,
        eligibleForDiscount: value.eligibleForDiscount,
        createdAt: value.createdAt,
      }));
      const filteredSubscriptions = subscriptions.filter(
        ({ nextChargeDate }) => nextChargeDate,
      );
      const subscriptionsOrdered = filteredSubscriptions.sort(
        (a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix(),
      );

      return subscriptionsOrdered;
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
};

export default getSubscriberByStoreId;
