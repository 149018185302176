import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';

interface Props {
  requestSubscription: () => Promise<string>;
  confirmSubscription: (orderId: string) => Promise<void>;
}

const PayPalCheckout = ({
  requestSubscription,
  confirmSubscription,
}: Props) => {
  const [{ isPending }] = usePayPalScriptReducer();

  if (isPending) {
    return null;
  }

  return (
    <>
      <PayPalButtons
        createOrder={requestSubscription}
        onApprove={({ orderID }) => confirmSubscription(orderID)}
      />
    </>
  );
};

export default PayPalCheckout;
