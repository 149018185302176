import { formatVariationInput } from '../../helpers';
import isEmpty from 'lodash/isEmpty';

const getPayload = ({
  values,
  productId,
  getVariationId,
  user,
  createdAt,
  defaultLocationId,
}: Params) => {
  const variationPayloads: any = Object.entries(values.variations).map(
    ([, variation]: any, index: number) => {
      return formatVariationInput(
        {
          id: getVariationId(),
          productId: productId,
          createdAt,
          order: index + 1,
          ...variation,
        },
        values.format,
      );
    },
  );

  const { locations, trackedVariationsIds } = formatLocations(
    variationPayloads,
    values.locations,
  );

  const variations = variationPayloads.reduce((obj: any, payload: any) => {
    const defaultLocation = locations[defaultLocationId];
    const inventoryLevel = defaultLocation?.variations[payload.id];

    return {
      ...obj,
      [payload.id]: {
        ...payload,
        ...(inventoryLevel && {
          trackQty: trackedVariationsIds.includes(payload.id),
          quantity: inventoryLevel.available,
        }),
      },
    };
  }, {});

  const payload = {
    ...values,
    ...(!isEmpty(locations) ? { locations } : {}),
    variations,
    createdAt,
    storeId: user.storeId,
  };

  return payload;
};

const formatLocations = (variationArray: any, locations: any = {}) => {
  const trackedVariationsIds: string[] = [];
  const formattedLocations = Object.entries(locations).reduce(
    (obj: any, [id, location]: any) => {
      const variations = Object.entries(location.variations).reduce(
        (obj: any, [, variation]: any, index: number) => {
          const variationId = variationArray[index].id;
          variation?.available && trackedVariationsIds.push(variationId);

          return {
            ...obj,
            [variationId]: {
              ...variation,
              available: Number(variation?.available),
              tracked: true,
            },
          };
        },
        {},
      );
      return {
        ...obj,
        [id]: {
          ...location,
          variations,
        },
      };
    },
    {},
  );

  return {
    locations: formattedLocations,
    trackedVariationsIds,
  };
};

type Params = {
  values: any;
  productId: string;
  getVariationId: any;
  user: any;
  createdAt: number;
  defaultLocationId: string;
};

export default getPayload;
