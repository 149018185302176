import { doc, getFirestore, updateDoc } from 'firebase/firestore';

type SettingsInput = {
  allowVariations: boolean;
  hideOutOfStockItems: boolean;
  hideCanceledOrders: boolean;
  productListOrderBy: string;
  catalogLayout: string;
};

const updateSettings = (storeId: string, payload: SettingsInput) => {
  return updateDoc(doc(getFirestore(), 'stores', storeId), {
    settings: payload,
  });
};

export default updateSettings;
