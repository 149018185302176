import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProductForm from './ProductForm';
import { useProviderProducts } from 'providers/ProductsProvider';
import getProductTemplate from './getProductTemplate';
import useLocations from 'hooks/useLocations';
import { useSearchParams } from 'react-router-dom';
import getCategories from 'api/categories/getCategories';
import { useAuth } from 'Auth/AuthProvider';
import createProduct from 'api/products/createProduct/createProduct';
import updateProduct from 'api/products/updateProduct/updateProduct';
import { useAnalytics } from 'providers/AnalyticsProvider';

const Product = () => {
  const { id } = useParams();
  const { products, defaultLocationId } = useProviderProducts();
  const [product, setProduct] = useState<any>();
  const [searchParams] = useSearchParams();
  const barcode = searchParams.get('barcode');
  const foundProduct = products.find((product: any) => product.id === id);
  const { locations } = useLocations();
  const [categories, setCategories] = useState<any>([]);
  const { user } = useAuth();
  const { logEvent } = useAnalytics();
  const navigate = useNavigate();

  useEffect(() => {
    getCategories(user?.storeId).then(setCategories);
  }, [user?.id]);

  useEffect(() => {
    const shouldSet = id ? foundProduct : true;
    if (locations.length > 0 && shouldSet) {
      setProduct(getProductTemplate(foundProduct, barcode));
    }
  }, [Boolean(foundProduct), locations]);

  const submit = async (values: any) => {
    if (id) {
      updateProduct(id, values, product, user, defaultLocationId);
      logEvent('update_product');
    } else {
      createProduct(values, user, defaultLocationId);
      logEvent('create_product');
    }
    navigate(-1);
  };

  return (
    <ProductForm
      id={id}
      product={product}
      locations={locations}
      categories={categories}
      onSubmit={submit}
    />
  );
};

export default Product;
