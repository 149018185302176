import { useTranslation } from 'react-i18next';

interface Props {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  goToResetPassword: () => void;
}

const Login = ({ handleSubmit, goToResetPassword }: Props) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="space-y-1">
        <label
          htmlFor="password"
          className="block text-sm font-medium text-gray-700"
        >
          {t('form.password')}
        </label>
        <div className="mt-1">
          <input
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="text-sm text-right">
          <a
            onClick={goToResetPassword}
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            {t('forgotPassword')}
          </a>
        </div>
      </div>

      <div>
        <button
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {t('signIn')}
        </button>
      </div>
    </form>
  );
};

export default Login;
