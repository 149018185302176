import { useParams } from 'react-router-dom';
import { Field, useForm, useSidePanel } from '@posy/components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import FormCard from 'components/FormCard';
import Button from 'components/Button';
import { useState } from 'react';

const PaymentGateway = () => {
  const { navigateTo, params } = useSidePanel();
  const { t } = useTranslation();
  const { id } = useParams();
  const [defaultValues] = useState<any>({
    name: 'Transferencia',
    provider: 'manual',
    ...(params.values || {}),
  });

  const { registerField, handleSubmit, getField } = useForm({ defaultValues });

  const fields = [
    {
      label: t('form.name'),
      name: 'name',
      type: 'text',
      rules: ['required'],
      readOnly: true,
    },
    {
      label: 'Proveedor',
      name: 'provider',
      type: 'text',
      rules: ['required'],
      readOnly: true,
    },
  ];

  const addressFields = [
    {
      label: 'Datos de transferencia',
      name: 'accountDetails',
      type: 'paragraph',
      rules: ['required'],
    },
    {
      label: 'Instrucciones',
      name: 'instructions',
      type: 'paragraph',
      rules: ['required'],
    },
  ];

  const onClose = () => {
    navigateTo('');
  };

  const submit = (values: Location) => {
    params.onSubmit(values);
    onClose();
  };

  return (
    <div className="px-4 overflow-auto">
      <div className="flex justify-end p-4">
        <div className="flex-1">
          <button
            type="button"
            className="inline-flex text-2xl font-semibold text-gray-900"
            onClick={onClose}
          >
            <ChevronLeftIcon className="h-8 w-8" aria-hidden="true" />
            {id ? getField('name') : 'Medio de pago'}
          </button>
        </div>
        <Button label={t('save')} onClick={handleSubmit(submit)} />
      </div>
      <Wrapper>
        <div className="gap-4">
          <FormCard>
            {fields.map((field) => (
              <Field key={field.name} {...registerField(field)} />
            ))}
          </FormCard>
          <FormCard>
            {addressFields.map((field) => (
              <Field key={field.name} {...registerField(field)} />
            ))}
          </FormCard>
        </div>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  grid-gap: 12px;
`;

export default PaymentGateway;
