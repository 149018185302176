import { useTranslation } from 'react-i18next';
import { useSidePanel } from '@posy/components';
import {
  CheckCircleIcon,
  ClockIcon,
  NewspaperIcon,
} from '@heroicons/react/outline';

import Balance from './components/Balance';
import { formatPriceValue } from '../../helpers';

const PaymentCompleted = () => {
  const { t } = useTranslation();
  const { navigateTo, params } = useSidePanel();
  const { toPay, amount, order, method, isPending, payLater } = params;
  const unpaid = toPay - amount;

  const goToPay = () => {
    navigateTo(`Payment`, { order, toPay: unpaid });
  };
  const goToReceipt = () => {
    const formattedOrder = { ...order, payments: [{ amount, method }] };
    navigateTo('Receipt', { order: formattedOrder, isCheckout: true });
  };

  const getIcon = () => {
    const Icon = isPending ? ClockIcon : CheckCircleIcon;

    return <Icon className="w-20 h-20 text-indigo-700" />;
  };

  return (
    <div className="flex flex-col h-full">
      <div className="mt-8 px-4 pt-6 mb-4 flex-1 flex flex-col items-center justify-center">
        <div className="text-center">
          <div className="flex justify-center mb-2">{getIcon()}</div>
          <p className="text-3xl font-medium tracking-normal text-gray-500">
            {isPending ? t('statusLabels.pending') : t('paidOut')}
          </p>
          {isPending ? null : (
            <>
              <div className="mt-2 items-center justify-center text-2xl font-bold tracking-tight text-gray-900">
                <span>{formatPriceValue(amount)}</span>
              </div>
              <Balance amount={unpaid} />
            </>
          )}
        </div>
      </div>

      <div className="mt-8 px-4 pt-6 mb-4">
        <div>
          {unpaid > 0 && !payLater && (
            <button
              className="bg-gray-400 text-white font-medium rounded-md w-full px-4 py-2 mb-2"
              onClick={goToPay}
            >
              {t('payLeftAmount')}
            </button>
          )}
          {order && (
            <button
              className="border-indigo-700 border text-indigo-700 font-medium rounded-md w-full px-4 py-2 my-4 flex justify-center items-center"
              onClick={goToReceipt}
            >
              <NewspaperIcon className="w-10 h-10 mr-4" />
              {t('digitalReceipt')}
            </button>
          )}
          <button
            className="bg-indigo-700 text-white font-medium rounded-md w-full px-4 py-4"
            onClick={() => navigateTo('')}
          >
            {t('finish')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentCompleted;
