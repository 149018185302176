const hasPermissionToVisit = (
  path: string,
  restrictedPaths: string[],
  isAdmin: boolean,
) => {
  if (isAdmin) {
    return true;
  } else {
    const isPathRestricted = restrictedPaths.some((restrictedPath) => {
      if (restrictedPath === '/') {
        return path === restrictedPath;
      } else {
        return path.startsWith(restrictedPath);
      }
    });
    return !isPathRestricted;
  }
};
const getRestrictedItems = (hasPermission: (permission: string) => boolean) => {
  const restrictedPaths = ['/', '/userAccounts', '/pricing'];

  if (!hasPermission('VIEW_ANALYTICS')) {
    restrictedPaths.push('/analytics');
  }
  if (!hasPermission('EDIT_ONLINE_CATALOG')) {
    restrictedPaths.push('/online-catalog');
  }

  return restrictedPaths;
};

export { hasPermissionToVisit, getRestrictedItems };
