import { useNavigate } from 'react-router-dom';
import { LocationMarkerIcon, CogIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'Auth/AuthProvider';

const Item = ({ item }: any) => {
  const Icon = item.Icon;
  const navigate = useNavigate();

  if (item.disabled) return null;

  return (
    <div
      onClick={() => navigate(item.path)}
      className="relative cursor-pointer flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
    >
      <div className="flex-shrink-0">
        {Icon && <Icon className="h-8 w-8" />}
      </div>
      <div className="min-w-0 flex-1">
        <a className="focus:outline-none">
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900">{item.name}</p>
          <p className="text-sm text-gray-500">{item.description}</p>
        </a>
      </div>
    </div>
  );
};

const Settings = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const items = [
    {
      name: t('settings.generalSettings.title'),
      path: '/settings/general',
      description: t('settings.generalSettings.description'),
      Icon: CogIcon,
    },
    {
      name: t('settings.locations.title'),
      path: '/settings/locations',
      description: t('settings.locations.description'),
      Icon: LocationMarkerIcon,
      disabled: !user?.isAdmin,
    },
    {
      name: 'Price lists',
      path: '/settings/priceLists',
      description: 'Maneja diferentes precios y reglas para tus clientes.',
      disabled: true,
    },
  ];

  return (
    <>
      <h1 className="font-bold text-xl mx-8 mt-8">{t('settings.title')}</h1>

      <div className="h-screen bg-white m-8">
        <div className="grid grid-cols-3 gap-4 sm:grid-cols-3 p-8">
          {items.map((item) => (
            <Item key={item.path} item={item} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Settings;
