import { Fragment, useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import DateRangePicker from './DateRangePicker';
import { getRange } from 'Analytics/helpers';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

interface Props {
  startDate?: string;
  endDate?: string;
  onChange: (startDate: string, endDate: string) => void;
}
type Range = {
  startDate: string;
  endDate: string;
};

export default function Modal({
  startDate: initialStartDate,
  endDate: initialEndDate,
  onChange,
}: Props) {
  const [range, setRange] = useState('day');
  const [startDate, setStartDate] = useState<string>(
    initialStartDate || moment().startOf('day').toISOString(),
  );
  const [endDate, setEndDate] = useState<string | null>(
    initialEndDate || moment().endOf('day').toISOString(),
  );
  const { t } = useTranslation();
  const rangeOptions = [
    { id: 'day', name: t('dateRanges.today') },
    { id: 'yesterday', name: t('dateRanges.yesterday') },
    { id: 'week', name: t('dateRanges.week') },
    { id: 'prevWeek', name: t('dateRanges.prevWeek') },
    { id: 'month', name: t('dateRanges.month') },
    { id: 'prevMonth', name: t('dateRanges.prevMonth') },
    { id: 'last7Days', name: t('dateRanges.last7Days') },
    { id: 'last30Days', name: t('dateRanges.last30Days') },
  ];

  useEffect(() => {
    if (startDate && endDate) {
      onChange(startDate, endDate);
    }
  }, [startDate, endDate]);

  const getLabel = () => {
    if (range === 'range') {
      return `${moment(startDate).format('DD/MM/YY')} - ${moment(
        endDate,
      ).format('DD/MM/YY')}`;
    } else {
      return t(`dateRanges.${range}`);
    }
  };

  return (
    <Popover>
      <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
        <span>{getLabel()}</span>
        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          className="absolute left-1/2 z-50 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4"
          style={{ marginLeft: -64 }}
        >
          {({ close }) => (
            <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 lg:max-w-3xl">
              <div className="grid grid-cols-1 gap-x-6 gap-y-1 p-4 lg:grid-cols-2">
                {rangeOptions.map((item) => (
                  <div
                    key={item.name}
                    className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50"
                  >
                    <a
                      className="font-semibold text-gray-900 cursor-pointer"
                      onClick={() => {
                        const { startDate, endDate }: Range = getRange(item.id);
                        setStartDate(startDate);
                        setEndDate(endDate);
                        setRange(item.id);
                        close();
                      }}
                    >
                      {item.name}
                      <span className="absolute inset-0" />
                    </a>
                  </div>
                ))}
              </div>
              <div className="bg-gray-50 px-8 py-6">
                <div className="flex items-center gap-x-3">
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">
                    {t('customPeriod')}
                  </h3>
                </div>

                <DateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(startDate, endDate) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                    setRange('range');
                  }}
                />
              </div>
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
