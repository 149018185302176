import { getFirestore, doc, getDoc } from 'firebase/firestore';

const getStore = async (storeId: string) => {
  const db = getFirestore();
  const storeRef = doc(db, 'stores', storeId);

  return getDoc(storeRef);
};

export default getStore;
