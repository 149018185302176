import { t } from 'i18next';

import { formatPriceValue } from 'helpers';
import { getAmountInDebt } from './helpers';

const getAmountToPay = ({
  total,
  payments,
}: {
  total: number;
  payments: any;
}) => {
  const amount = getAmountInDebt(total, payments);
  const formattedAmount = formatPriceValue(amount);

  return t('toPay', { amount: formattedAmount });
};

export default getAmountToPay;
