import React from 'react';

import Toast from './Toast';

interface ModalContextType {
  isOpen: any;
  openToast: ({
    title,
    description,
    type,
  }: {
    title: string;
    description: string;
    type: string;
  }) => void;
  closeToast: () => void;
}

const ModalContext = React.createContext<ModalContextType>(null!);

const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const [title, setTitle] = React.useState<string>('');
  const [description, setDescription] = React.useState<string>('');
  const [type, setType] = React.useState<string>('');
  const [entity, setEntity] = React.useState<any>('');

  const openToast = ({
    title,
    description,
    type,
  }: {
    title: string;
    description: string;
    type: string;
  }) => {
    setOpen(true);
    setEntity(entity);
    setTitle(title);
    setDescription(description);
    setType(type);
  };
  const closeToast = () => {
    setOpen(false);
  };

  const value = { isOpen, openToast, entity, closeToast };

  return (
    <ModalContext.Provider value={value}>
      <Toast
        show={isOpen}
        title={title}
        description={description}
        onClose={closeToast}
        type={type}
      />
      {children}
    </ModalContext.Provider>
  );
};

function useToast() {
  return React.useContext(ModalContext);
}

export { useToast };
export default ToastProvider;
