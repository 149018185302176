import { utils, read } from 'xlsx';

const UploadProducts = () => {
  const { user } = useAuth();
  const storeId = user?.storeId;

  const handleSubmit = (e: any) => {
    const files = e.target.files;
    let i, f;
    for (i = 0, f = files[i]; i !== files.length; ++i) {
      const reader = new FileReader();
      reader.onload = function (e: any) {
        const data = e.target.result;

        const workbook = read(data, { type: 'binary' });

        const productsFromExcel = utils.sheet_to_json(
          workbook.Sheets['Hoja 1'],
        );
        loadMultiple(storeId, productsFromExcel);
      };
      reader.readAsBinaryString(f);
    }
  };

  return (
    <>
      <input type="file" multiple={true} onChange={handleSubmit} />
    </>
  );
};

import { writeBatch, doc, getFirestore, collection } from 'firebase/firestore';
import { useAuth } from 'Auth/AuthProvider';

const loadMultiple = (storeId: string, productsFromExcel: any) => {
  const batch = writeBatch(getFirestore());
  const db = getFirestore();

  const categoriesIndex = productsFromExcel.reduce((result: any, p: any) => {
    const categoryName = p['Categoría'];
    if (result[categoryName]) {
      return result;
    } else {
      const categoryDoc = doc(collection(db, 'categories'));
      batch.set(categoryDoc, {
        name: categoryName,
        storeId,
        createdAt: new Date().getTime(),
      });
      return {
        ...result,
        [categoryName]: categoryDoc.id,
      };
    }
  }, {});

  const products = productsFromExcel.reduce((result: any, p: any) => {
    const productNameHeader = 'Item (Nombre del producto)';
    const descriptionHeader = 'Descripción del producto';
    const formatHeader = 'Formato (unit/fraction)';
    const categoryNameHeader = 'Categoría';
    const costPriceHeader = 'Precio Costo';
    const priceHeader = 'Precio Normal';
    const salePriceHeader = 'Precio Oferta';
    const availableHeader = 'Stock actual';
    const minAvailableHeader = 'Stock minimo';
    const barcodeHeader = 'Código de barra';
    const skuHeader = 'SKU';

    const name = p[productNameHeader].toString();
    const description = p[descriptionHeader];
    const format = p[formatHeader].toLowerCase();
    const price = p[priceHeader];
    const salePrice = p[salePriceHeader];
    const costPrice = p[costPriceHeader];
    const quantity = p[availableHeader] === '' ? 0 : p[availableHeader];
    const trackQty = p[availableHeader] !== '';
    const minQuantity = p[minAvailableHeader];
    const sku = p[skuHeader];
    const barcode = p[barcodeHeader];
    const matchName = `${name}-${sku}`;

    const product = result[matchName];
    const variationPayload = {
      name: p['Variation Name'] || '',
      price,
      ...(salePrice && { salePrice: Number(salePrice) }),
      ...(costPrice && { costPrice: Number(costPrice) }),
      ...(sku && { sku }),
      ...(barcode && { barcode }),
      trackQty,
      ...(trackQty && {
        quantity: Number(parseFloat(quantity).toFixed(3)),
        minQuantity: Number(parseFloat(minQuantity).toFixed(3)),
      }),
    };
    const variationDoc = doc(collection(db, 'variations'));

    if (product) {
      return {
        ...result,
        [matchName]: {
          ...product,
          variations: {
            ...product.variations,
            [variationDoc.id]: variationPayload,
          },
        },
      };
    } else {
      const categoryName = p[categoryNameHeader];

      return {
        ...result,
        [matchName]: {
          name,
          description,
          isPublished: true,
          categoryName,
          categoryId: categoriesIndex[categoryName],
          format,
          variations: {
            [variationDoc.id]: variationPayload,
          },
          storeId,
          createdAt: new Date().getTime(),
        },
      };
    }
  }, {});

  Object.entries(products).forEach(([, product]: any) => {
    const productDoc = doc(collection(db, 'products'));

    Object.entries(product.variations).forEach(
      ([variationId, variation]: any) => {
        if (variation.trackQty) {
          const variationRef = doc(getFirestore(), 'variations', variationId);
          const movementRef = doc(collection(variationRef, 'movements'));
          batch.set(variationRef, {
            user: { name: 'Posy' },
            productId: productDoc.id,
            name: variation.name,
            trackQty: variation.trackQty,
            quantity: variation.quantity,
            createdAt: new Date().getTime(),
          });
          batch.set(movementRef, {
            user: { name: 'Posy' },
            quantity: variation.quantity,
            action: 'reception',
            createdAt: new Date().getTime(),
          });
        }
      },
    );

    batch.set(productDoc, product);
  });

  batch
    .commit()
    .then(() => {
      console.info('ok');
    })
    .catch((err) => {
      console.error('ERROR', err);
    });
};

export default UploadProducts;
