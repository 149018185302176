import React from 'react';

interface Props {
  label?: string;
  name?: string;
  defaultValue?: string;
  options: any[];
  onChange: (event: any) => void;
  onBlur?: () => void;
  style?: any;
}

// eslint-disable-next-line react/display-name
const Radio = React.forwardRef(
  ({ name, onChange, options, defaultValue }: Props, ref: any) => {
    return (
      <div className="m-4" onChange={onChange} ref={ref}>
        {options.map(({ label, value }) => (
          <div key={label}>
            <input
              type="radio"
              id={value}
              name={name}
              value={value}
              className="mx-3 mt-3"
              checked={defaultValue === value}
            />
            <label htmlFor={value}>{label}</label>
          </div>
        ))}
      </div>
    );
  },
);

export default Radio;
