import { Field } from '@posy/components';

interface Props {
  fields: any[];
  registerField: (field: any) => any;
}

const Section = ({ fields, registerField }: Props) => {
  return (
    <div className="flex-1 px-4 bg-white y-4 p-4 my-4 flex flex-col gap-4">
      {fields.map((field) => (
        <Field key={field.name} {...registerField(field)} />
      ))}
    </div>
  );
};

export default Section;
