import { PermissionIndex } from './../types';

const decodePermissions = (permission: PermissionIndex = {}) =>
  Object.keys(permission).reduce(
    (result: string[], key: string) =>
      permission[key] ? result.concat(key.toUpperCase()) : result,
    [],
  );

export default decodePermissions;
