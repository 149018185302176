import { LocationMarkerIcon } from '@heroicons/react/outline';
import useLocations from 'hooks/useLocations';
import useFormModal from '../useFormModal';
import FormModal from '../FormModal';
import { useAuth } from 'Auth/AuthProvider';

const LocationPicker = ({ locationId, onChange }: Props) => {
  const { locations, getLocationNameById } = useLocations();
  const { modalProps, openLocationModal } = useFormModal();
  const { user } = useAuth();

  const openModal = () => {
    const shouldOpenModal = user?.isAdmin && locations.length > 1;

    if (shouldOpenModal) {
      const options = locations.map((location: any) => ({
        label: location.name,
        value: location.id,
      }));

      openLocationModal({
        options,
        initialValues: { locationId },
        onSubmit: ({ locationId }: any) => onChange(locationId),
      });
    }
  };

  return (
    <>
      <div
        className="text-gray-800 font-medium text-md flex w-64 items-center"
        onClick={openModal}
      >
        <LocationMarkerIcon className="h-4 w-4 mr-2" />
        {getLocationNameById(locationId)}
      </div>
      {modalProps.isOpen && <FormModal {...modalProps} />}
    </>
  );
};

interface Props {
  locationId: string;
  onChange: (locationId: string) => void;
}

export default LocationPicker;
