import { getFirestore, doc, writeBatch, arrayUnion } from 'firebase/firestore';
import { getCurrentTime, getTimelinePayload } from './helpers';
import updateInventoryLevels from './updateInventoryLevels';

const cancelOrder = (order: any, user: any, isInDefaultLocation: boolean) => {
  const db = getFirestore();
  const batch = writeBatch(db);
  const timelinePayload = getTimelinePayload('canceled', user);
  const orderPayload = {
    ...order,
    status: 'canceled',
    timeline: arrayUnion(timelinePayload),
    updatedAt: getCurrentTime(),
  };

  const orderRef = doc(db, 'orders', order.id);
  batch.update(orderRef, orderPayload);
  if (order.status !== 'pending') {
    updateInventoryLevels(
      order.items,
      batch,
      'return',
      timelinePayload.uid,
      order.locationId,
      isInDefaultLocation,
    );
  }
  batch.commit();
};

export default cancelOrder;
