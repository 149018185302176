import React from 'react';
import Select from './Select';
import SwitchField from './SwitchField';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import TextArea from './Textarea';
import Radio from './Radio';

interface Props {
  label: string;
  name: string;
  type: string;
  value: any;
  lines: number;
  onChange: (value: any) => object;
  disabled: boolean;
  errorMessage: string;
  options: any[];
  onBlur: () => void;
  onFocus: () => void;
  inputOptions: any;
  readOnly: boolean;
  hidden: boolean;
  placeholder: string;
  renderLabel: () => React.ReactNode;
  renderComponent: () => React.ReactNode;
}
// eslint-disable-next-line react/display-name
const Field = React.forwardRef(
  (
    {
      label,
      name,
      type,
      value,
      lines,
      onChange,
      disabled = false,
      errorMessage,
      options,
      onBlur,
      onFocus,
      inputOptions = {},
      readOnly,
      hidden,
      placeholder,
      renderLabel,
      renderComponent,
    }: Props,
    ref: any,
  ) => {
    const getComponent = () => {
      if (type === 'select') {
        return (
          <Select
            ref={ref}
            key={name}
            name={name}
            options={options}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
        );
      } else if (type === 'paragraph') {
        return (
          <TextArea
            ref={ref}
            name={name}
            value={value}
            lines={lines}
            onChange={onChange}
          />
        );
      } else if (type === 'radio') {
        return (
          <Radio
            ref={ref}
            key={name}
            name={name}
            options={options}
            defaultValue={value}
            onChange={onChange}
            onBlur={onBlur}
          />
        );
      } else if (type === 'boolean') {
        return (
          <SwitchField
            ref={ref}
            title={label}
            value={value}
            name={name}
            onChange={onChange}
          />
        );
      } else {
        return (
          <div className="flex rounded-md shadow-sm">
            <input
              ref={ref}
              type={type}
              id={name}
              name={name}
              value={value}
              onChange={onChange}
              disabled={disabled}
              onBlur={onBlur}
              onFocus={onFocus}
              placeholder={placeholder}
              className="block w-full border border-gray-300 rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm h-12 p-4 disabled:bg-gray-100"
            />
            {inputOptions.addOn && (
              <span className="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                {inputOptions.addOn?.label}
              </span>
            )}
          </div>
        );
      }
    };

    if (hidden) {
      return null;
    }

    if (readOnly) {
      return (
        <div ref={ref}>
          <DataLabel label={label} value={value} />
        </div>
      );
    }

    const renderInternalComponent = () => {
      const isInline = type === 'boolean';

      if (type === 'custom') {
        return <div ref={ref}>{renderComponent && renderComponent()}</div>;
      } else {
        return (
          <>
            <div className={`flex ${isInline ? 'items-center' : 'flex-col'}`}>
              <div className={`flex-1 ${!isInline && 'mb-2'}`}>
                {renderLabel ? (
                  renderLabel()
                ) : (
                  <Label name={name} label={label} />
                )}
              </div>
              <div className="relative rounded-md">
                {getComponent()}
                {errorMessage && (
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
            </div>
            {errorMessage && (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                {errorMessage}
              </p>
            )}
          </>
        );
      }
    };

    return <div>{renderInternalComponent()}</div>;
  },
);

const Label = ({ name, label }: { name: string; label: string }) => {
  return (
    <label htmlFor={name} className="block text-sm font-medium text-gray-700">
      {label}
    </label>
  );
};

const DataLabel = ({ label, value }: any) => {
  return (
    <div>
      <h6 className="font-bold">{label}</h6>
      <div>
        <p>{value || 'Sin datos'}</p>
      </div>
    </div>
  );
};

export default Field;
