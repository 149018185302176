import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Select } from '@posy/components';
import Payments from './components/Payments';
import getReportData from '../api/getReportData';
import { useAuth } from '../Auth/AuthProvider';
import Stats from './components/Stats';
import Items from './components/Items';
import Chart from './components/Chart';
import Customers from './components/Customers';
import Users from './components/Users';
import { t } from 'i18next';
import { useAnalytics } from '../providers/AnalyticsProvider';
import useLocations from '../hooks/useLocations';
import { useDefaultLocationProvider } from './../providers/DefaultLocationProvider';
import useReports from './useReports';
import Button from 'components/Button';
import DateModal from './components/DateModal';
import { DocumentSearchIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import AppLogo from 'components/AppLogo';

const Analytics = () => {
  const { user } = useAuth();
  const [data, setData] = useState<any>();
  const [isLoading, setLoading] = useState<boolean>();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [locationId, setLocationId] = useState<string>();
  const { logEvent } = useAnalytics();
  const { defaultLocationId } = useDefaultLocationProvider();
  const { locations } = useLocations();
  const { generateReport } = useReports(data);
  const isDay = getIsDay(startDate, endDate);
  const areDatesValid = startDate && endDate;
  const shouldDisplay = data?.overview?.length > 0 && !isLoading;

  useEffect(() => {
    logEvent('view_dashboard');
  }, []);

  useEffect(() => {
    if (areDatesValid) {
      setLoading(true);
      getReportData(
        user.storeId,
        startDate,
        endDate,
        locationId,
        locationId === defaultLocationId,
        (data: any) => {
          setData(data[0]);
          setLoading(false);
        },
      );
      logEvent('filter_dashboard', { startDate, endDate });
    }
  }, [startDate, endDate, locationId]);

  return (
    <div className="flex gap-1 overflow-auto p-4 h-full">
      <div className="flex-1">
        <div className="flex justify-between">
          <DateModal
            startDate={startDate}
            endDate={endDate}
            onChange={(startDate: string, endDate: string) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }}
          />
          <Button
            label={t('downloadReport')}
            isVisible={shouldDisplay}
            onClick={() => {
              areDatesValid && generateReport(startDate, endDate);
              logEvent('export_report', {
                type: 'closedOrders',
                startDate,
                endDate,
              });
            }}
          />
        </div>

        {shouldDisplay && (
          <Select
            defaultValue={locationId}
            options={locations}
            placeholder={'Todas las ubicaciones'}
            onChange={(e) => {
              setLocationId(e.target.value);
            }}
            style={{ height: 38, width: 240 }}
          />
        )}
        {isLoading ? (
          <Loading />
        ) : shouldDisplay ? (
          <>
            <Stats data={data?.overview[0]} />
            <Chart data={data} isDay={isDay} />
            <div
              className="grid grid-cols-2 gap-4"
              style={{ gridTemplateRows: '450px' }}
            >
              <Payments data={data?.payments} />
              <Items data={data?.items} />
              <Customers data={data?.customers} />
              <Users data={data?.users} />
            </div>
          </>
        ) : (
          <Empty />
        )}
      </div>
    </div>
  );
};

const Empty = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <DocumentSearchIcon className="w-20 h-20 text-gray-300" />
      <p className="text-gray-700 mt-4">{t('noAnalyticsResultMessage')}</p>
    </div>
  );
};

const Loading = () => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <AppLogo />
      <p className="text-gray-700 font-medium">{t('loading')}...</p>
    </div>
  );
};

const getIsDay = (startDate?: string, endDate?: string) => {
  return moment(startDate).isSame(endDate, 'day');
};

export default Analytics;
