const getProductTemplate = (product: any, barcode: string | null) => {
  if (product) {
    const data = product;

    const variations = Object.entries(data?.variations || {}).map(
      ([key, value]: any) => ({
        id: key,
        ...value,
      }),
    );

    return {
      id: product.id,
      ...product,
      variations,
    };
  } else {
    // doc.data() will be undefined in this case
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const initialVariation = {
      ...(barcode ? { barcode } : {}),
    };

    return {
      format: 'unit',
      variations: [initialVariation],
    };
  }
};

export default getProductTemplate;
