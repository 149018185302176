const formatPermissions = (permissions: any[] = []) => {
  return permissions.reduce((result, permission) => {
    const permissionName = permission.toLowerCase();

    return {
      ...result,
      [permissionName]: true,
    };
  }, {});
};

export default formatPermissions;
