import { formatPriceValue } from '@posy/helpers';
import moment from 'moment-timezone';
import keyBy from 'lodash/keyBy';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import Tabs from 'product/Products/components/Tabs';
import { useMemo, useState } from 'react';
import { getChartVariantTabs, weekDayLabels } from 'Analytics/constants';
import { t } from 'i18next';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { DateLabel, LabelKeys } from 'Analytics/types';

interface Props {
  data: any;
  isDay: boolean;
}

const Chart = ({ data, isDay }: Props) => {
  const [tab, setTab] = useState<LabelKeys>(isDay ? 'hours' : 'dates');
  const { logEvent } = useAnalytics();
  const formattedData = useMemo(
    () => ({
      ...data,
      weekDays: formatWeekDays(data.weekDays),
    }),
    [],
  );
  const formatted = formattedData[tab].map(({ _id, total }: any) => ({
    _id: getFormattedLabel(_id, tab),
    total: total,
  }));

  return (
    <div className="bg-white p-4 mt-2">
      <div className="flex flex-col items-center mb-4">
        <Tabs
          items={getChartVariantTabs(isDay)}
          value={tab}
          onTabChange={(tabName: string) => {
            setTab(tabName as LabelKeys);
            logEvent('filter_chart_by_dimension', { dimensionName: tabName });
          }}
        />
      </div>
      <ResponsiveContainer width="100%" height={240}>
        <LineChart
          data={formatted}
          margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
        >
          <Line
            type="monotone"
            dataKey="total"
            stroke="#6244bb"
            strokeWidth={3}
            yAxisId={0}
          />
          <Tooltip formatter={(value: any) => formatPriceValue(value)} />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />

          <XAxis dataKey="_id" dy={12} />
          <YAxis
            tickCount={3}
            dx={-2}
            axisLine={false}
            tickLine={false}
            tickFormatter={formatPriceValue}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

const getFormattedLabel = (value: number, formatId: LabelKeys) => {
  const weekDay = moment()
    .day(value - 1)
    .format('dddd')
    .toLowerCase();
  const months = moment()
    .month(value - 1)
    .format('MMM');

  const obj: DateLabel = {
    hours: moment(value.toString(), 'HHmm').format('h:mm A'),
    dates: moment(value).format('DD MMM'),
    weekDays: t(`date.weekDay.${weekDay}`),
    months,
  };

  return obj[formatId];
};

const formatWeekDays = (weekDays: any) => {
  const daysIndex = keyBy(weekDays, '_id');

  return weekDayLabels.map((weekDay) => {
    const weekDayIndex = weekDay.index;

    return daysIndex[weekDayIndex] || { _id: weekDayIndex, total: 0 };
  });
};

export default Chart;
