import { utils, writeFile } from 'xlsx';

const header_products = [
  'ProductId',
  'VariationId',
  'Codigo',
  'Item',
  'Categoría',
  'Formato',
  'Precio Normal',
  'Precio Costo',
  'Precio Oferta',
  'Seguimiento de stock',
  'Stock actual',
];

export const generateProductsReport = async (products: any[]) => {
  const productsData: any = products.map((product) => {
    return Object.entries(product.variations).map(([id, value]: any) => {
      return transformResultToCSV(product, { id, ...value });
    });
  });
  const ws_products = [header_products, ...[].concat(...productsData)];
  const wb = utils.book_new();
  wb.SheetNames.push('Hoja 1');
  wb.Sheets['Hoja 1'] = utils.aoa_to_sheet(ws_products);
  return writeFile(wb, 'test.xlsx');
};
const transformResultToCSV = (product: any, variation: any) => {
  const barcode = variation.barcode || '';
  const item = (product.name + ' ' + variation.name).replace(/,/g, ' ');
  const category = (product.categoryName || '').replace(/,/g, ' ');
  const format = product.format;
  const price = variation.price;
  const trackQty =
    'trackQty' in variation
      ? variation.trackQty
        ? 'Activado'
        : 'Desactivado'
      : product.trackQty
      ? 'Activado'
      : 'Desactivado';
  const costPrice = variation.costPrice || '';
  const salePrice = variation.salePrice || '';
  const quantity = variation.quantity || '';

  return [
    product.id,
    variation.id,
    barcode,
    item,
    category,
    format,
    price,
    costPrice,
    salePrice,
    trackQty,
    quantity,
  ];
};

export default generateProductsReport;
