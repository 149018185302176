import { useEffect } from 'react';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useAuth } from '../Auth/AuthProvider';

const useFeatureFlags = () => {
  const flags = useFlags();
  const { user } = useAuth();
  const ldClient = useLDClient();

  useEffect(() => {
    ldClient?.identify({ key: user?.storeId });
  }, []);

  return flags;
};

export default useFeatureFlags;
