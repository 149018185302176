import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useSidePanel,
  Field,
  useForm,
  useLocation,
  LoadingDim,
} from '@posy/components';

import FormCard from '../components/FormCard';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { useAuth } from '../Auth/AuthProvider';
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';
import { useAnalytics } from '../providers/AnalyticsProvider';

const BillingFormScreen = () => {
  const { t } = useTranslation();
  const { navigateTo, params } = useSidePanel();
  const [isLoading, setIsLoading] = useState(false);
  const { logEvent } = useAnalytics();
  const plan = params.plan;
  const cycleId = params.cycleId;
  const [billingData, setBillingData] = useState({});
  const { registerField, getField, handleSubmit } = useForm({
    defaultValues: billingData,
  });
  const hasBillingData = Boolean(billingData);
  const { user } = useAuth();
  const storeDoc = doc(getFirestore(), 'stores', `${user?.storeId}`);
  const { regiones, comunas } = useLocation(getField('companyState'));

  const form = {
    readOnly: hasBillingData,
    fields: [
      {
        label: t('billingForm.companyName'),
        name: 'companyName',
        type: 'text',
        rules: ['required', 'textOnly'],
      },
      {
        label: t('billingForm.companyTaxId'),
        name: 'companyTaxId',
        type: 'text',
        rules: ['required'],
      },
      {
        label: t('billingForm.companyEmail'),
        name: 'companyEmail',
        type: 'text',
        rules: ['required', 'email'],
      },
      {
        label: t('billingForm.companyActivity'),
        name: 'companyActivity',
        type: 'text',
        rules: ['required'],
      },
      {
        label: t('billingForm.companyAddress'),
        name: 'companyAddress',
        type: 'text',
        rules: ['required'],
      },
      {
        label: t('billingForm.companyState'),
        name: 'companyState',
        type: 'select',
        rules: ['required'],
        options: regiones,
      },
      {
        label: t('billingForm.companyCity'),
        name: 'companyCity',
        type: 'select',
        rules: ['required'],
        options: comunas,
      },
    ],
  };
  const { fields } = form;

  useEffect(() => {
    getDoc(storeDoc).then((docSnap) => {
      if (docSnap.exists()) {
        setBillingData(docSnap.data().billing || {});
      }
    });
  }, []);

  const storeData = (values: any) => {
    const payload = {
      'billing.companyName': values.companyName,
      'billing.companyTaxId': values.companyTaxId,
      'billing.companyEmail': values.companyEmail,
      'billing.companyActivity': values.companyActivity,
      'billing.companyAddress': values.companyAddress,
      'billing.companyState': values.companyState,
      'billing.companyCity': values.companyCity,
    };
    setIsLoading(true);
    updateDoc(storeDoc, payload).then(() => {
      setIsLoading(false);
      logEvent('update_billing_data');
      navigateTo('SubscriptionResume', {
        plan,
        cycleId,
      });
    });
  };

  return (
    <div className="px-4 overflow-auto">
      <div className="flex justify-end p-4">
        <div className="flex-1">
          <button
            type="button"
            className="inline-flex text-2xl font-semibold text-gray-900"
            onClick={() => navigateTo('SubscriptionResume', { plan, cycleId })}
          >
            <ChevronLeftIcon className="h-8 w-8" aria-hidden="true" />
            <h3>{t('billingFormTitle')}</h3>
          </button>
        </div>
      </div>
      <FormCard>
        {fields.map((field) => (
          <Field key={field.name} {...registerField(field)} />
        ))}
      </FormCard>
      <button
        className="bg-indigo-700 text-white font-medium rounded-md w-full px-4 py-2"
        onClick={handleSubmit(storeData)}
      >
        {t('save')}
      </button>
      <LoadingDim isLoading={isLoading} />
    </div>
  );
};

export default BillingFormScreen;
