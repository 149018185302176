import { t } from 'i18next';

type Item = {
  costPrice: number;
  price: number;
  quantity: number;
};

const getItemDiscount = (
  gross: number,
  orderGross: number,
  orderDiscount: number,
) => {
  if (orderDiscount) {
    const rate = gross / orderGross;

    return orderDiscount * rate;
  } else {
    return 0;
  }
};

const getItemProfit = (gross: number, cost: number, discount: number) =>
  gross - cost - discount;

const getItemCostPrice = (item: Item) =>
  item.costPrice || item.price * item.quantity;

const getItemGross = (item: Item) => item.price * item.quantity;

const getPaymentMethods = (payments: any[]) =>
  payments
    .map(({ method }: { method: string }) => t(`paymentMethods.${method}`))
    .join(',');

const getFinancialItems = (
  obj: any,
  items: Item[],
  orderSubtotal: number,
  orderDiscount: number,
) => {
  Object.entries(items || {}).forEach(([, item]: any) => {
    const gross = getItemGross(item);
    const discount = getItemDiscount(gross, orderSubtotal, orderDiscount);
    const costPrice = getItemCostPrice(item);
    const profit = getItemProfit(gross, costPrice, discount);

    obj[item.variationId] = {
      name: item.name,
      count: ((obj[item.variationId] && obj[item.variationId].count) || 0) + 1,
      quantity:
        ((obj[item.variationId] && obj[item.variationId].quantity) || 0) +
        item.quantity,
      gross:
        ((obj[item.variationId] && obj[item.variationId].gross) || 0) + gross,
      discount:
        ((obj[item.variationId] && obj[item.variationId].discount) || 0) +
        discount,
      profit:
        ((obj[item.variationId] && obj[item.variationId].profit) || 0) + profit,
      costPrice:
        ((obj[item.variationId] && obj[item.variationId].costPrice) || 0) +
        costPrice,
    };
  });

  return obj;
};

export {
  getItemDiscount,
  getItemProfit,
  getItemCostPrice,
  getPaymentMethods,
  getFinancialItems,
  getItemGross,
};
