import React from 'react';
import { formatPriceValue } from '../../helpers';

const stats = [
  {
    id: 'total',
    name: 'Vendido',
    type: 'money',
  },
  {
    id: 'count',
    name: 'Pedidos',
    type: 'number',
  },
  {
    id: 'average',
    name: 'Ticket medio',
    type: 'money',
  },
  {
    id: 'profit',
    name: 'Ganancia',
    type: 'money',
  },
];

const Stats = ({ data }: { data: any }) => {
  if (!data) {
    return null;
  }

  return (
    <div>
      <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-4 md:divide-y-0 md:divide-x">
        {stats.map((item: any) => (
          <div key={item.name} className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900">{item.name}</dt>
            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                {formatValue(data[item.id], item.type)}
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

const formatValue = (value: number, type: string) => {
  if (type === 'money') {
    return formatPriceValue(Math.round(value));
  } else {
    return value;
  }
};

export default Stats;
