const getInventory = ({
  locationId,
  product,
}: {
  locationId?: string;
  product: any;
}) => getInventoryLevel(product.locations)(locationId);

const getInventoryLevel =
  (locations: any = {}) =>
  (locationId: any) => {
    if (locationId) {
      return Object.entries(locations[locationId]?.variations || {}).reduce(
        (obj, [id, variation]: any) => {
          if (variation.tracked) {
            return {
              ...obj,
              allVariations: obj.allVariations + variation.available,
              variations: {
                ...obj.variations,
                [id]: variation,
              },
            };
          } else {
            return obj;
          }
        },
        {
          allVariations: 0,
          variations: {},
        }
      );
    } else {
      let finalObj: any = {
        allVariations: 0,
        variations: {},
      };

      Object.entries(locations || {}).forEach(([, { variations }]: any) => {
        Object.entries(variations).forEach(([id, variation]: any) => {
          if (variation.tracked) {
            finalObj = {
              ...finalObj,
              allVariations: finalObj.allVariations + variation.available,
              variations: {
                ...finalObj.variations,
                [id]: {
                  ...(finalObj.variations[id] || {}),
                  available:
                    (finalObj.variations[id]?.available || 0) +
                    variation.available,
                },
              },
            };
          }
        });
      });

      return finalObj;
    }
  };

export default getInventory;
