import { formatProduct } from '@posy/products';
import useOnSnapshot from '../components/useOnSnapshot';
import { useSettings } from 'providers/SettingsProvider';

type Product = {
  name: string;
};

const useProducts = (defaultLocationId: string) => {
  const { data, isLoading } = useOnSnapshot('products');
  const { settings } = useSettings();
  const formattedProducts = data.map((product) =>
    formatProduct(product, defaultLocationId),
  );
  const sortedProducts = sortProducts(
    formattedProducts,
    settings?.productListOrderBy,
  );

  return {
    products: sortedProducts,
    isLoading,
  };
};

const sortProducts = (products: Product[], orderBy: string): Product[] => {
  if (orderBy === 'alphabetical') {
    return products.sort((a: Product, b: Product) => {
      const firstName = a.name.toLowerCase();
      const secondName = b.name.toLowerCase();

      if (firstName < secondName) {
        return -1;
      } else if (firstName > secondName) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  return products;
};

export default useProducts;
