import { addDoc, getFirestore, collection } from 'firebase/firestore';

const createCustomer = (values: any, user: any) => {
  addDoc(collection(getFirestore(), 'customers'), {
    ...values,
    storeId: user.storeId,
    uid: user.id,
    createdAt: new Date().getTime() * 1000,
  });
};

export default createCustomer;
