import React from 'react';
import Item from './Item';
import { TrashIcon, UserIcon, ChatIcon } from '@heroicons/react/outline';
import FormModal from './FormModal';
import useFormModal from './useFormModal';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../Auth/AuthProvider';
import { priceFormatter } from '@posy/helpers';
const { getItemGross, toPriceTag } = priceFormatter;

const Cart = ({
  items,
  delivery,
  customer,
  notes,
  discount,
  onRemoveItem,
  onSelectCustomer,
  onSelectDelivery,
  onRemoveDelivery,
  onSelectNotes,
  onRemoveNotes,
  onItemChange,
  onSelectDiscount,
  onRequestPayment,
  onSave,
  onReset,
  onItemLevelRender,
  onItemBadgeRender,
  onEditItemQuantityPress,
}: {
  items: any[];
  delivery: any;
  customer: any;
  notes: string;
  discount: number;
  onRemoveItem: (item: any) => void;
  onRequestPayment: () => void;
  onReset: () => void;
  onSave: () => void;
  onSelectCustomer?: () => void;
  onSelectDelivery: (delivery: any) => void;
  onRemoveDelivery: () => void;
  onSelectNotes: (notes: string) => void;
  onRemoveNotes: () => void;
  onItemChange: (item: any) => void;
  onSelectDiscount: (discount: number) => void;
  onItemLevelRender: (item: any) => JSX.Element;
  onItemBadgeRender: (item: any) => JSX.Element;
  onEditItemQuantityPress: (item: any) => void;
}) => {
  const { hasPermission } = useAuth();
  const {
    modalProps,
    openPriceModal,
    openNoteModal,
    openDeliveryModal,
    openDiscountModal,
  } = useFormModal();
  const { subtotal, count } = items.reduce(
    (result, item: any) => ({
      subtotal: result.subtotal + getItemGross(item),
      count: result.count + 1,
    }),
    { subtotal: 0, count: 0 },
  );
  const toPay = subtotal - discount;
  const { t } = useTranslation();
  const discountRate = getDiscountRate({ subtotal, discount });
  const onSelectDeliveryType = () => {
    openDeliveryModal({
      initialValues: {
        type: delivery?.type || 'pickup',
      },
      shipping: customer?.shipping,
      onSubmit: ({ type }: any) => onSelectDelivery(type),
      onReset: delivery ? onRemoveDelivery : null,
    });
  };

  const openDiscountSelection = () => {
    openDiscountModal({
      initialValues: {
        discount,
        subtotal,
        discountRate,
      },
      onSubmit: ({ discount }: any) => {
        onSelectDiscount(Number(discount));
      },
    });
  };

  return (
    <div className="h-[calc(100vh-64px)] p-4 flex flex-col">
      <div className="bg-white h-full flex flex-col">
        <div className="flex justify-between">
          <div className="mt-2 ml-1">
            <div className="flex items-center mx-2">
              {customer && (
                <>
                  <UserIcon className="h-4 w-4 mr-1" />
                  <p className="text-sm">{customer.name}</p>
                </>
              )}
              <button
                className="text-indigo-700 text-sm mx-2"
                onClick={onSelectCustomer}
              >
                {customer ? 'Editar' : 'Agregar cliente'}
              </button>
            </div>
            <div>
              {customer &&
                (delivery ? (
                  <div className="ml-2 mt-1 text-sm">
                    Entrega: {t(`deliveryTypes.${delivery.type}`)}
                    <button
                      className="text-indigo-700 text-sm mx-2"
                      onClick={onSelectDeliveryType}
                    >
                      {customer ? 'Editar' : 'Agregar cliente'}
                    </button>
                  </div>
                ) : (
                  <button
                    className="text-indigo-700 text-sm ml-3"
                    onClick={onSelectDeliveryType}
                  >
                    {t('editDeliveryModalTitle')}
                  </button>
                ))}
            </div>
          </div>

          <div>
            <button
              className="text-indigo-700 text-sm mr-3 mt-2"
              onClick={() =>
                openNoteModal({
                  initialValues: {
                    notes,
                  },
                  onSubmit: (values: any) => onSelectNotes(values.notes),
                  onReset: notes ? onRemoveNotes : null,
                })
              }
            >
              <ChatIcon className="w-6 h-6 mr-2" />
            </button>
            <button className="text-indigo-700 text-sm mr-3" onClick={onReset}>
              <TrashIcon className="w-6 h-6" />
            </button>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto">
          {items.map((item) => (
            <Item
              key={item.variationId}
              item={item}
              onItemBadgeRender={onItemBadgeRender}
              onItemLevelRender={onItemLevelRender}
              onRequestPriceEdition={() =>
                openPriceModal({
                  initialValues: { price: item.price },
                  onSubmit: ({ price }: any) => {
                    onItemChange({ ...item, price });
                  },
                })
              }
              onRequestQuantityEdition={onEditItemQuantityPress}
              onItemChange={onItemChange}
              onRemoveItem={onRemoveItem}
            />
          ))}
          {modalProps.isOpen && <FormModal {...modalProps} />}
        </div>
        <div className="px-4 py-3 flex flex-col justify-center">
          {discount > 0 ? (
            <>
              <hr />
              <div className="p-3">
                <div className="flex justify-between my-1">
                  <p>{t('order.subtotal')}</p>
                  {toPriceTag(subtotal)}
                </div>
                <div
                  className="flex justify-between my-1"
                  style={{ cursor: 'pointer' }}
                  onClick={openDiscountSelection}
                >
                  <p>{t('order.discount')}</p>
                  <span className="text-indigo-700">
                    {`(${discountRate.toFixed(0)}%) -${toPriceTag(discount)}`}
                  </span>
                </div>
              </div>
            </>
          ) : (
            hasPermission('EDIT_ORDER_DISCOUNT') && (
              <button
                className={` px-2 py-3 mt- text-sm text-right ${
                  items.length > 0 ? 'text-indigo-700 ' : 'text-gray-400'
                }`}
                onClick={() => toPay > 0 && openDiscountSelection()}
              >
                {t('editDiscountModalTitle')}
              </button>
            )
          )}

          <button
            className={`px-2 py-3 w-full font-medium ${
              toPay > 0
                ? 'bg-indigo-700 text-white '
                : 'bg-gray-200 text-gray-400'
            }`}
            onClick={() => toPay > 0 && onRequestPayment()}
          >
            {count} items por {toPriceTag(toPay)}
          </button>
          <button
            className={` px-2 py-3 mt-2 ${
              items.length > 0 ? 'text-indigo-700 ' : 'text-gray-400'
            }`}
            onClick={() => items.length > 0 && onSave()}
          >
            {t('savePending')}
          </button>
        </div>
      </div>
    </div>
  );
};

const getDiscountRate = ({
  discount,
  subtotal,
}: {
  discount: number;
  subtotal: number;
}) => {
  return (discount * 100) / subtotal;
};

export default Cart;
