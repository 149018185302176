import React, { useEffect, ChangeEvent } from 'react';
import get from 'lodash/get';
import { formatPriceValue } from '@posy/helpers';
import {
  SwitchVerticalIcon,
  ArrowSmDownIcon,
  ArrowSmUpIcon,
} from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import isNumber from 'lodash/isNumber';
import Select from '../Form/Select';
import Searchbar from '../SearchBar';
import useLocalStorage from '../../useLocalStorage';

type SearchField = {
  label: string;
  value: string;
};

type Column = {
  key: string;
  label: string;
  render?: (row: any, getFilterValue: (field: string) => string) => void;
  isSortActive?: boolean;
  hidden?: boolean;
  type?: string;
  maxWidth?: number;
};

export interface TableProps {
  columns: Column[];
  rows: any;
  style?: any;
  onRowClick?: any;
  searchFields?: SearchField[];
  filters?: any[];
  sort?: any[];
  isLoading?: boolean;
  keepFilterBarVisible?: boolean;
  onFilteredRowsNumber?: (value: number) => void;
  storageKey?: string;
}

const getFilteredData = (
  data: any[],
  query: string,
  searchFields?: SearchField[],
  filterIndex?: any,
) => {
  const formatData = (data: any, searchFields: SearchField[] = []): string => {
    return searchFields.map((field: SearchField) => data[field.value]).join('');
  };

  return data.filter((data) => {
    const nameSanitized = formatData(data, searchFields)
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    const everyFilter = () =>
      Object.entries(filterIndex).every(([key, { value, disable }]: any) =>
        value && !disable ? get(data, key, null) === value : true,
      );

    return (
      nameSanitized.toLowerCase().includes(query.toLowerCase()) && everyFilter()
    );
  });
};

const formatSortValues = (a: any, b: any, sortKey: string) => {
  const firstValue = a[sortKey];
  const secondValue = b[sortKey];

  return {
    firstValue: sortKey === 'stock' ? parseFloat(firstValue) : firstValue,
    secondValue: sortKey === 'stock' ? parseFloat(secondValue) : secondValue,
  };
};
const getSortedData = (filteredData: any[], sort: any[]) => {
  const [sortKey, sortDir] = sort;

  if (sortKey) {
    return filteredData.sort((a: any, b: any) => {
      const { firstValue = '', secondValue = '' } = formatSortValues(
        a,
        b,
        sortKey,
      );

      if (isNumber(firstValue)) {
        return sortDir === 'DESC'
          ? secondValue - firstValue
          : firstValue - secondValue;
      } else {
        if (sortDir === 'DESC') {
          return secondValue?.localeCompare(firstValue, 'en', {
            sensitivity: 'base',
          });
        } else {
          return firstValue?.localeCompare(secondValue, 'en', {
            sensitivity: 'base',
          });
        }
      }
    });
  } else {
    return filteredData;
  }
};

const getSearchFields = (searchFields: SearchField[]): string => {
  return searchFields.map((field: SearchField) => field.label).join(' , ');
};

const getSearchPlaceholder = (t: any, searchFields: SearchField[]) => {
  const fields = getSearchFields(searchFields);

  return t('searchFor', { fields });
};

const getInitialFilters = (filters: any[] = [], persistedState: any = {}) =>
  filters.reduce((result, filter) => {
    const persistedValue = persistedState[filter.name];

    return {
      ...result,
      [filter.name]: {
        value: persistedValue || filter.default,
        disable: filter.disable,
      },
    };
  }, {});

const getInitialSort = (initialSort: any[] = [], persistedState: any = {}) => {
  if (persistedState.sortKey) {
    return [persistedState.sortKey, persistedState.sortDir];
  } else {
    return initialSort;
  }
};

const getSortDir = (direction: string) => {
  if (direction === null) {
    return 'DESC';
  } else if (direction === 'DESC') {
    return 'ASC';
  } else {
    return null;
  }
};

const Table = ({
  columns,
  rows,
  sort: initialSort,
  style,
  onRowClick,
  searchFields,
  isLoading,
  filters,
  keepFilterBarVisible,
  onFilteredRowsNumber,
  storageKey,
}: TableProps) => {
  const { persistedState, setPersistedValue } = useLocalStorage(
    `table.${storageKey}`,
    {},
  );
  const [search, setSearch] = React.useState(persistedState.search || '');
  const [filterIndex, setFilters] = React.useState(
    getInitialFilters(filters, persistedState),
  );
  const [sort, setSort] = React.useState<any[]>(
    getInitialSort(initialSort, persistedState),
  );
  const { t } = useTranslation();
  const tableRef = React.useRef<HTMLTableElement | null>(null);

  useEffect(() => {
    if (tableRef.current && persistedState) {
      tableRef.current.scrollTop = persistedState.scrollPosition;
    }
    setPersistedValue('scrollPosition', 0);
  }, []);

  const getValue = (key: string, row: any, type?: string) => {
    const value = get(row, key, '');
    if (type === 'money') {
      return formatPriceValue(value);
    } else {
      return value;
    }
  };

  const onTableRowClick = (id: string) => {
    const scrollTop = tableRef?.current?.scrollTop;
    storageKey && setPersistedValue('scrollPosition', scrollTop);
    setTimeout(() => onRowClick(id), 0);
  };

  const onFilterChange =
    (name: string, disable: boolean, onChange: (field: string) => void) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      onChange && onChange(value);
      setFilters((state: any) => ({
        ...state,
        [name]: { value, disable },
      }));
      storageKey && setPersistedValue(name, value);
    };

  const onSortChange = (key: string) => {
    setSort((sort: any[]) => {
      const sortDir = getSortDir(sort[1]);
      const sortKey = sortDir ? key : null;

      if (storageKey) {
        setPersistedValue('sortKey', sortKey);
        setPersistedValue('sortDir', sortDir);
      }

      return [sortKey, sortDir];
    });
  };

  const onSearchChange = (e: any) => {
    const value = e.target.value;
    setSearch(value);
    storageKey && setPersistedValue('search', value);
  };

  const getFilterValue = (field: string) =>
    filterIndex[field] ? filterIndex[field].value : null;

  const getSortedIcon = (columnKey: string, sort: any[]) => {
    const [sortKey, sortDir] = sort;
    const className = 'h-4 w-4';
    if (sortKey === columnKey) {
      if (sortDir === 'ASC') {
        return <ArrowSmUpIcon className={className} />;
      } else {
        return <ArrowSmDownIcon className={className} />;
      }
    } else {
      return <SwitchVerticalIcon className={className} />;
    }
  };

  const filteredData = getFilteredData(rows, search, searchFields, filterIndex);
  const sortedData = getSortedData(filteredData, sort);

  useEffect(() => {
    onFilteredRowsNumber && onFilteredRowsNumber(filteredData.length);
  }, [filteredData.length]);

  const shouldDisplayFilters =
    Object.keys(filterIndex).length > 0 ||
    rows.length > 0 ||
    keepFilterBarVisible;

  const getColumnStyle = (maxWidth?: number) => {
    return maxWidth ? { maxWidth: maxWidth, overflow: 'hidden' } : {};
  };

  return (
    <div
      className="bg-red-100 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
      style={style}
      ref={tableRef}
    >
      {shouldDisplayFilters && (
        <div className="flex items-center justify-end">
          {searchFields && (
            <Searchbar
              value={search}
              placeholder={getSearchPlaceholder(t, searchFields)}
              onChange={onSearchChange}
            />
          )}
          {filters?.map(
            (
              {
                label,
                name,
                options,
                onChange,
                disable,
                default: defaultValue,
              },
              index,
            ) => (
              <div key={`${name}-${index}`} className="mx-2 w-48">
                <Select
                  options={options}
                  placeholder={label}
                  value={getFilterValue(name) || defaultValue}
                  onChange={onFilterChange(name, disable, onChange)}
                />
              </div>
            ),
          )}
        </div>
      )}
      {rows.length > 0 ? (
        <div className="overflow-y-auto h-full pb-14">
          <table className="divide-y divide-gray-300 w-full">
            <thead className="bg-gray-50 sticky top-0 z-10 border-t">
              <tr>
                {columns.map(
                  ({ key, label, isSortActive, maxWidth, hidden }) => {
                    return hidden ? null : (
                      <th
                        key={key}
                        scope="col"
                        className="py-4 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        style={getColumnStyle(maxWidth)}
                      >
                        {label}
                        {isSortActive && (
                          <button
                            className="ml-4"
                            onClick={() => onSortChange(key)}
                          >
                            {getSortedIcon(key, sort)}
                          </button>
                        )}
                      </th>
                    );
                  },
                )}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {sortedData.map((row: any) => (
                <Row
                  key={row.id}
                  row={row}
                  onClick={() => onTableRowClick(row.id)}
                  columns={columns}
                  getValue={getValue}
                  getFilterValue={getFilterValue}
                  getColumnStyle={getColumnStyle}
                />
              ))}
            </tbody>
          </table>
        </div>
      ) : isLoading ? (
        <div className="flex items-center justify-center h-full">
          <p>{t('loading')}</p>
        </div>
      ) : (
        <div className="flex items-center justify-center h-full">
          <p>{t('noDataToShow')}</p>
        </div>
      )}
    </div>
  );
};

const Row = ({
  row,
  onClick,
  columns,
  getFilterValue,
  getValue,
  getColumnStyle,
}: {
  row: any;
  onClick: any;
  columns: Column[];
  getFilterValue: (field: string) => string;
  getValue: any;
  getColumnStyle: (maxWidth?: number) => any;
}) => {
  return (
    <tr onClick={onClick}>
      {columns.map(({ key, render, type, maxWidth, hidden }) => {
        if (hidden) {
          return null;
        }

        return render ? (
          <td key={`${key}-${row.id}`} style={getColumnStyle(maxWidth)}>
            {render(row, getFilterValue)}
          </td>
        ) : (
          <td
            key={`${key}-${row.id}`}
            className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 pl-6"
            style={getColumnStyle(maxWidth)}
          >
            <div className="text-gray-900">{getValue(key, row, type)}</div>
          </td>
        );
      })}
    </tr>
  );
};

export default Table;
