import { useState } from 'react';
import { Transition } from '@headlessui/react';

const features = [
  {
    id: 'in-person',
    title: 'Punto de venta para tu negocio',
    description:
      'Nunca más pierdas una venta. Comienza a vender en simples pasos',
    image: '/carousel/in-person.png',
  },
  {
    id: 'team',
    title: 'Gestiona tu equipo de trabajo',
    description:
      'Entrega a tus colaboradores su propio sistema de venta y mide su rendimiento',
    image: '/carousel/team.png',
  },
  {
    id: 'online',
    title: 'Crea tu tienda online en 1 minuto',
    description:
      'Publica tus productos en un sitio web optimizado para vender y aumenta tus ingresos',
    image: '/carousel/online.png',
  },
];

const Carousel = () => {
  const [current, setCurrent] = useState('in-person');

  return (
    <div className="w-2/4">
      <section className="relative h-[540px] w-full overflow-hidden text-center">
        {features.map((feature) => (
          <Transition
            key={feature.id}
            show={feature.id === current}
            enter="transition-transform ease-in-out duration-300 transform translate-x-0"
            enterFrom="transform translate-x-full"
            enterTo="transform translate-x-0"
            leave="transition-transform ease-in-out duration-300 transform translate-x-full"
            leaveFrom="transform translate-x-0"
            leaveTo="transform translate-x-full"
          >
            <div className="absolute w-full">
              <img className="w-full" src={feature.image} />
              <h3 className="text-2xl text-gray-900 font-bold mt-4">
                {feature.title}
              </h3>
              <p className="mt-2">{feature.description}</p>
            </div>
          </Transition>
        ))}
      </section>
      <div className="flex justify-center">
        {features.map((feature) => (
          <Dot
            key={feature.id}
            selected={current === feature.id}
            onClick={() => setCurrent(feature.id)}
          />
        ))}
      </div>
    </div>
  );
};

const Dot = ({
  onClick,
  selected,
}: {
  onClick: () => void;
  selected: boolean;
}) => (
  <div
    className={`w-4 h-4 ${
      selected ? 'bg-indigo-700' : 'bg-white'
    }  rounded-lg mt-4 mx-2 cursor-pointer border-gray-300 border-2`}
    onClick={onClick}
  />
);

export default Carousel;
