import { t } from 'i18next';

export const ordersHeader = [
  'Numero',
  'Cliente',
  'Subtotal',
  'Descuento',
  'Total',
  'Medio de pago',
  'Estado',
  'Fecha creación',
  'Fecha completado',
  'Vendedor',
  'Notas',
];

export const itemsHeader = [
  'Item',
  'Nº pedidos',
  'Cantidad pedida',
  'Subtotal',
  'Descuento total',
  'Total',
  'Costo total',
  'Ganancia total',
  'Precio unitario promedio',
  'Costo unitario promedio',
  'Descuento unitario promedio',
  'Ganancia unitaria promedio',
];

export const customersHeader = [
  'Nombre',
  'Nº pedidos',
  'Subtotal',
  'Descuento total',
  'Total',
  'Costo total',
  'Ganancia total',
];

export const getChartVariantTabs = (isDay) => {
  const baseTabs = [{ name: t('date.filter.hour'), id: 'hours' }];

  if (!isDay) {
    baseTabs.push(
      { name: t('date.filter.date'), id: 'dates' },
      { name: t('date.filter.week'), id: 'weekDays' },
      { name: t('date.filter.month'), id: 'months' },
    );
  }

  return baseTabs;
};

export const weekDayLabels = [
  { name: t('date.weekDay.monday'), index: 2 },
  { name: t('date.weekDay.tuesday'), index: 3 },
  { name: t('date.weekDay.wednesday'), index: 4 },
  { name: t('date.weekDay.thursday'), index: 5 },
  { name: t('date.weekDay.friday'), index: 6 },
  { name: t('date.weekDay.saturday'), index: 7 },
  { name: t('date.weekDay.sunday'), index: 1 },
];
