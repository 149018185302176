const formatPriceValue = (value: string) => {
  return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const getPriceLabel = (variations: any[]) => {
  const variationPrices = Object.entries(variations).map(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([_, { salePrice, price }]) => salePrice || price,
  );

  const minPrice = Math.min(...variationPrices);
  const maxPrice = Math.max(...variationPrices);
  return minPrice === maxPrice
    ? `${String(minPrice)}`
    : `${minPrice} - $${maxPrice}`;
};

const getProductDisplayPrice = (variations: any[]) => {
  const priceLabel = getPriceLabel(variations);
  return formatPriceValue(priceLabel);
};

const formatFractionQty = (quantity: number): string =>
  quantity.toFixed(3).replace('.', ',');

const getBadgeClasses = (available: number, minStock: number) => {
  if (available > 0 && available <= minStock) {
    return 'bg-yellow-100 text-yellow-800';
  } else if (available < 1) {
    return 'bg-red-100 text-red-800';
  } else {
    return 'bg-green-100 text-green-800';
  }
};

export { getProductDisplayPrice, formatFractionQty, getBadgeClasses };
