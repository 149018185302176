/* This example requires Tailwind CSS v2.0+ */
import React from 'react';

interface Props {
  label?: string;
  name?: string;
  defaultValue?: string;
  value?: string;
  options: any[];
  placeholder?: string;
  onChange: (event: any) => void;
  onBlur?: () => void;
  style?: any;
}
// eslint-disable-next-line react/display-name
const Select = React.forwardRef(
  (
    { label, name, onChange, options, value, placeholder, style }: Props,
    ref: any,
  ) => {
    return (
      <>
        <div className="sm:col-span-3">
          <label
            htmlFor={name}
            className="block text-sm font-medium text-gray-700"
          >
            {label}
          </label>
          <div className="mt-1">
            <select
              id={name}
              ref={ref}
              name={name}
              value={value}
              autoComplete="country-name"
              onChange={(e) => {
                const { name, value } = e.target;
                onChange({
                  target: { name, value: value === '' ? null : value },
                });
              }}
              style={style}
              className="h-12 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 px-2 rounded-md"
            >
              <option value={''}>
                {placeholder || 'Selecciona una opción'}
              </option>

              {options.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </>
    );
  },
);

export default Select;
