import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatData } from '../helpers';
import Chart from './Chart';
import { getCatalogHostname } from '@posy/helpers';
import { Image } from '@posy/components';
import background from './../../../public/background.png';
import devices from './../../../public/devices.png';

const Avatar = ({ logo }: any) => {
  return (
    <div className="w-14 h-14 bg-gray-300 rounded-full">
      <Image src={logo} alt="logo" />
    </div>
  );
};

export interface Store {
  id: string;
  name: string;
  subtitle: string;
  isActive: true;
  uniqueName: string;
  logo: string;
}

export interface CatalogOverviewProps {
  store: Store;
  onlineData: any[];
}

const CatalogOverview = (props: CatalogOverviewProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const catalogHostname = getCatalogHostname();
  const { store, onlineData = [] } = props;
  const formattedData = formatData(onlineData);

  if (!store) return null;

  if (!store.uniqueName) {
    return (
      <div
        className="flex flex-col-reverse md:flex-row bg-cover rounded-md"
        style={{ backgroundImage: `url(${background})`, minHeight: 380 }}
      >
        <div className="md:w-1/2 flex flex-col justify-center p-12">
          <div className="w-62 flex flex-col">
            <p className="text-white text-left text-xl">
              {t('cardsHome.onlineCatalog.subtitle')}
            </p>
            <p className="text-white text-left font-bold text-4xl">
              {t('cardsHome.onlineCatalog.title')}
            </p>
            <p className="text-white text-left text-xl">
              <strong>{t('cardsHome.onlineCatalog.subtitle2')}</strong>{' '}
              {t('cardsHome.onlineCatalog.subtitle3')}
            </p>
            <button
              onClick={() => {
                navigate('/online-catalog/' + store.id);
              }}
              className="bg-white text-indigo-700 font-medium rounded-md px-5 py-3 mt-4 w-fit"
            >
              <strong>{t('cardsHome.onlineCatalog.button')}</strong>
            </button>
          </div>
        </div>
        <div className="md:w-1/2 flex justify-center items-center mb-2 mr-4">
          <img
            src={devices}
            alt="image"
            className="h-82 w-82 lg:p-6 rounded-md"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white y-4 p-4 mb-2 mt-6">
      <div>
        <div className="flex">
          <div className="flex flex-1">
            <Avatar logo={store.logo} />
            <div>
              <p className="ml-4 text-gray-700 font-bold">{store.name}</p>
              <p className="ml-4 text-gray-500 text-sm">{`${store.uniqueName}.${catalogHostname}`}</p>
            </div>
          </div>
          <div>
            <button
              onClick={() => {
                navigate('/online-catalog/' + store.id);
              }}
              className="text-indigo-700 border border-indigo-700 font-medium rounded-md px-4 py-1 mr-4"
            >
              {t('setup')}
            </button>
            <button
              onClick={() => {
                window.open(
                  `https://${store.uniqueName}.${catalogHostname}`,
                  '_blank',
                );
              }}
              className="bg-indigo-700 text-white font-medium rounded-md px-4 py-1"
            >
              {t('goToSite')}
            </button>
          </div>
        </div>

        <div className="py-8">
          <Chart formattedData={formattedData} />
        </div>
      </div>
    </div>
  );
};

export default CatalogOverview;
