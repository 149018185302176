import { useTranslation } from 'react-i18next';

interface Props {
  status: 'pending' | 'confirmed' | 'completed' | 'canceled';
  isPaid?: boolean;
  amountToPayLabel?: string;
  label?: string | null;
}

const StatusBadge = ({ status, isPaid, amountToPayLabel }: Props) => {
  const { t } = useTranslation();
  const color = getColorByStatus(status, Boolean(!isPaid));
  const isConfirmed = status === 'confirmed';

  return (
    <>
      {!isConfirmed && (
        <span
          className={`inline-flex items-center gap-x-1.5 rounded-full bg-${color}-100 px-2 py-1 text-xs font-medium text-${color}-800`}
        >
          <svg
            className={`h-1.5 w-1.5`}
            fill={getOriginalColors(status, false)}
            viewBox="0 0 6 6"
            aria-hidden="true"
          >
            <circle cx={3} cy={3} r={3} />
          </svg>
          {t(`statusLabels.${status}`)}
        </span>
      )}
      {isConfirmed && (
        <span
          className={`inline-flex items-center gap-x-1.5 rounded-full bg-${color}-100 px-2 py-1 text-xs font-medium text-${color}-800`}
        >
          <svg
            className={`h-1.5 w-1.5`}
            fill={getOriginalColors(status, Boolean(!isPaid))}
            viewBox="0 0 6 6"
            aria-hidden="true"
          >
            <circle cx={3} cy={3} r={3} />
          </svg>
          {isPaid ? 'Pagado' : amountToPayLabel}
        </span>
      )}
    </>
  );
};

type StatusColor = {
  [key: string]: string;
};

const getOriginalColors = (status: string, hasLabel: boolean) => {
  const originalColors: any = {
    pending: '#eab308',
    confirmed: hasLabel ? '#ef4444' : '#6366f1',
    completed: '#22c55e',
    canceled: '#6b7280',
  };

  return originalColors[status];
};

const getColorByStatus = (status: string, hasLabel: boolean) => {
  const statusColors: StatusColor = {
    pending: 'yellow',
    confirmed: hasLabel ? 'red' : 'indigo',
    completed: 'green',
    canceled: 'gray',
  };

  return statusColors[status];
};

export default StatusBadge;
