const validate = async (storeId: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CATALOG_API_URL}/api/payment/validate`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ storeId }),
      },
    );

    const result = await response.json();

    return result.isValid;
  } catch (e) {
    return false;
  }
};

export default validate;
