import React from 'react';

interface ModalContextType {
  isOpen: any;
  route?: string;
  params?: any;
  openSidePanel: () => void;
  closeSidePanel: () => void;
  navigateTo: (route: string, params?: any) => void;
}

const ModalContext = React.createContext<ModalContextType>(null!);

const SidePanelProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const [route, setRoute] = React.useState<string>('');
  const [params, setParams] = React.useState<any>({});
  const [routes, setRoutes] = React.useState<any>([]);

  const openSidePanel = () => {
    setOpen(true);
  };
  const closeSidePanel = () => {
    setOpen(false);
  };

  const navigateTo = (route: string, params: any = {}) => {
    const found = routes.find(({ name }: { name: string }) => name === route);
    if (found) {
      setRoute(found.name);
      setParams({ ...(found.params || {}), ...(params || {}) });
    } else {
      setRoute(route);
      setParams(params);
      setRoutes((routes: any) => routes.concat({ name: route, params }));
    }
  };

  const value = {
    isOpen,
    route,
    params,
    navigateTo,
    openSidePanel,
    closeSidePanel,
  };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

function useSidePanel() {
  return React.useContext(ModalContext);
}

export { useSidePanel };

export default SidePanelProvider;
