import { useState, useEffect } from 'react';

const useLocation = (shippingState: string) => {
  const [regiones, setRegiones] = useState([]);
  const [comunas, setComunas] = useState([]);

  useEffect(() => {
    const get = async () => {
      const response = await fetch('/regiones.json');
      const data = await response.json();
      setRegiones(
        data.regiones.map((region: any) => ({
          id: region.region,
          name: region.region,
          comunas: region.comunas,
        })),
      );
    };

    get();
  }, []);

  useEffect(() => {
    if (shippingState) {
      const comuna: any = regiones.find(
        (region: any) => region.id === shippingState,
      );

      setComunas(
        comuna
          ? comuna.comunas.map((c: { id: string; name: string }) => ({
              id: c,
              name: c,
            }))
          : [],
      );
    }
  }, [shippingState, regiones.length]);

  return { regiones, comunas };
};

export default useLocation;
