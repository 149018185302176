import { Table } from '@posy/components';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useLocations from '../../hooks/useLocations';
import { ChevronLeftIcon } from '@heroicons/react/solid';
import useFeatureFlags from 'hooks/useFeatureFlags';
import Button from './../../components/Button';

const Locations = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const columns = [{ key: 'name', label: t('form.name') }];
  const { locations, isLoading } = useLocations();
  const path = '/settings/locations';
  const { enableCreateLocations } = useFeatureFlags();
  const shouldCreateLocations = locations.length < 3;

  return (
    <>
      <div className="px-4 pt-6 ">
        <BackButton
          onClick={() => navigate('/settings')}
          label={t('sidebar.settings')}
        />
        <div className="flex mb-4">
          <div className="flex-1">
            <h1 className="text-2xl font-semibold text-gray-900">
              {t('settings.locations.title')}
            </h1>
          </div>

          {shouldCreateLocations && (
            <Button
              isVisible={enableCreateLocations}
              label={t('createLocation')}
              onClick={() => navigate(`${path}/new`)}
            />
          )}
        </div>
      </div>

      <Table
        columns={columns}
        rows={locations}
        onRowClick={(id: string) => navigate(`${path}/${id}`)}
        isLoading={isLoading}
        style={{
          height: '100%',
          background: 'white',
          margin: 16,
        }}
      />
    </>
  );
};

const BackButton = ({ onClick, label }: any) => {
  return (
    <a
      className="flex items-center cursor-pointer text-indigo-700"
      onClick={onClick}
    >
      <ChevronLeftIcon className="h-4 w-4" />
      <span className="mr-2 text-sm font-medium">{label}</span>
    </a>
  );
};

export default Locations;
