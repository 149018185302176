import { Field, useSidePanel } from '@posy/components';
import Button from 'components/Button';
import FieldLabel from 'components/FieldLabel';
import { useTranslation } from 'react-i18next';

const TransferPayment = ({
  values,
  registerField,
  setField,
}: {
  values: any;
  registerField: any;
  setField: (field: string, value: any) => void;
}) => {
  const { navigateTo } = useSidePanel();

  const onSubmit = (data: any) => {
    setField('paymentMethods.transfer', data);
  };

  const goToPanel = () => {
    navigateTo('PaymentMethod', { values, onSubmit, registerField });
  };

  const { t } = useTranslation();
  const customField = {
    label: 'Transferencia',
    name: 'paymentMethods.transfer',
    type: 'custom',
    renderComponent: () => (
      <div>
        {values ? (
          <Field
            {...registerField({
              label: 'Transferencia',
              name: 'paymentMethods.transfer.active',
              type: 'boolean',
              default: true,
            })}
          />
        ) : (
          <div className="flex items-center">
            <FieldLabel
              label={t('paymentMethods.transfer')}
              className="flex-1"
            />
            <Button bordered noMargin label="Configurar" onClick={goToPanel} />
          </div>
        )}
        {values && (
          <Button noBorder label="Editar" textSize="sm" onClick={goToPanel} />
        )}
      </div>
    ),
  };

  return <Field {...registerField(customField)} />;
};

export default TransferPayment;
