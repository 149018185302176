import { ChevronLeftIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

interface Props {
  label?: string;
  onPress: any;
}

const BackButton = ({ label, onPress }: Props) => {
  const { t } = useTranslation();

  return (
    <button
      type="button"
      className="inline-flex items-center text-sm font-semibold text-indigo-700"
      onClick={onPress}
    >
      <ChevronLeftIcon className="h-4 w-4" aria-hidden="true" />
      {label || t('goBack')}
    </button>
  );
};

export default BackButton;
