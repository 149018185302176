import { useAuth } from 'Auth/AuthProvider';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from '@posy/components';
import SubscribeButton from './SubscribeButton';

interface Props {
  visible: boolean;
  onSubscribeClick: () => void;
}

const ProBanner = ({ visible = true, onSubscribeClick }: Props) => {
  const { user } = useAuth();
  const { trialDayNumber, trialProgress } = user || {};
  const { t } = useTranslation();

  return visible ? (
    <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-gradient-to-r from-indigo-800 to-purple-500 hover:bg-black-600 px-6 py-2 sm:rounded-lg sm:py-3 sm:pl-4 sm:pr-3.5 my-1.5 w-full">
      <div className="flex flex-1">
        <p className="text-sm leading-6 text-white w-2/5">
          <strong className="font-semibold text-sm">
            {t('trialBannerLabel')}
          </strong>
        </p>
        <ProgressBar
          label={`${trialDayNumber}/${t('sevenDays')}`}
          progress={`${trialProgress}%`}
        />
      </div>
      <SubscribeButton label={t('getProLabel')} onClick={onSubscribeClick} />
    </div>
  ) : null;
};

export default ProBanner;
