import React from 'react';
import { formatPriceValue } from '@posy/helpers';
import { Image } from '@posy/components';

import getItemInventory from 'checkout/Checkout/getItemInventory';
import StockLabel from './StockLabel';
import StockBadge from './StockBadge';

const VariantItem = ({
  product,
  locationId,
  quantity = 0,
  productFormat,
  item,
  onChange,
}: {
  product: any;
  locationId: string;
  quantity: number;
  productFormat: string;
  item: any;
  onChange: (id: string, quantity: number) => void;
}) => {
  const { name, price, id: variationId } = item;
  const { isLowStock, isOutOfStock, available, tracked } = getItemInventory(
    product,
    variationId,
    quantity,
    locationId,
  );

  const shouldDisplayStockLabel = quantity > 0 && tracked;

  return (
    <div className="bg-white flex flex-col m-4 ">
      <div className="flex justify-between">
        <div className="flex">
          <div className="bg-gray-300 w-12 h-12 rounded-md relative">
            {item.thumbnail ? (
              <Image
                className="h-12 w-12 absolute z-0"
                src={item.thumbnail}
                alt="Product logo"
              />
            ) : (
              <div className="flex justify-center items-center h-full h-12 w-12 absolute z-0">
                <p>{item.name.slice(0, 2)}</p>
              </div>
            )}
          </div>
          <div className="ml-3">
            <p className="">{name}</p>
            <p className="text-xs text-gray-500">{formatPriceValue(price)}</p>

            {tracked && (
              <StockLabel available={available} minStock={item.minStock} />
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <QuantityInput
            quantity={quantity}
            isFraction={productFormat === 'fraction'}
            onChange={(value: number) => {
              onChange(variationId, value);
            }}
          />
          <div className="flex justify-end">
            <StockBadge
              visible={shouldDisplayStockLabel}
              isOutOfStock={isOutOfStock}
              isLowStock={isLowStock}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const QuantityInput = ({ quantity, isFraction, onChange }: any) => {
  const [value, setValue] = React.useState(quantity);

  const increment = () => {
    setValue((value: number) => {
      const newValue = Number(value) + 1;
      onChange(newValue);

      return newValue.toString();
    });
  };

  const decrement = () => {
    setValue((value: number) => {
      const newValue = Number(value) - 1;
      if (newValue > 0) {
        onChange(newValue);
        return newValue.toString();
      } else {
        return value;
      }
    });
  };

  return (
    <div className="text-right">
      <button onClick={decrement}>-</button>
      <input
        type="number"
        value={value}
        onKeyDown={(e) => {
          const baseDisabledChars = [',', 'e', '-'];
          const disabledChars = isFraction
            ? baseDisabledChars
            : baseDisabledChars.concat('.');

          if (disabledChars.includes(e.key)) {
            e.preventDefault();
          }
        }}
        onChange={(e) => {
          const { value } = e.target;
          setValue(value);
          onChange(Number(value));
        }}
        className="w-16 h-10 text-md text-center font-medium mx-2"
      />
      <button onClick={increment}>+</button>
    </div>
  );
};

export default VariantItem;
