import { doc, getFirestore, writeBatch, collection } from 'firebase/firestore';
import { formatVariationInput } from '../../helpers';
import { increment, deleteField } from 'firebase/firestore';
import omit from 'lodash/omit';

const updateProduct = async (
  productId: string,
  values: any,
  defaultProduct: any,
  user: { id: string; name: string },
  defaultLocationId: string,
) => {
  try {
    const db = getFirestore();
    const batch = writeBatch(db);

    const createdAt = new Date().getTime();
    let tempIds: any = {};

    const { formattedVariations, deletedIds } = values.variations.reduce(
      (obj: any, variation: any, index: number) => {
        if (variation.isRemoved) {
          const variationRef = doc(db, 'variations', variation.id);
          batch.delete(variationRef);

          return {
            ...obj,
            deletedIds: { ...obj.deletedIds, [variation.id]: true },
          };
        } else {
          const preFormattedVariation = {
            ...variation,
            id: variation.id || doc(collection(db, 'variations')).id,
          };
          const formattedVariation = formatVariationInput(
            preFormattedVariation,
            values.format,
          );

          const variationRef = doc(db, 'variations', formattedVariation.id);
          batch.set(variationRef, formattedVariation);

          if (!variation.id) {
            tempIds = {
              ...tempIds,
              [String(index)]: preFormattedVariation.id,
            };
          }

          return {
            ...obj,
            formattedVariations: {
              ...obj.formattedVariations,
              [variationRef.id]: formattedVariation,
            },
          };
        }
      },
      { deletedIds: {}, formattedVariations: {} },
    );

    const movements = Object.entries(values.locations || {}).reduce(
      (obj: any, [locationId, location]: any) => {
        const variations = Object.entries(location.variations).reduce(
          (results: any, [id, variation]: any) => {
            const variationId = getVariationId({ id, tempIds });
            const currentQuantity = Number(variation.available);
            if (formattedVariations[variationId]) {
              const oldQuantity =
                defaultProduct.locations?.[locationId]?.variations?.[id]
                  ?.available || 0;
              const [action, quantity] = getMovementState(
                oldQuantity,
                currentQuantity,
              );
              if (quantity !== 0) {
                return results.concat({
                  id: user.id,
                  user: {
                    name: user.name,
                  },
                  quantity,
                  action,
                  createdAt,
                  locationId,
                  variationId,
                });
              } else {
                return results;
              }
            } else {
              return results;
            }
          },
          [],
        );
        return [...obj, ...variations];
      },
      [],
    );

    const locationUpdates = movements.reduce((result: any, movement: any) => {
      const { locationId, variationId, action, quantity } = movement;
      const variationRef = doc(db, 'variations', movement.variationId);
      const movementRef = doc(collection(variationRef, 'movements'));
      batch.set(movementRef, movement);
      const inventoryLevelPath = `locations.${locationId}.variations.${variationId}`;
      const variationPath = `variations.${variationId}`;
      const isDefaultLocation = defaultLocationId === locationId;
      const operation = getQuantityField(action, quantity);

      return {
        ...result,
        ...(isDefaultLocation && {
          [`${variationPath}.quantity`]: operation,
          [`${variationPath}.trackQty`]: true,
        }),
        [`${inventoryLevelPath}.available`]: operation,
        [`${inventoryLevelPath}.tracked`]: true,
      };
    }, {});

    const filteredValues = omit(values, ['locations', 'variations']);

    const variationUpdates = Object.entries(formattedVariations).reduce(
      (obj: any, [id, variation]: any, index: number) => {
        const variationId = getVariationId({
          id: id || index.toString(),
          tempIds,
        });
        const filteredVariation = omit(variation, ['quantity', 'trackQty']);
        const results = Object.keys(filteredVariation).reduce(
          (keys, key) => ({
            ...keys,
            [`variations.${variationId}.${key}`]: variation[key],
          }),
          {},
        );

        return {
          ...obj,
          ...results,
        };
      },
      {},
    );

    const deletionUpdates = Object.keys(deletedIds).reduce((obj, id) => {
      const locationObject = Object.keys(values.locations).reduce(
        (obj, locationId) => {
          return {
            ...obj,
            [`locations.${locationId}.variations.${id}`]: deleteField(),
          };
        },
        {},
      );
      return { ...obj, [`variations.${id}`]: deleteField(), ...locationObject };
    }, {});

    const payload = {
      ...filteredValues,
      ...variationUpdates,
      ...deletionUpdates,
      ...locationUpdates,
    };

    const productRef = doc(db, 'products', productId);
    batch.update(productRef, payload);
    await batch.commit();
  } catch (e) {
    console.error(e);
  }
};

const getMovementState = (oldQuantity: number, newQuantity: number) => {
  const oldFormattedQuantity = parseFloat(oldQuantity.toFixed(3));
  const newFormattedQuantity = parseFloat(newQuantity.toFixed(3));

  if (newQuantity > oldQuantity) {
    return ['reception', newFormattedQuantity - oldFormattedQuantity];
  } else {
    return ['shrinkage', oldFormattedQuantity - newFormattedQuantity];
  }
};

const getQuantityField = (action: string, quantity: number) => {
  switch (action) {
    case 'order':
      return increment(-quantity);
    case 'return':
      return increment(quantity);
    case 'shrinkage':
      return increment(-quantity);
    case 'reception':
      return increment(quantity);
  }
};

const getVariationId = ({ id, tempIds }: { id: string; tempIds: any }) => {
  const isNumericId = id.length <= 3;

  return isNumericId ? tempIds[id] : id;
};

export default updateProduct;
