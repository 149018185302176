import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LoadingDim } from '@posy/components';
import { useAuth } from './AuthProvider';
import ProviderOptions from './components/ProviderOptions';
import CustomAuth from './components/CustomAuth';
import Carousel from './components/Carousel';

type LocationProps = {
  state: {
    from: Location;
  };
};

const Auth = () => {
  const navigate = useNavigate();
  const location = useLocation() as unknown as LocationProps;
  const { user, signWithGoogle, signWithApple, userIsLoading } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>();
  const [isSigning, setIsSigning] = useState(false);
  const from = location.state?.from?.pathname || '/';

  useEffect(() => {
    if (user) {
      navigate(from, { replace: true });
    }
  }, [user?.id]);

  const handleGoogleAuth = () => {
    setIsLoading(true);
    signWithGoogle().catch((error: any) => {
      setIsLoading(false);
      const errorMessage = getTranslatedErrorMessage(error);
      setError(errorMessage);
    });
  };

  const handleAppleAuth = () => {
    setIsLoading(true);
    signWithApple().catch((error: any) => {
      setIsLoading(false);
      const errorMessage = getTranslatedErrorMessage(error);
      setError(errorMessage);
    });
  };

  const goToResetPassword = () => {
    navigate('/reset-password');
  };

  const getTranslatedErrorMessage = (error: any) => {
    if (
      error.code === 'auth/user-not-found' ||
      error.code === 'auth/wrong-password'
    ) {
      return 'Su usuario o contraseña es inválida';
    } else if (error.code === 'auth/user-disabled') {
      return 'La cuenta ha sido desactivada por el administrador';
    } else {
      return 'Algo salió mal, intente nuevamente.';
    }
  };

  if (userIsLoading) {
    return <LoadingDim isLoading />;
  }

  return (
    <>
      <LoadingDim isLoading={isLoading} />

      <div className="h-screen flex">
        <Left />

        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div className="mt-8">
              {isSigning ? (
                <CustomAuth
                  goToResetPassword={goToResetPassword}
                  goBack={() => setIsSigning(false)}
                />
              ) : (
                <ProviderOptions
                  signWithGoogle={handleGoogleAuth}
                  signWithApple={handleAppleAuth}
                  signInWithEmail={() => setIsSigning(true)}
                />
              )}

              {error && (
                <div className="bg-red-100 border border-red-500 px-4 py-2 rounded-md mt-4">
                  <p className="text-red-700 font-medum">{error}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Left = () => {
  return (
    <div className="hidden lg:flex bg-gray-100 items-center justify-center w-full h-full border-2">
      <Carousel />
    </div>
  );
};

export default Auth;
