import {
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  UserGroupIcon,
  UserIcon,
  ShoppingCartIcon,
  CogIcon,
} from '@heroicons/react/outline';
import { GlobeAltIcon } from '@heroicons/react/solid';

const getNavigationItems = (t: any, flags: any, restrictedItems: string[]) => {
  const menuItems = [
    { name: t('sidebar.home'), path: '/', icon: HomeIcon },
    {
      name: t('sidebar.sell'),
      path: '/checkout',
      icon: ShoppingCartIcon,
      beta: true,
      visible: flags.enableCheckoutModule,
    },
    {
      name: t('sidebar.catalog'),
      path: '/online-catalog',
      icon: GlobeAltIcon,
      visible: flags.enableCheckoutModule,
    },
    { name: t('sidebar.products'), path: '/products', icon: FolderIcon },
    {
      name: t('sidebar.orders'),
      icon: InboxIcon,
      children: [
        {
          name: t('sidebar.openOrders'),
          path: '/open-orders',
        },
        {
          name: t('sidebar.closedOrders'),
          path: '/closed-orders',
        },
      ],
    },
    { name: t('sidebar.customers'), path: '/customers', icon: UserIcon },
    {
      name: t('sidebar.userAccounts'),
      path: '/userAccounts',
      icon: UserGroupIcon,
      visible: flags.enableEmployeeModule,
    },
    {
      name: t('sidebar.analytics'),
      path: '/analytics',
      icon: ChartBarIcon,
      visible: flags.enableAnalyticsModule,
      beta: true,
    },
    {
      name: t('sidebar.settings'),
      path: '/settings',
      icon: CogIcon,
    },
  ];

  return menuItems.filter(
    ({ visible = true, path = '' }) =>
      visible && !restrictedItems.includes(path),
  );
};

export default getNavigationItems;
