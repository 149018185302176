import SubscriptionStatus from './SubscriptionStatus';

const RightSidebar = ({
  visible = true,
  subscriptions = [],
  trialEndDate,
}: any) => {
  if (!visible) {
    return null;
  }

  return (
    <div className="w-1/4 mt-8">
      <SubscriptionStatus
        trialEndDate={trialEndDate}
        subscription={subscriptions.length > 0 ? subscriptions[0] : null}
      />
    </div>
  );
};

export default RightSidebar;
