import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { formatPriceValue } from '@posy/helpers';

const Payments = ({ data }: { data: any }) => {
  const { t } = useTranslation();
  const colors = ['#454A4E', '#56AFB5', '#9d7143', '#3E6D9C'];
  const filtered = data.map((item: any, index: number) => {
    return {
      ...item,
      fill: colors[index],
    };
  });

  const getLabel = (index: number) => t(`paymentMethods.${data[index]._id}`);

  return (
    <div className="bg-white p-4 mt-4 overflow-y-auto">
      <dt className="text-base font-normal text-gray-900 mb-2">
        {t('paymentMethods.title')}
      </dt>
      <ResponsiveContainer width="100%" height={240}>
        <PieChart style={{ margin: 'auto' }}>
          <Pie
            data={filtered}
            dataKey="total"
            nameKey="_id"
            innerRadius={50}
            outerRadius={80}
            label={({ cx, cy, midAngle, innerRadius, outerRadius, index }) => {
              const RADIAN = Math.PI / 180;
              const radius = 25 + innerRadius + (outerRadius - innerRadius);
              const x = cx + radius * Math.cos(-midAngle * RADIAN);
              const y = cy + radius * Math.sin(-midAngle * RADIAN);

              return (
                <text
                  x={x}
                  y={y}
                  fontSize={15}
                  textAnchor={x > cx ? 'start' : 'end'}
                  dominantBaseline="central"
                >
                  {getLabel(index)}
                </text>
              );
            }}
          />
        </PieChart>
      </ResponsiveContainer>

      <div className="grid grid-cols-2 w-2/3 mx-auto gap-4 mt-2">
        {filtered.map((filter: any, index: number) => (
          <div key={index} className="flex items-center">
            <Circle fill={colors[index]} />
            <div className="ml-2">
              <p className="text-xs mb-1">{getLabel(index)}</p>
              <p className="text-xs">{formatPriceValue(filter.total)}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Circle = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: ${({ fill }: { fill: string }) => fill};
`;

export default Payments;
