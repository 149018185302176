import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Field, LoadingDim, useForm } from '@posy/components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FormCard from '../../components/FormCard';
import { useAuth } from '../../Auth/AuthProvider';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import useFormModal from '../../checkout/Checkout/components/useFormModal';
import FormModal from '../../checkout/Checkout/components/FormModal';
import requestDeletion from 'api/requestDeletion';

const Delete = () => {
  const { modalProps, openDeleteModal } = useFormModal();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { user, signOut } = useAuth();
  const { registerField, handleSubmit } = useForm({
    defaultValues: user,
  });

  const deleteModal = (formData: { [key: string]: any }) => {
    openDeleteModal({
      onSubmit: () => onSubmit(formData),
    });
  };

  const onSubmit = (formData: { [key: string]: any }) => {
    setIsLoading(true);
    requestDeletion(user.storeId, formData.reason, formData.comments)
      .then((response) => {
        signOut();
        console.info(response);
      })
      .catch(() => {
        alert('Error, Intente nuevamente');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addComment = [
    {
      label: t('addComment'),
      name: 'comments',
      type: 'paragraph',
      rules: ['required'],
    },
  ];
  const fields = [
    {
      name: 'reason',
      type: 'radio',
      options: [
        { label: t('businessClosing'), value: 'business-end' },
        { label: t('missingFunctionality'), value: 'missing-features' },
        { label: t('subscriptionPrice'), value: 'price' },
        { label: t('difficultUse'), value: 'difficulty' },
        { label: t('betterOption'), value: 'better-option' },
        { label: t('anotherAccount'), value: 'duplicate-account' },
        { label: t('otherReason'), value: 'other' },
      ],
      rules: ['required'],
    },
  ];

  return (
    <div className="px-4 overflow-auto">
      <div className="flex justify-end p-4">
        <div className="flex-1">
          <button
            type="button"
            className="inline-flex text-2xl font-semibold text-gray-900"
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon className="h-8 w-8" aria-hidden="true" />
            {t('deleteUser')}
          </button>
        </div>
      </div>
      <Wrapper>
        <div className={'flex flex-row0  lg:flex-row gap-4'}>
          <div className="flex-col flex-1 text-lg">
            <FormCard title={t('whyYouLeave')}>
              {fields.map((field) => (
                <Field key={field.name} {...registerField(field)} />
              ))}
            </FormCard>
          </div>
          <div className="flex-col flex-1">
            <FormCard>
              {addComment.map((field) => (
                <Field key={field.name} {...registerField(field)} />
              ))}
              <div className="flex justify-end">
                <button
                  className="bg-red-700 text-white font-medium rounded-md px-10 py-2.5 m1-auto"
                  onClick={handleSubmit(deleteModal)}
                >
                  {t('deleteUser')}
                </button>
              </div>
            </FormCard>
          </div>
        </div>
      </Wrapper>
      <LoadingDim isLoading={isLoading} />
      {modalProps.isOpen && <FormModal {...modalProps} />}
    </div>
  );
};

const Wrapper = styled.div`
  grid-gap: 12px;
`;

export default Delete;
