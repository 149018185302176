import { useAuth } from 'Auth/AuthProvider';
import getUserAccounts from 'api/UserAccount/getUserAccounts';
import { useState, useEffect } from 'react';
import { UserAccount } from 'types';

const useUserAccounts = () => {
  const { user } = useAuth();
  const storeId = user?.storeId;
  const [userAccounts, setUserAccounts] = useState<UserAccount[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (storeId) {
      getUserAccounts(storeId).then((accounts: UserAccount[]) => {
        setUserAccounts(accounts);
        setIsLoading(false);
      });
    }
  }, [storeId]);

  return { userAccounts, isLoading };
};

export default useUserAccounts;
