interface Props {
  label: string;
  onClick: () => void;
}

const SubscribeButton = ({ label, onClick }: Props) => {
  return (
    <button
      type="button"
      className="rounded-md bg-indigo-900 ml-4 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default SubscribeButton;
