import { useTranslation } from 'react-i18next';
import { formatData } from '../helpers';
import { UserCircleIcon } from '@heroicons/react/solid';
import Chart from './Chart';

export interface Device {
  title: string;
  subtitle: string;
  isActive: true;
  isAdmin: true;
}

export interface DeviceOverviewProps {
  device: Device;
  inPersonData: any[];
}

const DeviceOverview = (props: DeviceOverviewProps) => {
  const { device, inPersonData } = props;
  const { t } = useTranslation();
  const formattedData = formatData(inPersonData);

  return (
    <div className="bg-white y-4 p-4 my-4">
      <div>
        <div className="flex">
          <div className="flex flex-1">
            <UserCircleIcon className="w-12 h-12 text-gray-400" />
            <div>
              <p className="ml-4">{device.title}</p>
              <p className="ml-4 text-gray-500 text-sm">{device.subtitle}</p>
            </div>
          </div>
          <div>
            {device.isAdmin && (
              <p className="font-medium text-gray-500"> {t('admin')} </p>
            )}
          </div>
        </div>

        <div className="py-8">
          <Chart formattedData={formattedData} />
        </div>
      </div>
    </div>
  );
};

export default DeviceOverview;
