import { getDatabase, ref, query, onValue } from 'firebase/database';

const getProPlan = async (isBaseCountry: boolean, setPlan: any) => {
  const db = getDatabase();
  const basePath = isBaseCountry ? 'plansV2' : 'plansUSD';

  try {
    const storeRef = query(ref(db, `${basePath}/pro`));

    onValue(
      storeRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const data: any = snapshot.val();
          setPlan({
            id: snapshot.key,
            ...data,
          });
        } else {
          setPlan();
        }
      },
      {
        onlyOnce: true,
      },
    );
  } catch (e) {
    console.error(e);
  }
};

export default getProPlan;
