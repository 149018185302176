import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSidePanel, Field, useModal, useForm } from '@posy/components';
import styled from 'styled-components';
import FormCard from '../../components/FormCard';
import { useAuth } from '../../Auth/AuthProvider';
import { ChevronLeftIcon, TrashIcon } from '@heroicons/react/outline';
import deleteCategory from '../../api/categories/deleteCategory';
import { useAnalytics } from '../../providers/AnalyticsProvider';
import { Category } from '../../types';
import Button from 'components/Button';
import { getCategory, createCategory, updateCategory } from 'api/categories';

const CategoryProfile = () => {
  const { t } = useTranslation();
  const { logEvent } = useAnalytics();
  const { navigateTo, params } = useSidePanel();
  const [category, setCategory] = useState<Category | null>();
  const { registerField, handleSubmit } = useForm({
    defaultValues: category,
  });
  const { user } = useAuth();
  const { id } = params;
  const { openModal } = useModal();

  useEffect(() => {
    if (id) {
      loadCategory();
    }
  }, [id]);

  const loadCategory = async () => {
    const category = await getCategory(id);
    setCategory(category);
  };

  const fields = [
    {
      label: t('form.name'),
      name: 'name',
      type: 'text',
      rules: ['required', 'textOnly'],
    },
  ];

  if (!category && id) {
    return null;
  }

  const submit = (values: any) => {
    if (id) {
      updateCategory(id, values);
      logEvent('update_category');
    } else {
      createCategory(values, user);
      logEvent('create_category');
    }
    navigateTo('Categories');
  };

  const requestDeleteCategory = () => {
    deleteCategory(id);
    logEvent('delete_category');
    navigateTo('Categories');
  };

  return (
    <div className="px-4 overflow-auto">
      <div className="flex justify-end p-4">
        <div className="flex-1">
          <button
            type="button"
            className="inline-flex text-2xl font-semibold text-gray-900"
            onClick={() => navigateTo('Categories')}
          >
            <ChevronLeftIcon className="h-8 w-8" aria-hidden="true" />
            {id ? t('editCategory') : t('newCategory')}
          </button>
        </div>
        <Button label={t('save')} onClick={handleSubmit(submit)} />
      </div>
      <Wrapper>
        <div className="flex gap-4">
          <FormCard>
            {fields.map((field) => (
              <Field key={field.name} {...registerField(field)} />
            ))}
          </FormCard>
        </div>
      </Wrapper>
      <div className="flex justify-end  my-4">
        {id && (
          <button
            className="flex items-center text-red-700"
            onClick={() =>
              openModal({
                title: t('modals.delete.title'),
                body: t('modals.delete.body'),
                onConfirm: requestDeleteCategory,
              })
            }
          >
            <TrashIcon className="w-4 h-4 mr-2" />
            {t('deleteCategory')}
          </button>
        )}
      </div>
    </div>
  );
};

const Wrapper = styled.div`
  grid-gap: 12px;
`;

export default CategoryProfile;
