import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/outline';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const ActionMenu = ({
  items,
  bordered,
}: {
  items: any[];
  bordered?: boolean;
}) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button
        className={`flex items-center text-gray-600 ${
          bordered && 'border'
        } border-gray-400 p-3`}
      >
        <span className="sr-only">Open options</span>
        <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {items.map(
              ({ label, Icon, onClick, visible = true }: any) =>
                visible && (
                  <Menu.Item key={label}>
                    {({ active }) => (
                      <a
                        onClick={onClick}
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'group flex items-center px-4 py-2 text-sm',
                        )}
                      >
                        <Icon
                          className="mr-3 h-5 w-5 text-gray-800 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        {label}
                      </a>
                    )}
                  </Menu.Item>
                ),
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ActionMenu;
