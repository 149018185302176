import { useState } from 'react';
import styled from 'styled-components';
import ModalTemplate from './ModalTemplate';
import VariantItem from './VariantItem';
import { useTranslation } from 'react-i18next';

const VariationSelection = ({
  product,
  productName,
  productFormat,
  variations = {},
  locationId,
  isOpen,
  onClose,
  onSubmit,
}: {
  product: any;
  productName: string;
  productFormat: string;
  variations: any;
  isOpen: boolean;
  locationId: string;
  onClose: () => void;
  onSubmit: (selectedItems: any) => void;
}) => {
  const { t } = useTranslation();

  const [selectedItems, setSelectedItems] = useState<any>({});

  const closeModal = () => {
    onClose();
    setSelectedItems({});
  };

  const onChange = (id: string, quantity: number) => {
    const formattedQuantity = formatQuantity(quantity, productFormat);

    setSelectedItems((state: any) => {
      return { ...state, [id]: formattedQuantity };
    });
  };

  return (
    <ModalTemplate title={t('cart.add')} isOpen={isOpen} onClose={closeModal}>
      <>
        <Wrapper>
          {Object.entries(variations).map(([id, value]: any) => (
            <VariantItem
              key={id}
              quantity={selectedItems[id]}
              productFormat={productFormat}
              product={product}
              locationId={locationId}
              item={{
                id,
                name: value.name || productName,
                price: value.salePrice || value.price,
                thumbnail: value.thumbnail,
                trackQty: value.trackQty,
                format: productFormat,
              }}
              onChange={onChange}
            />
          ))}
        </Wrapper>
        <div className="flex justify-end">
          <button
            className="bg-indigo-700 px-3 py-2 text-white rounded-md"
            onClick={() => {
              onSubmit(selectedItems);
              closeModal();
            }}
          >
            {t('add')}
          </button>
        </div>
      </>
    </ModalTemplate>
  );
};

const formatQuantity = (quantity: number, format: string) => {
  const isFraction = format === 'fraction';
  const decimalPlaces = isFraction ? 3 : 0;
  return Number(quantity.toFixed(decimalPlaces));
};

const Wrapper = styled.div`
  max-height: 380px;
  overflow-y: scroll;
`;

export default VariationSelection;
