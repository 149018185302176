import { useTranslation } from 'react-i18next';
import { useSidePanel } from '@posy/components';
import {
  ChevronLeftIcon,
  CashIcon,
  CreditCardIcon,
  ViewGridIcon,
  SwitchVerticalIcon,
  PencilIcon,
} from '@heroicons/react/outline';

import { useAnalytics } from '../../providers/AnalyticsProvider';
import { formatPriceValue } from '../../helpers';
import payOrder from '../../api/payOrder';
import { useState } from 'react';
import Balance from './components/Balance';
import createOrder from '../../api/createOrder';
import useLocations from 'hooks/useLocations';
import { useAuth } from 'Auth/AuthProvider';

const methods = ['cash', 'card', 'transfer', 'other'];

interface IconProps {
  className: string;
}

const methodIcons: { [iconName: string]: React.ComponentType<IconProps> } = {
  cash: CashIcon,
  card: CreditCardIcon,
  transfer: SwitchVerticalIcon,
  other: ViewGridIcon,
};

const Payment = () => {
  const { t } = useTranslation();
  const { navigateTo, params } = useSidePanel();
  const { user } = useAuth();
  const { logEvent } = useAnalytics();
  const { locations } = useLocations();
  const defaultLocation: any = locations.find(
    (location: any) => location.default,
  );
  const defaultLocationId = defaultLocation?.id;
  const { order, toPay, editedAmount, method, onFinish } = params;
  const [selectedMethod, setSelectedMethod] = useState(method || 'cash');
  const [amount, setAmount] = useState(editedAmount || toPay);
  const unpaid = toPay - amount;
  const isInDefaultLocation = defaultLocationId === order.locationId;

  const goToEditPayment = () => {
    navigateTo(`EditPayment`, {
      order,
      toPay,
      amount,
      method: selectedMethod,
      onFinish,
    });
  };

  const submitPayment = () => {
    let commitId = order.id;

    if (order.id) {
      payOrder(
        order.id,
        unpaid,
        {
          method: selectedMethod,
          amount,
        },
        user,
      );
    } else {
      const paymentDraft = { method: selectedMethod, amount };
      const response = createOrder(
        order,
        unpaid,
        paymentDraft,
        isInDefaultLocation,
      );
      commitId = response.id;
      if (unpaid === 0) {
        logEvent('create_completed_order');
      }
    }
    const finalOrder = { ...order, id: commitId };
    onFinish && onFinish();

    navigateTo(`PaymentCompleted`, {
      order: finalOrder,
      toPay,
      amount,
      method: selectedMethod,
    });
  };

  const submitWithNoPayment = () => {
    createOrder(order, 1, undefined, isInDefaultLocation);
    onFinish && onFinish();
    logEvent('create_confirmed_order');
    navigateTo(`PaymentCompleted`, { order, toPay, amount: 0, payLater: true });
  };

  const selectMethod = (method: string) => {
    setSelectedMethod(method);
    setAmount(toPay);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="px-4 pt-6 flex mb-4">
        <div className="flex-1">
          <button
            type="button"
            className="inline-flex text-2xl font-semibold text-gray-900"
            onClick={() => navigateTo('')}
          >
            <ChevronLeftIcon className="h-8 w-8 " aria-hidden="true" />
            {t('payment')}
          </button>
        </div>
      </div>

      <div className="flex flex-col flex-1 justify-center items-center">
        <p className="text-lg font-medium tracking-normal text-gray-500 ">
          {t('receivable')}
        </p>
        <button className="items-center" onClick={goToEditPayment}>
          <div className="mt-2 flex items-center justify-center text-2xl font-bold tracking-tight text-gray-900">
            <span>{formatPriceValue(amount)}</span>
            <PencilIcon className="w-4 h-4 ml-2" />
          </div>
          <Balance amount={unpaid} />
        </button>
      </div>
      <div>
        <div className="grid grid-cols-3 gap-4 m-4">
          {methods.map((method) => {
            const isSelected = method === selectedMethod;
            const Icon = methodIcons[method];

            return (
              <button
                key={method}
                className={`flex flex-col items-center`}
                onClick={() => selectMethod(method)}
              >
                <Icon
                  className={`w-10 h-10 ${isSelected && 'text-indigo-700'}`}
                />
                <p
                  className={`text-l font-medium tracking-normal ${
                    isSelected && 'text-indigo-700 font-bold'
                  }`}
                >
                  {t(`paymentMethods.${method}`)}
                </p>
              </button>
            );
          })}
        </div>
        <div className="mt-8 px-4 pt-6 flex flex-col justify-center">
          <button
            className="bg-indigo-700 text-white font-medium rounded-md w-full px-4 py-2"
            onClick={submitPayment}
          >
            {t('collectNow')}
          </button>
          {order.id ? null : (
            <button
              className="px-2 py-3 mt-2 text-indigo-700 text-center"
              onClick={submitWithNoPayment}
            >
              {t('collectLater')}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Payment;
