import { useTranslation } from 'react-i18next';
import { useEffect, useState, useRef } from 'react';
import { sanitizeString } from '@posy/helpers';
import _ from 'lodash';

const useForm = ({ defaultValues }: { defaultValues: any }): any => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState<any>();
  const [formState, setFormState] = useState<any>({
    errors: {},
    touched: [],
  });
  const _fields = useRef<any>({});
  const numberOfFields = Object.keys(_fields.current).length;

  const setField = (path: string, fieldValue: string) => {
    _.set(formValues, path, fieldValue);
    setFormValues({ ...formValues });
  };

  useEffect(() => {
    const finalValues = Object.keys(_fields.current).reduce((obj, key) => {
      const value = _.get(_.cloneDeep(defaultValues), key, undefined);
      const defaultValue = _.get(_fields.current, `${key}.default`, undefined);
      const formValue = _.get(_.cloneDeep(formValues), key, undefined);

      if (
        !_.isUndefined(formValue) ||
        !_.isUndefined(value) ||
        !_.isUndefined(defaultValue)
      ) {
        const initialValue = _.isUndefined(value) ? defaultValue : value;
        const finalValue = formValue || initialValue;

        return _.setWith(obj, key, finalValue, Object);
      } else {
        return obj;
      }
    }, {});

    setFormValues(finalValues);
  }, [defaultValues, numberOfFields]);

  const onChange = (e: any) => {
    const { name, value } = e.target;
    const field = _fields.current[name];
    const error = validateField(value, field.rules, field.validate, name);
    const formattedValue = field.onFormatValue
      ? field.onFormatValue({ value, setField })
      : value;
    setFormState((formState: any) => ({
      ...formState,
      errors: { ...formState.errors, [name]: error },
    }));
    setField(name, formattedValue);
  };

  const onFocus = () => {};
  const onBlur = (e: any) => {
    const { name } = e.target;
    setFormState((formState: any) => ({
      ...formState,
      touched: [...formState.touched, name],
    }));
  };

  const register = (field: any) => {
    _fields.current = { ..._fields.current, [field.name]: field };
  };

  const unregister = (name: string) => {
    // eslint-disable-next-line no-unused-vars
    const { [name]: remove, ...rest } = _fields.current;
    _fields.current = rest;
  };

  const registerFieldArray = (fieldName: string) => {
    _fields.current = { ..._fields.current, [fieldName]: true };
    return formValues?.[fieldName] || [];
  };

  const registerField = (field: any) => {
    const error = formState.errors[field.name];
    return {
      value: _.get(formValues, field.name, ''),
      ...field,
      onChange,
      onBlur,
      onFocus,
      errorMessage: error?.message,
      ref: (ref: any) => {
        if (ref) {
          register(field);
        } else {
          unregister(field.name);
        }
      },
    };
  };

  const isEmpty = (value: string) => value.length === 0;
  const isEmail = (value: string) =>
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
  const isNumber = (value: string) =>
    value && isNaN(Number(value)) ? 'Debe ser un número' : undefined;
  const charactersOnly = (value: string) =>
    !/^([a-zA-Z]+\s)*[a-zA-Z]+$/.test(value);
  const hasDecimals = (values: string) => values.includes('.');
  const lesserOrEqualToZero = (values: string) => Number(values) <= 0;

  const validateField = (
    value: string,
    rules: any[] = [],
    validate: any,
    key: string,
  ) => {
    if (typeof value === 'boolean') {
      return undefined;
    }
    const defaultValue = _.get(defaultValues, key, null);
    const input = sanitizeString(value);

    if (isEmpty(input)) {
      if (rules.includes('required')) {
        return {
          type: 'required',
          message: t('validationMessages.required'),
        };
      } else if (validate) {
        const error = validate(value, formValues, defaultValue);

        return error || undefined;
      }
    } else {
      if (rules.includes('number') && isNumber(input)) {
        return {
          type: 'number',
          message: t('validationMessages.number'),
        };
      } else if (rules.includes('integer') && hasDecimals(input)) {
        return {
          type: 'integer',
          message: t('validationMessages.integer'),
        };
      } else if (
        rules.includes('greaterThanZero') &&
        lesserOrEqualToZero(input)
      ) {
        return {
          type: 'greaterThanZero',
          message: t('validationMessages.greaterThanZero'),
        };
      } else if (rules.includes('email') && isEmail(input)) {
        return {
          type: 'email',
          message: t('validationMessages.email'),
        };
      } else if (rules.includes('textOnly') && charactersOnly(input)) {
        return {
          type: 'text',
          message: t('validationMessages.textOnly'),
        };
      } else if (validate) {
        const error = validate(value, formValues, defaultValue);

        return error || undefined;
      }
    }
  };
  const getField = (fieldName: any) => {
    return _.get(formValues, fieldName, null);
  };

  const validateAllFields = () => {
    return Object.entries(_fields.current).reduce((obj, [key, field]: any) => {
      const fieldExists = !!_.get(_fields.current, key, null);
      const value = _.get(formValues, key, null);
      const error = validateField(value, field.rules, field.validate, key);
      if (error && fieldExists) {
        return {
          ...obj,
          [key]: error,
        };
      } else {
        return obj;
      }
    }, {});
  };

  const filterEmptyData = (values: any) => {
    const data = _.cloneDeep(values);
    Object.keys(_fields.current).forEach((path) => {
      const fieldValue = _.get(data, path, null);

      if (fieldValue === null) {
        const basePath = getBasePath(path);
        _.unset(data, basePath);
      } else {
        _.set(data, path, fieldValue);
      }
    }, {});

    return data;
  };

  const getBasePath = (path: string): any => {
    const arr = path.split('.');
    const baseArr = arr.slice(0, arr.length - 1);
    const basePath = baseArr.join('.');
    const value = _.get(formValues, basePath, null);

    if (_.isObject(value) && Object.keys(value).length === 1) {
      return getBasePath(basePath);
    } else {
      return path;
    }
  };

  const handleSubmit = (onSubmit: any) => (e: any) => {
    e.preventDefault();
    const errors = validateAllFields();
    setFormState((formState: any) => ({ ...formState, errors }));
    if (Object.entries(errors).some(([_, value]) => value)) {
      // render error messages
      console.log('Submit error', { errors });
    } else {
      onSubmit(formValues);
    }
  };

  return {
    formValues,
    setFormValues,
    registerField,
    setField,
    getField,
    handleSubmit,
    registerFieldArray,
  };
};

export default useForm;
