import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useOnSnapshot from '../../components/useOnSnapshot';
import { LoadingDim, Image, Table, useSidePanel } from '@posy/components';
import { useAnalytics } from 'providers/AnalyticsProvider';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import { useAuth } from '../../Auth/AuthProvider';
import { getBadgeClasses } from './helpers';
import useLocations from '../../hooks/useLocations';
import { useProviderProducts } from '../../providers/ProductsProvider';
import Button from '../../components/Button';
import PageHeader from '../../components/PageHeader';
import { getStockLevel } from '@posy/products';
import { Product as ProductType } from 'types';
import generateProductsReport from './generateReport';

const Product = () => {
  const { t } = useTranslation();
  const { user, hasPermission } = useAuth();
  const { locations } = useLocations();
  const [locationId, setLocationId] = useState('');
  const { products, isLoading } = useProviderProducts();
  const formattedProducts = useMemo(
    () => formatProductsByLocation({ products, locationId }),
    [locationId, products],
  );
  const { data: categories } = useOnSnapshot('categories');
  const { isOpen } = useSidePanel();
  const { navigateTo } = useSidePanel();
  const { enableStockSidepanel, enableProductsExport } = useFeatureFlags();
  const navigate = useNavigate();
  const { logEvent } = useAnalytics();

  const onStockBadgeClick = (product: any) => (e: any) => {
    e.stopPropagation();
    const variations = product.variations;
    const hasVariations = Object.keys(product.variations).length > 1;
    if (hasVariations) {
      navigateTo('InventorySelection', {
        product,
        variations,
        locationId,
      });
    } else {
      const variationId = Object.keys(variations)[0];
      const variation = variations[variationId];
      navigateTo('InventoryMovements', {
        variationId,
        variation,
        productName: product.name,
        product,
        locationId,
        maxWidth: '36rem',
      });
    }
  };

  const columns = [
    {
      key: 'name',
      label: t('products.item'),
      isSortActive: true,
      render: (row: any) => (
        <div className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
          <div className="flex items-center">
            <div className="h-10 w-10 flex-shrink-0">
              <Image
                className="h-10 w-10"
                src={row?.thumbnail}
                alt="Product logo"
              />
            </div>
            <div className="ml-4">
              <div className="font-medium text-gray-900">{row?.name}</div>
            </div>
          </div>
        </div>
      ),
    },
    { key: 'categoryName', label: t('products.category'), isSortActive: true },
    { key: 'price', label: t('products.price') },
    {
      key: 'available',
      label: t('products.stock'),
      isSortActive: true,
      render: (row: any) => {
        const { tracked, available, minStock } = row;
        const badgeClasses = getBadgeClasses(available, minStock);

        return (
          <div
            className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 cursor-pointer"
            onClick={enableStockSidepanel && onStockBadgeClick(row)}
          >
            {tracked ? (
              <span
                className={` inline-flex rounded-full ${badgeClasses} px-2 text-xs font-semibold leading-5 `}
              >
                {available}
              </span>
            ) : null}
          </div>
        );
      },
    },
  ];

  const searchFields = [{ label: t('form.name'), value: 'name' }];
  return (
    <>
      <PageHeader title={t('products.items')}>
        {enableProductsExport && (
          <Button
            isVisible={hasPermission('CUSTOMER_UPDATE')}
            label={t('exportProducts')}
            onClick={() => {
              generateProductsReport(products);
              logEvent('export_products');
            }}
            bordered
          />
        )}
        <Button
          isVisible={false}
          bordered
          label={'Subir productos'}
          onClick={() => navigateTo(`UploadProducts`)}
        />
        <Button
          bordered
          label={t('categories')}
          onClick={() => navigateTo(`Categories`)}
        />
        <Button
          isVisible={hasPermission('PRODUCT_UPDATE')}
          label={t('products.createItem')}
          onClick={() => navigate(`/products/new`)}
        />
      </PageHeader>
      <Table
        storageKey={`products.${getStorageId(user)}`}
        columns={columns}
        rows={formattedProducts}
        onRowClick={(rowId: string) => navigate(`/products/${rowId}`)}
        isLoading={isLoading}
        searchFields={searchFields}
        filters={[
          {
            label: t('products.category'),
            name: 'categoryId',
            options: categories.map(({ id, name }) => ({ id, name })),
          },
          {
            label: 'Todas las ubicaciones',
            name: 'locationId',
            options: locations,
            onChange: (value: string) => {
              setLocationId(value);
            },
            disable: true,
          },
        ]}
        style={{
          height: '100%',
          background: 'white',
          margin: 16,
        }}
      />
      <LoadingDim isLoading={isOpen} />
    </>
  );
};

const getStorageId = (user: any): string => {
  const { id: userId, store } = user;

  return `${store?.id.slice(0, 4)}-${userId.slice(0, 4)}`;
};

const formatProductsByLocation = ({
  products,
  locationId,
}: {
  products: ProductType[];
  locationId: string;
}) => {
  return products.map((product: ProductType) => {
    const { tracked, available } = getStockLevel({ locationId, product });

    return { ...product, tracked, available };
  });
};

export default Product;
