import { getFirestore, doc, updateDoc } from 'firebase/firestore';

const refundOrder = async (order: any) => {
  const payload = {
    orderId: order.id,
    storeId: order.storeId,
    amount: order.total,
    email: order.customer?.email,
    reference: order.payments[0].transactionCode,
  };

  const response = await fetch(`https://${getHost()}/api/refund/request`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  const data = await response.json();

  if (!data.code) {
    const ref = doc(getFirestore(), 'orders', order.id);
    updateDoc(ref, {
      ['refund.status']: 'pending',
    });
  } else {
    alert(data.message);
  }
};

const getHost = () => {
  return process.env.REACT_APP_ENV === 'PRODUCTION'
    ? 'myposyshop.com'
    : 'staging-myposyshop.com';
};

export default refundOrder;
