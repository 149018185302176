interface Props {
  hasPermission: (permission: string) => boolean;
}

const usePermissions = ({ hasPermission }: Props) => {
  const canUpdateSettings = hasPermission('SETTINGS_UPDATE');
  return {
    shouldShowSaveButton: canUpdateSettings,
  };
};

export default usePermissions;
