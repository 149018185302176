import { doc, getFirestore, collection, writeBatch } from 'firebase/firestore';
import { setInventory } from '@posy/products';
import getPayload from './getPayload';

const createProduct = async (
  values: any,
  user: { id: string; name: string; storeId: string },
  defaultLocationId: string,
) => {
  try {
    const db = getFirestore();
    const batch = writeBatch(db);
    const productRef = doc(collection(db, 'products'));
    const createdAt = new Date().getTime();

    const payload = getPayload({
      values,
      productId: productRef.id,
      getVariationId: () => doc(collection(db, 'variations')).id,
      user,
      createdAt,
      defaultLocationId,
    });

    if (payload.locations) {
      setInventory({
        locations: payload.locations,
        user,
        createdAt,
        batch,
        getVariationRef: getVariationRef(db),
        getMovementRef,
      });
    }

    Object.entries(payload.variations).forEach(([, variationPayload]: any) => {
      const variationRef = doc(db, 'variations', variationPayload.id);
      batch.set(variationRef, variationPayload);
    });

    batch.set(productRef, payload);
    await batch.commit();
  } catch (e) {
    console.error(e);
  }
};

const getVariationRef = (db: any) => (variationId: string) => {
  return doc(db, 'variations', variationId);
};

const getMovementRef = (variationRef: any) => {
  return doc(collection(variationRef, 'movements'));
};

export default createProduct;
