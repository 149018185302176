import { addDoc, getFirestore, collection } from 'firebase/firestore';
import { collectionName } from './constants';

const createCategory = (values: any, user: any) => {
  addDoc(collection(getFirestore(), collectionName), {
    ...values,
    storeId: user.storeId,
    uid: user.id,
    createdAt: new Date().getTime() * 1000,
  });
};

export default createCategory;
