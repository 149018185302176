import { Image } from '@posy/components';
import { getProductDisplayPrice } from '../../../product/Products/helpers';
import { StarIcon } from '@heroicons/react/solid';
import styled from 'styled-components';
import StockLabel from './StockLabel';

interface Props {
  item: any;
  selectItem: (item: any) => void;
  tracked: boolean;
  available: number;
}

const ItemCard = ({ item, tracked, available, selectItem }: Props) => {
  const priceLabel = getProductDisplayPrice(item.variations);

  return (
    <Container
      key={`${item.variationId}-${item.productId}`}
      title={item.name}
      className="bg-gray-300 rounded-md relative cursor-pointer"
      onClick={() => selectItem(item)}
    >
      <div className="absolute w-full h-full">
        {item.isHighlighted && (
          <StarIcon className="w-4 h-4 m-2 text-amber-500 absolute" />
        )}
        {item.thumbnail ? (
          <Image src={item.thumbnail} alt="Product logo" />
        ) : (
          <TitleBadge name={item.name} />
        )}
      </div>
      <div className="flex flex-col z-10 h-full absolute w-full justify-end">
        <div className="pl-2 pb-1 pt-1 bg-gray-200 opacity-90">
          <Title className="line-clamp-1">{item.name}</Title>
          <div className="flex items-center">
            <PriceLabel className="text-sm font-medium flex-1">
              {priceLabel}
            </PriceLabel>
          </div>
          {tracked && (
            <StockLabel available={available} minStock={item.minStock} />
          )}
        </div>
      </div>
    </Container>
  );
};

const TitleBadge = ({ name }: { name: string }) => (
  <div className="flex justify-center items-center h-full">
    <p className="font-bold text-gray-600 text-xl">{name.slice(0, 2)}</p>
  </div>
);

const Container = styled.div`
  width: 176px;
  height: 176px;
  overflow: hidden;
`;

const Title = styled.p`
  font-size: 13px;
`;

const PriceLabel = styled.p`
  font-size: 13px;
  font-weight: medium;
`;

export default ItemCard;
