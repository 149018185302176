import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getOverviewData from '../api/getOverviewData';
import getStore from '../api/getStore';
import { useAuth } from '../Auth/AuthProvider';
import CatalogOverview from '../Home/components/CatalogOverview';

const Catalog = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [store, setStore] = useState<any>();
  const [overviewData, setOverviewData] = useState<any>();

  useEffect(() => {
    const storeId = user?.storeId;
    if (storeId) {
      fetchStore();
      getOverviewData(storeId, (data: any) => setOverviewData(data));
    }
  }, [user?.storeId]);

  const fetchStore = async () => {
    try {
      const storeSnapshot = await getStore(user.storeId);
      setStore({ id: storeSnapshot.id, ...storeSnapshot.data() });
    } catch (e) {
      console.error(e);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <>
      <div className="px-4 pt-6 flex mb-4">
        <div className="flex-1">
          <h1 className="text-2xl font-semibold text-gray-900">
            {t('onlineCatalog')}
          </h1>
        </div>
      </div>
      <div className="px-4">
        <CatalogOverview store={store} onlineData={overviewData?.onlineData} />
      </div>
    </>
  );
};

export default Catalog;
