import { getAuth } from 'firebase/auth';

interface OrderUser {
  name: string;
  thumbnail?: string;
}

interface OrderTimeline {
  status: string;
  createdAt: number;
  user: OrderUser;
  uid?: string;
}

const formatVariationInput = (variation: any, format: string) => {
  return {
    ...variation,
    name: variation.name || '',
    price: Number(variation.price),
    ...(variation.quantity
      ? {
          trackQty: true,
          quantity: formatQuantity(variation.quantity, format),
          ...(variation.minQuantity
            ? { minQuantity: Number(variation.minQuantity) }
            : {}),
        }
      : {}),
    ...(variation.minQuantity
      ? { minQuantity: Number(variation.minQuantity) }
      : {}),
  };
};

const formatQuantity = (quantity: string, format: string): number => {
  if (format === 'fraction') {
    return parseFloat(quantity);
  } else {
    return Number(quantity);
  }
};

const getCurrentTime = (): number => {
  return new Date().getTime();
};

const getUserPayload = (userInput: OrderUser | null): OrderUser => {
  return {
    name: userInput?.name || '',
  };
};

const getTimelinePayload = (status: string, userInput: any): OrderTimeline => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const user = getUserPayload(userInput);

  return {
    user,
    uid: currentUser?.uid,
    createdAt: getCurrentTime(),
    status,
  };
};

const getUpdatedQuantity = (action: string, quantity: number): number => {
  const obj: any = {
    reception: quantity,
    return: quantity,
    shrinkage: -quantity,
    order: -quantity,
  };

  return obj[action];
};

export {
  formatVariationInput,
  getTimelinePayload,
  getCurrentTime,
  getUpdatedQuantity,
};
