import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

const MobileModal = ({ isOpen, onClose }: any) => {
  const { t } = useTranslation();

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-2xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="md:flex md:items-start h-full">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full h-full">
                    <div className="mt-2 flex flex justify-center w-full h-full">
                      <div className="w-1/2 h-full flex flex-col">
                        <div className="flex-1 h-full">
                          <div>
                            <Dialog.Title
                              as="h3"
                              className="text-lg leading-6 font-medium text-gray-900"
                            >
                              {t('downloadAppTitle')}
                            </Dialog.Title>
                            <p className="text-gray-600">
                              {t('downloadAppMessage')}
                            </p>
                          </div>

                          <img
                            src="/qrcode.svg"
                            style={{
                              width: 160,
                              height: 160,
                              margin: 'auto',
                              marginTop: 24,
                            }}
                          />
                        </div>

                        <div className="flex items-center w-full mt-8">
                          <img src="/google-store.png" className="h-16" />
                          <img src="/apple-store.svg" className="h-11" />
                        </div>
                      </div>
                      <div className="w-1/2">
                        <img
                          src="/app-preview.png"
                          style={{
                            position: 'absolute',
                            width: 240,
                            height: 480,
                            top: -6,
                            right: -12,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MobileModal;
