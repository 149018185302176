const formatUserAccount = (snapshot: any) => {
  const { name, email, active, employee, permissions, locationId } =
    snapshot.data();

  return {
    id: snapshot.id,
    name,
    email,
    active,
    employee,
    permissions,
    locationId,
  };
};

export default formatUserAccount;
