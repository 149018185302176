import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import UserDropdown from './UserDropdown';
import { useTranslation } from 'react-i18next';
import useFeatureFlags from '../hooks/useFeatureFlags';
import { useAuth } from '../Auth/AuthProvider';
import { getRestrictedItems, hasPermissionToVisit } from './helpers';
import getNavigationItems from './getNavigationItems';
import MobileModal from '../MobileModal';
import { useAnalytics } from '../providers/AnalyticsProvider';
import ProBanner from './ProBanner';
import MarketingModal from 'components/MarketingModal';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import moment from 'moment';

export interface LayoutProps {
  path: string;
  children: React.ReactNode;
}

const shouldDisplayMobileModal = false;

const Layout = (props: LayoutProps) => {
  const navigate = useNavigate();
  const { logEvent } = useAnalytics();
  const { pathname } = useLocation();
  const { user, hasPermission } = useAuth();
  const { t } = useTranslation();
  const flags = useFeatureFlags();
  const [isOpen, setIsOpen] = useState(false);
  const restrictedPaths = user?.isAdmin
    ? []
    : getRestrictedItems(hasPermission);
  const isUserAllowed = hasPermissionToVisit(
    pathname,
    restrictedPaths,
    user?.isAdmin,
  );
  const navigation = user?.isSubscriptionActive
    ? getNavigationItems(t, flags, restrictedPaths)
    : [];
  const isLargeScreen = useIsLargeScreen();
  const shouldDisplayBanner =
    user?.plan === 'trial' && pathname !== '/pricing' && isLargeScreen;

  useEffect(() => {
    if (pathname === '/' && !user?.isAdmin) {
      navigate('checkout', { replace: true });
    }
  }, [pathname]);

  useEffect(() => {
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'jtyw2blm',
      user_id: user.id,
      name: user.name, // Full name
      email: user.email, // the email for your user
      created_at: moment(user.createdAt).unix(), // Signup date as a Unix timestamp
      plan: user.plan,
      store_id: user.storeId,
      user_count: Object.keys(user?.store.users).length,
    });
  }, []);

  return (
    <div className="flex">
      <div className="hidden md:flex md:w-64 md:flex-col md:inset-y-0">
        <div className="flex flex-col flex-grow pt-5 bg-slate-700 overflow-y-auto">
          <div className="flex items-center flex-shrink-0 px-4">
            <img className="h-14 w-auto" src="/posy_icon.png" alt="Posy Logo" />
          </div>
          <div className="mt-5 flex-1 flex flex-col">
            <nav className="flex-1 px-2 pb-4 space-y-1">
              {navigation.map((item) => (
                <div
                  key={item.name}
                  onClick={() => item.path && navigate(item.path)}
                  className={classNames(
                    props.path === item.path
                      ? 'bg-indigo-800 text-white'
                      : 'text-indigo-100 hover:bg-indigo-600',
                    'group flex-col flex justify-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer',
                  )}
                >
                  <div className="flex items-center">
                    <item.icon
                      className="mr-3 flex-shrink-0 h-6 w-6 text-indigo-300"
                      aria-hidden="true"
                    />
                    {item.name}
                    {item.beta && <Badge />}
                  </div>

                  {item.children && (
                    <div className="flex flex-col px-8 py-2">
                      {item.children.map(({ name, path }) => (
                        <a
                          key={path}
                          className={classNames(
                            props.path === path
                              ? 'bg-indigo-800 text-white'
                              : 'text-indigo-100 hover:bg-indigo-600',
                            'group flex-col flex justify-center px-2 py-2 text-sm font-medium rounded-md',
                          )}
                          onClick={() => navigate(path)}
                        >
                          {name}
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1 h-screen bg-gray-100">
        <div className="flex h-16 bg-white shadow">
          <div className="flex-1 px-4 flex justify-between">
            <ProBanner
              visible={shouldDisplayBanner && isLargeScreen}
              onSubscribeClick={() => navigate('/pricing')}
            />
            <div className="flex-1 flex"></div>
            {shouldDisplayMobileModal && (
              <button
                className={classNames(
                  'text-white bg-gradient-to-r from-indigo-600 to-purple-700 hover:bg-black-600 m-2 py-2 px-6 border border-transparent rounded-md text-center font-medium',
                )}
                onClick={() => {
                  setIsOpen(true);
                  logEvent('open_download_app_modal');
                }}
              >
                {t('downloadAppButton')}
              </button>
            )}

            <div className="ml-4 flex items-center md:ml-6">
              <UserDropdown />
            </div>
          </div>
        </div>
        {isUserAllowed ? props.children : <RoleRestrictionMessage />}
        <MobileModal
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
        />
      </div>
      <MarketingModal visible={shouldDisplayBanner} />
    </div>
  );
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const RoleRestrictionMessage = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center h-screen w-full">
      <p className="text-lg font-medium text-gray-900">
        {t('restrictionMessage.screen')}
      </p>
    </div>
  );
};

const Badge = () => {
  return (
    <div
      className="px-3 py-1 rounded-md ml-4"
      style={{
        backgroundColor: '#6244bb',
      }}
    >
      <p className="text-xs font-bold">Beta</p>
    </div>
  );
};

export default Layout;
