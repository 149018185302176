import { useSidePanel } from '@posy/components';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from '@react-pdf/renderer';

import { useAuth } from 'Auth/AuthProvider';
import moment from 'moment-timezone';
import { priceFormatter } from '@posy/helpers';
import { useTranslation } from 'react-i18next';
import { ChevronLeftIcon } from '@heroicons/react/outline';

const { toPriceTag, getItemGross } = priceFormatter;

const Receipt = () => {
  const { t } = useTranslation();
  const { params, navigateTo } = useSidePanel();
  const { user } = useAuth();
  const order = params.order;
  const isCheckout = params.isCheckout;

  if (!order) {
    return null;
  }

  return (
    <>
      <div className="px-4 overflow-auto">
        <div className="flex justify-end p-4">
          <div className="flex-1">
            <button
              type="button"
              className="inline-flex text-2xl font-semibold text-gray-900"
              onClick={() => navigateTo(isCheckout ? 'PaymentCompleted' : '')}
            >
              <ChevronLeftIcon className="h-8 w-8" aria-hidden="true" />
              {t('digitalReceipt')}
            </button>
          </div>
        </div>
      </div>
      <PDFViewer height="100%">
        <Document>
          <Page size="A4" style={styles.page}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <BusinessBlock store={user?.store} />
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                }}
              >
                <Text style={{ fontSize: 22, fontWeight: 400 }}>
                  {order?.name}
                </Text>
                <Text style={{ fontSize: 32, fontWeight: 'bold' }}>
                  {toPriceTag(order?.total)}
                </Text>
                <Text
                  style={{ fontSize: 18, fontWeight: 'bold', marginTop: 2 }}
                >
                  {moment(order?.createdAt).format('DD/MM/YY HH:MM')}
                </Text>
              </View>
            </View>

            <View style={{ marginVertical: 12, flexDirection: 'row' }}>
              <CustomerBlock customer={order?.customer} />
              <DeliveryBlock delivery={order?.delivery} />
            </View>

            <View style={{ marginVertical: 14 }}>
              {Object.entries(order.items).map(([id, item]: any) => (
                <View
                  key={id}
                  style={{ marginVertical: 8, flexDirection: 'row' }}
                >
                  <View style={{ flex: 1, marginRight: 8 }}>
                    <Text>{item.name}</Text>
                    <Text style={{ fontSize: 16, marginTop: 2 }}>{`${toPriceTag(
                      item.price,
                    )} x ${item.quantity}`}</Text>
                  </View>
                  <Text style={{ marginLeft: 24 }}>
                    {toPriceTag(getItemGross(item))}
                  </Text>
                </View>
              ))}
            </View>

            <View style={{ marginVertical: 12 }}>
              <ResumeItem
                visible={!order.discount}
                label={t('order.subtotal')}
                value={order.subtotal}
              />
              <ResumeItem
                visible={!order.discount}
                label={t('order.discount')}
                value={order.discount}
              />
              <ResumeItem label={t('order.total')} value={order.total} />
            </View>

            {Object.entries(order.payments || {}).map(
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              ([id, item]: any, index: number) => (
                <PaymentItem
                  key={index}
                  label={t(`paymentMethods.${item.method}`)}
                  value={item.amount}
                />
              ),
            )}

            {order.notes && (
              <View style={{ marginTop: 24 }}>
                <Text style={{ marginBottom: 8 }}>Notas del pedido:</Text>
                <Text>{order.notes}</Text>
              </View>
            )}
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
};

const ResumeItem = ({ visible = true, label, value }: any) => {
  if (!visible) {
    return null;
  }

  return (
    <View style={{ flexDirection: 'row', marginVertical: 2 }}>
      <View style={{ flex: 1, marginRight: 8 }}>
        <Text>{label}</Text>
      </View>
      <Text>{toPriceTag(value)}</Text>
    </View>
  );
};

const PaymentItem = ({ visible = true, label, value }: any) => {
  if (!visible) {
    return null;
  }

  return (
    <View style={{ flexDirection: 'row', marginVertical: 2 }}>
      <View style={{ flex: 1, marginRight: 8 }}>
        <Text>{label}</Text>
      </View>
      <Text>{toPriceTag(value)}</Text>
    </View>
  );
};

const DeliveryBlock = ({ delivery }: any) => {
  const { t } = useTranslation();
  const shippingAddress = delivery?.shippingAddress || {};
  const { address, city, state } = shippingAddress;
  const type = delivery?.type;
  const isShipping = type === 'shipping';
  const style = { marginTop: 2 };

  if (!delivery) {
    return null;
  }

  return (
    <View style={{ alignItems: 'flex-end' }}>
      <Text style={style}>{t(`deliveryTypes.${delivery?.type}`)}</Text>
      {isShipping && (
        <>
          <Text style={style}>{`${address}, ${city || ''}`}</Text>
          <Text>{`${state || ''}`}</Text>
        </>
      )}
    </View>
  );
};

const CustomerBlock = ({ customer }: any) => {
  if (!customer) {
    return null;
  }

  return (
    <View style={{ flex: 1 }}>
      <Text>{customer?.name}</Text>
      <Text>{customer?.phone}</Text>
    </View>
  );
};

const BusinessBlock = ({ store = {} }: any) => {
  const { name, address, city, state, whatsapp } = store;
  const style = { marginTop: 2 };

  return (
    <View
      style={{
        paddingVertical: 24,
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {store.logo && (
        <View style={styles.image}>
          <Image src={store.logo} />
        </View>
      )}
      <View>
        <Text>{name}</Text>
        {address && <Text style={style}>{`${address}, ${city}`}</Text>}
        {state && <Text style={style}>{state}</Text>}
        {whatsapp && <Text style={style}>{whatsapp}</Text>}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 24,
  },
  section: {
    flexDirection: 'row',
  },
  image: {
    width: 96,
    height: 96,
    backgroundColor: 'whitesmoke',
    marginRight: 12,
  },
});

export default Receipt;
