import { t } from 'i18next';

const getMainFields = (
  isEditing: boolean,
  locations: any[],
  enableEmployeeCustomLocation: boolean,
) => [
  {
    label: t('form.name'),
    name: 'name',
    type: 'text',
    rules: ['required', 'textOnly'],
  },
  {
    label: t('form.email'),
    name: 'email',
    type: 'text',
    disabled: isEditing,
  },
  {
    label: t('form.password'),
    name: 'password',
    type: 'password',
    hidden: isEditing,
  },
  {
    label: t('permissions.activateAccess'),
    name: 'active',
    type: 'boolean',
    default: true,
  },
  {
    label: t('stockLocation'),
    name: 'locationId',
    type: 'select',
    options: locations,
    hidden: !enableEmployeeCustomLocation,
  },
];

const getPermissionFields = () => [
  {
    label: t('permissions.registerProducts'),
    name: 'permissions.product_update',
    type: 'boolean',
    default: false,
  },
  {
    label: t('permissions.controlStock'),
    name: 'permissions.handle_stock',
    type: 'boolean',
    default: false,
  },
  {
    label: t('permissions.registerCustomers'),
    name: 'permissions.customer_update',
    type: 'boolean',
    default: false,
  },
  {
    label: t('permissions.viewOrdersOtherUsers'),
    name: 'permissions.view_all_orders',
    type: 'boolean',
    default: false,
  },
  {
    label: t('permissions.editOrdersOtherUsers'),
    name: 'permissions.edit_all_orders',
    type: 'boolean',
    default: false,
  },
  {
    label: t('permissions.editItemPriceInCart'),
    name: 'permissions.edit_item_price',
    type: 'boolean',
    default: false,
  },
  {
    label: t('permissions.giveDiscounts'),
    name: 'permissions.edit_order_discount',
    type: 'boolean',
    default: false,
  },
  {
    label: t('permissions.editOnlineCatalog'),
    name: 'permissions.edit_online_catalog',
    type: 'boolean',
    default: false,
  },
  {
    label: t('permissions.viewAnalytics'),
    name: 'permissions.view_analytics',
    type: 'boolean',
    default: false,
  },
];

export { getMainFields, getPermissionFields };
