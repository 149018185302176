import { SearchIcon } from '@heroicons/react/outline';

const Searchbar = ({
  value,
  onChange,
  placeholder,
  style,
}: {
  value?: string;
  onChange: (e: any) => void;
  placeholder: string;
  style?: any;
}) => {
  return (
    <div
      className="relative w-full text-gray-400 focus-within:text-gray-600 h-12"
      style={style}
    >
      <div className="absolute inset-y-0 left-2 flex items-center pointer-events-none">
        <SearchIcon className="h-5 w-5" aria-hidden="true" />
      </div>
      <input
        value={value}
        className="block w-full h-full pl-8 pr-3 py-2 ml-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
        type="search"
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

export default Searchbar;
