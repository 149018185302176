import { useAuth } from 'Auth/AuthProvider';
import React, { useEffect, useState } from 'react';
import { Settings } from 'types';
import { priceFormatter } from '@posy/helpers';
const { setCurrency } = priceFormatter;

interface ModalContextType {
  settings: Settings;
  setSettings: (settings: Settings) => void;
}

const ModalContext = React.createContext<ModalContextType>(null!);

const SettingsProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuth();
  const [settings, setSettings] = useState<Settings>({
    productListOrderBy: 'alphabetical',
    catalogLayout: 'list',
    allowVariations: false,
    hideCanceledOrders: false,
    hideOutOfStockItems: false,
    currency: 'CLP',
  });

  useEffect(() => {
    const store = user?.store;
    const settings = {
      currency: store?.settings?.currency || store?.currency,
      ...store?.settings,
    };
    if (settings) {
      updateSettings(settings);
    }
  }, [user?.id]);

  const updateSettings = (settings: Settings) => {
    setSettings(settings);
    setCurrency(settings?.currency);
  };

  const value: ModalContextType = {
    settings,
    setSettings: updateSettings,
  };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

function useSettings() {
  return React.useContext(ModalContext);
}

export { useSettings };
export default SettingsProvider;
