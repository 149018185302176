interface Props {
  label: string;
  progress: string;
  color?: string;
  labelColor?: string;
  bgColor?: string;
}

const ProgressBar = ({
  label,
  progress,
  color = 'white',
  labelColor = 'white',
  bgColor = 'gray',
}: Props) => {
  return (
    <div className="flex w-full items-center w-3/5">
      <div
        className="flex-1  rounded-full h-2 dark:bg-gray-700 mr-2"
        style={{ backgroundColor: bgColor }}
      >
        <div
          className="h-2 rounded-full"
          style={{ width: progress, backgroundColor: color }}
        ></div>
      </div>
      <p className="text-xs" style={{ color: labelColor }}>
        {label}
      </p>
    </div>
  );
};

export default ProgressBar;
