import { ReactNode } from 'react';

const PageHeader = ({ title, children }: Props) => {
  return (
    <div className="px-4 pt-6 flex mb-4">
      <div className="flex-1">
        <h1 className="text-2xl font-semibold text-gray-900">{title}</h1>
      </div>
      {children}
    </div>
  );
};

interface Props {
  title: string;
  children?: ReactNode;
}

export default PageHeader;
