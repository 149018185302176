import { useTranslation } from 'react-i18next';

const LoginHeader = () => {
  const { t } = useTranslation();

  return (
    <div className="mb-4 flex flex-col items-center">
      <img className="h-16 w-auto" src="posy_icon.png" alt="Logo" />
      <h2 className="mt-6 text-sm font-bold text-gray-600">{t('authTitle')}</h2>
    </div>
  );
};

export default LoginHeader;
