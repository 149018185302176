import React, { useEffect, useState } from 'react';

const PLACEHOLDER_URL = '/no-image.png';

const Image = ({
  src,
  alt,
  className,
}: {
  src: string;
  alt: string;
  className?: string;
}) => {
  const [imageUrl, setImageUrl] = useState<string>(PLACEHOLDER_URL);
  const onError = () => setImageUrl(PLACEHOLDER_URL);

  useEffect(() => {
    if (src) {
      setImageUrl(src);
    }
  }, [src]);

  return (
    <img src={imageUrl} onError={onError} alt={alt} className={className} />
  );
};

export default Image;
