import React from 'react';
import { Image } from '@posy/components';
import { PencilAltIcon, TagIcon, TrashIcon } from '@heroicons/react/outline';
import ActionMenu from '../../../components/ActionMenu';
import { useAnalytics } from '../../../providers/AnalyticsProvider';
import { useAuth } from '../../../Auth/AuthProvider';
import { priceFormatter } from '@posy/helpers';
const { toPriceTag, getItemGross } = priceFormatter;

const Item = ({
  item,
  onRequestPriceEdition,
  onRequestQuantityEdition,
  onRemoveItem,
  onItemLevelRender,
  onItemBadgeRender,
}: {
  item: any;
  onItemChange: (item: any) => void;
  onRemoveItem?: (item: any) => void;
  onRequestPriceEdition: (item: any) => void;
  onRequestQuantityEdition: (item: any) => void;
  onItemLevelRender: (item: any) => JSX.Element;
  onItemBadgeRender: (item: any) => JSX.Element;
}) => {
  const { logEvent } = useAnalytics();
  const { hasPermission } = useAuth();
  const { name, price } = item;
  const items = [
    {
      label: 'Editar cantidad',
      Icon: PencilAltIcon,
      onClick: () => {
        logEvent('edit_cart_item_quantity');
        onRequestQuantityEdition(item);
      },
    },
    {
      label: 'Editar precio',
      Icon: TagIcon,
      isVisible: hasPermission('EDIT_ITEM_PRICE'),
      onClick: () => {
        logEvent('edit_cart_item_price');
        onRequestPriceEdition(item);
      },
    },

    {
      label: 'Quitar del carro',
      Icon: TrashIcon,
      onClick: () => {
        logEvent('remove_cart_item');
        onRemoveItem && onRemoveItem(item);
      },
    },
  ];
  const filteredActions = items.filter(
    ({ isVisible = true }: any) => isVisible,
  );

  return (
    <div className="m-4">
      <div className="bg-white flex items-center">
        <div className="flex flex-1">
          <div className="bg-gray-300 w-12 h-12 rounded-md relative">
            {item.thumbnail ? (
              <Image
                className="h-12 w-12 absolute z-0"
                src={item.thumbnail}
                alt="Product logo"
              />
            ) : (
              <div className="flex justify-center items-center h-full h-12 w-12 absolute z-0">
                <p>{item.name.slice(0, 2)}</p>
              </div>
            )}

            {onItemBadgeRender && onItemBadgeRender(item)}
          </div>
          <div className="ml-3">
            <p className="">{name}</p>
            <p className="text-xs text-gray-500">{toPriceTag(price)}</p>
          </div>
        </div>
        <p className="mr-2 font-medium">{toPriceTag(getItemGross(item))}</p>
        <ActionMenu items={filteredActions} />
      </div>
      {onItemLevelRender && onItemLevelRender(item)}
    </div>
  );
};

export default Item;
