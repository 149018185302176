import keyBy from 'lodash/keyBy';
import { getCurrentTimestamp } from './helpers';
import { priceFormatter } from '@posy/helpers';
const { getItemGross } = priceFormatter;
const getFormattedCount = (quantity, format) => {
  const isFractionable = format === 'fraction';

  return isFractionable ? 1 : quantity;
};

const getCartResume = (cartItems) => {
  return cartItems.reduce(
    (obj, item) => {
      const { quantity, format } = item;
      obj.subtotal = obj.subtotal + getItemGross(item);
      obj.count = obj.count + getFormattedCount(quantity, format);

      return obj;
    },
    { subtotal: 0, count: 0 },
  );
};

const getDeliveryPayload = (delivery) => {
  if (!delivery) {
    return {};
  } else if (delivery.type === 'pickup') {
    return { delivery: { type: delivery.type } };
  } else {
    return { delivery };
  }
};

const getStatusPayload = (status) => {
  const createdAt = getCurrentTimestamp();

  return {
    status,
    timeline: [
      {
        status,
        createdAt,
      },
    ],
    createdAt,
  };
};
const getStorePayload = (store) => {
  const { id, name, logo, uniqueName } = store;
  return {
    storeId: id,
    store: {
      name,
      ...(logo ? { logo } : {}),
      ...(uniqueName ? { uniqueName } : {}),
    },
  };
};

const getUserPayload = (user) => {
  const { id, name, thumbnail } = user;
  return {
    uid: id,
    user: {
      name: name || '',
      ...(thumbnail ? { thumbnail } : {}),
    },
  };
};

const getItemsPayload = (cartItems, discount = 0) => {
  const { subtotal } = getCartResume(cartItems);
  const items = keyBy(cartItems, 'variationId');

  return {
    items,
    discount,
    subtotal,
    total: subtotal - discount,
  };
};

const getCustomerPayload = (customer) => {
  return customer
    ? {
        customerId: customer.id,
        customer: {
          name: customer.name,
          ...(customer.email ? { email: customer.email } : {}),
          ...(customer.phone ? { phone: customer.phone } : {}),
        },
      }
    : {};
};

const getNotesPayload = (notes) => {
  return notes?.length > 0 ? { notes } : {};
};

const getOrderPayload = (
  items,
  customer,
  delivery,
  store,
  user,
  notes,
  discount,
  locationId = null,
) => {
  const statusPayload = getStatusPayload('pending');
  const storePayload = getStorePayload(store);
  const customerPayload = getCustomerPayload(customer);
  const deliveryPayload = getDeliveryPayload(delivery);
  const itemsPayload = getItemsPayload(items, discount);
  const notesPayload = getNotesPayload(notes);
  const userPayload = getUserPayload(user);

  return {
    ...customerPayload,
    ...deliveryPayload,
    ...itemsPayload,
    ...storePayload,
    ...statusPayload,
    ...notesPayload,
    ...userPayload,
    locationId,
  };
};

export default getOrderPayload;
